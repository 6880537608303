import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ThemeService } from '../../../services/theme.service';
import { AppointmentRequest, Client, Equipment } from '../../../models';
import { AppointmentService } from '../../../services/appointment.service';
import { WksEntityParams, WksEntity, WksEntityImg } from '../../../models/shared.model';

@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.less'],
  providers: [AppointmentService],
})
export class AppointmentFormComponent implements OnChanges {
  @Input()
  wksEntityParams: WksEntityParams;
  @Input()
  wksEntity: WksEntity;
  @Input()
  wksLogoHome: WksEntityImg;
  @Input()
  countryFmt: any;

  appointmentRequestValidate: AppointmentRequest;
  step = 0;
  maxstep: number;
  client: Client = {
    stdEntity: '',
    lastName: '',
    firstName: '',
    companyName: '',
    phoneNumber: '',
    mailAddress: '',
    nbEquipments: 0
  };
  equipment: Equipment = {
    type: '',
    brand: '',
    model: '',
    serialNumber: '',
    nbHoursOfUse: 0,
    askedService: '',
    askedServiceHours: 0,
    equipmentNum: 0
  };
  appointmentRequest: AppointmentRequest;
  equipments: Equipment[];
  // this.equipments = [];
  idxEquip: number;
  themeIsDark: boolean;
  argCode = '';
  logoToShow: any;
  nbCols: number;
  nbEquipments: number;
  isRegistred: boolean;
  isCheckList: boolean;
  ctrlInput: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
  };

  constructor(private appointmentService: AppointmentService,
              private themeService: ThemeService,
              private translateService: TranslateService) {
    this.themeIsDark = true;
    // this.currentURL = window.location.href;
    // const pathCur = window.location.pathname;
    // this.argCode = pathCur.replace(/\//g, '');
    const pathCur = window.location.href.split('?');
    this.argCode = pathCur[1].replace(/=/g, '');
    // console.log(this.argCode);
    this.client.stdEntity = this.argCode;
  }
  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'wksEntityParams': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntityParams = changes['wksEntityParams'].currentValue;
            this.ctrlInput = this.wksEntityParams.ctrlInput;
            break;
          }
          case 'wksEntityImgCur': {
            // tslint:disable-next-line:no-string-literal
            this.wksLogoHome = changes['wksLogoHome'].currentValue;
            break;
          }

          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'countryFmt': {
            // tslint:disable-next-line:no-string-literal
            this.countryFmt = changes['countryFmt'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.isCheckList = false;
    this.idxEquip = 0;
    this.appointmentRequest = {
      client: this.client,
      equipments: undefined,
      equipmentLocation: '',
      equipmentPosition: '',
      equipmentJobsite: '',
      nbEquipments: 0,
      askedComment: '',
      appointmentStatut: '',
      appointmentsOtherdata: undefined,
      stdEntity: '',
    };
    this.appointmentRequest.stdEntity = this.argCode;
    this.appointmentRequest.appointmentStatut = 'quotation_waiting';

    this.setNbEquip(1);

    this.maxstep = 4;
    this.themeIsDark = this.themeService.isDarkTheme();
    this.themeService.setDarkTheme(this.themeIsDark);
    this.nbCols = 1;
    this.isRegistred = true;

    const widthCur = window.innerWidth;
    if (widthCur > 900) {
      this.nbCols = 2;
    }
    if (this.nbCols === 2) {
      this.createImageFromBlob(this.wksLogoHome);
    }
   }
    // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.logoToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;
    }
  toggleDarkTheme(event: any): void {
    this.themeIsDark = !this.themeIsDark;
    this.themeService.setDarkTheme(this.themeIsDark);
  }
  goStep(nextStep: number): void {

    this.step += nextStep;
    if (this.step < 0)  {
      this.step = 0;
    }
    if (this.step  === this.maxstep  )  {
      this.isRegistred = false;
      // this.step = nextStep;
      this.saveAppointmentRequest()
      .then ((reponseCur: any) => {
        this.isRegistred = true;
        this.appointmentRequestValidate = reponseCur;
      });
    }
  }
  setNbEquip(nbEquip: number): void {

    this.nbEquipments = nbEquip;
    if (!this.ctrlInput.multiEquipment)  {
      nbEquip = 1;
    }
    const equipments: Equipment[] = [];

    for (let nb = 0; nb < nbEquip; nb++) {
      const equipment: Equipment = {
          type: '',
          brand: '',
          model: '',
          serialNumber: '',
          nbHoursOfUse: 0,
          askedService: '',
          askedServiceHours: 0,
          equipmentNum: nb + 1
      };
      equipments.push(equipment);
    }
    this.appointmentRequest.equipments = equipments;
    this.maxstep = nbEquip + 3;
  }
  saveAppointmentRequest(): any {
    const otherData = {
      langCustomer: this.translateService.currentLang
    };
    this.appointmentRequest.nbEquipments = this.appointmentRequest.client.nbEquipments;
    this.appointmentRequest.appointmentsOtherdata = JSON.stringify(otherData);
    return new Promise((resolve, reject) => {
      this.appointmentService.save(this.appointmentRequest).subscribe(result => {
        console.log(result);
        const reponseCur = result.body;
        resolve(reponseCur);
      },
      err => {
        console.log(err);
        reject(err.status);
      });
    });
  }
}
