import { Component, OnInit, Input } from '@angular/core';
import { TranslateService  } from '@ngx-translate/core';
import { WksEntity, WksEntityImg, InternationalsFormats, CalendarEvt,
        CalendarEventBdd, WksEntityParams, EventChanged, WorkingTime, UserModel } from '../../../../models';
import { AuthService } from '../../../../services/auth.service';
import { CalendarService } from '../../../../services/calendar.service';
import { SharingDataService } from '../../../../services/sharing-data.service';
import moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { CommonMethods } from '../../../common/tools/commonMethods';
@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.less']
})
export class PlanningComponent implements OnInit {
  @Input()
  wksEntityParams: WksEntityParams;
  @Input()
  wksEntity: WksEntity;
  @Input()
  wksLogoHome: WksEntityImg;
  @Input()
  countryFmt: any;

  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;

  planningLoaded: boolean;
  eventsList: CalendarEvt[];
  calendardEventLBddist: CalendarEventBdd[];
  workingTime: WorkingTime;
  userCur: UserModel;

  constructor(private sharingDataService: SharingDataService,
              private translateService: TranslateService,
              private authService: AuthService,
              private calendarService: CalendarService) { }

  ngOnInit(): void {
    this.userCur = this.authService.getUserLogged();
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this.sharingDataService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }

      const userLang = navigator.language ;
      // this.localelang = userLang;
      this.localelang =  this.translateService.currentLang;
      this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
    }
    this.planningLoaded = false;
    this.eventsList = [];
    this.calendardEventLBddist = [];
    this.workingTime = {
      dayHours: this.wksEntityParams.dayHours,
      weekStartsOn: this.wksEntityParams.weekStartsOn,
      weekendDays: this.wksEntityParams.weekendDays,
    };

    this.loadEvents(this.wksEntity.stdEntity, this.userCur.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  }
  loadEvents(entityCur: string, actor: string): any {
    return new Promise((resolve, reject) => {
      this.calendarService.getEventsByActors(entityCur, actor, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        },
        err => {
          console.log('loadEvents' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  formatEvents(): void {
    this.eventsList = [];
    let evtId = 0;

    for (const eventCur of this.calendardEventLBddist) {
      let modifOption = false;
      if ((eventCur.calStatut === '') ||
        (eventCur.calStatut === null) ||
        (eventCur.calStatut === 'WAITING')) {
        modifOption = true;
      }
      if (eventCur.calDateStart === undefined) {
        eventCur.calDateStart = eventCur.calStart;
      }
      if (eventCur.calDateEnd === undefined) {
        eventCur.calDateEnd = eventCur.calEnd;
      }

      const otherDatas = JSON.parse(eventCur.calOtherdatas);
      this.eventsList.push({
        id: evtId,
        start: new Date(eventCur.calDateStart),
        end: new Date(eventCur.calDateEnd),
        title: eventCur.calTitle,
        allDay: false,
        resizable: {
          beforeStart: modifOption,
          afterEnd: modifOption
        },
        draggable: modifOption,
        linkId: eventCur.calLinkid,
        calType: eventCur.calType,
        bddId: eventCur.id,
        calStatut: eventCur.calStatut,
        ref: (otherDatas.appointmentCustomer && otherDatas.appointmentCustomer.ref) ? otherDatas.appointmentCustomer.ref : undefined,
        outside: eventCur.calOutside,
        stdCreationDate: eventCur.stdCreationDate,
        stdCreationUser: eventCur.stdCreationUser,
        oldTime: {
          start: new Date(eventCur.calDateStart),
          end: new Date(eventCur.calDateEnd),
        }
      });
      evtId++;
    }
  }
  refreshEvents($event: any): void {
    this.loadEvents(this.wksEntity.stdEntity, this.userCur.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  }
  eventClicked(event: EventChanged): void {
    // console.log('eventClicked ' + JSON.stringify(event));
    const eventBdd = this.fillModelBdd(event.eventCur);
    if (eventBdd.id !== undefined) {
      this.updateListEvents(eventBdd);
    }
    this.saveEvents(event.action, this.userCur.username, eventBdd)
    .then (
      (responseParams1: any ) => {
        // this.calendardEventList = responseParams1;
        // this.formatEvents(responseParams1);
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('saveEvents error : ' + err);
        }
      }
    );
  }
  updateListEvents(calendarEventBdd: CalendarEventBdd): void {
    let idxRow = 0;
    for (const eventTmp of this.calendardEventLBddist) {
      if (eventTmp.id === calendarEventBdd.id ) {
        this.calendardEventLBddist.splice(idxRow, 1);
        this.calendardEventLBddist.push(calendarEventBdd);
        break;
      }
      idxRow ++ ;
    }
    this.formatEvents();
  }
  fillModelBdd(eventCur: CalendarEvt): CalendarEventBdd {
    let idxTmp;
    if (CommonMethods.isUUID(eventCur.id.toString())) {
      idxTmp = eventCur.id.toString();
    }
    if (idxTmp === undefined) {
      try {
        const idxCur = Number.parseInt(eventCur.id.toString(), 10);
        idxTmp = (!idxCur ? eventCur.id.toString() : undefined );
        // idxTmp = eventCur.id.toString();
      } catch (error) {
        idxTmp = undefined;
      }
    }
    const idCur = (idxTmp ? idxTmp : eventCur.bddId ? eventCur.bddId : undefined);
    const appointmentCustomer = {ref:  eventCur.ref};
    const othersData = ' {"appointmentCustomer" :' + JSON.stringify(appointmentCustomer) +
                            ',"actorMail" : "' + this.userCur.email + '"}';
    const dateStartTime: any = moment(eventCur.start).format(environment.fmtDateTimeBdd);
    const dateEndTime: any = moment(eventCur.end).format(environment.fmtDateTimeBdd);
    const calendarEventBdd: CalendarEventBdd = {
      id: idCur,
      stdEntity: this.wksEntity.stdEntity,
      calApplication: 'appointmentCustomer',
      calType: eventCur.calType,
      calLinkid: eventCur.linkId,
      calStart: dateStartTime,
      calEnd: dateEndTime,
      calAction: '',
      calTitle: eventCur.title,
      calOutside: eventCur.outside,
      calDescription: eventCur.description,
      calComment: eventCur.comment,
      calAllday: false,
      calActor:  this.userCur.username,
      calOtherdatas: othersData,
      calStatut: eventCur.calStatut,
      stdCreationDate: eventCur.stdCreationDate,
      stdCreationUser: eventCur.stdCreationUser,
    };

    return calendarEventBdd;
  }
  saveEvents(action: string, actor: string, calendarEventBdd: CalendarEventBdd): any {
    let actionCur = '';
    if (action === 'TimeChanged') {
      actionCur = 'updateDate';
    }
    return new Promise((resolve, reject) => {
      this.calendarService.saveWksCalendar(calendarEventBdd, this.wksEntity.entityTz, actionCur, this.userCur.email)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        },
        err => {
          console.log('saveEvents ' + err.message);

          reject(err.status);
        }
      );
    });
  }
}
