 
<div #refElement class="container-fluid stdTheme workCardContent"
    [ngStyle]="{margin: '0px', 'margin-top': '10px', width: '100%', padding: '0px'}" >
    <div class="row justify-content-around" style="margin-left: 0px;margin-right: 0px;">
        <div class="col-11 stdTheme">      
            {{nameParent}}
        </div>
        <!--
        <div class="col-1 container-fluid d-flex justify-content-end" style="padding-left: 0px; padding-right: 0px;">
            <button *ngIf="!isReadOnly" mat-icon-button (click)="addContact()">
                <span class="fa fa-plus-square" 
                    style="margin-left: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 30px" >
                </span>
            </button>
        </div>
        -->
    </div>
    <div *ngIf="isLoaded"  [style.overflow]="'auto'">
        <!--<ng-template ngFor let-iRow="index" let c = "count" let-contactCur [ngForOf]="listContacts">-->
        <div  *ngFor= "let contactCur of listContacts; let iRow=index; let c=count;let isLast=last" >
            <div class="container-fluid" style="margin: 0px; padding: 0px;"  >
                <div id="contactCur_{{iRow}}" #contactCur_{{iRow}} class="row rowLine" style="line-height: 30px; " >
                    <!--
                    <div class="col-1" style="margin-top: 10px;">
                        <button *ngIf="!isReadOnly" mat-icon-button (click)="removeContact(iRow) ">
                            <span class="fa fa-trash" style="font-size: 15px" ></span>
                        </button>      
                    </div>
                    -->
                    <div class="col-lg-10 labelLine" style="margin-top: 10px;">
                        <span style="margin: 0px;"><h4>{{ contactCur.identity.cntFirstname }} {{ contactCur.identity.cntLastname }} </h4> </span>               
                    </div>
                    <div class="col-lg-1" style="margin-top: 10px;">
                        <button mat-icon-button
                            (click)="contactCur.isCollapsed = !contactCur.isCollapsed"
                            [attr.aria-expanded]="!contactCur.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="contactCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="!contactCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                    </div>

                </div>             
                <div  [ngbCollapse]="contactCur.isCollapsed">
                    <div class="flex-row row"  style="margin: 0px; margin-top: 0px;">
                        <app-address-block style="margin-left: 5px;" [config]="configContacts" [shareData]="shareData" [dataIn]="getJsonContact(contactCur, iRow)" (dataOut)="dataOutContact($event,iRow)"></app-address-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
