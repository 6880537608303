import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { Alert, AlertType } from '../../../models/shared.model';

@Component({
  selector: 'app-alert-common',
  templateUrl: './alert-common.component.html',
  styleUrls: ['./alert-common.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertCommonComponent implements OnInit {

  alerts: Alert[] = [];

  @Input() messageInfo: string;
  @Input() data1: string;
  @Input() data2: string;
  @Input() messageType: string;
  @Input() alertType: string;
  @Input() actionCur: string;
  isOkToDisplay: boolean;

  constructor(private translate: TranslateService,
                private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isOkToDisplay = false;
    const currentAlert = new Alert();
    currentAlert.type = this.getAlertType(this.messageType);
    currentAlert.message = this.messageInfo;
    currentAlert.data1 = this.data1;
    currentAlert.data2 = this.data2;
    currentAlert.alertType = this.alertType;
    currentAlert.htmlVar = this.sanitizer.bypassSecurityTrustHtml(this.messageInfo);

    this.alerts.push(currentAlert);
    this.isOkToDisplay = true;
  }
  removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter(x => x !== alert);
}

getAlertType(messageType: string): any {

    switch (messageType) {
        case 'SUCCESS':
            return AlertType.Success;
        case 'ERROR':
            return AlertType.Error;
        case 'INFO':
            return AlertType.Info;
        case 'IMPORTANT':
            return AlertType.Important;
        case 'WARNING':
            return AlertType.Warning;
    }
}
cssClass(alert: Alert): string {
    if (!alert) {
        return '';
    }

    // return css class based on alert type
    switch (alert.type) {
        case AlertType.Success:
            return 'alert alert-success';
        case AlertType.Important:
            return 'alert alert-primary';
        case AlertType.Error:
            return 'alert alert-danger';
        case AlertType.Info:
            return 'alert alert-info';
        case AlertType.Warning:
            return 'alert alert-warning';
    }
  }
}
