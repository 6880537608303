import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { IhmOptions, ContactInt, ShareDataInt } from '../../../../../models';

@Component({
  selector: 'app-work-contacts',
  templateUrl: './work-contacts.component.html',
  styleUrls: ['./work-contacts.component.less']
})
export class WorkContactsComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() dataIn: any;
  @Input() config: any;
  @Input() nameParent: any;
  @Output() dataOut = new EventEmitter<any[]>();
  @Input() optionItem: string;
  @Input() shareData: ShareDataInt;
  @Input() ihmOptions: IhmOptions;

  private readonly onDestroy = new Subject<void>();
  isLoaded: boolean;
  listContacts: ContactInt[];
  listContactsInput: ContactInt[];
  contactTest: ContactInt;
  configContacts: any;

  dataOrig: any;
  dataCur: any;
  isDataIn: boolean;
  nRow: number;
  isReadOnly: boolean;

  constructor(private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.initData();
    this.isLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listContactsInput = this.dataIn;
    if (this.listContactsInput === undefined) {
      this.listContacts = [];
      this.listContactsInput = [];
      this.isDataIn = false;
    } else {
      this.dataOrig = Object.assign('', this.dataCur);
      this.listContacts = this.dataIn;
    }
    this.initData();
    this.isLoaded = true;
  }
  ngAfterContentInit(): void {

  }
  initData(): void {

    this.configContacts = Object.assign('', this.config);
    this.configContacts.address = false;
    this.configContacts.telephone = true;
    this.configContacts.contact = true;
    this.configContacts.email = true;
    this.configContacts.isReadOnly = this.ihmOptions.readOnly;
    this.isReadOnly = this.ihmOptions.readOnly;

  }
  getJsonContact(contactCur: ContactInt, nRow: number): ContactInt {
    this.nRow = nRow;
    return contactCur;
   // return JSON.stringify(contactCur);
  }

  dataOutContact(jsonData: any, iRow: number): void {
    // const jsonData = JSON.parse($event);
    this.listContacts[iRow].identity = jsonData.identity;
    this.listContacts[iRow].phones = jsonData.phones;
    this.listContacts[iRow].emails = jsonData.emails;
    this.listContacts[iRow].selectApps = jsonData.selectApps;

    if ((this.listContacts[iRow].statut === 'new') && ( this.listContactsInput[iRow] === undefined)) {
        this.listContactsInput.push(this.listContacts[iRow]);
    }
    if (this.listContacts[iRow].statut === 'new') {
      this.listContactsInput[iRow] = this.listContacts[iRow];
    } else {
      for (let contactInput of this.listContactsInput) {
        let exists = false;
        if (contactInput.statut === 'exists') {
          for (const contact of this.listContacts) {
            if (contact.id === undefined) {
              continue;
            }
            if (contactInput.id === contact.id) {
              exists = true;
              contactInput = contact;
              break;
            }

          } // end loop listContacts
          if ((!exists) && (contactInput.statut !== 'new')) {
            contactInput.statut = 'remove';
          }
        } // end test exists

      } // end loop listContactsInput
    }
    this.dataOut.emit(this.listContactsInput);
  }
}
