import { FormGroup, FormControl, FormGroupDirective, NgForm, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * https://obsessiveprogrammer.com/validating-confirmation-fields-in-angular-reactive-forms-with-angular-material/
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {
    /**
     * Validates that child controls in the form group are equal
     */
    /*
  static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
      const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
      const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
      return isValid ? null : { childrenNotEqual: true };
  }
*/// example 
  priceValidator: ValidatorFn = (priceControl: AbstractControl)
  :  ValidationErrors | null => {
    
    return priceControl.value > 500 ? null : { tooLittleBudget: true }
  }
    /**
     * Validates that child controls in the form group are equal
     */
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ childrenNotEqual: true });
        return { childrenNotEqual: true };
      } else {
        return null;
      }
    };
  }

  static areEqual(formGroup: FormGroup) {
    let value: any;
    let valid = true;
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (value === undefined) {
          value = control.value;
        } else {
          if (value !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }
    if (valid) {
      return null;
    }
    return {
      areEqual: true
    };
  }
  /**
   * https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/
   * @param formGroup 
   */
  static wordsValid(formGroup: FormGroup) {
    const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
    const isValid = otherControlNames.every(function (controlName): boolean { 
      if ((!formGroup.get(controlName).value) || (formGroup.get(controlName).value === null)) {
        return true;
      }
      const reg = new RegExp('(\\W|^)(' + formGroup.get(controlName).value + ')(\\W|$)', 'gim');
      return !reg.test(formGroup.get(firstControlName).value);
      });
    return isValid ? null : { wordsNotValid: true };
  }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.parent.invalid && control.touched;
    }
}

/**
* Collection of reusable RegExps
*/
export const regExps: { [key: string]: RegExp } = {
   password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
    fullName: 'Full name must be between 1 and 128 characters',
    email: 'Email must be a valid email address (username@domain)',
    confirmEmail: 'Email addresses must match',
    password: 'Password must be between 7 and 15 characters, and contain at least one number and special character',
    confirmPassword: 'Passwords must match'
};
