import { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable,  } from '@angular/core';
import { GeoValues, LatLng } from '../models/geoLocation.model';


@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  geocoder: google.maps.Geocoder;
  constructor() {
      this.geocoder = new google.maps.Geocoder();
  }

  latLngToAddress(latLng: google.maps.LatLng): Observable<GeoValues> {
    const latLngCur: LatLng = {
      lat: latLng.lat(),
      lng:  latLng.lng(),
    };
    const returnValue: GeoValues =  {
      latLng: latLngCur,
    };
    return new Observable((observer: Observer<GeoValues>) => {
        // Invokes geocode method of Google Maps API geocoding.
        this.geocoder.geocode({ location: latLng }, (
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                returnValue.status = status;
                if (status === google.maps.GeocoderStatus.OK) {
                    returnValue.addresses = [];
                    for (const place of results ) {
                      try {
                        returnValue.addresses.push(place.formatted_address);
                      } catch  {
                        continue;
                      }
                    }
                    observer.next(returnValue);
                    observer.complete();
                } else {
                    console.log('Geocoding service: geocoder failed due to: ' + status);
                    observer.error(returnValue);
                }
            })
        );
    });
  }

  addressTolatLng(addressCur: string): Observable<GeoValues> {
    const returnValue: GeoValues =  {
      address: addressCur,
    };
    return new Observable((observer: Observer<GeoValues>) => {
        // Invokes geocode method of Google Maps API geocoding.
        this.geocoder.geocode({ address: addressCur }, (
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                returnValue.status = status;
                if (status === google.maps.GeocoderStatus.OK) {
                  returnValue.latLngs = [];
                  returnValue.addresses = [];
                  for (const place of results ) {
                    try {
                      returnValue.latLngs.push(place.geometry.location.toJSON() as LatLng);
                      returnValue.addresses.push(place.formatted_address);
                    } catch  {
                      continue;
                    }
                  }
                  observer.next(returnValue);
                  observer.complete();
                } else {
                    console.log('Geocoding service: geocode was not successful for the following reason: ' + status);
                    observer.error(returnValue);
                }
            })
        );
    });
  }
}
