import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { ContactInt, WksBusinessModel } from '../../../../../models';
import { AuthService } from '../../../../../services/auth.service';

@Component({
  selector: 'app-work-customer',
  templateUrl: './work-customer.component.html',
  styleUrls: ['./work-customer.component.less']
})
export class WorkCustomerComponent implements OnInit {

  @Input() workCustomer: WksBusinessModel;

  clientForm: FormGroup;
  isCompany: boolean;
  isPerson: boolean;
  isAdministration: boolean;
  isProfessionnal: boolean;
  configAddress: any;
  addressCur: any;
  listContacts: ContactInt[];

  constructor(private translateService: TranslateService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.initData();
  }
  initData(): void {
    this.initBooleans();
    switch (this.workCustomer.bsnType) {
      case 'company': {
        this.isCompany = true;
        break;
      } // end case
      case 'administration': {
        this.isAdministration = true;
        break;
      } // end case
      case 'person': {
        this.isPerson = true;
        break;
      } // end case
    }
    this.configAddress = {
      langCur: this.translateService.getBrowserLang,
      countryDefault: this.authService.getUserLogged().entitycountry,
      address: true,
      telephone: false,
      email: false,
      contact: false,
      corporate: true,
      identifier: true,
      isReadOnly: true,
      fieldsId: []
    };
    if ((this.workCustomer.bsnType === 'company') || (this.workCustomer.bsnType === 'administration')) {
      this.configAddress.address = true;
      this.configAddress.telephone = false;
      this.configAddress.contact = false;
      this.configAddress.corporate = true;
      this.configAddress.email = false;
      this.configAddress.identifier = 'professional';
      this.addressCur = JSON.parse(this.workCustomer.bsnCompany);
    } else {
      this.isProfessionnal = false;
      if (this.workCustomer.bsnTax === 'professional') {
        this.isProfessionnal = true;
      }
      this.configAddress.address = true;
      this.configAddress.telephone = true;
      this.configAddress.contact = true;
      this.configAddress.corporate = false;
      this.configAddress.email = true;
      this.configAddress.identifier = this.workCustomer.bsnTax;
      this.addressCur = JSON.parse(this.workCustomer.bsnPerson);
      if (this.workCustomer.dataContacts !== undefined) {
        const listContacts =  this.workCustomer.dataContacts;
        if (listContacts !== undefined && listContacts !== null) {
          const contactCur = listContacts[0];
          this.addressCur.emails = JSON.parse(contactCur.cntMails);
          this.addressCur.phones = JSON.parse(contactCur.cntPhones);
        }
      }
    }
    this.buildForm();
  }
  initBooleans(): void {
    this.isCompany = false;
    this.isPerson = false;
    this.isAdministration = false;
    this.isProfessionnal = false;
  }
  buildForm(): void {
    this.clientForm = new FormGroup({});
    this.clientForm.addControl('bsnName', new FormControl({value: '', disabled: true}));

    this.fillForm();
  }
  fillForm(): void {
    this.clientForm.controls.bsnName.setValue(this.workCustomer.bsnName);
  }
  dataOutAddress(event: any): void {

  }
}
