
<div class="stdTheme" style="margin-top: 5px; width: 100%;">

  <div *ngIf="!displayRequest && !displayEvent && !displayChecklist">
    <app-calendar-header
      [(view)]="viewHeader"
      [(viewDate)]="viewDate"
      [locale]="locale"
      [sizeWidth]="sizeWidth"
      [userName]="userCur.username"
      (viewChange)="viewChange($event)"
      (todoRefresh)="refreshEvents($event)">
    </app-calendar-header>

    <div [ngSwitch]="viewCur" >

        <mwl-calendar-month-view 
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [openDayEventsTemplate]="customOpenDayEventsTemplate"
            [cellTemplate]="customMonthCellTemplate"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeMonthViewRender($event)"
            [excludeDays] = "weekendDays"
            >
        </mwl-calendar-month-view>
        
        <mwl-calendar-week-view 
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [eventTitleTemplate]="eventTitleTemplate2"
            [tooltipTemplate]="eventTitleTemplate2"
            [refresh]="refresh"
            [dayStartHour]="8"
            [dayEndHour]="20"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (beforeViewRender)="beforeWeekViewRender($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            [excludeDays] = "weekendDays"
            >
        </mwl-calendar-week-view>
        <!-- https://angular-calendar.com/docs/components/CalendarDayViewComponent.html -->
        <mwl-calendar-day-view 
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [refresh]="refresh"
            [eventTitleTemplate]="eventTitleTemplate2"
            [tooltipTemplate]="eventTitleTemplate2"
            [hourSegments]="4"
            [dayStartHour]="8"
            [dayEndHour]="20"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (hourSegmentClicked)="hourSegmentClicked($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeDayViewRender($event)"
            >
        </mwl-calendar-day-view>
    </div>
  </div>
  <div *ngIf="displayRequest">
    <app-request
      [appointmentRequest]="appointmentRequest"
      [parentForm]="'fullScreen'"
      (dataOut)="closeModal($event)"
    >
    </app-request>
  </div>
  <div *ngIf="displayChecklist">
    <app-checklist
      [appointmentRequest]="appointmentRequest"
      [parentForm]="'fullScreen'"
      (dataOut)="closeModal($event)"
    >
    </app-checklist>
  </div>
  <div *ngIf="displayEvent">
    <div>
      <mat-card class="stdTheme"  style="padding: 0px; padding-right: 5px; padding-left: 5px; " [style.height]="cardMaxHeight">
        <mat-card-header>
          <mat-card-title>
            <div class="container-fluid d-flex justify-content-between" >
              <h3 ><strong>{{ 'CALENDAR_EVENT.eventDetail' | translate }}</strong></h3>
              <h3 style="margin-left: 20px" *ngIf="isAppointmentCustomer">{{eventCur.ref}}</h3>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-card-content-event" [style.height]="contentMaxHeight">
          <ng-container [ngTemplateOutlet]="templateDetailEventForm" ></ng-container> 
        </mat-card-content>
        <mat-card-actions  [style.margin-top]="actionMargin">
          <div class="container-fluid d-flex justify-content-between" >
              <button type="button" mat-raised-button (click)="closeModal('canceled')">
                <mat-icon>arrow_back</mat-icon>
                {{ 'COMMUN.BUTTON.CLOSE' | translate }}
              </button>

              <button type="button" *ngIf="detailEventForm.valid && detailEventForm.dirty && timeIsValid" mat-raised-button (click)="validEvent()">
                <mat-icon>save</mat-icon>
                {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
              </button>
            </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #modalDetailEvent let-close="close" let-event="event">
  <div class="modal-header" style="padding: 10px">
    <h3 class="modal-title"><strong>{{ 'CALENDAR_EVENT.eventDetail' | translate }}</strong></h3>
    <h3 class="modal-title" style="margin-left: 20px" *ngIf="isAppointmentCustomer">{{eventCur.ref}}</h3>

    <button type="button" class="close" (click)="closeModal('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body" style="padding: 0px">
    <ng-container [ngTemplateOutlet]="templateDetailEventForm" ></ng-container> 
  </div>
  <div class="modal-footer" >
    <div class="flex-row row w-100"  style="padding: 0px; margin: 0px;">
      <div class="col-sm-4" style="text-align: start">
        <button type="button" mat-raised-button (click)="closeModal('canceled')">
          <mat-icon>arrow_back</mat-icon>
          {{ 'COMMUN.BUTTON.CLOSE' | translate }}
        </button>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4"  style="text-align: end">
        <button type="button" *ngIf="detailEventForm.valid && detailEventForm.dirty && timeIsValid" mat-raised-button (click)="validEvent()">
          <mat-icon>save</mat-icon>
          {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
        </button>
      </div>
    </div> 
  </div>
</ng-template>
<ng-template #templateDetailEventForm >
  <div class="container-fluid" class="stdTheme" style="padding: 0px;">
    <form [formGroup]="detailEventForm" > 
      <div class="row" style= "margin: 0px;" *ngIf="isAppointmentCustomer">
        <div class="col-sm-3" style="height: 60px!important">
          <mat-form-field class="mdiinput form-group" appearance="standard" >
              <mat-label>{{ 'CALENDAR_EVENT.eventTitle' | translate }}</mat-label>
              <input id="eventTitle" #eventTitle class="form-control inputTheme" 
                      formControlName="eventTitle" required
                          matInput placeholder="{{ 'CALENDAR_EVENT.eventTitle' | translate }}"
                          >
          </mat-form-field>
        </div>
        <div class="col-sm-3" style="height: 60px!important">
          <mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%!important;">
              <mat-label>{{ 'CALENDAR_EVENT.eventType' | translate }}</mat-label>
              <input id="eventType" #eventType class="form-control inputTheme" 
                      formControlName="eventType" required readonly matInput  >
          </mat-form-field>
        </div>
        <div class="col-sm-5" style="height: 60px!important" *ngIf="isAppointmentCustomer">
          <mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%!important;">
              <mat-label>{{ 'CALENDAR_EVENT.eventOutside' | translate }}</mat-label>
              <input id="eventOutside" #eventOutside class="form-control inputTheme" 
                      formControlName="eventOutside" required
                          matInput placeholder="{{ 'CALENDAR_EVENT.eventOutside' | translate }}"
                          >
          </mat-form-field>
        </div>
        <div class="col-sm-3" style="height: 60px!important" *ngIf="applicationCall === 'rhPlanning'">
          <mat-form-field appearance="standard"  style="width: 100%!important;">
            <mat-label>{{ 'CALENDAR_EVENT.eventTitle' | translate }}</mat-label>
            <mat-select id="language" placeholder="{{ 'Choose equipment' | translate }}" formControlName="eventTitle" 
                      #eventTitle required
                      (ngModelChange)="onChangeTitle('rhPlanning', eventTitle.value)"
                      ngDefaultControl >
                      <mat-option class="selectOptionsTheme" *ngFor="let eventRh of eventsRh" 
                                  [value]="eventRh.label"  >{{ eventRh.label | translate }}</mat-option>
            </mat-select>         
          </mat-form-field>
        </div>

      </div>
      
      <div class="flex-row row" style= "margin: 0px;">
        <div class="col-sm-5" style="height: 60px!important">
          <mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%!important;">
              <mat-label>{{ 'CALENDAR_EVENT.eventDescription' | translate }}</mat-label>

              <textarea matInput
                cdkTextareaAutosize
                id="eventDescription"
                class="form-control inputTheme"
                formControlName="eventDescription" 
                #eventDescription="cdkTextareaAutosize"
                placeholder="{{ 'CALENDAR_EVENT.eventDescription' | translate }}"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3">
              </textarea>
          </mat-form-field>
        </div>
        <div class="col-sm-1" ></div>
        <div class="col-sm-5" style="height: 60px!important;">
          <mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%!important;">
              <mat-label>{{ 'CALENDAR_EVENT.eventComment' | translate }}</mat-label>
              <textarea matInput
                cdkTextareaAutosize
                id="eventComment"
                class="form-control inputTheme"
                formControlName="eventComment" 
                #eventComment="cdkTextareaAutosize"
                placeholder="{{ 'CALENDAR_EVENT.eventComment' | translate }}"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3">
              </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row row" style= "margin: 0px;">
        <div class="col-sm-6" style="padding-left: 0px;padding-right: 0px;">
          <div class="flex-row row" style= "margin: 0px;">
            <div class="col-sm-5" style="height: 60px!important">
              <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-right: -20px!important;">
                <mat-label>{{ 'CALENDAR_EVENT.eventStart' | translate }}</mat-label>

                <input matInput  class="form-control inputTheme" 
                              formControlName="eventStartDate"
                              #eventStartDate 
                              placeholder="{{ 'CALENDAR_EVENT.eventStart' | translate }}" 
                              [max]="maxInputDate" 
                              [min]="minInputDate"  
                              [matDatepickerFilter]="filterDate"
                              [matDatepicker]="eventStartDatePicker" 
                              (dateChange)="validStatutDate($event, 'eventStartDate')" 
                              readonly required style="text-align: center">
                <mat-datepicker-toggle matSuffix [for]="eventStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker name="eventStartDatePicker" #eventStartDatePicker ></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-sm-4" style="height: 60px!important;">

              <mat-form-field  class="mdiinput form-group" appearance="standard">
                <input matInput [ngxTimepicker]="toggleStartTimepicker" [disableClick]="true" readonly required #eventStartTime
                        formControlName="eventStartTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]"
                        class="form-control inputTheme"  style="text-align: center">

                  <button mat-icon-button matSuffix (click)="toggleStartTimepicker.open(); $event.stopPropagation();">
                    <mat-icon style="margin-top: -10px">access_time</mat-icon>
                  </button>
                  <ngx-material-timepicker name="toggleStartTimepicker" (timeChanged)="changeTime('start', $event)" (timeSet)="validTimeInput('start',$event, true)" #toggleStartTimepicker></ngx-material-timepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-6" style="padding-left: 0px;padding-right: 0px;height: 60px!important;">
          <div class="flex-row row" style= "margin: 0px;">
            <div class="col-sm-5" style="height: 60px!important">
              <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-right: -20px!important;">
                <mat-label>{{ 'CALENDAR_EVENT.eventEnd' | translate }}</mat-label>

                <input matInput  class="form-control inputTheme" 
                              formControlName="eventEndDate"
                              #eventEndDate 
                              placeholder="{{ 'CALENDAR_EVENT.eventEnd' | translate }}" 
                              [max]="maxInputDate" 
                              [min]="minInputDate"  
                              [matDatepickerFilter]="filterDate"
                              [matDatepicker]="eventEndDatePicker" 
                              (dateChange)="validStatutDate($event, 'eventEndDate')" 
                              readonly required style="text-align: center">
                <mat-datepicker-toggle matSuffix [for]="eventEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker name="eventEndDatePicker" #eventEndDatePicker ></mat-datepicker>
              </mat-form-field>
            </div>
            
            <div class="col-sm-5" style="height: 60px!important" >
              <mat-form-field  class="mdiinput form-group" appearance="standard">
                <input matInput [ngxTimepicker]="toggleEndTimepicker" [disableClick]="true" readonly required
                        formControlName="eventEndTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]" 
                        class="form-control inputTheme" style="text-align: center">
                <!-- https://github.com/h2qutc/angular-material-components/issues/170 -->
                <button mat-icon-button matSuffix (click)="toggleEndTimepicker.open(); $event.stopPropagation();">
                  <mat-icon style="margin-top: -10px">access_time</mat-icon>
                </button>
                <ngx-material-timepicker name="toggleEndTimepicker" (timeChanged)="changeTime('end',$event)" (timeSet)="validTimeInput('end',$event, true)" #toggleEndTimepicker></ngx-material-timepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</ng-template>
<ng-template #modalAppointment >
  <div class="modal-header" style="padding: 10px">
    <!--<h3 class="modal-title">{{ 'CALENDAR_EVENT.eventDetail' | translate }}</h3>
    <h3 class="modal-title" style="margin-left: 20px" >{{eventCur.ref}}</h3>-->
    <button type="button" class="close" (click)="closeModal('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body" style="padding: 0px">
    <app-request [appointmentRequest]="appointmentRequest" [parentForm]="'modal'"></app-request>
  </div>
  <div class="modal-footer" style="margin-top: -10px">
    <div class="flex-row row w-100">
      <div class="col-2" style="text-align: start">
        <button type="button" mat-raised-button (click)="closeModal('canceled')">
          <mat-icon>arrow_back</mat-icon>
          {{ 'COMMUN.BUTTON.CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>

</ng-template>
<ng-template #modalChecklist >
  <div class="modal-header" style="padding: 10px">
    <!--<h3 class="modal-title">{{ 'CALENDAR_EVENT.eventDetail' | translate }}</h3>
    <h3 class="modal-title" style="margin-left: 20px" >{{eventCur.ref}}</h3>-->
    <button type="button" class="close" (click)="closeModal('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body" style="padding: 0px; height: 100%; max-height: 100%;overflow: hidden!important;">
    <app-checklist [appointmentRequest]="appointmentRequest" [parentForm]="'modal'"></app-checklist>
  </div>
  <div class="modal-footer" style="margin-top: -10px">
    <div class="flex-row row w-100">
      <div class="col-2" style="text-align: start">
        <button type="button" mat-raised-button (click)="closeModal('canceled')">
          <mat-icon>arrow_back</mat-icon>
          {{ 'COMMUN.BUTTON.CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>

</ng-template>
<ng-template
  #customOpenDayEventsTemplate
  let-events="events"
  let-isOpen="isOpen">

  <div class="cal-open-day-events stdTheme" *ngIf="isOpen">
      <div *ngFor="let event of events" [ngClass]="event?.cssClass">
        <span class="cal-event" [style.backgroundColor]="event.color?.primary"></span>
        <mwl-calendar-event-title [event]="event" view="month" [customTemplate]="eventTitleTemplate"> </mwl-calendar-event-title>
        <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
      </div>
      <!--
      <div class="flex-row row" style= "margin: 0px;padding: 0px;">
        <p style= "margin: 0px;padding: 0px;">
          <button mat-icon-button class="buttonTheme" 
            style="margin-left: 0px;margin-right: 0px;height:20px !important;width:20px !important;font-size:20px !important;" 
              (click)="addEvent(viewDate)">
            <span class="fa fa-plus-square" style="font-size: 20px;margin-top: -20px" ></span>
          </button>
        </p>
      </div>
-->
  </div>

</ng-template>
<ng-template #eventTitleTemplate2 let-event="event" let-view="view">

  <span >
    {{getEventItem2(event)}}
  </span>
</ng-template>
<ng-template #eventTitleTemplate let-event="event" let-view="view">

  <span >
    {{getEventItem(event)}}
  </span>
</ng-template>
<ng-template #customMonthCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked">
  <div class="cell-border stdTheme" style="height: 100%">
    <div class="cal-cell-top" [class.todayLoc]="day.isToday" [class.stdTheme]="!day.isToday">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>

    </div>
    <div class="cell-totals stdTheme" *ngIf="day.events.length > 0">
      <span
        *ngFor="let group of day.eventGroups"
        class="badge badge-{{ group[0] }}">
        {{ group[1].length }}
      </span>
    </div>
    <div class="cal-events stdTheme" style="margin: 0px; padding: 3px" *ngIf="day.events.length > 0">
      <div
        class="cal-event"
        *ngFor="let event of day.events"
        [ngStyle]="{ backgroundColor: event.color?.primary }"
        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({ event: event })"
        (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        mwlDraggable
        [class.cal-draggable]="event.draggable"
        dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }"
        [dragAxis]="{ x: event.draggable, y: event.draggable }"
        [validateDrag]="validateDrag"
        [touchStartLongPress]="{ delay: 300, delta: 30 }"
        (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
        [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"
      ></div>
    </div>

  </div>
</ng-template>
<!-- https://github.com/mattlewis92/angular-calendar/issues/370
  exemple customisation openDayEentsTemplate
<ng-template
  #openDayEventsTemplate
  let-events="events"
  let-eventClicked="eventClicked">
  <div
    *ngFor="let event of events"
    [ngClass]="event?.cssClass"
    mwlDraggable
    [dropData]="{event: event}"
    [dragAxis]="{x: event.draggable, y: event.draggable}">
    <span
      class="cal-event"
      [style.backgroundColor]="event.color.primary">
    </span>
    <mwl-calendar-event-title
      [event]="event"
      [customTemplate]="eventTitleTemplate"
      view="month"
      (mwlClick)="eventClicked.emit({event: event})">
    </mwl-calendar-event-title>
    <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
  </div>
</ng-template>
-->
<!-- https://stackoverflow.com/questions/53063562/more-than-one-badge-on-a-cell-angular-calendar
  <ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <div class="cell-totals">
    <span
      *ngFor="let group of day.eventGroups"
      class="badge badge-{{ group[0] }}">
      {{ group[1].length }}
    </span>
  </div>
</ng-template>
-->
<!--
<ng-template #ngbModalDetailEvent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Hello, World!</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
  </div>
</ng-template>
-->
<ng-template #ngbModalDetailEvent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'CALENDAR_EVENT.eventDetail' | translate }}</h5>
    <h5 class="modal-title" style="margin-left: 20px" *ngIf="isAppointmentCustomer">{{eventCur.ref}}</h5>

    <button type="button" class="close" (click)="closeModal('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body">
    <form [formGroup]="detailEventForm" > 
      <div class="flex-row row" style= "margin: 0px;" *ngIf="isAppointmentCustomer">
        <div class="col-5">
          <mat-form-field class="mdiinput form-group" appearance="standard" >
              <mat-label>{{ 'CALENDAR_EVENT.eventTitle' | translate }}</mat-label>
              <input id="eventTitle" #eventTitle class="form-control inputTheme" 
                      formControlName="eventTitle" required
                          matInput placeholder="{{ 'eventTitle' | translate }}"
                          >
          </mat-form-field>
        </div>
        <div class="col-2">
        </div>
        <div class="col-3" >
          <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'CALENDAR_EVENT.eventType' | translate }}</mat-label>
              <input id="eventType" #eventType class="form-control inputTheme" 
                      formControlName="eventType" required readonly matInput  >
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row row" style= "margin: 0px;"  *ngIf="isAppointmentCustomer">
        <div class="col-5">
          <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'CALENDAR_EVENT.eventOutside' | translate }}</mat-label>
              <input id="eventOutside" #eventOutside class="form-control inputTheme" 
                      formControlName="eventOutside" required
                          matInput placeholder="{{ 'eventOutside' | translate }}"
                          >
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row row" style= "margin: 0px;"  *ngIf="applicationCall === 'rhPlanning'">
        <div class="col-3" >
          <mat-form-field appearance="standard"  style="width: 100%!important;">
            <mat-label>{{ 'CALENDAR_EVENT.eventTitle' | translate }}</mat-label>
            <mat-select id="language" placeholder="{{ 'Choose equipment' | translate }}" formControlName="eventTitle" 
                      #eventTitle required
                      (ngModelChange)="onChangeTitle('appointmentCustomer', eventTitle.value)"
                      ngDefaultControl >
                      <mat-option class="selectOptionsTheme" *ngFor="let eventRh of eventsRh" 
                                  [value]="eventRh.label"  >{{ eventRh.label | translate }}</mat-option>
            </mat-select>         
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row row" style= "margin: 0px;height: 100px">
        <div class="col-5">
          <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'CALENDAR_EVENT.eventDescription' | translate }}</mat-label>

              <textarea matInput
                cdkTextareaAutosize
                id="eventDescription"
                class="form-control inputTheme"
                formControlName="eventDescription" 
                #eventDescription="cdkTextareaAutosize"
                placeholder="{{ 'eventDescription' | translate }}"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3">
              </textarea>
          </mat-form-field>
        </div>

        <div class="col-5" style="min-height: 70px;">
          <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'CALENDAR_EVENT.eventComment' | translate }}</mat-label>

              <textarea matInput
                cdkTextareaAutosize
                id="eventComment"
                class="form-control inputTheme"
                formControlName="eventComment" 
                #eventComment="cdkTextareaAutosize"
                placeholder="{{ 'eventComment' | translate }}"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3">
              </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row row" style= "margin: 0px;">
        <div class="col-2">
          <mat-form-field class="mdiinput form-group" appearance="standard">
            <mat-label>{{ 'CALENDAR_EVENT.eventStart' | translate }}</mat-label>

            <input matInput  class="form-control inputTheme" 
                          formControlName="eventStartDate"
                          #eventStartDate 
                          placeholder="{{ 'eventStart' | translate }}" 
                          [max]="maxInputDate" 
                          [min]="minInputDate"  
                          [matDatepickerFilter]="filterDate"
                          [matDatepicker]="eventStartDatePicker" 
                          (dateChange)="validStatutDate($event, 'eventStartDate')" 
                          readonly required>
            <mat-datepicker-toggle matSuffix [for]="eventStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker name="eventStartDatePicker" #eventStartDatePicker >
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-2">
          <mat-form-field  class="mdiinput form-group" appearance="standard">
            <input matInput [ngxTimepicker]="toggleStartTimepicker" [disableClick]="true" readonly required #eventStartTime
                    formControlName="eventStartTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]"
                    class="form-control inputTheme" >

              <button mat-icon-button matSuffix (click)="toggleStartTimepicker.open(); $event.stopPropagation();">
                <mat-icon>access_time</mat-icon>
              </button>
              <ngx-material-timepicker name="toggleStartTimepicker" (timeChanged)="changeTime('start', $event)" (timeSet)="validTimeInput('start',$event, true)" #toggleStartTimepicker></ngx-material-timepicker>
          </mat-form-field>
        </div>
        <div class="col-2"></div>
        <div class="col-2">
          <mat-form-field class="mdiinput form-group" appearance="standard">
            <mat-label>{{ 'CALENDAR_EVENT.eventEnd' | translate }}</mat-label>

            <input matInput  class="form-control inputTheme" 
                          formControlName="eventEndDate"
                          #eventEndDate 
                          placeholder="{{ 'eventEnd' | translate }}" 
                          [max]="maxInputDate" 
                          [min]="minInputDate"  
                          [matDatepickerFilter]="filterDate"
                          [matDatepicker]="eventEndDatePicker" 
                          (dateChange)="validStatutDate($event, 'eventEndDate')" 
                          readonly required>
            <mat-datepicker-toggle matSuffix [for]="eventEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker name="eventEndDatePicker" #eventEndDatePicker >
            </mat-datepicker>
          </mat-form-field>
        </div>
        
        <div class="col-2">
          <mat-form-field  class="mdiinput form-group" appearance="standard">
            <input matInput [ngxTimepicker]="toggleEndTimepicker" [disableClick]="true" readonly required
                    formControlName="eventEndTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]" 
                    class="form-control inputTheme">
            <!-- https://github.com/h2qutc/angular-material-components/issues/170 -->
            <button mat-icon-button matSuffix (click)="toggleEndTimepicker.open(); $event.stopPropagation();">
              <mat-icon>access_time</mat-icon>
            </button>
            <ngx-material-timepicker name="toggleEndTimepicker" (timeChanged)="changeTime('end',$event)" (timeSet)="validTimeInput('end',$event, true)" #toggleEndTimepicker></ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div> 
    </form>
  </div>
  <div class="modal-footer">
    <div class="flex-row row w-100">
      <div class="col-2" style="text-align: start">
        <button type="button" mat-raised-button (click)="closeModal('canceled')">
          <mat-icon>arrow_back</mat-icon>
          {{ 'goback' | translate }}
        </button>
      </div>
      <div class="col-8"></div>
      <div class="col-2"  style="text-align: end">
        <button type="button" *ngIf="detailEventForm.valid && detailEventForm.dirty && timeIsValid" mat-raised-button (click)="validEvent()">
          <mat-icon>save</mat-icon>
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
