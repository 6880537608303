import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Client, WksEntity, LabelValue, LabelInt } from 'src/app/models';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['../../../../shared.less', './client-form.component.less']
})
export class ClientFormComponent implements OnChanges {
  @Input()
  client!: Client;
  @Input()
  wksEntity: WksEntity;
  @Input()
  countryFmt: any;
  @Input()
  readOnlyData: boolean;

  @Output()
  nextStepEvent = new EventEmitter<number>();

  @Output()
  nbEquipmentsEvent = new EventEmitter<number>();

  clientForm: FormGroup;
  nbEquipments: number;
  arabicsToLatin: LabelInt[];
  latinToArabics: LabelValue[];
  hindusArabic: boolean;

  domesticMask: any;
  maxLengthTelArabic: number;
  latinTelValue: string;
  langCur: string;

  equipNb = {
    min: 1,
    max: 4,
  };
  constructor(private translate: TranslateService) {

  }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'client': {
            // tslint:disable-next-line:no-string-literal
            this.client = changes['client'].currentValue;
            break;
          }
          case 'readOnlyData': {
            // tslint:disable-next-line:no-string-literal
            this.readOnlyData = changes['readOnlyData'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'countryFmt': {
            // tslint:disable-next-line:no-string-literal
            this.countryFmt = changes['countryFmt'].currentValue;
            this.settingHindusArabic();
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  settingHindusArabic(): void {
    this.hindusArabic = false;
    if (!this.translate.currentLang.startsWith('ar')) {
      return;
    }
    const numbersFmt = this.countryFmt.numbers;
    for (const numberItem of numbersFmt) {
      if (numberItem.name === 'hindusArabics') {
        this.hindusArabic = (numberItem.value === 'true' ? true : false);
        break;
      }
    }
    if (this.hindusArabic) {
      const unitsArabicsNumbers = environment.indusArabicsNumbers.units;
      this.arabicsToLatin = [];
      this.latinToArabics = [];
      for (const item of unitsArabicsNumbers) {
        const latin = item.latin;
        const hindus = item.hindus;
        this.arabicsToLatin.push({
          label: item.hindus,
          value: parseInt(item.latin, 10)
        });
        this.latinToArabics.push({
          label: item.latin,
          value: item.hindus
        });
      }
      const arrayChar = [...this.wksEntity.telFmt.domesticMask];
      this.domesticMask = '';
      for (const charCur of arrayChar) {
        for (const latinItem of this.latinToArabics) {
          if (charCur === latinItem.label) {
            this.domesticMask += latinItem.value;
            break;
          }
        }
      }
      this.maxLengthTelArabic = this.domesticMask.length;

      // console.log(unitsArabicsNumbers);
    }
  }

  initData(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.settingHindusArabic();
    });
    this.clientForm = new FormGroup({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      companyName: new FormControl(''),
      // phoneNumber: new FormControl('', [Validators.required, Validators.pattern(this.wksEntity.telFmt.domesticFmt)]),
      phoneNumber: new FormControl('', [Validators.required]),
      mailAddress: new FormControl('', [Validators.required, Validators.email]),
      nbEquipments: new FormControl('', [Validators.max(this.equipNb.max), Validators.min(this.equipNb.min)]),
    });
    this.fillForm();
  }
  ctrlArabicHindus(value: any): void  {
    let isArabicHindus = true;
    let isFound = false;
    let telValue = '';
    this.latinTelValue = '';

    const arrayChar = [...value];
    for (const charCur of arrayChar) {
      isFound = false;
      for (const arabicItem of this.arabicsToLatin) {
        if (charCur === arabicItem.label) {
          isFound = true;
          this.latinTelValue += arabicItem.value;
          break;
        }
      }
      if (!isFound) {
        isArabicHindus = false;
        break;
      } else {
        telValue += charCur;
        if (telValue.length === this.maxLengthTelArabic) {
          break;
        }
      }
    }
    if (!isArabicHindus) {
      this.clientForm.controls.phoneNumber.setValue('');
      this.latinTelValue = '';
    } else {
      this.clientForm.controls.phoneNumber.setValue(telValue);
    }
  }
  convertTelToArabic(value: any): string {
    let telArabic = '';
    let valueCur = '';
    if (!isNaN(value)) {
      valueCur = value.toString();
    } else {
      valueCur = value;
    }
    const arrayChar = [...valueCur];
    for (const charCur of arrayChar) {
      for (const arabicItem of this.latinToArabics) {
        if (charCur === arabicItem.label) {
          telArabic += arabicItem.value;
          break;
        }
      }
    }
    return telArabic;
  }
  counter(field: string, increment: number, value: any ): void  {

    switch (field) {
      case 'nbEquipments': {
        let inputValue = 0;
        if (this.hindusArabic) {
          for (const arabicItem of this.arabicsToLatin) {
            if (value === arabicItem.label) {
              inputValue = arabicItem.value;
              break;
            }
          }
        } else {
          inputValue = Number.parseInt(value, 10);
        }
        let valueCur = inputValue;
        valueCur = valueCur + increment;
        if (valueCur < this.equipNb.min)  {
          valueCur = this.equipNb.min;
        }
        if (valueCur > this.equipNb.max)  {
          valueCur = this.equipNb.max;
        }
        if (this.hindusArabic) {
          for (const arabicItem of this.latinToArabics) {
            if (valueCur.toString() === arabicItem.label) {
              this.clientForm.controls.nbEquipments.setValue(arabicItem.value);
              break;
            }
          }
        } else {
          this.clientForm.controls.nbEquipments.setValue(valueCur);
        }
        this.nbEquipments = valueCur;
        this.nbEquipmentsEvent.emit(valueCur);
        break;
      }
      default: {
        break;
      }
    }
  }
  onSubmit(): void {
    this.fillModel();
    this.nextStepEvent.emit(1);
  }
  onGoHome(): void {
    this.nextStepEvent.emit(-1);
  }

  fillForm(): void {
    this.clientForm.setValue({
      lastName: this.client.lastName,
      firstName: this.client.firstName,
      companyName: this.client.companyName,
      phoneNumber: this.client.phoneNumber,
      mailAddress: this.client.mailAddress,
      nbEquipments: 1
    });
    this.nbEquipments = 1;
    if (this.hindusArabic) {
      if (this.client.phoneNumber !== '') {
        this.clientForm.controls.phoneNumber.setValue(this.convertTelToArabic(this.client.phoneNumber));
      }
      this.clientForm.controls.nbEquipments.setValue(this.convertTelToArabic(1));
    }
  }

  fillModel(): void {
    // tslint:disable-next-line:no-non-null-assertion
    this.client.lastName = this.clientForm.get('lastName')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.client.firstName = this.clientForm.get('firstName')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.client.companyName = this.clientForm.get('companyName')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.client.phoneNumber = this.clientForm.get('phoneNumber')!.value;
    if (this.hindusArabic)  {
      this.client.phoneNumber = this.latinTelValue;
    }
    // tslint:disable-next-line:no-non-null-assertion
    this.client.mailAddress = this.clientForm.get('mailAddress')!.value;
    this.client.nbEquipments = this.nbEquipments;

  }
}
