import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  getNavigatorLanguage(): string {
    return navigator.language;
  }

}

