import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { WksWorksModel, DataLocationWorkModel, IhmOptions} from '../../../../../models/';

@Component({
  selector: 'app-work-location',
  templateUrl: './work-location.component.html',
  styleUrls: ['./work-location.component.less']
})
export class WorkLocationComponent implements OnInit {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() wksWorks: WksWorksModel;
  @Input() ihmOptions: IhmOptions;
  @Input() planningOptions: IhmOptions;
  @Output() dataOut = new EventEmitter<any>();

  isReadOnly: boolean;
  isReadOnlyPlanning: boolean;
  dataLocationWork: DataLocationWorkModel;

  constructor() { }

  ngOnInit(): void {
    this.dataLocationWork =  {
      typeLocation: (this.wksWorks.workLocation ? this.wksWorks.workLocation : undefined),
      dataLocation: (this.wksWorks.workLocationData ? JSON.parse(this.wksWorks.workLocationData) : undefined)
    };
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.isReadOnlyPlanning = this.isReadOnly;
    if (this.planningOptions !== undefined) {
      this.isReadOnlyPlanning = this.planningOptions.readOnly;
    }
  }
  dataOutLocation(dataReturn: any): void {
    this.dataLocationWork = dataReturn as DataLocationWorkModel;

  }
}
