import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TranslateService  } from '@ngx-translate/core';

import { SharingDataService } from '../../../../../services/sharing-data.service';
import { AuthService } from '../../../../../services/auth.service';
import { IhmOptions, TasksActionCompletedModel, WksEquipmentModel, TasksActionsModel, AdditionalTasksModel } from '../../../../../models/';
import { CommonMethods } from '../../../../common/tools/commonMethods';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-tasks-checklist',
  templateUrl: './tasks-checklist.component.html',
  styleUrls: ['./tasks-checklist.component.less'],
  encapsulation : ViewEncapsulation.None,
})
export class TasksChecklistComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() ihmOptions: IhmOptions;
  @Input() tasksActionsArgs: TasksActionCompletedModel[];
  @Input() itemEquip: WksEquipmentModel;

  @Output() dataOut = new EventEmitter<any>();

  checkEquipForm: FormGroup;
  isReadOnly: boolean;
  isOkToBuild: boolean;
  suppliesList: TasksActionsModel[];


  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };
  tasksActionsCur: TasksActionCompletedModel[];
  additionalTasks: AdditionalTasksModel[] = [];
  waitAction: boolean[][];
  dataLabelCur: string[];

  constructor(private fb: FormBuilder,
              private translateService: TranslateService,
              private authService: AuthService,
              private sharingDataService: SharingDataService) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            // tslint:disable-next-line:no-string-literal
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          // ihmOptions
          case 'ihmOptions': {
            // tslint:disable-next-line:no-string-literal
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          }
          case 'tasksActionsArgs': {
            // tslint:disable-next-line:no-string-literal
            this.tasksActionsArgs = changes['tasksActionsArgs'].currentValue;
            break;
          }
          case 'itemEquip': {
            // tslint:disable-next-line:no-string-literal
            this.itemEquip = changes['itemEquip'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {

    this.initAllBooleans();

    this.settingFormats();
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }

    this.buildForm();
    this.fillForm();
    this.isOkToBuild = true;
    this.tasksActionsCur[0].isCollapsed = true;
    /* if (this.ihmOptions !== undefined && this.ihmOptions.collapse !== undefined) {
      this.tasksActionsCur[0].isCollapsed = this.ihmOptions.collapse;
    }*/

  }
  settingFormats(): void {
    const otherData = JSON.parse(this.sharingDataService.getWksEntity().otherData);
    const intFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this.sharingDataService.getFormat(intFormat, 'numbers', 'locale'),
      minDecimal: this.sharingDataService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this.sharingDataService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this.sharingDataService.getFormat(intFormat, 'numbers', 'currency'),
    };

  }
  initAllBooleans(): void {
    this.isReadOnly = false;
    this.isOkToBuild = false;
    this.waitAction = [];
    for (let iRow = 0 ; iRow < this.tasksActionsArgs.length; iRow++) {
      this.waitAction[iRow] = new Array(this.tasksActionsArgs[iRow].supplies.length);
      for (let iSupplie = 0; iSupplie < this.tasksActionsArgs[iRow].supplies.length; iSupplie++) {
        this.waitAction[iRow][iSupplie] = true;
      }
    }
  }
  buildForm(): void {
    this.checkEquipForm = this.fb.group({
      equipSN: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipHours: this.fb.control({value: '', disabled: this.isReadOnly}),
    });
    this.dataLabelCur = [];
    this.dataLabelCur[0] = this.translateService.instant('WORK_TASK.suppliesLabel');
    this.dataLabelCur[1] = this.translateService.instant('WORK_TASK.brandRef');
    this.dataLabelCur[2] = this.translateService.instant('WORK_TASK.billingUnit');
    this.dataLabelCur[3] = this.translateService.instant('WORK_TASK.quantity');
    this.dataLabelCur[4] = this.translateService.instant('WORK_TASK.actionTask');
    this.dataLabelCur[5] = this.translateService.instant('WORK_TASK.realized');

  }
  fillForm(): void {
    this.checkEquipForm.controls.equipSN.setValue(this.tasksActionsArgs[0].equipSN);
    this.checkEquipForm.controls.equipHours.setValue(this.tasksActionsArgs[0].equipHours);
    this.tasksActionsCur = JSON.parse(JSON.stringify(this.tasksActionsArgs));
    for (let iRow = 0 ; iRow < this.tasksActionsArgs.length; iRow++) {
      for (let iSupplie = 0; iSupplie < this.tasksActionsArgs[iRow].supplies.length; iSupplie++) {
        if ((this.tasksActionsArgs[iRow].supplies[iSupplie].actionTaken !== undefined) &&
            (this.tasksActionsArgs[iRow].supplies[iSupplie].actionTaken !== '')) {
            this.waitAction[iRow][iSupplie] = false;
        }
      }

      this.tasksActionsCur[iRow].isCollapsed = false;
    }
  }

  getThemeRow(rowTitle: string): boolean {
    let returnValue = false;
    switch (rowTitle) {
      case 'SN-hours': {
        if (this.checkEquipForm.controls.equipHours.value === ''
            || this.checkEquipForm.controls.equipSN.value === '' ) {
          returnValue = false;
        } else {
          returnValue = true;
        }
        break;
      } // end case
      case 'taskAction': {
        returnValue = false;
        break;
      }
      default : {
        returnValue = false;
        break;
      }
    }
    return returnValue;
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  changeSN_Hours(orig: string, value: any): void {
    switch (orig) {
      case 'equipSN': {
        this.tasksActionsCur[0].equipSN = value;
        this.dataOutValid();
        break;
      }
      case 'equipHours': {
        // const valueNumber = CommonMethods.fmtNumber(value, 'integer', this.authService.getUserLogged().userlang);
        if (value === null || value === '') {
          value = 0;
        }
        this.tasksActionsCur[0].equipHours = Number.parseInt(value, 10);
        this.dataOutValid();
        break;
      }

      default : {

        break;
      }
    }
  }
  addAdditionalTasks(iRow: number): void {
    const additionalTask: AdditionalTasksModel = {
      taskDescription: '',
      taskParts: '',
      taskDuration: 0,
      taskComment: '',
      isCollapsed: true
    };
    if (this.tasksActionsCur[iRow].additionalTasks === undefined) {
      this.tasksActionsCur[iRow].additionalTasks  = [];
    }
    this.tasksActionsCur[iRow].additionalTasks.push(additionalTask);
  }
  changeTaskActionComment(inputComment: string, iRow: number, i: number): void {
    this.tasksActionsCur[iRow].supplies[i].comment = inputComment;
    this.dataOutValid();
  }
  removeAdditionalTasks(iRow: number, iTask: number): void {
    this.tasksActionsCur[iRow].additionalTasks.splice(iTask, 1);
    if (this.tasksActionsCur[iRow].additionalTasks.length === 0) {
      this.tasksActionsCur[iRow].additionalTasks = undefined;
    }
    this.dataOutValid();
  }
  onChangeAdditionalTask(orig: string, value: any, iRow: number, iTask: number): void {

    switch (orig) {
      case 'description': {
        let labelCur = value;
        if (value.length > 40)  {
          labelCur = value.substring(0, 39);
        }
        this.tasksActionsCur[iRow].additionalTasks[iTask].taskDescription = labelCur;
        this.dataOutValid();
        break;
      }
      case 'parts': {
        this.tasksActionsCur[iRow].additionalTasks[iTask].taskParts = value;
        this.dataOutValid();
        break;
      }

      case 'duration': {
        if (value === null || value === '') {
          value = 0;
        }
        const nbHours = Number.parseInt(value, 10);
        this.tasksActionsCur[iRow].additionalTasks[iTask].taskDuration = nbHours;
        this.dataOutValid();
        break;
      }
      case 'comment': {
        this.tasksActionsCur[iRow].additionalTasks[iTask].taskComment = value;
        this.dataOutValid();
        break;
      }
      default : {

        break;
      }
    }
  }
  changeAction(actionTask: string, value: string, iSupplie: number, iRow: number ): void {
    // test
    // actionTask = 'Check';
    switch (actionTask) {
      case 'Check': {
        this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = value;
        this.waitAction[iRow][iSupplie] = false;
        this.dataOutValid();
        break;
      }
      case 'ExchFirst': {
        this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = value;
        this.waitAction[iRow][iSupplie] = false;
        this.dataOutValid();
        break;
      }
      case 'Exchange': {
        this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = value;
        this.waitAction[iRow][iSupplie] = false;
        this.dataOutValid();
        break;
      }
      default : {

        break;
      }
    }

  }
  clickAction(actionTask: string, checked: boolean, iSupplie: number, iRow: number): void {

    switch (actionTask) {
      case 'ExchFirst': {
        if (this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken === 'changed') {
          this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = undefined;
          this.waitAction[iRow][iSupplie] = true;
        } else {
          this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = 'changed';
          this.waitAction[iRow][iSupplie] = false;
        }
        this.dataOutValid();
        break;
      }
      case 'Exchange': {
        if (this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken === 'changed') {
          this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = undefined;
          this.waitAction[iRow][iSupplie] = true;
        } else {
          this.tasksActionsCur[iRow].supplies[iSupplie].actionTaken = 'changed';
          this.waitAction[iRow][iSupplie] = false;
        }
        this.dataOutValid();
        break;
      }

      default : {

        break;
      }
    }

  }
  dataOutValid(): void {
    if (this.tasksActionsCur[0].equipSN === undefined
        || this.tasksActionsCur[0].equipSN === ''
        || this.tasksActionsCur[0].equipSN === null )  {
          return;
    }
    if (this.tasksActionsCur[0].equipHours === undefined
      || this.tasksActionsCur[0].equipHours <= 1
      || this.tasksActionsCur[0].equipHours === null )  {
        return;
    }
    if (JSON.stringify(this.tasksActionsArgs) !== JSON.stringify(this.tasksActionsCur)) {
      const dataInput = {
        tasksActions: this.tasksActionsCur,
        inputChange: true
      };
      this.dataOut.emit(dataInput);
    } else {
      const dataInput = {
        tasksActions: this.tasksActionsCur,
        inputChange: false
      };
      this.dataOut.emit(dataInput);
    }
  }
}
