<div *ngIf="initElements" class="stdTheme">
    <form [formGroup]="locationForm" [dir]="wksEntity.directionInput" >
      <div *ngIf="choiceLocation && !readOnlyData" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom: 0px;" >
        <div   class="col-10"  style="padding-left: 5px; padding-right: 0px;" >
          <span style="margin-top: 5px;margin-right:3%;">{{ 'locationJobsite' | translate }} </span>
          <mat-radio-group  #jobsite="matRadioGroup" aria-label="Select an option" 
                formControlName="jobsite" (change)="jobsiteChange(jobsite.value);validInput(jobsite.value);">
            <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" disabled="readOnlyData" value="workshop">{{ 'locationWorkshop' | translate }}</mat-radio-button>
            <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" disabled="readOnlyData" value="address">{{ 'locationAddress' | translate }}</mat-radio-button>
            <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" disabled="readOnlyData" value="mooring">{{ 'locationMooring' | translate }}</mat-radio-button>
            <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" disabled="readOnlyData" value="wintering">{{ 'locationWintering' | translate }}</mat-radio-button>
            <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" disabled="readOnlyData" value="another">{{ 'locationAnother' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div *ngIf="readOnlyData && choiceLocation" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom: 0px;" >
        <span>{{ 'ADDRESS_BLOCK.LOCATION' | translate }}</span>
      </div> 
      <div *ngIf="locationAddress" class="stdTheme " style="margin-right: 0px;margin-left: 0px;" style="margin-bottom: 20px;">
        <div #addressBlock class="stdTheme margin-0 padding-0"  formGroupName="addressBlockFg" style="width: 100%">
          <div *ngFor="let rowCur of rowsAddress; let iRow = index" >
            <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
              <div class="col-1"></div>
              <!--<div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                      [ngStyle]="{width: calWidthCol(rowCur.cols,colCur.dataLengthMax,38)}"
                      [ngSwitch]="colCur.controlType">-->
              <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                      [ngSwitch]="colCur.controlType">
                <div  [class]="calColNumber(colCur.dataName, rowCur.cols)">
                  <div *ngSwitchDefault>
                      <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                          <mat-label >{{colCur.elemLabel}}</mat-label>
                          <input matInput class="locationInput" type="text" #nbHoursOfUse id="addressItem_+{{colCur.elmtNumber}}" 
                              [attr.maxlength]="colCur.dataLengthMax"
                              [ngClass]="{
                                  'inputAlert': colCur.inputError,
                                  'inputNormal': !colCur.inputError
                                  }"
                              [value]="colCur.inputValue" 
                              (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                              (blur)="dataOutForm()"
                              formControlName="{{colCur.dataName}}"
                              >
                      </mat-form-field>
                  </div> 
                  <div *ngSwitchCase="'zipcode'">
                      <mat-form-field style="width: 100%" appearance="standard" class="locationAdress form-group">
                          <mat-label >{{colCur.elemLabel}}</mat-label>
                          <input matInput class="locationInput" type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                              [attr.maxlength]="colCur.dataLengthMax"
                              [ngClass]="{
                                  'inputAlert': colCur.inputError,
                                  'inputNormal': !colCur.inputError
                                  }"
                              [value]="colCur.inputValue" 
                              (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                              (blur)="dataOutForm()"
                              formControlName="{{colCur.dataName}}"
                              >
                      </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end addressBlock -->
      </div> <!-- locationAddress-->
      <div #homeAnchorageBloc *ngIf="locationHomeAnchorage" formGroupName="homeAnchorageBlockFg" >
        <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
          <div *ngIf="choiceAnchorage" style="height: 66px"  class="col-lg-6 col-xs-12">  
            <mat-form-field class="wksInput form-group" appearance="standard">
              <mat-label>{{ 'LOCATION_BLOCK.homeAnchoragePort' | translate }}</mat-label>
              <mat-select id="homeAnchoragePort" #homeAnchoragePort 
                      formControlName="homeAnchoragePort" 
                      [disabled]="readOnlyData"
                      panelClass="panelOptions" ngDefaultControl  
                      (selectionChange)="onChangeAnchorage('homeAnchoragePort',homeAnchoragePort.value)">
                  <mat-select-header>
                      <div class="filterInput" style="display: inline-block;" >            
                          <button mat-icon-button matPrefix aria-label="search" >
                              <mat-icon>search</mat-icon>
                          </button>  
                          <input class="searchTheme" #filterHomeAnchorage placeholder="{{ 'filterInput' | translate }}" 
                                      style="margin: 5px" (keyup)="onKeyHomeAnchorage($event)" >
                          <button mat-icon-button matSuffix aria-label="clear" 
                                      *ngIf="filterHomeAnchorage.value" (click)="filterHomeAnchorage.value=''; onKeyHomeAnchorage('');">
                              <mat-icon>close</mat-icon>
                          </button>
                      </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let anchorage of homeAnchoragesFilteredList" 
                      [value]="anchorage.value"  >{{anchorage.label}}
                  </mat-option>
              </mat-select>  
            </mat-form-field>
          </div>
        </div>
      </div>
      <div #mooringBlock *ngIf="locationMooring" formGroupName="mooringBlockFg" >
        <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px; margin-top: 10px" >
          <div  style="height: 66px"  class="col-lg-6 col-xs-12">  
            <div *ngIf="!choiceAnchorage">
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'LOCATION_BLOCK.anchoragePort' | translate }}</mat-label>
                <input id="anchoragePort" class="form-control inputTheme" formControlName="anchoragePort" matInput #anchoragePort readonly >
              </mat-form-field>
            </div>
            <div *ngIf="choiceAnchorage">
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'LOCATION_BLOCK.anchoragePort' | translate }}</mat-label>
                <mat-select id="anchoragePort" #anchoragePort 
                        formControlName="anchoragePort" 
                        [disabled]="readOnlyData"
                        panelClass="panelOptions" ngDefaultControl  
                        (selectionChange)="onChangeAnchorage('anchoragePort',anchoragePort.value)">
                    <mat-select-header>
                        <div class="filterInput" style="display: inline-block;" >            
                            <button mat-icon-button matPrefix aria-label="search" >
                                <mat-icon>search</mat-icon>
                            </button>  
                            <input class="searchTheme" #filteranchoragePort placeholder="{{ 'filterInput' | translate }}" 
                                        style="margin: 5px" (keyup)="onKeyAnchorage($event)" >
                            <button mat-icon-button matSuffix aria-label="clear" 
                                        *ngIf="filteranchoragePort.value" (click)="filteranchoragePort.value=''; onKeyAnchorage('');">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-select-header>
                    <mat-option class="selectOptionsTheme" *ngFor="let anchorage of anchoragesFilteredList" 
                        [value]="anchorage.value"  >{{anchorage.label}}
                    </mat-option>
                </mat-select>  
              </mat-form-field>
            </div>
          </div>
          <div *ngFor="let rowCur of rowsMooring; let iRow = index" class="col-lg-4 col-xs-12">
             <!--<div class="col-2"></div>-->
            <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                    [ngSwitch]="colCur.controlType">
              <div  [class]="calColNumber(colCur.dataName, rowCur.cols)" style="height: 66px;padding-left: 0px;padding-right: 0px;">
                <div *ngSwitchDefault>
                    <mat-form-field style="width: 100%" appearance="standard" class="locationAdress form-group">
                        <mat-label >{{colCur.elemLabel}}</mat-label>
                        <input matInput class="locationInput"  type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                            [attr.maxlength]="colCur.dataLengthMax"
                            [ngClass]="{
                                'inputAlert': colCur.inputError,
                                'inputNormal': !colCur.inputError
                                }"
                            [value]="colCur.inputValue" 
                            (input)="formatFieldMooring('mooring', iRow, iCol, colCur,$any($event.target).value)"
                            (blur)="dataOutForm()"
                            formControlName="{{colCur.dataName}}"
                            >
                    </mat-form-field>
                </div> 
              </div> 
            </div>
          </div>
        </div>
      </div> <!-- end locationMooring -->
      <div #anotherBlock *ngIf="locationAnother" formGroupName="anotherBlockFg" >
        <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px; margin-top: 0px" >
          <div *ngIf="choiceAnchorage" style="height: 66px"  class="col-lg-6 col-xs-12">  
            <mat-form-field class="wksInput form-group" appearance="standard">
              <mat-label>{{ 'LOCATION_BLOCK.anotherPort' | translate }}</mat-label>
              <mat-select id="anotherPort" #anotherPort 
                      formControlName="anotherPort" 
                      [disabled]="readOnlyData"
                      panelClass="panelOptions" ngDefaultControl  
                      (selectionChange)="onChangeAnchorage('anotherPort',anotherPort.value)">
                  <mat-select-header>
                      <div class="filterInput" style="display: inline-block;" >            
                          <button mat-icon-button matPrefix aria-label="search" >
                              <mat-icon>search</mat-icon>
                          </button>  
                          <input class="searchTheme" #filterAnotherPort placeholder="{{ 'filterInput' | translate }}" 
                                      style="margin: 5px" (keyup)="onKeyAnother($event)" >
                          <button mat-icon-button matSuffix aria-label="clear" 
                                      *ngIf="filterAnotherPort.value" (click)="filterAnotherPort.value=''; onKeyAnother('');">
                              <mat-icon>close</mat-icon>
                          </button>
                      </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let anchorage of anotherFilteredList" 
                      [value]="anchorage.value"  >{{anchorage.label}}
                  </mat-option>
              </mat-select>  
            </mat-form-field>
          </div>
          <div *ngFor="let rowCur of rowsAnother; let iRow = index" class="col-lg-4 col-xs-12">
             <!--<div class="col-2"></div>-->
            <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                    [ngSwitch]="colCur.controlType">
              <div [class]="calColNumber(colCur.dataName, rowCur.cols)" style="height: 66px;padding-left: 0px;padding-right: 0px;">
                <div *ngSwitchDefault>
                    <mat-form-field style="width: 100%" appearance="standard" class="locationAdress form-group">
                        <mat-label >{{colCur.elemLabel}}</mat-label>
                        <input matInput class="locationInput"  type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                            [attr.maxlength]="colCur.dataLengthMax"
                            [ngClass]="{
                                'inputAlert': colCur.inputError,
                                'inputNormal': !colCur.inputError
                                }"
                            [value]="colCur.inputValue" 
                            (input)="formatFieldMooring('mooring', iRow, iCol, colCur,$any($event.target).value)"
                            (blur)="dataOutForm()"
                            formControlName="{{colCur.dataName}}"
                            >
                    </mat-form-field>
                </div> 
              </div> 
            </div>
          </div>
        </div>
      </div> <!-- end locationMooring -->
      <div #winteringBlock *ngIf="locationWintering" formGroupName="winteringBlockFg" >
        <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px; margin-top: 0px" >
          <div style="height: 66px"  class="col-lg-6 col-xs-12" >  
            <div *ngIf="!choiceAnchorage">
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'LOCATION_BLOCK.winteringPort' | translate }}</mat-label>
                <input id="winteringPort" class="form-control inputTheme" formControlName="winteringPort" matInput #winteringPort readonly >
              </mat-form-field>
            </div>
            <div *ngIf="choiceAnchorage">
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'LOCATION_BLOCK.winteringPort' | translate }}</mat-label>
                <mat-select id="winteringPort" #winteringPort 
                        formControlName="winteringPort" 
                        [disabled]="readOnlyData"
                        panelClass="panelOptions" ngDefaultControl  
                        (selectionChange)="onChangeAnchorage('winteringPort', winteringPort.value)">
                    <mat-select-header>
                        <div class="filterInput" style="display: inline-block;" >            
                            <button mat-icon-button matPrefix aria-label="search" >
                                <mat-icon>search</mat-icon>
                            </button>  
                            <input class="searchTheme" #filterwinteringPort placeholder="{{ 'filterInput' | translate }}" 
                                        style="margin: 5px" (keyup)="onKeyWintering($event)" >
                            <button mat-icon-button matSuffix aria-label="clear" 
                                        *ngIf="filterwinteringPort.value" (click)="filterwinteringPort.value=''; onKeyWintering('');">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-select-header>
                    <mat-option class="selectOptionsTheme" *ngFor="let anchorage of winteringsFilteredList" 
                        [value]="anchorage.value"  >{{anchorage.label}}
                    </mat-option>
                </mat-select>  
              </mat-form-field>
            </div>
          </div>
          <div *ngFor="let rowCur of rowsWintering; let iRow = index" class="col-lg-4 col-xs-12" >
            <!--<div class="col-2"></div>-->
            <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                    
                    [ngSwitch]="colCur.controlType">
              <div  style="height: 66px;padding-left: 0px;padding-right: 0px;">
                <div *ngSwitchDefault>
                    <mat-form-field style="width: 100%" appearance="standard" class="locationAdress form-group">
                        <mat-label >{{colCur.elemLabel}}</mat-label>
                        <input matInput class="locationInput wksInput"  type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                            [attr.maxlength]="colCur.dataLengthMax"
                            [ngClass]="{
                                'inputAlert': colCur.inputError,
                                'inputNormal': !colCur.inputError
                                }"
                            [value]="colCur.inputValue" 
                            (blur)="dataOutForm()"
                            (input)="formatFieldWintering('wintering', iRow, iCol, colCur,$any($event.target).value)"
                            formControlName="{{colCur.dataName}}"
                            >
                    </mat-form-field>
                </div> 
              </div> 
            </div>
          </div>
        </div>
      </div> <!-- end locationMooring -->
    </form>
  </div>
  <ng-template #modalMap let-modal >
    <mdi-google-location [googleMaps] = "googleMaps" (outputMap)="getReturnMap($event)" ></mdi-google-location>
  </ng-template>