import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { TranslateService  } from '@ngx-translate/core';

import { AuthService } from '../../../../services/auth.service';
import { WorksService } from '../../../../services/works.service';
import { SharingDataService } from '../../../../services/sharing-data.service';

import { WksWorksModel, ContactInt, WksContactsModel,
          IhmOptions, WksEntity, InternationalsFormats,
          WksEquipmentModel, ShareDataInt} from '../../../../models';


@Component({
  selector: 'app-work-card',
  templateUrl: './work-card.component.html',
  styleUrls: ['./work-card.component.less']
})
export class WorkCardComponent implements OnInit {

  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) mSidenav: any;
  modalTitle: string;
  workId: string;
  workCur: WksWorksModel;
  workIsLoaded: boolean;
  configAddress: any;
  itemChoose: string;
  nameParent: string;
  dataContacts: ContactInt[];
  shareData: ShareDataInt;
  ihmOptions: IhmOptions;
  planningOptions: IhmOptions;
  wksEntity: WksEntity;
  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  subEquipments: WksEquipmentModel [];

  itemTemplate: any;

  constructor(private dialogRef: MatDialogRef<WorkCardComponent>,
              private worksService: WorksService,
              private translateService: TranslateService,
              private sharingDataService: SharingDataService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.modalTitle = data.title;
                this.workId = data.workId;

  }
  ngOnInit(): void {
    this.workIsLoaded = false;
    this.workCur = undefined;
    this.worksService.getWorksById(this.workId)
    .subscribe(
      data => {
        this.workCur = data.body as WksWorksModel;
        this.getIhmOptions();
        this.configAddress = {
          langCur: this.translateService.getBrowserLang(),
          countryDefault: this.authService.getUserLogged().entitycountry,
          address: true,
          telephone: false,
          email: false,
          contact: false,
          professionnal: this.workCur.workCustomer.bsnTax !== 'personnal' ? true : false,
          identity: undefined,
          fieldsId: []
        };
        this.shareData = {
          isAdministration: this.workCur.workCustomer.bsnType !== 'administration' ? true : false,
          isCompany: this.workCur.workCustomer.bsnType !== 'company' ? true : false,
          isPerson: this.workCur.workCustomer.bsnType !== 'person' ? true : false,
          isProfessionnal: this.workCur.workCustomer.bsnTax !== 'individual' ? true : false,
          isIndividual: this.workCur.workCustomer.bsnTax === 'individual' ? true : false,
        };
        if (this.workCur.workCustomer.bsnAddress) {
          const dataAddress = JSON.parse(this.workCur.workCustomer.bsnAddress);
          if ((this.workCur.workCustomer.bsnType === 'company') || (this.workCur.workCustomer.bsnType === 'administration')) {
            this.nameParent = dataAddress.corporateName;
          } else {
            this.nameParent = dataAddress.identity.cntLastname + ' ' + dataAddress.identity.cntFirstname;
          }
        }
        const contactsList = this.workCur.workCustomer.dataContacts as WksContactsModel[];

        this.dataContacts = [];
        if (contactsList.length > 0) {
          for (const cntCur of contactsList) {
            this.dataContacts.push({
              id: cntCur.id,
              selectDept: cntCur.cntSpeciality,
              fonctionDept: cntCur.cntFunction,
              selectApps: cntCur.cntChannels,
              emails: JSON.parse(cntCur.cntMails),
              phones: JSON.parse(cntCur.cntPhones),
              identity: {
                cntCivility: cntCur.cntCivility,
                cntFirstname: cntCur.cntFirstname,
                cntLastname: cntCur.cntLastname,
              },
              statut: 'existant',
            });
          }
        }
        this.subEquipments = this.workCur.wksSubEquipments;
        this.workIsLoaded = true;
      },
      err =>  {
        this.getIhmOptions();
        console.log('getWorksById ' + err.message);
      }
    );

    this.wksEntity = this.sharingDataService.getWksEntity();
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this.sharingDataService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
  }
  getIhmOptions(): void {
    this.ihmOptions = {
      readOnly: true
    };
    this.planningOptions = {
      readOnly: true
    };
    if (this.workCur === undefined) {
      return;
    }
    this.ihmOptions = this.worksService.getIhmOptions(this.workCur.workStatut, this.workCur.workStatut);
    this.planningOptions = this.ihmOptions;
  }
  dataOutContacts(event: any): void {
  }
  dataOutWorkIntervention(event: any): void {

  }
  closeModal(): void {
    this.dialogRef.close();
  }
  choose($event: string, itemArg?: any): void {
    if (itemArg !== undefined) {
      this.itemTemplate = itemArg;
    }
    if ($event !== '') {
      this.itemChoose = $event;
    }
    this.mSidenav.close();
    this.sidenavCollapsed = !this.sidenavCollapsed;
  }
  toggleSidenav(): void {
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.mSidenav.close();
    } else {
      this.mSidenav.open();
    }
  }
  toggleBackdropSidenav(): void {
    this.sidenavCollapsed = true;
    this.mSidenav.close();
  }
}
