<div #refElement class="container-fluid stdTheme workCardContent"
        [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px'}" >
    <div style="padding-right: 5px; padding-left: 5px;" >
        <form [formGroup]="equipmentForm"  style="margin: 0px; height: 100%;" >
            <mat-card class="register-card stdTheme wkscard" layout="column" flex 
                    style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;padding-bottom: 10px;">
                <mat-card-content class="stdTheme">
                    <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-4 col-xs-12" *ngIf="isBoat" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipName' | translate }}</mat-label>
                                <input id="equipName" class="form-control inputTheme" formControlName="equipName" 
                                        matInput #equipName placeholder="{{ 'WORK_EQUIPMENT.equipName' | translate }}" 
                                        >
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-4 col-xs-12" *ngIf="!isBoat">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label >{{ 'WORK_EQUIPMENT.equipDesign' | translate }}</mat-label>
                                <input id="equipDesign" class="form-control inputTheme" formControlName="equipDesign" matInput #equipDesign 
                                            placeholder="{{ 'WORK_EQUIPMENT.equipDesign' | translate }}" 
                                            >
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-xs-12" *ngIf="isMainEquip">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.commissioningDate' | translate }}</mat-label>
                                <input matInput class="form-control inputTheme" formControlName="commissioningDate" 
                                            #commissioningDate placeholder="{{ 'WORK_EQUIPMENT.commissioningDate' | translate }}"
                                            >
                            </mat-form-field>
                        </div> 
                    </div>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-5 col-xs-12">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipBrand' | translate }}</mat-label>
                                <input matInput class="form-control inputTheme" formControlName="equipBrand" 
                                        #equipBrand placeholder="{{ 'WORK_EQUIPMENT.equipBrand' | translate }}"
                                    >                  
                            </mat-form-field>
                        </div>
                        <div class="col-lg-5 col-xs-12">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipModel' | translate }}</mat-label>
                                <input matInput class="form-control inputTheme" formControlName="equipModel" 
                                        #equipModel placeholder="{{ 'WORK_EQUIPMENT.equipModel' | translate }}"
                                    >    
                            </mat-form-field>
                        </div>      
                        <div class="col-lg-2 col-xs-12">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipYear' | translate }}</mat-label>
                                <input matInput class="form-control inputTheme" formControlName="equipYear" 
                                        #equipYear placeholder="{{ 'WORK_EQUIPMENT.equipYear' | translate }}"
                                    >    
                            </mat-form-field>
                        </div>   
                    </div>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-4 col-xs-12" *ngIf="isBoat || isTrailer">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipCountry' | translate }}</mat-label>
                                <input matInput class="form-control inputTheme" formControlName="equipCountry" 
                                #equipCountry placeholder="{{ 'WORK_EQUIPMENT.equipCountry' | translate }}"
                            >    
                            </mat-form-field>
                        </div>   
                        <div class="col-lg-4 col-xs-12" *ngIf="isBoat || isTrailer">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipImmat' | translate }}</mat-label>
                                <input id="equipImmat" #equipImmat class="form-control inputTheme" formControlName="equipImmat" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.equipImmat' | translate }}" >
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-xs-12">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipSN' | translate }}</mat-label>
                                <input id="equipSN" #equipSN class="form-control inputTheme" formControlName="equipSN" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.equipSN' | translate }}">
                            </mat-form-field>
                        </div>  
                    </div>

                    <div *ngIf="isBoat" class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div  class="container-fluid stdTheme" style="padding-left: 0px; padding-right: 0px;">
                            <app-location-wks
                                [applicationCall]="'equipmentCard'" 
                                [choiceLocation]="false"
                                [dataLocationEquip]="undefined"
                                [dataLocationArg]="homeAnchorageData"
                                [readOnlyData]="true" 
                                >
                            </app-location-wks>
                        </div>
                    </div>
                    <div *ngIf="isBoat" class="flex-row row" style="margin-left: 0px; margin-right: 0px;">  
                        <div  class="container-fluid stdTheme" style="padding-left: 0px; padding-right: 0px;" >
                            <app-location-wks  
                                [applicationCall]="'equipmentCard'" 
                                [choiceLocation]="false"
                                [dataLocationEquip]="undefined"
                                [dataLocationArg]="mooringData"
                                [readOnlyData]="true" 
                                >
                            </app-location-wks>
                        </div>
                    </div>
                    <div *ngIf="isBoat" class="flex-row row" style="margin-left: 0px; margin-right: 0px;">     
                        <div  class="container-fluid stdTheme" style="padding-left: 0px; padding-right: 0px;" >
                            <app-location-wks
                                [applicationCall]="'equipmentCard'" 
                                [choiceLocation]="false" 
                                [dataLocationEquip]="undefined"
                                [dataLocationArg]="winteringData"
                                [readOnlyData]="true" 
                                >
                            </app-location-wks>
                        </div>
                    </div>
                    <div class="flex-row row" *ngIf="isBoat " style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-3 col-xs-12" *ngIf="isBoat">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.propulsion' | translate }}</mat-label>
                                <input id="propulsion" #propulsion class="form-control inputTheme" formControlName="propulsion" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.propulsion' | translate }}">
                            </mat-form-field>
                        </div>  
                        <div class="col-lg-3 col-xs-12" *ngIf="isBoat">
                            <mat-form-field  class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.motorsConfiguration' | translate }}</mat-label>
                                <input id="motorsConfiguration" #motorsConfiguration class="form-control inputTheme" formControlName="motorsConfiguration" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.motorsConfiguration' | translate }}">
                            </mat-form-field>
                        </div>  
                        <div class="col-lg-6 col-xs-12 " *ngIf="isBoat" >
                            <div class="checkbox"  >
                                <label class="labelLine" style="margin-top: 38px; margin-left: 0px; margin-right: 15px" >{{ 'WORK_EQUIPMENT.equipBoatManeuvring' | translate }}</label>
                                <input type="checkbox" id="equipBoatManeuvring"  #equipBoatManeuvring class="inputTheme" formControlName="equipBoatManeuvring"
                                    [checked]="(equipmentCur !== undefined && equipmentCur.equipBoatManeuvring) ? equipmentCur.equipBoatManeuvring : false" 
                                    />
                            </div>
                        </div>
                    </div>
                    <div class="flex-row row" *ngIf="isEngine" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-4" *ngIf="isThrusterPossible">
                            <div class="checkbox">
                                <label class="labelLine" style="margin-top: 38px; margin-left: 0px; margin-right: 15px" >{{ 'WORK_EQUIPMENT.thrusterMotor' | translate }}</label>
                                <input type="checkbox" id="thrusterOption"  #thrusterOption class="inputTheme" formControlName="thrusterOption"
                                    [checked]="(equipmentCur !== undefined && equipmentCur.thrusterOption) ? equipmentCur.thrusterOption : false" 
                                    >
                            </div>
                        </div> 
                        <div class="col-lg-4 col-xs-12" *ngIf="!isSingleMotor">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.motorPosition' | translate }}</mat-label>
                                <input id="motorPosition" #motorPosition class="form-control inputTheme" 
                                            formControlName="motorPosition" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.motorPosition' | translate }}">
                            </mat-form-field>
                        </div>
                        <!--isThusterMotor-->
                        <div class="col-lg-4 col-xs-12" *ngIf="isThusterMotor">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'motorPosition' | translate }}</mat-label>
                                <input id="motorPosition" #motorPosition class="form-control inputTheme" 
                                            formControlName="motorPosition" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.motorPosition' | translate }}">
                            </mat-form-field>
                        </div> 

                    </div>
                    <div class="flex-row row" *ngIf="isTrailer" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipHitch' | translate }}</mat-label>
                                <input id="equipHitch" #equipHitch class="form-control inputTheme" 
                                            formControlName="equipHitch" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.equipHitch' | translate }}">
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.hitchSizes' | translate }}</mat-label>
                                <input id="hitchSizes" #hitchSizes class="form-control inputTheme" 
                                            formControlName="hitchSizes" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.hitchSizes' | translate }}">
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.axles' | translate }}</mat-label>
                                <input id="axles" #hitchSizes class="form-control inputTheme" 
                                            formControlName="axles" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.axles' | translate }}">
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.tires' | translate }}</mat-label>
                                <input id="tires" #hitchSizes class="form-control inputTheme" 
                                            formControlName="tires" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.tires' | translate }}">
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.rims' | translate }}</mat-label>
                                <input id="rims" #rims class="form-control inputTheme" 
                                            formControlName="rims" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.rims' | translate }}">
                            </mat-form-field>
                        </div> 
                        <div class="col-lg-2 col-xs-4" >
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.nuts' | translate }}</mat-label>
                                <input id="nuts" #rims class="form-control inputTheme" 
                                            formControlName="nuts" 
                                            matInput placeholder="{{ 'WORK_EQUIPMENT.nuts' | translate }}">
                            </mat-form-field>
                        </div> 
                    </div>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-lg-2 col-xs-4">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipHours' | translate }}</mat-label>
                                <input id="equipHours" class="form-control inputTheme" 
                                        formControlName="equipHours" matInput #equipHours placeholder="{{ 'WORK_EQUIPMENT.equipHours' | translate }}"
                                >
                            </mat-form-field>
                        </div>   
                        <div class="col-lg-3 col-xs-6">
                            <mat-form-field class="wksInput form-group" appearance="standard">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipHoursDate' | translate }}</mat-label>
                                <input matInput id="equipHoursDate"class="form-control  inputTheme" formControlName="equipHoursDate" 
                                        placeholder="{{ 'WORK_EQUIPMENT.equipHoursDate' | translate }}" #equipHoursDate
                                         >
                            </mat-form-field>
                        </div>    
                        <div class="col-lg-6 col-xs-12">
                            <mat-form-field class="wksInput form-group" appearance="standard" style="margin-top: -15px;">
                                <mat-label>{{ 'WORK_EQUIPMENT.equipAddInfo' | translate }}</mat-label>
                                <textarea  id="equipAddInfo" class="form-control inputTheme textarea1" formControlName="equipAddInfo" 
                                            matInput #equipAddInfo placeholder="{{ 'WORK_EQUIPMENT.equipAddInfo' | translate }}"
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            style="height: 50px; min-height: 50px;"
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="4"
                                ></textarea >
                            </mat-form-field>
                        </div>
                    </div>   
                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>
