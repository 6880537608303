<div  class="addressForm container-fluid" style="margin: 0px; padding: 0px;">
    <form [formGroup]="addressForm" id="addressForm"  >
        <div *ngIf="isFormLoaded " class="justify-content-center stdTheme" style="margin-right: 0px;margin-left: 0px;">
             <!-- debut bloc denomination -->
            <div *ngIf="corporate || contact" class="flex-row row" style="width: 100%; margin-right: 0px; margin-left: 0px;">
                <div *ngIf="corporate" #corporateBlock class="col-lg-8 col-xs-12 stdTheme" formGroupName="corporateBlockFg" 
                                                style="padding-right: 2px; padding-left: 0px;height: 51px" >
                    <mat-form-field class="itemAddress form-group" appearance="standard" style="margin-bottom: 5px">
                        <mat-label>{{ 'ADDRESS_BLOCK.CORPORATE_NAME' | translate }}</mat-label>
                        <input id="corporateName" class="form-control inputTheme corporate" #corporateName formControlName="corporateName" 
                                    matInput placeholder="{{ 'ADDRESS_BLOCK.CORPORATE_NAME' | translate }}"
                                    (keyup)="corporateChange(corporateName.value)"
                        >
                    </mat-form-field>
                </div> <!-- fin bloc denomination -->
                <!-- debut bloc contact -->
                <div *ngIf="contact" #contactBlock class="stdTheme" formGroupName="contactBlockFg"  
                                                style="width: 100%; margin-right: 0px; margin-left: 0px;padding-right: 0px; padding-left: 0px" >
                    <div class="flex-row row" style="width: 100%; margin-right: 0px; margin-left: 0px">
                        <div class="col-lg-2 col-xs-12" style="padding-right: 2px; padding-left: 0px">
                            <mat-form-field class="itemAddress form-group" appearance="standard"  >
                                <mat-label>{{ 'ADDRESS_BLOCK.CNT_CIVILITY' | translate }}</mat-label>
                                <mat-select id="cntCivility" #cntCivility placeholder="{{ 'ADDRESS_BLOCK.CNT_CIVILITY' | translate }}" formControlName="cntCivility" 
                                            panelClass="mdiselectcontent" required
                                            (selectionChange)="contactChange('civility',$event)"
                                            ngDefaultControl >
                                    <mat-option class="selectOptionsTheme" *ngFor="let optCiv of civilityList" [value]="optCiv.item">{{ optCiv.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-xs-12" style="padding-right: 2px; padding-left: 2px">
                            <mat-form-field class="itemAddress form-group" appearance="standard"  >
                                <mat-label>{{ 'ADDRESS_BLOCK.CNT_FIRST_NAME' | translate }}</mat-label> 
                                <input id="cntFirstname" matInput class="addressItem" formControlName="cntFirstname" #cntFirstname
                                        #input placeholder="{{ 'ADDRESS_BLOCK.CNT_FIRST_NAME' | translate }}" required
                                        (keyup)="contactChange('firstname',cntFirstname.value)"
                                        (change)="contactChange('firstname',cntFirstname.value)"
                                >
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-xs-12" style="padding-right: 0px; padding-left: 2px">
                            <mat-form-field class="itemAddress form-group" appearance="standard"  >
                                <mat-label>{{ 'ADDRESS_BLOCK.CNT_LAST_NAME' | translate }}</mat-label>
                                <input id="cntLastname" matInput class="addressItem" formControlName="cntLastname"  #cntLastname
                                        #input placeholder="{{ 'ADDRESS_BLOCK.CNT_LAST_NAME' | translate }}" required
                                        (keyup)="contactChange('lastname',cntLastname.value)"
                                        (change)="contactChange('lastname',cntLastname.value)"
                                >
                            </mat-form-field>
                        </div>
                    </div>
                  
                    <div *ngIf="isProfessionnal" class="flex-row row" style="width: 100%; margin-right: 0px; margin-left: 0px"
                                            [ngStyle]="{'margin-top': calMarginTopField()}">
                        
                        <div  class="col-lg-4 col-xs-12" style="padding-right: 0px; padding-left: 0px" >
                            <mat-form-field  style="width: 100%;" class="itemAddress form-group" >
                                <mat-label >{{ 'ADDRESS_BLOCK.CNT_DEPT' | translate }}"</mat-label>       
                                <mat-select #selectDept class="stdTheme stdSelect" formControlName="selectDept" 
                                            ngDefaultControl multiple 
                                            (selectionChange)="contactChange('selectDept',selectDept.value)">
                                    <mat-option class="selectOptionsTheme" *ngFor="let deptCur of deptList"  [value]="deptCur.value">
                                            {{ deptCur.label }}
                                    </mat-option>
                                    <div class="flex-row button-row text-center" style="margin-bottom: 5px;" >
                                        <button mat-raised-button class="buttonTheme"  (click)="selectDept.toggle()">
                                          {{'Close' | translate}}
                                          <mat-icon>cancel</mat-icon>
                                        </button>    
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div  class="col-lg-4 col-xs-12" style="padding-right: 0px; padding-left: 0px;" 
                                            [ngStyle]="{'margin-left': calMarginLeftField()}">
                            <mat-form-field  style="width: 100%;" class="itemAddress form-group" >
                                <mat-label >{{ 'ADDRESS_BLOCK.CNT_FONCT' | translate }}"</mat-label>   
                                <input id="fonctionDept" class="addressItem" formControlName="fonctionDept" 
                                        matInput #fonctionDept placeholder="{{ 'fonctionDept' | translate }}"
                                        (keyup)="contactChange('fonctionDept',fonctionDept.value)"
                                            > 
                            </mat-form-field>
                        </div>
                    </div>
                   
                </div><!-- fin bloc contact -->
            </div>
            <div class="flex-row row" style="width: 100%; margin-right: 0px; margin-left: 0px;">
                <div *ngIf="contact" formGroupName="selectAppsFg"  class="col-lg-4 col-xs-12" 
                                                style="padding-right: 0px; padding-left: 0px;padding-top: 15px;">
                    <mat-form-field  style="width: 100%;" class="itemAddress form-group">
                        <mat-label >{{ 'ADDRESS_BLOCK.CNT_APPS' | translate }}</mat-label>       
                        <mat-select #selectApps class="stdTheme stdSelect"  panelClass="panelOptions" #listApps  formControlName="selectApps" 
                                    ngDefaultControl multiple required
                                    (selectionChange)="contactChange('selectApps',selectApps.value)">
                            <mat-option class="selectOptionsTheme" *ngFor="let app of appList"  [value]="app">
                                    {{ app }}
                            </mat-option>
                            <div class="flex-row button-row text-center" style="margin-bottom: 5px;">
                                <button mat-raised-button class="buttonTheme"  (click)="selectApps.toggle()">
                                  {{'Close' | translate}}
                                  <mat-icon>cancel</mat-icon>
                                </button>    
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="identifierInput" formGroupName="identifierBlockFg" class="col-lg-4 col-xs-12" 
                                            style="padding-right: 2px; padding-left: 2px;padding-top: 15px;">
                    <mat-form-field style="width: 100%"  class="itemAddress form-group">
                        <mat-label >{{identifier.elemLabel}}</mat-label>
                        <input matInput class='addressItem' type="text" id="identifier"
                            [attr.maxlength]="identifier.dataLengthMax"
                            [ngStyle]="{width: calWidthCol('contactBlock', null,identifier.dataLengthMax,38)}"
                            [ngClass]="{
                                'inputAlert': identifier.inputError,
                                'inputNormal': !identifier.inputError
                                }"
                            [required] = "identifierMandatory === true ? true : false"
                            [value]="identifier.inputValue" 
                            (input)="formatField('identifier', -1, -1, identifier,$event)"
                            formControlName="identifier"
                            appFormatField="{'pattern': '{{identifier.controlFmt}}',
                                    'displayFmt': '{{identifier.displayFmt}}',
                                    'displayUnfmt': '[ -]',
                                    'fieldType': 'text'}"
                            >
                    </mat-form-field>
                </div>
             </div>
             
            <div class="lineWks" style="margin-top: 15px;"></div>
        </div> <!-- fin bloc contact -->
        <div *ngIf="address" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;margin-bottom: 10px;">
           <!--<div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;">--> 
                <div class="col-lg-3 col-xs-12" style="padding-right: 0px; padding-left: 0px">
                    <mat-form-field  style="width: 100%;">
                        <mat-label >{{ 'ADDRESS_BLOCK.SELECT_COUNTRY' | translate }}"</mat-label>       
                        <mat-select class="stdTheme stdSelect"  panelClass="panelOptions" #listCountries  
                                    ngDefaultControl formControlName="countrySelect"
                                    [(value)]="countryKeyToLoad"
                                    (selectionChange)="onSelectionCountryChangeMat($event)">
                            <mat-select-header>
                                <div class="filterInput" style="display: inline-block;" >
                                    <button mat-icon-button matPrefix aria-label="search" >
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                                style="margin: 5px" (keyup)="onKeyCountry(filtercountry.value)"
                                                formControlName="filterCountry" >
                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </mat-select-header>
                            <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList"  [value]="country.isoAlpha2">
                                    {{country.commonName | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-8 col-xs-12">
                    <div #addressBlock class="stdTheme margin-0 padding-0" *ngIf="address && isFormLoaded && isAddressLoaded" formGroupName="addressBlockFg" >
                        <div *ngFor="let rowCur of rowArray; let iRow = index" >
                            <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                                <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                                        [ngStyle]="{'width': calWidthCol('addressBlock',rowCur.cols,colCur.dataLengthMax,38),
                                                    'margin-left': calMarginLeft(iCol)}"
                                        [ngSwitch]="colCur.controlType">
                                    <!--<div  class="col-lg-6 col-xs-12" >-->
                                        <div *ngSwitchDefault>
                                            <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                                                <mat-label >{{colCur.elemLabel}}</mat-label>
                                                <input matInput class="addressItem" type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                                                    [attr.maxlength]="colCur.dataLengthMax"
                                                    [ngClass]="{
                                                        'inputAlert': colCur.inputError,
                                                        'inputNormal': !colCur.inputError
                                                        }"
                                                    [value]="colCur.inputValue" 
                                                    [required] = "(colCur.dataMandatory === 'O' ? true : false)"
                                                    (input)="formatField('address', iRow, iCol, colCur,$event)"
                                                    formControlName="{{colCur.dataName}}"
                                                    >
                                            </mat-form-field>
                                        </div> 
                                        <div *ngSwitchCase="'zipcode'">
                                            <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                                                <mat-label >{{colCur.elemLabel}}</mat-label>
                                                <input matInput class='addressItem' type="text" id="addressItem_+{{colCur.elmtNumber}}" 

                                                    [attr.maxlength]="colCur.dataLengthMax"
                                                    [ngClass]="{
                                                        'inputAlert': colCur.inputError,
                                                        'inputNormal': !colCur.inputError
                                                        }"
                                                    [value]="colCur.inputValue" 
                                                    (input)="formatField('address', iRow, iCol, colCur, $event)"
                                                    [required] = "(colCur.dataMandatory === 'O' ? true : false)"
                                                    formControlName="{{colCur.dataName}}"
                                                    appFormatField="{'pattern': '{{colCur.controlFmt}}',
                                                        'displayFmt': '{{colCur.displayFmt}}',
                                                        'displayUnfmt': '[ ()-]',
                                                        'fieldType': 'text'}"
                                                    >
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'statesList'">
                                            <mat-form-field style="width: 100%" appearance="standard">
                                                <mat-label class="stdTheme">{{ 'state' | translate }}</mat-label>
                                                <mat-select class="stdTheme"  panelClass="panelSelectContent" #stateSelected  
                                                            disableOptionCentering
                                                            id="{{colCur.dataName}}"
                                                            required formControlName="{{colCur.dataName}}" 
                                                            (selectionChange)="onSelectionStateChangeMat($event)" >
                                                    <div class="filterInput" style="display: inline-block;" >
                                                        <input class="searchTheme" #filterState placeholder="{{ 'filterInput' | translate }}" 
                                                                formControlName="filterState" style="margin: 5px" (keyup)="setFilterState(filterState.value)" >
                                                        <button mat-icon-button matSuffix aria-label="clear" 
                                                                    *ngIf="filterState.value" (click)="filterState.value='';setFilterState(filterState.value)">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                    </div>
                                                    <mat-option class="stdTheme stdSelect" *ngFor="let state of statesFilteredList" 
                                                            class="selectOptionsTheme"  [value]="state.stateAbb" >
                                                            {{state.stateName }} ({{state.stateAbb}})
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> 
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                    </div> <!-- end addressBlock -->
                </div>
            <!--</div>-->
            <div class="lineWks" style="margin-top: 20px;"></div>
        </div>
        <div #latLngBlock *ngIf="inputLatLng" formGroupName="latLngBlockFg">
            <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                <div class="col-lg-2 col-xs-12" style="height: 35px; margin-top: 30px;">
                    <a><h6>{{ 'addressPosition' | translate }}</h6></a>
                </div>
                <div class="col-lg-5 col-xs-12"  >
                    <mat-form-field class="itemAddress form-group" appearance="standard" style="text-align: center; height: 40px;">
                        <button  matPrefix type="button" class="btn btn-default" mat-icon-button (click)="displayMap()" >
                            <mat-icon style="margin-top: -10px;margin-bottom: 0px;" >my_location</mat-icon>
                        </button>
                        <input id="addressPosition" class="form-control inputTheme" formControlName="addressPosition" 
                                        #addressPosition
                                        (change)="positionChange()"  matInput  readonly  >
                    </mat-form-field>
                </div>
                <div class="col-lg-3 col-xs-12" >
                    <mat-form-field class="itemAddress form-group" appearance="standard" style="text-align: center;">
                        <input id="addressPositionCoord" class="form-control inputTheme" formControlName="addressPositionCoord" 
                                        matInput #addressPositionCoord  readonly  >
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-xs-2" >
                    <button mat-icon-button class="buttonTheme" matTooltip="{{ 'addressPosition' | translate }}" 
                                (click)="checkGeoLocAddress()"
                            style="margin-left: 10px;margin-right: 10px; margin-top: 15px; font-size: 40px">
                        <mat-icon class="mat-icon-work">location_searching</mat-icon>
                    </button>
                </div>
            </div>
            <!-- <mat-icon> edit_location</mat-icon>-->

            <div class="lineWks" style="margin-top: 15px;"></div>
        </div>
        <div #telephoneBlock *ngIf="telephone && isFormLoaded && isPhonesLoaded" formArrayName="telephoneListFg"  >
            <div *ngFor= "let phoneCur of phonesList; let rowTel=index; let isLast=last" >
                <div class="flex-row row stdTheme" [formGroupName]="rowTel" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-lg-12 col-xs-12" style="width: 100%;padding-right: 0px; padding-left: 0px">
                        <table class="addressTable table table-bordered table-striped table-responsive-sm" border="0" cellpadding="0" cellspacing="0">
                            <tbody class="stdTheme" style="width: 100%;display: table;">
                                <tr  style="height: 100%">
                                    <td class="addressTable" style="width: 5%; padding-bottom: 0px;">
                                       <!-- <i class="fa fa-phone fieldInlineBst " style="font-size:20px;"></i>-->
                                       <div class="row">
                                            <button class="buttonFunction" mat-icon-button >
                                                <mat-icon class="iconeFunction" >phone</mat-icon>
                                            </button>
                                        </div>
                                    </td>
                                    <td class="addressTable" style="width: 30%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                        <mat-form-field  style="width: 90%">
                                            <mat-label >{{ 'ADDRESS_BLOCK.Country' | translate }}"</mat-label>       
                                            <mat-select panelClass="panelSelectContent" #phoneCountries  
                                                        placeholder="{{ 'Select country' | translate }}" 
                                                        disableOptionCentering
                                                        id="phoneCountries"
                                                        [(value)]="phoneCur.phoneCountry"
                                                        (selectionChange)="onPhoneCountryChangeMat($event, rowTel)"
                                                        >
                                                <mat-select-header>
                                                    <div class="filterInput" style="display: inline-block;" >
                                                        <button mat-icon-button matPrefix aria-label="search" >
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                        <input class="searchTheme" #filtercountry1 placeholder="{{ 'filterInput' | translate }}" 
                                                                    style="margin: 5px" (keyup)="onKeyCountry(filtercountry1.value)" >
                                                        <button mat-icon-button matSuffix aria-label="clear" 
                                                                    *ngIf="filtercountry1.value" (click)="filtercountry1.value=''; onKeyCountry('');">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-select-header>
                                                <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList"  [value]="country.isoAlpha2">
                                                        {{country.commonName | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <!--
                                    <td class="addressTable" style="width: 15%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                        <mat-form-field *ngIf="phoneCur.phoneCountry != countryDefault" style="width : 3em;">
                                            <mat-label >{{'phonePrefix' | translate }}</mat-label>
                                            
                                            <input class="textFieldFilter" matInput type="text" id="phonePrefix"
                                                readonly value="{{phoneCur.phonePrefix}}"  >
                                        </mat-form-field> 
                                    </td>
                                    -->
                                    <td class="addressTable" style="width: 30%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                        <div class="row" style="margin-left: 0px;margin-right: 0px;">
                                            <div class="col-2" style ="margin-top: 24px;padding-left: 30px; padding-right: 0px;">
                                                <span  *ngIf="phoneCur.phoneCountry != countryDefault" >
                                                    {{phoneCur.phonePrefix}}</span>
                                            </div>
                                            <div class="col-8">
                                                <mat-form-field [style.width.%]="100" >
                                                    <mat-label >{{'ADDRESS_BLOCK.phoneNumber' | translate }}</mat-label>
                                                    <input matInput type="text" id="phoneNumber" #phoneNumber 
                                                            [ngClass]="{
                                                                'inputAlert': phoneCur.inputError,
                                                                'inputNormal': !phoneCur.inputError
                                                                }"
                                                            [value]="phoneCur.phoneNumber"    
                                                            [attr.maxlength]="phoneCur.phoneLength" 
                                                            (input)="formatField('telephone',rowTel, 0, phoneCur,$event)"
                                                            appFormatField="{'pattern': '{{phoneCur.controlFmt}}',
                                                                'displayFmt': '{{phoneCur.displayFmt}}',
                                                                'displayUnfmt': '[ ()]',
                                                                'fieldType': 'phone'}"
                                                            formControlName="phoneNumber"
                                                            >
                                                </mat-form-field> <!-- end phoneNumber-->
                                            </div>
                                        </div>
                                    </td>
                                    <td class="addressTable" style="width: 25%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                        <mat-form-field  class="textFieldFilter" style="width: 90%" >
                                            <mat-label >{{ 'ADDRESS_BLOCK.phoneType' | translate }}</mat-label>
                                            <mat-select  panelClass="panelSelectContent" #selectPhoneType 
                                                        placeholder="{{ 'phoneType' | translate }}" 
                                                        disableOptionCentering
                                                        id="selectPhoneType"
                                                        [(value)]="phoneCur.phoneType"
                                                        (selectionChange)="onSelectionPhoneTypeChangeMat($event, rowTel)"  >
                                                <mat-option *ngFor="let phoneTypeCur of phoneTypeList" 
                                                            class="selectOptionsTheme" [value]="phoneTypeCur">
                                                            {{'ADDRESS_BLOCK.' + phoneTypeCur | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field> <!-- end phoneType-->
                                    </td>     
                                    <td class="addressTable" style="width: 10%; padding: 0px; vertical-align: middle;">
                                        <div *ngIf="!isReadOnly" class="row" style="padding-bottom: 0px; margin-left: 0px; margin-right: 0px; vertical-align: middle;">
                                            <button class="buttonFunction" mat-icon-button (click)="addPhone(rowTel)" >
                                                <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                                            </button>
                                            <button class="buttonFunction" mat-icon-button (click)="removePhone(rowTel)">
                                                <mat-icon class="iconeFunction" >remove_circle_outline</mat-icon>
                                            </button>
                                        </div>
                                    </td>    
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> <!-- end telephoneBlock-->  
        <div #emailsBlock *ngIf="email && isFormLoaded && isEmailsLoaded" formArrayName="emailsListFg" >
            <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px;" 
                            *ngFor= "let emailCur of emailsList; let rowEmail=index; let isLast=last" 
                [formGroupName]="rowEmail">
                <div class="col-lg-12 col-xs-12" style="width: 100%;padding-right: 0px; padding-left: 0px">
                    <table class="addressTable table table-bordered table-striped table-responsive-sm">
                        <tbody class="stdTheme" style="width: 100%;display: table;" >
                            <tr style="height: 100%">
                                <td class="addressTable" style="width: 5%; padding-bottom: 0px">
                                    <!-- <i class="fa fa-at fieldInlineBst " style="font-size:20px;"></i> -->
                                    <div class="row">
                                        <button class="buttonFunction" mat-icon-button >
                                            <mat-icon class="iconeFunction" >email</mat-icon>
                                        </button>
                                    </div>
                                </td>
                                <td class="addressTable" style="width: 15%">

                                </td>
                                <td class="addressTable" style="width: 45%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                    <div class="row" style="margin-left: 0px;margin-right: 0px;">
                                        <mat-form-field style="width: 95%;" >
                                            <mat-label >{{'ADDRESS_BLOCK.emailAddress' | translate }}</mat-label>
                                            <input matInput  type="email" id="emailAddress" #emailAddress 
                                                style="width: 90%"
                                                [ngClass]="{
                                                    'inputAlert': emailCur.inputError,
                                                    'inputNormal': !emailCur.inputError
                                                    }"
                                                [value]="emailCur.emailAddress"    
                                                [attr.maxlength]="emailAttr.emailLengthMax" 
                                                (input)="formatField('email',rowEmail, 0, emailCur,$event)"
                                                formControlName="emailAddress"
                                            >
                                        </mat-form-field> <!-- end emailAddress-->   
                                    </div>
                                </td>
                                <td class="addressTable" style="width: 25%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                    <mat-form-field class="textFieldFilter" style="width: 95%;" >
                                        <mat-label >{{ 'ADDRESS_BLOCK.emailType' | translate }}</mat-label>
                                        <mat-select   panelClass="panelSelectContent" #selectEmailType 
                                                    placeholder="{{ 'emailType' | translate }}" 
                                                    disableOptionCentering
                                                    id="selectEmailType"
                                                    [value]="emailCur.emailType"
                                                    (selectionChange)="onSelectionEmailTypeChangeMat($event, rowEmail)"  >
                                            <mat-option class="selectOptionsTheme" *ngFor="let emailTypeCur of emailAttr.emailTypeList" 
                                                    [value]="emailTypeCur"> 
                                                    {{'ADDRESS_BLOCK.' + emailTypeCur | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field><!-- end emailType-->
                                </td> 
                                <td class="addressTable" style="width: 10%; padding: 0px; vertical-align: middle;">
                                    <div class="row" *ngIf="!isReadOnly" style="padding-bottom: 0px; margin-left: 0px; margin-right: 0px; vertical-align: middle;" >
                                        <button class="buttonFunction" mat-icon-button (click)="addEmail(rowEmail)" >
                                            <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                                        </button>
                                        <button class="buttonFunction" mat-icon-button (click)="removeEmail(rowEmail)">
                                            <mat-icon class="iconeFunction" >remove_circle_outline</mat-icon>
                                        </button>
                                    </div><!-- end butttons -->
                                </td>    
                            </tr>
                        </tbody>
                    </table>
                </div> <!-- end butttons -->
            </div>  <!-- end email loop -->   
        </div>  <!-- end email block-->  
    </form>
</div>
<ng-template #modalMap let-modal >
    <app-google-location [googleMaps] = "googleMaps"  ></app-google-location>
</ng-template>
