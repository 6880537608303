import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { TranslateService  } from '@ngx-translate/core';

import { AuthService } from '../../../../../services/auth.service';
import { WorksService } from '../../../../../services/works.service';
import { SharingDataService } from '../../../../../services/sharing-data.service';

import { WksWorksModel, TasksActionsModel, WorkEquipmentRef, IhmOptions,  TasksActionCompletedModel, WksEquipmentModel,
          TaskrefJsonModel, WksWorksTracking  } from '../../../../../models';


export interface CheckEquipment {
  equipDesign: string;
  equipId: string;
  equipSN: string;
  equipHours: number;
  equipFailed: boolean;
  missingParts: boolean;
  isCollapsed: boolean;
  // tasksCompleted: TasksActionCompletedModel[];
  tasksList: {
    taskrefId: string;
    taskrefLabel: string;
    missingParts: boolean;
  }[];
}

@Component({
  selector: 'app-verification-ckecklist',
  templateUrl: './verification-ckecklist.component.html',
  styleUrls: ['./verification-ckecklist.component.less']
})
export class VerificationCkecklistComponent implements OnInit {

  modalTitle: string;
  workId: string;
  workCur: WksWorksModel;
  ihmOptions: IhmOptions;

  checkEquipmentsIsLoaded: boolean;
  itemChoose: string;
  nameParent: string;

  checkEquipments: WksEquipmentModel [];
  checkEquipmentsList: CheckEquipment[];

  isReadOnly: boolean;
  isCheckLoaded: boolean;
  isOkToValid: boolean;
  itemEquip: WksEquipmentModel;
  itemTasks: TasksActionCompletedModel;
  tasksActionsCompleted: TasksActionCompletedModel[];
  tasksActionsArgs: TasksActionCompletedModel[];
  equipLabels: string[];

  constructor(private dialogRef: MatDialogRef<VerificationCkecklistComponent>,
              private worksService: WorksService,
              private translateService: TranslateService,
              private sharingDataService: SharingDataService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.modalTitle = data.title;
                this.workId = data.workId;
                this.ihmOptions = data.ihmOptions;
  }
  ngOnInit(): void {
    this.initBooleans();
    this.buildForm();
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.worksService.getWorksById(this.workId)
    .subscribe(
      data => {
        this.workCur = data.body as WksWorksModel;
        if (this.ihmOptions === undefined) {
          this.getIhmOptions();
        }
        if (this.workCur.workCustomer.bsnAddress) {
          const dataAddress = JSON.parse(this.workCur.workCustomer.bsnAddress);
          if ((this.workCur.workCustomer.bsnType === 'company') || (this.workCur.workCustomer.bsnType === 'administration')) {
            this.nameParent = dataAddress.corporateName;
          } else {
            this.nameParent = dataAddress.identity.cntLastname + ' ' + dataAddress.identity.cntFirstname;
          }
        }
        this.checkEquipments = [];
        if (this.workCur.wksSubEquipments) {
          this.checkEquipments = this.workCur.wksSubEquipments;
        } else {
          this.checkEquipments.push(this.workCur.wksMainEquipment);
        }
        this.fillCheckTasks();
        this.itemEquip = this.checkEquipments[0];
        this.tasksActionsArgs = [];
        for (const itemTasks of this.tasksActionsCompleted) {
          if (itemTasks.equipId === this.itemEquip.id) {
            this.tasksActionsArgs.push(itemTasks);
          }
        }
        this.checkEquipmentsIsLoaded = true;
      },
      err =>  {
        console.log('getWorksById ' + err.message);
      }
    );

  }
  getIhmOptions(): void {
    this.ihmOptions = {
      readOnly: true
    };

    if (this.workCur === undefined) {
      return;
    }
    // this.ihmOptions = this.worksService.getIhmOptions(this.workCur.workStatut, this.workCur.workStatut);
    this.ihmOptions = this.worksService.getIhmOptions(this.workCur.workStatut, 'checkList');
  }
  fillCheckTasks(): void {

    let worksActionsSelected: TasksActionsModel[] = [];
    let suppliesActionsSelected: TasksActionsModel[] = [];

    this.tasksActionsCompleted = [];
    let workEquipmentId: WorkEquipmentRef;
    let numberEquip = 0;
    workEquipmentId = JSON.parse(this.workCur.workEquip);
    if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
      numberEquip = 1;
    } else {
      numberEquip = workEquipmentId.subEquipments.length;
    }
    this.checkEquipmentsList = [];
    for (const taskCur of this.workCur.workTasksContent) {
      worksActionsSelected = [];
      suppliesActionsSelected = [];

      const taskrefJson = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskrefJson.tasksActionCompleted !== undefined) {
        // this.tasksActionsCompleted = [...taskrefJson.tasksActionCompleted as TasksActionCompletedModel[]];
        for (const taskTmpCur of taskrefJson.tasksActionCompleted as TasksActionCompletedModel[] ) {
          if (taskTmpCur.equipId ===  this.workCur.wksMainEquipment.id) {
            taskTmpCur.equipSN = this.workCur.wksMainEquipment.equipSN;
          } else {
            if (this.workCur.wksSubEquipments) {
              for (const equipCur of this.workCur.wksSubEquipments) {
                if (taskTmpCur.equipId ===  equipCur.id) {
                  taskTmpCur.equipSN = equipCur.equipSN;
                  break;
                }
              }
            }
          }
          this.tasksActionsCompleted.push(taskTmpCur);
        }
      }
    }
    for (const equipItem of this.checkEquipments) {
      const equipmentItem: CheckEquipment = {
        equipDesign: (equipItem.equipName ? equipItem.equipName : equipItem.equipDesign),
        equipId: equipItem.id,
        equipSN: equipItem.equipSN,
        equipHours: (equipItem.equipHours ? equipItem.equipHours : 0),
        equipFailed: false,
        missingParts: false,
        tasksList: [],
        isCollapsed: true,
      };

      let missingPartsEquipCur = false;
      if (this.tasksActionsCompleted.length === 0) {
        equipmentItem.missingParts = true;
        missingPartsEquipCur = true;
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      } else {
        for (const taskItem of this.tasksActionsCompleted) {
          if (taskItem.equipId ===  equipItem.id) {
            if (taskItem.equipHours > 0 ) {
              equipmentItem.equipHours = taskItem.equipHours;
            }
            let missingPartsCur = false;
            if (taskItem.additionalTasks === undefined) {
              for (const suppliescur of taskItem.supplies) {
                if (suppliescur.actionTaken === undefined || suppliescur.actionTaken === null) {
                  missingPartsCur = true;
                  missingPartsEquipCur = true;
                  this.isOkToValid = false;
                  equipmentItem.equipFailed = true;
                  break;
                }
              }
            }
            const taskCur =  {
              taskrefId: taskItem.taskrefId,
              taskrefLabel: taskItem.taskrefLabel,
              missingParts: missingPartsCur
            };
            if (taskItem.equipHours > 0 ) {
              equipmentItem.equipHours = taskItem.equipHours;
            }
            equipmentItem.missingParts = missingPartsEquipCur;
            equipmentItem.tasksList.push(taskCur);
          }
        }
      }
      if (equipmentItem.equipSN === '' || equipmentItem.equipSN === undefined  || equipmentItem.equipSN === null)  {
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      }
      if (equipmentItem.equipHours < 1 || equipmentItem.equipHours === undefined  || equipmentItem.equipHours === null)  {
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      }
      this.checkEquipmentsList.push(equipmentItem);
    }
    this.isCheckLoaded = true;
  }

  initBooleans(): void {
    this.checkEquipmentsIsLoaded = false;
    this.isReadOnly = true;
    this.isCheckLoaded = false;
    this.isOkToValid = true;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  lockWork(): any {

    this.fillModel();

    return new Promise((resolve, reject) => {
      this.worksService.lockWork(this.workCur).subscribe(result => {
        const reponseCur = result.body;
        this.dialogRef.close();
        resolve(reponseCur);
      },
      err => {
        console.log(err);
        reject(err.status);
      });
    });
  }
  fillModel(): void {
    this.workCur.statutChange = true;
    this.workCur.workStatut = 'finished';

    let wksWorksTracking: WksWorksTracking[] = [];

    if (this.workCur.workJsonData) {
      const workJsonData = JSON.parse(this.workCur.workJsonData);
      if (workJsonData.wksWorksTracking) {
        wksWorksTracking = JSON.parse(JSON.stringify(workJsonData.wksWorksTracking));
      }
    }

    wksWorksTracking.push({
      numberTrack: (wksWorksTracking.length === 0) ? 0 : wksWorksTracking.length,
      userLogged: this.authService.getUserLogged().username,
      recordedTrack: undefined,
      workStatut: this.workCur.workStatut,
      workComment: '',
      alarmsMes: undefined
      });
    this.workCur.wksWorksTracking =  wksWorksTracking;

  }
  buildForm(): void {
    this.equipLabels = [];
    this.equipLabels[0] = this.translateService.instant('WORK_EQUIPMENT.equipDesign');
    this.equipLabels[1] = this.translateService.instant('WORK_EQUIPMENT.equipHours');
    this.equipLabels[2] = this.translateService.instant('WORK_EQUIPMENT.equipSN');
    this.equipLabels[3] = this.translateService.instant('WORK_EQUIPMENT.missingParts');
  }
}
