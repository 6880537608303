import { Injectable, EventEmitter } from '@angular/core';
import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';
import { catchError, tap, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { SharingDataService } from '../services/sharing-data.service';
import { AuthService } from '../services/auth.service';
import { WksWorksModel, WksAnchoragesModel, LabelValue, IhmOptions, WksBusinessModel, WksWorksDocs, UserModel, WksEquipmentModel } from '../models';
import { CommonMethods } from '../components/common/tools/commonMethods';
import { BrandWksModel, WksUuidLinksModel } from '../models/works/wks-common.model';

@Injectable({
    providedIn: 'root'
  })
export class WorksService {

    wksAnchorages: LabelValue[];
    wksAnchoragesWintering: LabelValue[];

    constructor(private  httpClient: HttpClient,
                private sharingDataService: SharingDataService,
                private authService: AuthService,
                private translateService: TranslateService) {}

    public getIhmOptions(statut: string, option: string): IhmOptions {

        let ihmOptions: IhmOptions = {
            readOnly: true
        };

        const workListTmp = this.sharingDataService.getWorkStatuts();
        let isFound = false;
        for (const statutCur of workListTmp) {
            if (statutCur.label === statut) {
                for ( const options of statutCur.ihmOptions ) {
                    if (options.optionName === option) {
                        /*
                        ihmOptions = {
                            readOnly: options.readOnly
                        };
                        */
                        ihmOptions = options;
                        isFound = true;
                        break;
                    }
                }
            }
            if (isFound) {
                break;
            }
        }
        return ihmOptions;
    }
    // equipments
    getWksBrandsList(entity: string): Observable<HttpResponse<BrandWksModel[]>> {
        const urlCur = `${environment.baseUrl}/works/equipment/brands/?` + 'entity=' + entity;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<BrandWksModel[]>(urlCur, undefined,
          {
            headers: localHttpHeaders,
            observe: 'response'
          })

          .pipe(
            // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
              // console.log(err.status);
              return observableThrowError(err);
            } ));
    }
    getWksModelsList(entity: string, brandName: string, equipType: string): Observable<HttpResponse<BrandWksModel[]>> {

        equipType = CommonMethods.cleanURLString(equipType);
        const urlCur = `${environment.baseUrl}/works/equipment/models/?` + 'entity=' + entity + '&brandname=' + brandName
                                            + '&equiptype=' + equipType;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<BrandWksModel[]>(urlCur, undefined,
            {
            headers: localHttpHeaders,
            observe: 'response'
            })
            .pipe(
            // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
                // console.log(err.status);
                return observableThrowError(err);
            } ));
    }
    getWksEquipmentListOwner(entity: string, ownerId: string, equipType: string): Observable<HttpResponse<WksEquipmentModel[]>> {
        const urlCur = `${environment.baseUrl}/works/equipment/owner/?` + 'entity=' + entity + '&ownerId=' + ownerId
                                + '&equipType=' + equipType;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksEquipmentModel[]>(urlCur, undefined,
          {
            headers: localHttpHeaders,
            observe: 'response'
          })
          .pipe(
            // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
              // console.log(err.status);
              return observableThrowError(err);
            } ));
    }
    getWksEquipmentListBoat(entity: string, equipPartOf: string, equipType: string): Observable<HttpResponse<WksEquipmentModel[]>> {
        const urlCur = `${environment.baseUrl}/works/equipment/partof/?` + 'entity=' + entity + '&equipPartOf=' + equipPartOf
                                    + '&equipType=' + equipType;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksEquipmentModel[]>(urlCur, undefined,
          {
            headers: localHttpHeaders,
            observe: 'response'
          })
          .pipe(
            // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
              // console.log(err.status);
              return observableThrowError(err);
            } ));
    }

    getWksEquipmentByEquipId(entity: string, id: string): Observable<HttpResponse<WksEquipmentModel>> {

    const urlCur = `${environment.baseUrl}/works/equipment/equipid/?` + 'entity=' + entity + '&id=' + id;
    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
    return this.httpClient.post<WksEquipmentModel>(urlCur, undefined,
        {
        headers: localHttpHeaders,
        observe: 'response'
        })
        .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        } ));
    }
    // Work
    getWorksById(workId: string): Observable<HttpResponse<WksWorksModel>> {
        const userlang = this.authService.getUserLogged().userlang;
        const entity = this.authService.getUserLogged().entity;
        const urlCur = `${environment.baseUrl}/works/wksworksById/?` + 'entity=' + entity + '&userlang=' + userlang + '&id=' + workId  ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();

        return this.httpClient.post<WksWorksModel>(urlCur, undefined,
        {
            headers: localHttpHeaders,
            observe: 'response'
        })
            .pipe(
                catchError(err => {
                return observableThrowError(err);
        }));
    }
    saveWorks(wksWorksCur: WksWorksModel, actionRequest: string): Observable<HttpResponse<WksWorksModel>> {
        const userlang = this.authService.getUserLogged().userlang;
        const entity = this.authService.getUserLogged().entity;
        const urlCur = `${environment.baseUrl}/works/saveWork/?` + 'entity=' + entity + '&userlang=' + userlang
                            + '&actionRequest=' + actionRequest ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksWorksModel>(urlCur, wksWorksCur, {
            headers: localHttpHeaders,
            observe: 'response',
          });
    }
    lockWork(wksWorksCur: WksWorksModel): Observable<HttpResponse<WksWorksModel>> {
        const userlang = this.authService.getUserLogged().userlang;
        const entity = this.authService.getUserLogged().entity;
        const urlCur = `${environment.baseUrl}/works/lockWork/?` + 'entity=' + entity + '&userlang=' + userlang ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksWorksModel>(urlCur, wksWorksCur, {
            headers: localHttpHeaders,
            observe: 'response',
          });
    }
    // quotationValidation
    getQuotationValidation(entity: string, encodedReference: string): Observable<HttpResponse<WksUuidLinksModel>> {
        // const userlang = this.authService.getUserLogged().userlang;
        // const entity = this.authService.getUserLogged().entity;
        const urlCur = `${environment.baseUrl}/works/quotationValidation/?` + 'entity=' + entity + '&encodedReference=' + encodedReference ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksUuidLinksModel>(urlCur, null, {
            headers: localHttpHeaders,
            observe: 'response',
          });
    }
    updateQuotation(entity: string,
                    userlang: string,
                    wlReference: string,
                    docId: string,
                    workId: string,
                    action: string): Observable<HttpResponse<WksWorksModel>> {


        // workId = CommonMethods.cleanURLString(workId);
        // docId = CommonMethods.cleanURLString(docId);
        // wlReference = CommonMethods.cleanURLString(wlReference);
        const urlCur = `${environment.baseUrl}/works/updateQuotation/?` + 'entity=' + entity + '&userlang=' + userlang
                                + '&workId=' + workId + '&docId=' + docId + '&wlReference=' + wlReference + '&action=' + action;

        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient
          .post<WksWorksModel>(urlCur, undefined, {
            headers: localHttpHeaders,
            observe: 'response',
          });
      }
    getWorkDoc(entity: string, docId: string): Observable<HttpResponse<WksWorksDocs>> {
        const urlCur = `${environment.baseUrl}/works/workdoc/?` + 'entity=' + entity + '&docId=' + docId ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksWorksDocs>(urlCur, null, {
            headers: localHttpHeaders,
            observe: 'response',
          });
    }
    /*========================================== anchorages =======================*/
    getWksAnchoragesList(): Observable<HttpResponse<WksAnchoragesModel[]>> {
        const urlCur = `${environment.baseUrl}/works/getAllAnchorages/`;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        return this.httpClient.post<WksAnchoragesModel[]>(urlCur, undefined,
        {
            headers: localHttpHeaders,
            observe: 'response'
        })
        .pipe(
        // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
                // console.log(err.status);
                return observableThrowError(err);
        } ));
    }
    public loadAnchorages(): any {

        return new Promise<LabelValue[] | void>((resolve, reject) => {
            this.getWksAnchoragesList()
            .subscribe(
                data => {
                    const dataList = data.body as WksAnchoragesModel[];
                    this.extractListAnchorages(dataList);
                    resolve() ;
                },
                err => {
                    console.log(err);
                    if ((err.status === 500) || (err.status === 404)) {
                        console.log('loadAnchorages : ' + err.message);
                    }
                    reject(err.status);
                }
            );
        });
    }
    extractListAnchorages(dataList: any[]): LabelValue[]  {
        const anchoragesCacheList: LabelValue[] = [];
        const anchoragesWinteringList: LabelValue[] = [];
        for (const anchorageCur of dataList) {
            const countryTmp = this.sharingDataService.getCountry(anchorageCur.anchorageCountry);
            const anchorageCountryLabel =
                this.sharingDataService.getLabelCountry(this.translateService.currentLang.toUpperCase(), countryTmp.commonName);
            const labelCur: LabelValue = {
                value: anchorageCur.id,
                label: anchorageCur.anchorageName + ' - ' + anchorageCur.anchorageCity + ' (' + anchorageCountryLabel + ')',
                otherData: anchorageCur.anchoragePosition,
            };
            anchoragesCacheList.push(labelCur);
            if (anchorageCur.anchorageWintering) {
                anchoragesWinteringList.push(labelCur);
            }
        }
        this.setWksAnchorages(anchoragesCacheList);
        this.setWksAnchoragesWintering(anchoragesWinteringList);
        return anchoragesCacheList;

    }
    public setWksAnchorages(wksAnchorages: LabelValue[]): void {
        this.wksAnchorages = wksAnchorages;
    }
    public getWksAnchorages(): LabelValue[] {
        return this.wksAnchorages;
    }
    public setWksAnchoragesWintering(wksAnchorages: LabelValue[]): void {
        this.wksAnchoragesWintering = wksAnchorages;
    }
    public getWksAnchoragesWintering(): LabelValue[] {
        return this.wksAnchoragesWintering;
    }
    loadCountries(): Observable<HttpResponse<string>> {

        // return this.http.get('/getallfiles');
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/countries`;

        return this.httpClient.post<string>(urlCur, undefined,
          {
            headers: localHttpHeaders,
            observe: 'response'
          });
    }
// ========================================================================= wks-business ===
    getCustomerByContact(contactId: string, entity: string): Observable<HttpResponse<WksBusinessModel>> {
        const userlang = this.authService.getUserLogged().userlang;

        const urlCur = `${environment.baseUrl}/works/customerByContact/?` + 'entity=' + entity + '&userlang=' + userlang
                                    + '&id=' + contactId  ;
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();

        return this.httpClient.post<WksBusinessModel>(urlCur, undefined,
        {
            headers: localHttpHeaders,
            observe: 'response'
        })
            .pipe(
                catchError(err => {
                return observableThrowError(err);
        }));
    }
    saveWksBusiness(bsnCard: WksBusinessModel, statutCall: string): Observable<HttpResponse<WksBusinessModel>> {

    const urlCur = `${environment.baseUrl}/works/savebsn/?` + 'action=' + statutCall;
    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();

    const body = JSON.stringify(bsnCard);

    return this.httpClient
      .post<WksBusinessModel>(urlCur, body, {
        headers: localHttpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
            catchError(err => {
                // console.log(err.status);
                return observableThrowError(err);
            } )
        );
  }
  getPdfDoc(path: string): Observable<HttpResponse<any>> {
    path = CommonMethods.cleanURLString(path);
    const urlCur = `${environment.baseUrl}/works/document/download/?` + 'path=' + path;

    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
    localHttpHeaders.set('Accept', 'application/pdf');
    return this.httpClient.post<any> ( urlCur, undefined,
      {
        headers: localHttpHeaders,
        observe: 'response',
        // JLG - old version - responseType: 'blob' as 'json',
        responseType: 'arraybuffer' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
}
