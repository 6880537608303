import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';
import { WksEntityParams, WksEntity, WksEntityImg, UserLogged, CommonReport, InternationalsFormats,
  CountryLib, CountryDef,  MotorsConfigModel, PropulsionsModel, ThrusterConfigModel, TrailersOptions, NameValue, WorkStatutModel } from '../models';
import * as I18N_FR from '../../assets/i18n/countries_FR.json';
import * as I18N_EN from '../../assets/i18n/countries_EN.json';

@Injectable({
  providedIn: 'root'
})
export class SharingDataService {

  private civilityList = ['mrs', 'miss', 'mr'];

  private wksEntityParams: WksEntityParams;
  private wksEntity: WksEntity;
  private countryFmt: any;
  private userLogged: UserLogged;
  private wksLogoHome: WksEntityImg;

  private wksParamsStr: any;
  private wksParamsJson: any;

  public commonReportSubject = new Subject<CommonReport>();
  public synchroEvents = new Subject();
  private intFormats: Map<string, InternationalsFormats>;

  private allCountriesStr: any;
  private allCountriesJson: any;
  private countriesList: CountryLib[];
  private workStatutsEntity: WorkStatutModel[];

  i18nFr: any;
  i18nEn: any;
  constructor() {
    this.i18nFr = JSON.parse(JSON.stringify(I18N_FR)).default;
    this.i18nEn = JSON.parse(JSON.stringify(I18N_EN)).default;
   }
   public getDeptsList(): string[] {
    return ['purchase department', 'juridical department', 'financial department', 'technical department'];
  }
  public setIntFormats(intFormatArgs: Map<string, InternationalsFormats>): void  {

    this.intFormats = intFormatArgs;
    // this.getFormat('EN', 'numbers', 'currency');
  }
  public getComponentsList(): string[] {
    return this.wksParamsJson.typologies.components;
  }
  public getEquipmentsList(): any[] {
    return this.wksParamsJson.typologies.equipments;
  }
  public getCountriesList(): CountryLib[] {
    return this.countriesList;
  }
  public setWksEntityParams(wksEntityParams: WksEntityParams): void {
    this.wksEntityParams = wksEntityParams;
  }
  public setWksEntity(wksEntity: WksEntity): void {
    this.wksEntity = wksEntity;
  }
  public setCountryFmt(countryFmt: any): void {
    this.countryFmt = countryFmt;
  }
  public setUserLogged(userLogged: UserLogged): void {
    this.userLogged = userLogged;
  }
  public setWksEntityImg(wksLogoHome: WksEntityImg): void {
    this.wksLogoHome = wksLogoHome;
  }
  public setWksParams(wksParams: any, entityActivity: string): void {
    this.wksParamsStr = JSON.stringify(wksParams);
    this.wksParamsJson = JSON.parse(this.wksParamsStr);
    this.workStatutsEntity = [];
    if (entityActivity === 'workshop') {
      this.workStatutsEntity = this.wksParamsJson.workStatuts.workshop;
    }
    if (entityActivity === 'concierge') {
      this.workStatutsEntity = this.wksParamsJson.workStatuts.concierge;
    }
  }
  public getWorkStatuts(): WorkStatutModel[] {
    return this.workStatutsEntity;
  }
  public getWksEntityParams(): WksEntityParams {
    return this.wksEntityParams;
  }
  public getWksEntity(): WksEntity {
    return this.wksEntity;
  }
  public getCountryFmt(): any {
    return this.countryFmt;
  }
  public getUserLogged(): UserLogged {
    return this.userLogged;
  }
  public getWksEntityImg(): WksEntityImg {
    return this.wksLogoHome;
  }
  public getIntFormat(keyFmt: string): InternationalsFormats {
    /*
    Object.keys(this.intFormats).map(key => {
      const locCountry = this.intFormats[key] as InternationalsFormats;
      console.log(key, locCountry);
    });
*/
    let intFormat: InternationalsFormats;
    const objKeys = Object.keys(this.intFormats);
    const objValues = Object.values(this.intFormats);
    let idx = 0;
    for (const objKey of objKeys) {
      // console.log(objKey);
      if (objKey ===  keyFmt) {
        intFormat = objValues[idx];
        break;
      }
      idx++;
    }

    // const intFormat = this.intFormats.get(keyFmt);
    return intFormat;
  }
  public getFormat( keyFmt: string, fmtType: string, fmtName: string ): string {
    const intFormat: InternationalsFormats = this.getIntFormat(keyFmt);
    let fmtReturn: string;
    let namesValues: NameValue[];
    switch (fmtType) {
      case 'datetime':
        namesValues = intFormat.datetime;
        break;
      case 'numbers':
        namesValues = intFormat.numbers;
        break;
      case 'separators':
        namesValues = intFormat.separators;
        break;
      case 'justifs':
        namesValues = intFormat.justifs;
        break;
      default:
        return null;
    }
    if ((namesValues === null)  || (namesValues === undefined)  || (namesValues.length === 0)) {
      return null;
    }
    for (const nameValue of namesValues) {
      if (nameValue.name === fmtName)  {
        fmtReturn = nameValue.value;
        break;
      }
    }
   // console.log(fmtReturn);
    return fmtReturn;
  }
  public setCountries(countries: any): void {
    this.allCountriesStr = countries;
    this.allCountriesJson = JSON.parse(this.allCountriesStr);
    this.countriesList = [];
    Object.keys(this.allCountriesJson).map(key => {
      const locCountry = this.allCountriesJson[key] as CountryDef;
      this.countriesList.push({
        isoAlpha2: locCountry.isoAlpha2,
        commonName: locCountry.commonName,
      });
    });
  }
  public getCountry(countryKey: string): CountryDef {

    const locCountry = this.allCountriesJson[countryKey] as CountryDef;
    // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[countryKey])) as CountryDef;
    return locCountry;
  }
  public getLabelCountry(langKey: string, labelKey: any): string {
    let translateLabel: string;
    const map = new Map<string, string>();
    switch (langKey) {
      case 'FR': {
        translateLabel = this.i18nFr[labelKey];
        break;
      }
      case 'EN': {
        translateLabel = this.i18nEn[labelKey];
        break;
      }
      default: {
        translateLabel = this.i18nEn[labelKey];
        break;
      }
    }
    return translateLabel;
  }
  public getCivilityList(): string[] {
    return this.civilityList;
  }
  public getPropulsionsList(): PropulsionsModel[] {
    return this.wksParamsJson.typologies.propulsions;
  }
  public getMotorsconfigList(): MotorsConfigModel[] {
    /*const wksParamsJsonLocal = JSON.parse(this.wksParamsStr);
    return wksParamsJsonLocal.typologies.motorsconfig;*/
    return this.wksParamsJson.typologies.motorsconfig;
  }
  public getThrustersconfigList(): ThrusterConfigModel[] {
    return this.wksParamsJson.typologies.thrustersconfig;
  }
  public getTrailersOptions(): TrailersOptions {
    return this.wksParamsJson.trailersoptions;
  }
      // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Intl/NumberFormat
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
        //  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number));
        // (new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
        // new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR", currencyDisplay: "symbol"}).format(number);

  public instantNumber(value: number, words?: string | string[] ): any { // add optional parameter

    if (words[0] === 'price') {
        return new Intl.NumberFormat(words[1], { minimumFractionDigits: 2 }).format(value);
    } else if (words[0] === 'quantity') {
        let minFractionDigit = -1;
        let maxFractionDigit = -1;
        if (words.length > 2 ) {
            minFractionDigit = parseInt(words[2], 10);
        }
        if (words.length > 3 ) {
            maxFractionDigit = parseInt(words[3], 10);
        }
        const fractionDigit = {
            minimumFractionDigits: (minFractionDigit > -1 ? minFractionDigit : undefined ),
            maximumFractionDigits: (maxFractionDigit > -1 ? maxFractionDigit : undefined )
        };
        return new Intl.NumberFormat(words[1], fractionDigit).format(value);
    } else if (words[0] === 'currency') {
        let displayCur = 'symbol';
        if (words.length > 3 ) {
            displayCur = words[3];
        }
        const styleCurrency = {
            style: 'currency',
            currency: words[2],
            currencyDisplay: displayCur
        };
        return new Intl.NumberFormat(words[1], styleCurrency).format(value);
    } else {
        return value;
    }
  }
}
