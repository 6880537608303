
<mat-card class="stdTheme">
    <mat-card-title style="text-align: center;" >{{ 'APPOINTMENTS.WELCOME' | translate }}</mat-card-title> 
    <mat-card-content>
      <fieldset class="informationsborder">
        <div class="logo" >
          <img [src]="logoToShow | safeHtml : 'resourceUrl' "  [ngStyle]="{'width': wksLogoHome.width, 'height': wksLogoHome.height }" >
        </div>
      </fieldset>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button  mat-raised-button  (click)="startProcess()">
        {{ 'APPOINTMENTS.START-PROCESS' | translate }}
        <mat-icon class="matIconMiddle" >navigate_next</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>