<div *ngIf="isOkToDisplay" #refElement class="container-fluid stdTheme"
        [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px'}" >
    <div style="padding-right: 5px; padding-left: 5px;" >
        <fieldset class="fieldsetEquipment" >
            <legend *ngIf="isBoat || isTrailer || isJet" style="width: 70%">
                <div style="display: inline-block;" >
                    <span *ngIf="mainEquipNameLegend !== undefined" style="margin-right: 15px;">{{ 'APPOINTMENT_DETAIL.EQUIPMENT_MAIN' | translate }} ( {{ mainEquipNameLegend }} )</span>
                    <span *ngIf="mainEquipNameLegend === undefined" style="margin-right: 15px;">{{ 'APPOINTMENT_DETAIL.EQUIPMENT_MAIN' | translate }}</span>
                    <button mat-icon-button
                        (click)="isEquipmentCollapse = !isEquipmentCollapse"
                        [attr.aria-expanded]="!isEquipmentCollapse" aria-controls="collapseExample">
                            <span *ngIf="isEquipmentCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                            <span *ngIf="!isEquipmentCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                    </button> 
                </div>
            </legend>
            <legend *ngIf="!isBoat && !isTrailer && !isJet" style="width: 70%">
                <div style="display: inline-block;" >
                    <span style="margin-right: 15px;">{{ 'APPOINTMENT_DETAIL.EQUIPMENT_SUB' | translate }} ( Type : {{ subEquipTypeLegend }})</span>
                    <button mat-icon-button
                        (click)="isEquipmentCollapse = !isEquipmentCollapse"
                        [attr.aria-expanded]="!isEquipmentCollapse" aria-controls="collapseExample">
                            <span *ngIf="isEquipmentCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                            <span *ngIf="!isEquipmentCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                    </button> 
                </div>
            </legend>
            <div  [ngbCollapse]="isEquipmentCollapse">
                <div *ngIf="imgHelpLoaded" >
                    <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px;" >
                      <div class="col-12"  style="display:flex; justify-content: center; ">
                        <div class="logo" >
                          <img [src]="helpToShow | safeHtml: 'resourceUrl' " style="width: 290px; height: 300px;">
                        </div>
                      </div>
                    </div>
                    <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px;" >
                      <div class="col-12"  style="display:flex; justify-content: center; ">
                        <button mat-raised-button (click)="closeHelp()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                </div>
                <div *ngIf="!imgHelpLoaded">
                <form [formGroup]="equipmentForm"  style="margin: 0px; height: 100%;" >
                    <mat-card class="register-card stdTheme wkscard" layout="column" flex 
                            style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;padding-bottom: 10px;">
                        <mat-card-content class="stdTheme">
                            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;">
                                <div class="col-lg-4 col-xs-12 marginField" *ngIf="isBoat" style=" padding: 0px;height: 70px!important;" >
                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                        <mat-label>{{ 'WORK_EQUIPMENT.equipName' | translate }}</mat-label>
                                        <input id="equipName" class="form-control inputTheme" formControlName="equipName" 
                                                matInput #equipName placeholder="{{ 'WORK_EQUIPMENT.equipName' | translate }}" 
                                                (keyup)="updateData('equipName')" required>
                                    </mat-form-field>
                                </div> 
                            </div>
                            <div class="flex-row row" *ngIf="!isBoat && !isTrailer" style="margin-left: 0px;margin-right: 0px;width: 100%;margin-top: 5px">
                                <div class="col-lg-3 col-xs-12 marginField" style=" padding: 0px;height: 70px!important;">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" style="width: 100%">
                                        <mat-label>{{ 'WORK_EQUIPMENT.equipBrand' | translate }}</mat-label>
                                        <mat-select id="equipBrand" placeholder="{{'WORK_EQUIPMENT.equipBrand' | translate }}" formControlName="equipBrand" 
                                                    panelClass="mdiselectcontent" #equipBrand required
                                                    (selectionChange)="onChangeBrand(equipBrand.value)"
                                                    ngDefaultControl >
                                            <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                                [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                                </mat-option>
                                            </mat-select>                         
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3 col-xs-12 marginField" style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="mdiinput form-group" appearance="standard" style="width: 100%">
                                            <mat-label>{{  'WORK_EQUIPMENT.equipModel' | translate }}</mat-label>
                                            <mat-select id="equipModel" placeholder="{{  'WORK_EQUIPMENT.equipModel' | translate }}" formControlName="equipModel" 
                                                    panelClass="mdiselectcontent" #equipModel required
                                                    (selectionChange)="onChangeModel(equipModel.value)"
                                                    ngDefaultControl >
                                                <mat-option class="selectOptionsTheme" *ngFor="let modelCur of modelFilteredList" 
                                                    [value]="modelCur.modelName"  >{{modelCur.modelName}}
                                                </mat-option>
                                            </mat-select>    
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2 col-xs-12 marginField" *ngIf="ctrlInput.inputYear" style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.equipYear' | translate }}</mat-label>
                                            <mat-select id="equipYear" placeholder="{{ 'WORK_EQUIPMENT.equipYear' | translate }}" 
                                                    formControlName="equipYear" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipYear
                                                    (selectionChange)="updateData('equipYear')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let yearCur of listYears" 
                                                        [value]="yearCur"  >{{yearCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>    
                                    <div class="col-lg-2 col-xs-12 marginField" *ngIf="ctrlInput.inputHours" style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.equipHours' | translate }}</mat-label>
                                            <input id="equipHours" class="form-control inputTheme" 
                                            formControlName="equipHours" matInput #equipHours placeholder="{{ 'WORK_EQUIPMENT.equipHours' | translate }}"
                                            (keyup)="formatField('equipHours','', equipHours.value); updateData('equipHours')"
                                            >
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;width: 100%;margin-top: 5px">
                                    <div class="col-lg-3 col-xs-12  marginField" *ngIf="isBoat || isTrailer" style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="wksInput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.equipCountry' | translate }}</mat-label>
                                            <mat-select id="equipCountry" #equipCountry placeholder="{{ 'WORK_EQUIPMENT.equipCountry' | translate }}" formControlName="equipCountry" 
                                                        panelClass="panelOptions" ngDefaultControl required 
                                                        (selectionChange)="onChangeCountry(equipCountry.value)">
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matSuffix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filtercountry placeholder="{{ 'WORK_EQUIPMENT.filterCountry' | translate }}" 
                                                            (keyup)="onKeyCountry($event)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                                                <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                            <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                                                [value]="country.isoAlpha2"  >{{country.commonName | translate}}
                                            </mat-option>
                                        </mat-select>    
                                        </mat-form-field>
                                    </div>  
                                    <div class="col-lg-3 col-xs-12 marginField" *ngIf="isBoat || isTrailer" style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="wksInput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.equipImmat' | translate }}</mat-label>
                                            <input id="equipImmat" #equipImmat class="form-control inputTheme" formControlName="equipImmat" 
                                                        matInput placeholder="{{ 'WORK_EQUIPMENT.equipImmat' | translate }}" 
                                                        (keyup)="updateData('equipImmat')" >
                                        </mat-form-field>
                                    </div>
                                    
                                    <div class="col-lg-3 col-xs-12 marginField" *ngIf="(isBoat || isTrailer) && ctrlInput.inputSerialNumber" style=" padding: 0px;height: 70px!important;"> 
                                        <mat-form-field appearance="standard" class="wksInput form-group">
                                            <mat-label>{{ 'WORK_EQUIPMENT.equipSN' | translate }}</mat-label>
                            
                                            <input matInput class='serialNumber' type="text" id="serialNumber" 
                                            placeholder="{{ serialNumberInput.holder }}"
                                            [attr.maxlength]="serialNumberInput.dataLengthMax" 
                                            matInput #serialNumber 
                                            [ngClass]="{
                                                'inputAlert': serialNumberInput.inputError,
                                                'inputNormal': !serialNumberInput.inputError
                                                }"
                                            [value]="serialNumberInput.inputValue" 
                                            (input)="formatField('serialNumber',serialNumberInput,serialNumber.value)"
                                            formControlName="equipSN"
                                            appFormatField= "{{ formField }}"
                                            >
                                            <mat-icon matSuffix (click)="onHelp('serialNumber')">help_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="flex-row row" *ngIf="isBoat" style="margin-left: 0px;margin-right: 0px;width: 100%;margin-top: 5px">
                                    <div class="col-lg-3 col-xs-12 marginField" *ngIf="isBoat || isTrailer" 
                                                        style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field class="wksInput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.propulsion' | translate }}</mat-label>
                                            <mat-select id="equipBoatPropulsion" placeholder="{{ 'WORK_EQUIPMENT.propulsion' | translate }}" 
                                                    formControlName="equipBoatPropulsion" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #propulsion 
                                                    (selectionChange)="onChangePropulsion(propulsion.value)"
                                                        required>
                                                <mat-option class="selectOptionsTheme" *ngFor="let propulsionCur of propulsionsList" 
                                                    [value]="propulsionCur.propulsionName"  >{{'WORK_EQUIPMENT.' + propulsionCur.propulsionName | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-lg-3 col-xs-12 marginField" *ngIf="isMotorBoat || (isEngine && !isBoatEquip && !isThusterMotor)" 
                                                        style=" padding: 0px;height: 70px!important;">
                                        <mat-form-field  class="wksInput form-group" appearance="standard">
                                            <mat-label>{{ 'WORK_EQUIPMENT.motorsConfiguration' | translate }}</mat-label>
                                            <mat-select id="equipBoatConfiguration" #equipBoatConfiguration placeholder="{{ 'WORK_EQUIPMENT.motorsConfiguration' | translate }}" 
                                                    formControlName="equipBoatConfiguration" required
                                                    panelClass="mdiselectcontent"   ngDefaultControl (selectionChange)="onChangeMotorConfig(equipBoatConfiguration.value,'')">
                                                <mat-option class="selectOptionsTheme" *ngFor="let configCur of motorsConfigList" 
                                                    [value]="configCur.configName"  >{{'WORK_EQUIPMENT.' + configCur.configName | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </form>
                </div>
            </div>
        </fieldset>
    </div>
</div>
