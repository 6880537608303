import { Component, OnInit,  OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, HostListener  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Router } from '@angular/router';

import { ThemeService } from '../../../services/theme.service';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { SharingDataService } from '../../../services/sharing-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() optionItem: string;
  @Output() loginEvent = new EventEmitter<boolean>();
  @Output() returnData = new EventEmitter<string>();
  credentials = {
    login: '',
    password: ''
  };
  showPassword = false;
  private themeIsDark: boolean;
  authenticationFailed: boolean;
  scrHeight: string;
  scrWidth: string;
  sizeWidth: number;
  sizeHeight: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
        this.scrHeight = window.innerHeight + 'px';
        this.scrWidth = window.innerWidth + 'px';
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
  }

  constructor(private router: Router,
              private themeService: ThemeService,
              private sharingDataService: SharingDataService,
              private userService: UserService,
              private authService: AuthService) { }

  ngOnInit(): void{
    this.authenticationFailed = false;
    this.themeIsDark = this.themeService.isDarkTheme();
    this.themeService.setDarkTheme(this.themeIsDark);
   }
  toggleDarkTheme(event: any): void {
    this.themeIsDark = !this.themeIsDark;
    this.themeService.setDarkTheme(this.themeIsDark);
  }
  ngOnDestroy(): void { }

  authenticate(): void {
    this.authenticationFailed = false;
    this.authService.connectToken(this.credentials, true)
     .subscribe(
       (data)  => {
        this.authService.getUserDetail(this.credentials)
        .subscribe(
          (userDetail)  => {
              const oAuthResp = userDetail.body;
              const userLogged = {
                id: oAuthResp.id as number,
                username: oAuthResp.username as string,
                firstname: oAuthResp.firstname as string,
                lastname: oAuthResp.lastname as string,
                email: oAuthResp.email as string,
                entity: oAuthResp.entity as string,
                profile: oAuthResp.profile as string,
                datafilter: oAuthResp.datafilter as string,
                authorities: oAuthResp.authorities as string,
                usergroup: oAuthResp.usergroup as string,
                applications: oAuthResp.applications as string,
                userdata: oAuthResp.userdata as string,
              };
              this.sharingDataService.setUserLogged(userLogged);
              /*if (this.applicationCall === 'planning') {
                this.router.navigate(['/planning']);
              }*/
              this.loginEvent.emit(true);
            },
            (error) => {
              console.log('getUserDetail : ' + error);
              this.authenticationFailed = true;
              this.loginEvent.emit(false);
           }
          );
       },
       (error) => {
          console.log('connectToken : ' + error);
          this.authenticationFailed = true;
          this.loginEvent.emit(false);
       }

     );
  }
  getHeight(): string {
    return this.scrHeight;
  }
  returnHome(): void {
    this.returnData.emit('exitAuthentification');
  }
  changePassword(): void {
    this.authenticationFailed = false;
    this.returnData.emit('changePassword');
  }
}
