<div class="container-fluid" style="width: 100%;">
    <div *ngIf="!okToDisplay && isWaiting">
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="spinner-container  stdTheme col"  fxLayout="row" fxLayoutAlign="space-around center">
                <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
            </div>
        </div>
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="stdTheme col"  style="text-align: center;">
                <span > 
                    {{ 'APPOINTMENT_DETAIL.LOADING' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="okToDisplay"class="stdTheme" style="margin-top: 5px;margin-left: 10px;margin-right: 10px;">
        <h4  style="text-align: center;">{{customerName}}</h4>
        <mat-horizontal-stepper class="stdTheme" #stepper linear>
            <mat-step >
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.CUSTOMER_IDENTITY' | translate }}</span>
                </ng-template>
                <ng-container [ngTemplateOutlet]="customerFormTemplate" [ngTemplateOutletContext]="{valueArg: owner}" ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button (click)="returnHome()" >
                        <mat-icon>home</mat-icon>
                        {{ 'COMMUN.BUTTON.HOME' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext>
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step  [completed]="isEquipmentCompleted">
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.EQUIPMENT' | translate }}</span>
                </ng-template>
                <ng-container  [ngTemplateOutlet]="equipmentFormTemplate" [ngTemplateOutletContext]="{valueArg: owner}" ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext *ngIf="isEquipmentCompleted">
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step  [completed]="isServiceCompleted">
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.SERVICE' | translate }}</span>
                </ng-template>
                <ng-container  [ngTemplateOutlet]="serviceFormTemplate"  ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext *ngIf="isServiceCompleted">
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'ADDRESS_BLOCK.DONE' | translate }}</span>
                </ng-template>
                <p>{{ 'ADDRESS_BLOCK.DONE_LABEL' | translate }}</p>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button (click)="submitAppointment()" [disabled]="submitDone">
                        {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
                    </button>
                </div>
                <div *ngIf="submitDone && !workIsSave">
                    <div class="flex-row row  stdTheme" style="width: 100%;">
                        <div class="spinner-container  stdTheme col"  fxLayout="row" fxLayoutAlign="space-around center">
                            <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                        </div>
                    </div>
                    <div class="flex-row row  stdTheme" style="width: 100%;">
                        <div class="stdTheme col"  style="text-align: center;">
                            <span > 
                                {{ 'APPOINTMENT_DETAIL.REQUEST_VALIDATION' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>           
    </div>
</div>
<ng-template let-value="valueArg" #customerFormTemplate>
    <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
        <app-address-block 
            [config]="configAddress" 
            [dataIn]="addressCur" 
            (dataOut)="dataOutAddress($event)">
        </app-address-block>
    </div>
</ng-template>

<ng-template let-value="valueArg" #equipmentFormTemplate>
    <div *ngIf="equipmentChoiceDisplay" class="flex-row row stdTheme" style="margin: 0px;margin-bottom: 10px;">

        <form [formGroup]="equipmentChoiceForm" style="width: 100%">
            <div class="col-lg-6 col-xs-12">
                <div class="flex-row row stdTheme">
                    <div class="col-lg-4" style="padding-top: 20px;">
                        <label class="stdTheme ">{{ 'APPOINTMENT_DETAIL.EQUIPMENT_TYPE' | translate }}</label>
                    </div>
                    <div class="col-lg-6 col-xs-12" style="padding-top: 10px;">
                        <mat-select id="equipType" placeholder="{{ 'APPOINTMENT_DETAIL.EQUIPMENT_CHOICE_TYPE' | translate }}"
                            panelClass="mdiselectcontent" #equipTypeSelect ngDefaultControl
                            formControlName="equipType"
                            (selectionChange)="onChangeEquipType(equipTypeSelect.value)">
                            <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList"
                                [value]="equipTypeCur">{{equipTypeCur.labelType}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div *ngIf="choiceBoatDisplay" style="width: 100%" >
                    <div class="flex-row row stdTheme"  id="boatsSelect" style="padding-top: 10px;">
                        <div class="col-lg-4" style="padding-top: 20px;"></div>
                        <div class="col-lg-6 col-xs-12">
                            <div *ngIf="mainEquipmentsLoading">
                                <span class="blink_text">
                                    {{ 'APPOINTMENT_DETAIL.LOADING_BOATS' | translate }}
                                </span>
                            </div>
                            <div *ngIf="mainEquipmentsLoaded">
                                <mat-select id="workBoat" #workBoat panelClass="mdiselectcontent" ngDefaultControl
                                    placeholder=" {{ 'APPOINTMENT_DETAIL.CHOICE_BOATS' | translate }}"
                                    (selectionChange)="onChangeworkBoat(workBoat.value)" formControlName="workBoat">
                                    <mat-option class="selectOptionsTheme" *ngFor="let workBoatCur of boatsList"
                                        [value]="workBoatCur"> {{workBoatCur.equipName}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row row stdTheme"  id="boatsSelect" >
                        <div class="col-lg-4" style="padding-top: 20px;"></div>
                        <div class="col-lg-6 col-xs-12" *ngIf="mainEquipmentsLoaded" style="padding-top: 10px;">
                            <button type="button" class="stdTheme" style="border: 0 !important;" (click)="addMainEquipment()" id="CREATE_EQUIPMENT">
                                <i class="fa fa-plus"  aria-hidden="true"></i>
                                {{ 'APPOINTMENT_DETAIL.CREATE_FIRSTMAIN_EQUIPMENT' | translate }}
                            </button>
                        </div>
                    </div>
                </div>    
            </div>
        </form>
    </div>
    <div *ngIf="mainEquipmentDisplay" >
        <app-wks-appointment-equip
            [actionType]="mainEquipmentNew ? 'add' : 'update'" 
            [equipType]="mainItemType" 
            [mainEquipmentArg]="mainEquipmentCur"
            [subEquipmentArg]="undefined"
            (dataOutMain)="updateMainEquip($event)" 
            (dataOutSub)="updateSubEquip($event)"
            (dataOutForm)="formEquipInput($event)"
            >
        </app-wks-appointment-equip>
    </div>
    <div class="flex-row row stdTheme" *ngIf="numberSubEquipementsDisplay" style="padding-top: 10px; margin-bottom: 10px">
        <div class="col-lg-6 col-xs-12">
            <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'APPOINTMENT_DETAIL.NUMBER_SUB_EQUIP' | translate }}</mat-label>
                <input id="numberSubEquip" class="form-control inputTheme"  
                        matInput #numberSubEquip placeholder="{{ 'APPOINTMENT_DETAIL.NUMBER_SUB_EQUIP' | translate }}" 
                        (keyup)="numberOfSubEquipInput(numberSubEquip.value)" required>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="subEquipmentDisplay" >
        <app-wks-appointment-equip
            [actionType]="subEquipmentNew ? 'add' : 'update'" 
            [equipType]="equipTypeSelected.equipType" 
            [mainEquipmentArg]="undefined"
            [subEquipmentArg]="subEquipmentsCur"
            (dataOutMain)="updateMainEquip($event)" 
            (dataOutSub)="updateSubEquip($event)"
            (dataOutForm)="formEquipInput($event)"
            >
        </app-wks-appointment-equip>
    </div>
</ng-template>
<ng-template #serviceFormTemplate>
    <form [formGroup]="serviceForm" style="min-height: 350px!important;">
        <div class="flex-row row wkscard stdTheme" style="margin-right: 0px;margin-left: 0px;height:80px" >
            <div class="col-lg-3 col-xs-12 marginField" style="padding-left: 0px;padding-right: 0px;">
              <mat-form-field appearance="standard" class="wksNormalInput form-group" >
                <mat-label>{{ 'APPOINTMENT_DETAIL.ASKED_SERVICE' | translate }}</mat-label>
                <mat-select id="askedService" formControlName="askedService" 
                    #askedService required
                    (selectionChange)="onChangeService($event)"
                    ngDefaultControl >
                    <mat-option class="selectOptionsTheme" *ngFor="let serviceCur of servicesList" 
                                [value]="serviceCur.value"  >{{ serviceCur.label }}</mat-option>
                </mat-select>         
              </mat-form-field>
            </div>
            <div  *ngIf="displayCountHours" class="col-lg-2 col-xs-12 marginField" style="padding-left: 0px;padding-right: 0px;">
                <mat-form-field appearance="standard" floatLabel="never" class="wksNormalInput form-group">
                    <mat-label>{{ 'APPOINTMENT_DETAIL.HOURS_SERVICE' | translate }}</mat-label>
                    <button 
                        mat-icon-button 
                        matPrefix 
                        aria-label="Remove" 
                        (click)="counter('askedServiceHours',-100, askedServiceHours.value)" >
                        <mat-icon style="margin-top: 10px;">remove</mat-icon>
                    </button>
                    <input matInput formControlName="askedServiceHours" #askedServiceHours 
                                        readonly style="height: 30px;text-align: center" required />
                    <div matSuffix style="position: relative">
                        <button 
                            mat-icon-button
                            aria-label="Add" 
                            (click)="counter('askedServiceHours',100,  askedServiceHours.value)" >
                            <mat-icon style="margin-top: 10px;">add</mat-icon>
                        </button>
                        </div>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>