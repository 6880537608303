<div *ngIf="googleMaps.action === 'displayLatLng'" >
    <div [ngStyle]="{width: (displayWidth ? displayWidth : '100%'), 
                    height: (displayHeight ? displayHeight : '100%')}">
        <google-map [width]="null" [height]="null" [options]="googleMaps.mapOptions">
            <div  *ngFor="let markerItem of googleMaps.markers; let i = index">
                <map-marker [position]="markerItem.position" [title]="markerItem.title"></map-marker>
            </div>
        </google-map>
    </div>
</div>
<div >
    <input
      id="pacInput"
      #pacInput
      class="controls"
      type="text"
      placeholder=" {{ 'Search Box' | translate }}"
    />

</div>
<!--width: 618px; height: 859px; position:inherit; overflow: hidden;-->
<div id="mapLatLng" #mapLatLng [ngStyle]="{width: (displayWidth ? displayWidth : '100%'), 
                                            height: (displayHeight ? displayHeight : '100%'),
                                            position: 'inherit',
                                            overflow: 'hidden'}">

</div>