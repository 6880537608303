import { Pipe, PipeTransform } from '@angular/core';
import { SharingDataService } from '../services/sharing-data.service';

@Pipe({
  name: 'formatNumber',
  pure: false // add in this line, update value when we change language
})
export class FormatNumberPipe implements PipeTransform {

  constructor(private sharingDataService: SharingDataService) { }

  transform(value: number, args: string | string[] ): any { // args can be string or string array
      // tslint:disable-next-line:curly
      if (!value) return;
      return this.sharingDataService.instantNumber(value, args); // pass in args
  }

}
