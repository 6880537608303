<div *ngIf="formsBuildOK" class="container stdTheme col-lg-6 col-md-offset-3"  >
  <mat-card class="stdTheme" style="width: 100%">
    <mat-card-title style="text-align: center;" >{{ 'CREDENTIALS_BLOCK.PASSWORD_BOX_TITLE' | translate }}</mat-card-title> 
    <mat-card-content >
      <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px; width: 100%" >
        <form [formGroup]="identifierForm" style="width: 100%">
          <div class="col-lg-6 col-xs-12" style="padding-right: 2px; padding-left: 2px">
            <mat-form-field appearance="standard"  class="wksNormalInput" >
                <mat-label>{{ 'ADDRESS_BLOCK.IDENTIFIER' | translate }}</mat-label> 
                <input id="identifierName"  class="form-control inputTheme" matInput formControlName="identifierName" #identifierName
                        placeholder="{{ 'ADDRESS_BLOCK.IDENTIFIER_FMT' | translate }}" required
                        type="email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                        (change)="identifierNameChange(identifierName.value)"
                >
            </mat-form-field>
          </div>
        </form>
      </div>

      <div *ngIf="userDataJson !== undefined && userDataJson.securitiesDecrypt !== undefined"  >
        <fieldset class="fieldsetCredentials" >
          <legend *ngIf ="securitiesDecryptNb > 1" style="width: 40%">{{ 'CREDENTIALS_BLOCK.SECURITIES' | translate }}</legend>
          <legend *ngIf ="securitiesDecryptNb === 1" style="width: 40%">{{ 'CREDENTIALS_BLOCK.SECURITY' | translate }}</legend>
          <form [formGroup]="securitiesForm" style="width: 100%" >
            <div formArrayName="securities">
              <div *ngFor="let security of securities.controls; let i=index">
                <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 5px; width: 98%" [formGroupName]="i">
                  <div class="col-lg-6 col-xs-12" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
                    <mat-form-field class="wksNormalInput form-group" appearance="standard">
                      <mat-label>{{ 'CREDENTIALS_BLOCK.QUESTION' | translate }} {{i+1}}</mat-label>
                      <input  class="form-control inputTheme"
                          formControlName="question" 
                            matInput placeholder="{{ 'CREDENTIALS_BLOCK.QUESTION' | translate }}">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 col-xs-8 " style="margin-right: 5px;margin-left: 5px; padding-left: 0px; padding-right: 0px;">
                    <mat-form-field class="wksNormalInput form-group" appearance="standard">
                        <mat-label>{{ 'CREDENTIALS_BLOCK.ANSWER' | translate }} {{i+1}}</mat-label>
                        <input  class="form-control inputTheme"
                            formControlName="answer" (keyup)="ctrlAnswer(i)"
                            matInput placeholder="{{ 'CREDENTIALS_BLOCK.ANSWER' | translate }}">
                            <mat-error style="margin-bottom: 20px;font-size: 15px;" *ngIf="security.invalid">{{getErrorMessage('answer', i)}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
      </div>

      <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px; width: 100%">
        <form [formGroup]="passwordForm" *ngIf="securitiesOK && identifierOK" style="width: 100%">
          <div class="col-lg-6 col-xs-12 passwordField" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
            <mat-form-field class="wksNormalInput form-group" appearance="standard">
              <mat-label>{{ 'CREDENTIALS_BLOCK.PASSWORD' | translate }}</mat-label>
              <input id="password" class="form-control inputTheme" formControlName="password" 
                              matInput #password minlength="7" placeholder="{{ 'CREDENTIALS_BLOCK.PASSWORD' | translate }}"
                required [type]="showPassword ? 'password' : 'text'" (change)="sendPasswd();">
              <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              <mat-hint align="end">{{password.value?.length || 0}}/7</mat-hint>
              <mat-error style="margin-bottom: 20px;font-size: 15px;" *ngIf="passwordCtrl.invalid">{{getErrorMessage('password', 0)}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-xs-12" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
              <mat-form-field class="wksNormalInput form-group" appearance="standard">
                <mat-label>{{ 'CREDENTIALS_BLOCK.PASSWORD_CONFIRM' | translate }}</mat-label>
                <input id="confirm" class="form-control inputTheme" [type]="showPassword ? 'password' : 'text'" 
                        [readonly]="passwordCtrl.invalid"
                      formControlName="confirmPassword" [errorStateMatcher]="confirmValidParentMatcher"
                      matInput placeholder="{{ 'CREDENTIALS_BLOCK.PASSWORD_CONFIRM' | translate }}" required (change)="sendPasswd();"
                      (keyup)="ctrlPasswd()">
              <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              <mat-error style="margin-bottom: 20px;font-size: 15px;" *ngIf="confirmPasswordCtrl.invalid">{{getErrorMessage('confirmPassword', 0)}}</mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

    </mat-card-content>
    <mat-card-actions class="actions" style="margin-left: 0px;margin-right: 0px;">
      <div style="padding-bottom: 20px;display: flex;justify-content: space-between;">
        <button *ngIf="optionItem === 'authenticateCustomer'" mat-raised-button (click)="returnHome()" >
            <mat-icon>home</mat-icon>
            {{ 'COMMUN.BUTTON.HOME' | translate }}
        </button>
        <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!credentialsOK" (click)="savePasswd()" >
          <mat-icon>input</mat-icon>  {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>