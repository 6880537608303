import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { OauthResp, BodyResp, UserModel, UserAccount, UserResponse } from '../models';
import { CommonMethods } from '../components/common/tools/commonMethods';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpHeaders: HttpHeaders;
  httpEntityHeaders: HttpHeaders;
  httpParams: HttpParams;
  accessToken: string;
  jwtHelper = new JwtHelperService();
  userCur: UserModel;

  public userEvents = new BehaviorSubject<UserModel>(undefined);
  constructor(public  httpClient: HttpClient, private translate: TranslateService) {

  }
  saveUser(userCard: UserAccount, statutCall: string ): Observable<HttpResponse<UserResponse>> {
    let urlCur = `${environment.baseUrl}/users/addUser/`;

    if (statutCall === 'createUser') {
      urlCur = `${environment.baseUrl}/users/addUser/`;
    }
    // updatePwd
    if (statutCall === 'updatePwd') {
      urlCur = `${environment.baseUrl}/users/updatePwd/`;
    }
      // changePwd
    if (statutCall === 'changePwd') {
        urlCur = `${environment.baseUrl}/users/changePwd/`;
    }
    if (statutCall === 'disableUser') {
        urlCur = `${environment.baseUrl}/users/disableUser/`;
    }
    urlCur += userCard.username;
    // const body = { login, password, birthYear };
    const body = JSON.stringify(userCard);
    // this.retrieveUser();
    const localHttpHeaders: HttpHeaders  = this.getEntityHeaders();
    return this.httpClient
      .post<UserResponse>(urlCur, body, {
        headers: localHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );

  }
  connectToken(credentials: any, storeJwt: boolean): Observable<HttpResponse<OauthResp>> {

    const urlCur = `${environment.keycloakProp.url}/${environment.keycloakProp.realm}/protocol/openid-connect/token`;
    this.httpHeaders = new HttpHeaders ()
        .append('Content-Type', 'application/x-www-form-urlencoded')
        .append('Accept', 'application/json')
        // .append('rejectUnauthorized', 'false')
        // .append('usernamAccepte', 'application/json')
        // .append('Access-Control-Allow-Origin', '*')
        // .append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT')
        // .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Authorization')
          /*'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type,' +
          ' Access-Control-Request-Method, Access-Control-Request-Headers')*/
        ;
    const urlArgs = 'grant_type=password&username=' + credentials.login + '&password=' + credentials.password +
                    `&client_id=${environment.keycloakProp.clientId}&client_secret=${environment.keycloakProp.clientSecret}`  ;

    return this.httpClient
      .post<OauthResp>(urlCur, urlArgs, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        tap(userOauth => {
          if (storeJwt) {
            this.storeToken(userOauth);
          }
        }),
        catchError(this.handleErrorObservable)
      );
  }
  getHeaders(): HttpHeaders {
    return this.httpHeaders;
  }
  getEntityHeaders(): HttpHeaders {
    return this.httpEntityHeaders;
  }
  public getAccessToken(): string {
    return this.accessToken;
  }
  storeToken(userOauth: any): void {
    const userBody: BodyResp = userOauth.body;

    // const payload  = this.jwtHelper.decodeToken(userBody.access_token);
    // const organization = payload.organization;
    // console.log(organization);
    const mjlProperties = userBody.username + ';'
                    + userBody.accessentities + ';'
                    + userBody.accessgrps + ';'
                    + userBody.dataHabil;
    this.httpHeaders = new HttpHeaders ({
        authorization: `Bearer ${userBody.access_token}`,
        'content-type': 'application/json',
        accept: '*/*',
        'X-MjlProperties': `${mjlProperties}`
      });

    this.accessToken = userBody.access_token;

  }
  setEntityHeaders(entity: string): void {
    const mjlProperties = entity + ';entity;entity;entity';
    this.httpEntityHeaders = new HttpHeaders ({
      authorization: `Bearer ${this.accessToken}`,
      'content-type': 'application/json',
      accept: '*/*',
      'X-MjlProperties': `${mjlProperties}`
    });
  }
  public getUserLogged(): UserModel {
    return this.userCur;
  }
  public verifAccount(accountName: string): Observable<HttpResponse<UserAccount>> {

    const urlCur = `${environment.baseUrl}/users/verifUser/` + accountName;
    // this.retrieveUser();

    const localHttpHeaders: HttpHeaders  = this.getEntityHeaders();

    return this.httpClient.post<UserAccount>(urlCur, undefined,
    {
        headers: localHttpHeaders,
        observe: 'response'
    })
        .pipe(
            catchError(err => {
            return observableThrowError(err);
    }));
  }
  getUserDetail(credentials: any): Observable<HttpResponse<any>> {

    const urlCur = `${environment.baseUrl}/users/userDetail/` + credentials.login;
    // this.retrieveUser();

    return this.httpClient.get<any>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      }).pipe(
        tap(userOauth => this.storeLoggedInUser(userOauth)),
        catchError(this.handleErrorObservable) );
  }
  storeLoggedInUser(userOauth: any): void {
    const userBody: BodyResp = userOauth.body;

    const roles = CommonMethods.arrayStringToString(CommonMethods.jsonPropToArray(userBody.authorities, 'authority'), ',');
    const mjlProperties = userBody.username + ';'
                    + userBody.accessentities + ';'
                    + userBody.accessgrps + ';'
                    + userBody.dataHabil;

    this.httpHeaders = new HttpHeaders ({
        Authorization: `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-MjlProperties': `${mjlProperties}`
      });

    this.userEvents.next(this.oAuthToUser(userOauth));
  }
  oAuthToUser(oAuthCur: OauthResp): UserModel {
    const bodyCur: BodyResp = oAuthCur.body;

    const userConvert: UserModel = {
      id: bodyCur.id,
      username: bodyCur.username,
      password: null,
      firstname: bodyCur.firstname,
      lastname: bodyCur.lastname,
      sexe: bodyCur.sexe,
      birthday: bodyCur.birthday,
      email: bodyCur.email,
      datafilter: bodyCur.datafilter,
      authorities: bodyCur.authorities,
      accountNonExpired: bodyCur.accountNonExpired,
      accountNonLocked: bodyCur.accountNonLocked,
      credentialsNonExpired: bodyCur.credentialsNonExpired,
      enabled: bodyCur.enabled,
      entity: bodyCur.entity,
      profile: bodyCur.profile,
      usergroup: bodyCur.usergroup,
      bearerToken: this.accessToken,
      jti: bodyCur.jti,
      loglang: bodyCur.additionalInfo.loglang,
      userdebug: bodyCur.additionalInfo.userdebug,
      userlang: bodyCur.userlang,
      extendedgrp: bodyCur.extendedgrp,
      extendedentities: bodyCur.extendedentities,
      accessentities: bodyCur.accessentities,
      accessgrps: bodyCur.accessgrps,
      otherData: bodyCur.otherdata,
      forbiddenWords: bodyCur.additionalInfo.forbiddenWords,
      authorizedFunctionsList: bodyCur.additionalInfo.authorizedFunctionsList,
      dataCategories: bodyCur.additionalInfo.dataCategories,
      dataCategoriesTypeDefault: bodyCur.additionalInfo.dataCategoriesTypeDefault,
      dataCategoriesLevelDefault: bodyCur.additionalInfo.dataCategoriesLevelDefault,
      dataHabil: bodyCur.additionalInfo.dataHabil,
      entitycountry: bodyCur.entitycountry,
      entitylang: bodyCur.entitylang,
      applications: bodyCur.applications
    };
    this.userCur = userConvert;
    return userConvert;
  }
  // tslint:disable-next-line:typedef
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}
