<div #refElement class="container-fluid stdTheme workCardContent"
        [ngStyle]="{margin: '0px', 'margin-top': '10px', width: '100%', padding: '0px'}" >
    <form [formGroup]="clientForm" >
        <div class="flex-row row" style="margin: 0px;">
            <div class="col-12" style="padding-left: 0px;padding-right: 0px;">
                <mat-form-field class="wksInput form-group itemAddress" appearance="standard">
                    <mat-label>{{ 'WORK_CARD.CUSTOMER.BSNNAME' | translate }}</mat-label>
                    <input id="bsnName" class="form-control inputTheme" formControlName="bsnName" 
                            matInput #input placeholder="{{ 'WORK_CARD.CUSTOMER.BSNNAME' | translate }}" 
                    >
                </mat-form-field>
            </div>
        </div>
        <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
            <app-address-block 
                        [config]="configAddress" 
                        [dataIn]="addressCur" 
                        (dataOut)="dataOutAddress($event)"></app-address-block>
          </div>
    </form>
</div>
