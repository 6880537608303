<div  class="container-fluid stdTheme" style="padding-left: 0px;padding-right: 0px;" >
    <div *ngIf="!dataPdfLoaded" class="flex-row row" style="margin: 0px">
        <div class="container-fluid d-flex justify-content-center" >
            <mat-progress-bar 
                mode="indeterminate"
                style="margin-left: 30px;">
          </mat-progress-bar>
        </div>
    </div>
    <div *ngIf="dataPdfLoaded" class="flex-row row stdTheme" style="margin: 0px; margin-bottom: 5px; margin-top: 5px;">
        <div class="col-lg-6 col-xs-12 " style="padding-left: 0px;padding-right: 0px;">
            <select class="form-control stdTheme pdfselect" id="pdfSelect"  #pdfSelect style="width: auto;padding-left: 0px;padding-right: 0px;"  (change)="setPdfCur(pdfSelect.value)" >
                <option class="optionSelect" *ngFor="let pdfCur of pdfFiles" [selected]="pdfCur.fileLabel === selectedPdf.fileLabel" >{{ pdfCur.fileLabel}}</option>
            </select>
        </div>
        <div class="col-lg-6 col-xs-12" style="padding-left: 0px;padding-right: 0px;">
            <div class="container-fluid d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px;">

                <button  *ngIf="toValidated" mat-raised-button class="buttonTheme" 
                        (click)="validateEvent($event)"
                        style="margin-left: 5px; margin-right: 5px;padding-left: 5px;padding-right: 5px;">
                    <mat-icon><i class="material-icons">approval</i></mat-icon>
                    {{ 'WORK_DOCUMENT.validated' | translate }}
                </button>

                <button *ngIf="toApproved" mat-raised-button class="buttonTheme" 
                        (click)="refusedEvent($event)"
                        style="margin-left: 5px; margin-right: 5px;padding-left: 5px;padding-right: 5px;">
                    <mat-icon><i class="material-icons">report_off</i></mat-icon>
                    {{ 'WORK_DOCUMENT.refused' | translate }}
                </button>
                <button *ngIf="toApproved" mat-raised-button class="buttonTheme" style="margin-left: 5px; margin-right: 5px;padding-left: 5px;padding-right: 5px;"
                        (click)="approvedEvent($event)"
                        >
                    <mat-icon><i class="material-icons">approval</i></mat-icon>
                    {{ 'WORK_DOCUMENT.approved' | translate }}
                </button>

                <button *ngIf="displayClose" mat-raised-button class="buttonTheme" 
                        (click)="closeEvent($event)"
                        style="margin-left: 5px; margin-right: 5px;padding-left: 5px;padding-right: 5px;">
                    <mat-icon>close</mat-icon>
                    {{ 'WORK_DOCUMENT.close' | translate }}
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="dataPdfLoaded && toApproved" class="flex-row row stdTheme" style="margin: 0px; margin-bottom: 5px; margin-top: 5px;">
        <div class="col-lg-6 col-xs-12" style="padding-left: 0px;padding-right: 0px;">
            <p class="pdfselect">
                {{ quoteInWordWT }}
            </p>
            <p class="pdfselect">
                {{ 'WORK_DOCUMENT.quoteAccept' | translate }}
            </p>
        </div>
    </div>
    <div  class="flex-row row stdTheme" style="margin: 5px;">

<!-- 
    2022-10-14 JLG A voir pour interaction dropzone (ATTENTION EXEMPLE TRES INTERESSANT NE PAS PERDRE ): 
    https://stackblitz.com/edit/angular-ivy-ekxpyh?file=src%2Fapp%2Fpdf-viewer%2Fpdf-viewer.component.ts
-->
        <div class="col-lg-12 col-xs-12" style="height: 75vh; border-style: solid;border-radius: 25px;">
            
<!-- https://pdfviewer.net/extended-pdf-viewer/getting-started -->
<!--
            <ngx-extended-pdf-viewer [src]="srcPdf" [useBrowserLocale]="true"></ngx-extended-pdf-viewer>
-->
                <!--<ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>-->
                <pdf-viewer [src]="pdfSrc" 
                    [render-text]="true"
                    [show-borders]="false"
                    [original-size]="false"
                    [fit-to-page]="true"
                    [autoresize]="true"
                    (error)="onErroPdfviewer($event)"
                    style="display: block;width: 100%; height: 100%"
                ></pdf-viewer>
                
                <!--
                <ng2-pdfjs-viewer [src]="pdfSrc" 
                    [render-text]="true"
                    style="display: block;"
                    style="width: 100%; height: 100%"
                ></ng2-pdfjs-viewer>
                -->

           
        </div>
    </div>
</div>