import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleMapsModule } from '@angular/google-maps';
import { CoordinatesModule } from 'angular-coordinates';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CalendarModule, DateAdapter, CalendarCommonModule, CalendarMonthModule , CalendarDateFormatter } from 'angular-calendar';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppointmentFormComponent } from './components/job/appointment-form/appointment-form.component';
import { ClientFormComponent } from './components/job/appointment-form/client-form/client-form.component';
import { EquipmentFormComponent } from './components/job/appointment-form/equipment-form/equipment-form.component';
import { ConfirmationComponent } from './components/job/appointment-form/confirmation/confirmation.component';
import { HomeFormComponent } from './components/job/appointment-form/home-form/home-form.component';
import { GoogleLocationComponent } from './components/common/geolocation/google-location.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LocationFormComponent } from './components/job/appointment-form/location-form/location-form.component';
import { Page404Component } from './components/common/pages/page404/page404.component';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatFieldDirective } from './directives/format-field.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { LoginComponent } from './components/auth/login/login.component';
import { PlanningComponent } from './components/job/planning-card/planning/planning.component';
import { CalendarComponent } from './components/job/planning-card/calendar/calendar-card/calendar.component';
import { CalendarHeaderComponent } from './components/job/planning-card/calendar/utils/calendar-header/calendar-header.component';
import { ModalCommonComponent } from './components/common/modal-common/modal-common.component';
import { AlertCommonComponent } from './components/common/alert-common/alert-common.component';

import { CustomDateFormatter } from './components/job/planning-card/calendar/utils/customDateFormatter.component';

import { environment } from '../environments/environment';
import { RequestComponent } from './components/job/planning-card/request/request.component';
import { ChecklistComponent } from './components/job/planning-card/checklist/checklist.component';
import { WorkCardComponent } from './components/job/planning-card/work-card/work-card.component';
import { WorkCustomerComponent } from './components/job/planning-card/work-card/work-customer/work-customer.component';
import { WorkContactsComponent } from './components/job/planning-card/work-card/work-contacts/work-contacts.component';
import { WorkEquipmentsComponent } from './components/job/planning-card/work-card/work-equipments/work-equipments.component';
import { WorkInterventionComponent } from './components/job/planning-card/work-card/work-intervention/work-intervention.component';
import { AddressBlockComponent } from './components/common/address-block/address-block.component';
import { LocationWksComponent } from './components/common/location-wks/location-wks.component';
import { WorkLocationComponent } from './components/job/planning-card/work-card/work-location/work-location.component';
import { WorkChecklistComponent } from './components/job/planning-card/work-checklist/work-checklist.component';
import { TasksChecklistComponent } from './components/job/planning-card/work-checklist/tasks-checklist/tasks-checklist.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { VerificationCkecklistComponent } from './components/job/planning-card/work-checklist/verification-ckecklist/verification-ckecklist.component';

import { WksHomeComponent } from './components/job/wks-appointment/wks-home/wks-home.component';
import { CreateAccountComponent } from './components/job/wks-appointment/account/create-account/create-account.component';

import { UpdatePwdComponent } from './components/job/wks-appointment/account/update-pwd/update-pwd.component';
import { WksAppointmentDetailComponent } from './components/job/wks-appointment/wks-appointment-detail/wks-appointment-detail.component';
import { WksAppointmentEquipComponent } from './components/job/wks-appointment/wks-appointment-equip/wks-appointment-equip.component';
import { PdfViewerComponent } from './components/common/pdf-viewer/pdf-viewer.component';
import { QuotationWaitingComponent } from './components/job/wks-quotation/quotation-waiting/quotation-waiting.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
const maskConfig: Partial<IConfig> = {
  validation: false,
}; // NgxMaskModule

registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    AppointmentFormComponent,
    ClientFormComponent,
    EquipmentFormComponent,
    ConfirmationComponent,
    HomeFormComponent,
    LocationFormComponent,
    GoogleLocationComponent,
    SafeHtmlPipe,
    Page404Component,
    FormatFieldDirective,
    LoginComponent,
    PlanningComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    ModalCommonComponent,
    AlertCommonComponent,
    RequestComponent,
    ChecklistComponent,
    WorkCardComponent,
    WorkCustomerComponent,
    WorkContactsComponent,
    WorkEquipmentsComponent,
    WorkInterventionComponent,
    AddressBlockComponent,
    LocationWksComponent,
    WorkLocationComponent,
    WorkChecklistComponent,
    TasksChecklistComponent,
    FormatNumberPipe,
    VerificationCkecklistComponent,
    WksHomeComponent,
    CreateAccountComponent,
    UpdatePwdComponent,
    WksAppointmentDetailComponent,
    WksAppointmentEquipComponent,
    PdfViewerComponent,
    QuotationWaitingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    CoordinatesModule,
    GoogleMapsModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfig),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    PdfViewerModule,

    /*
    CalendarCommonModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CalendarMonthModule
    */
  ],
  providers: [
    {provide: CalendarDateFormatter, useClass: CustomDateFormatter}],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
