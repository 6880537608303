import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppointmentRequest, TelDef, LocalAddress, InternationalsFormats } from '../models';
import { WksEntityImg } from '../models';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    curHttpHeaders: HttpHeaders;

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getEntity(entityCur: string): Observable<HttpResponse<any>> {
        const urlService = `${environment.baseUrl}/getEntity/?` + 'entity=' + entityCur;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        const tokenCur = this.authService.getAccessToken();
        const httpHeaders = {
            Authorization: `Bearer ${tokenCur}`,
            'content-type': 'application/json',
            accept: '*/*'
          };
        // const httpOptions = {
        //    headers: new HttpHeaders({
        //        authorization: `Bearer ${tokenCur}`,
        //       'content-type': 'application/json',
        //        accept: '*/*'
        //    })
        // };
        // return this.http.post<any>(urlService, httpOptions);

        return this.http
        .post<any>(urlService, undefined, {
            headers: httpHeaders,
            observe: 'response'
        });

        // .pipe<any>(catchError(this.errorHandler));
    }
    loadTelDef(countryKey: string): Observable<HttpResponse<TelDef>> {

        const urlService = `${environment.baseUrl}/getTelephoneDef/?` + 'countrykey=' + countryKey;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        const tokenCur = this.authService.getAccessToken();
        const httpHeaders = {
            Authorization: `Bearer ${tokenCur}`,
            'content-type': 'application/json',
            accept: '*/*'
          };
        return this.http
        .post<TelDef>(urlService, undefined, {
            headers: httpHeaders,
            observe: 'response'
        });

    }
    loadLocalAddressDef(countryKey: string): Observable<HttpResponse<LocalAddress>> {

        const urlService = `${environment.baseUrl}/getLocalAddressDef/?` + 'countrykey=' + countryKey;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        const tokenCur = this.authService.getAccessToken();
        const httpHeaders = {
            Authorization: `Bearer ${tokenCur}`,
            'content-type': 'application/json',
            accept: '*/*'
          };
        return this.http
        .post<LocalAddress>(urlService, undefined, {
            headers: httpHeaders,
            observe: 'response'
        });

    }
    loadLocalDef(countryKey: string): Observable<HttpResponse<any>> {

        const urlService = `${environment.baseUrl}/getLocalDef/?` + 'countrykey=' + countryKey;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        const tokenCur = this.authService.getAccessToken();
        const httpHeaders = {
            Authorization: `Bearer ${tokenCur}`,
            'content-type': 'application/json',
            accept: '*/*'
          };
        return this.http
        .post<any>(urlService, undefined, {
            headers: httpHeaders,
            observe: 'response'
        });

    }
    loadWksParams(): Observable<HttpResponse<any>> {

        // return this.http.get('/getallfiles');
        const localHttpHeaders: HttpHeaders  =  this.authService.getEntityHeaders();
        const urlCur = `${environment.baseUrl}/getWksParams`;

        return this.http.post<any>(urlCur, undefined,
          {
            headers: localHttpHeaders,
            observe: 'response'
          });
      }
    loadCountryFmt(countryKey: string): Observable<HttpResponse<any>> {

        const urlService = `${environment.baseUrl}/getCountryFormat/?` + 'countrykey=' + countryKey;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        const tokenCur = this.authService.getAccessToken();
        const httpHeaders = {
            Authorization: `Bearer ${tokenCur}`,
            'content-type': 'application/json',
            accept: '*/*'
          };
        return this.http
        .post<any>(urlService, undefined, {
            headers: httpHeaders,
            observe: 'response'
        });

    }
    errorHandler(error: HttpErrorResponse): any {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.message}, ` +
            `error was: ${error.error.error}`);
        }
        // return an observable with a user-facing error message
        /*return throwError(
          'Something bad happened; please try again later.');*/
        return error.error;

    }
    getEntityParams(entityCur: string): Observable<HttpResponse<any>> {
        const urlService = `${environment.baseUrl}/getEntityParams/?` + 'entity=' + entityCur;
        // return this.http.get(urlService);
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        return this.http.post<any>(urlService, undefined,
        {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    downloadImg(fileName: string, entityCur: string, mimeApp: string, extension: string): Observable<any> {
       //  path = this.cleanURLString(path);

        // const fileExtension = _imgFile.split('.').pop();
        const urlCur = `${environment.baseUrl}/getEntityImg/?` + 'fileName=' + fileName  + '&entity=' + entityCur
                                                            + '&extension=' + extension;
        this.curHttpHeaders  = this.authService.getEntityHeaders();
        return this.http.post<any>(urlCur, undefined,
            {
                headers: this.curHttpHeaders,
                observe: 'response'
            }
        );

    }
    private handleErrorObservable(error: Response | any): any {
        console.error(error.message || error);
        return observableThrowError(error.message || error);
    }
    /**
     *  http://www.webanalytix.fr/comment-encoder-les-caracteres-speciaux-dans-les-urls/
     *
     */
    cleanURLString(strToClean: string): string {
        strToClean = strToClean.replace(/[&]/g, '%26');
        strToClean = strToClean.replace(/[ ]/g, '%20');
        return strToClean;
    }

    getTypeMime(suffixe: string): string {
        let returnMime: string;
        switch (suffixe) {
            case 'png':
                returnMime = 'image/png';
                break;
            case 'jpeg':
                returnMime = 'image/jpeg';
                break;
            case 'jpg':
                returnMime = 'image/jpeg';
                break;
        }
        return returnMime;
    }
    // tslint:disable-next-line:typedef
    save(appointment: AppointmentRequest): Observable<HttpResponse<AppointmentRequest>> {
        const urlService = `${environment.baseUrl}/appointments`;
        // console.log(JSON.stringify(appointment));
        this.curHttpHeaders  = this.authService.getHeaders();
        return this.http.post<AppointmentRequest>(urlService, appointment, {
            headers: this.curHttpHeaders,
            observe: 'response',
          });
    }
    getAppointment(uuidRef: string): Observable<HttpResponse<AppointmentRequest>> {
        const urlService = `${environment.baseUrl}/appointments/` + uuidRef;

        this.curHttpHeaders  = this.authService.getHeaders();
        return this.http.get<AppointmentRequest>(urlService, {
            headers: this.curHttpHeaders,
            observe: 'response',
          });
    }
    loadIntFormats(): Observable<HttpResponse<Map<string, InternationalsFormats>>> {
        const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
        const urlCur = `${environment.baseUrl}/internationalsFormats`;
        return this.http.post<Map<string, InternationalsFormats>>(urlCur, undefined,
        {
            headers: this.curHttpHeaders,
            observe: 'response'
        }
        );
      }
}
