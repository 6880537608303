<div class="container-fluid d-inline" >
<form [formGroup]="requestedServiceForm" [dir]="wksEntity.directionInput" >
  <mat-card class="stdTheme">
    <mat-card-title  *ngIf="!readOnlyData" style="text-align: center;">{{ 'LOCATION.FORM_TITLE' | translate }}</mat-card-title>
    <mat-card-content>
      <fieldset class="informationsborder">
        
        <div  class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px" >
          <div class="col-10" style="margin-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'LOCATION.ASKED_COMMENT' | translate }}</mat-label>
              <textarea matInput  style="margin: 0px;padding: 0px;"
              formControlName="askedComment"
              cdkTextareaAutosize
              #askedComment="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="2"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div  class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom: 5px;" >
          <div  *ngIf="sizeWidth > 899" class="col-10"  style="padding-left: 5px; padding-right: 0px;" >
            <span style="margin-top: 5px;margin-right:3%;">{{ 'LOCATION.EQUIPMENT_JOBSITE' | translate }} </span>
            <mat-radio-group  #jobsite="matRadioGroup" aria-label="Select an option" 
                  formControlName="jobsite" (change)="jobsiteChange(jobsite.value);validInput(jobsite.value);">
              <!--<mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="workshop">{{ 'LOCATION.WORKSHOP' | translate }}</mat-radio-button>-->
              <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="address">{{ 'LOCATION.ADDRESS' | translate }}</mat-radio-button>
              <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="mooring">{{ 'LOCATION.MOORING' | translate }}</mat-radio-button>
              <!--<mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="atSea">{{ 'LOCATION.AT_SEA' | translate }}</mat-radio-button>-->
            </mat-radio-group>
          </div>
          <div  *ngIf="sizeWidth < 900 " class="col-10"  style="padding-left: 5px; padding-right: 0px;" >
            <span style="margin-top: 5px;margin-right:3%;">{{ 'LOCATION.EQUIPMENT_JOBSITE' | translate }} </span>
            <mat-radio-group class="vertical-radio-group" #jobsite="matRadioGroup" aria-label="Select an option" 
                  formControlName="jobsite" (change)="jobsiteChange(jobsite.value);validInput(jobsite.value);">
              <!--<mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="workshop">{{ 'LOCATION.WORKSHOP' | translate }}</mat-radio-button>-->
              <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="address">{{ 'LOCATION.ADDRESS' | translate }}</mat-radio-button>
              <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="mooring">{{ 'LOCATION.MOORING' | translate }}</mat-radio-button>
              <!--<mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="atSea">{{ 'LOCATION.AT_SEA' | translate }}</mat-radio-button>-->
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="locationAddress" class="stdTheme " style="margin-right: 0px;margin-left: 0px;" style="margin-bottom: 20px;">
          <div #addressBlock class="stdTheme margin-0 padding-0"  formGroupName="addressBlockFg" style="width: 100%">
            <div *ngFor="let rowCur of rowsAddress; let iRow = index" >
              <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                <div class="col-1"></div>
                <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                        [ngStyle]="{width: calWidthCol(rowCur.cols,colCur.dataLengthMax,38)}"
                        [ngSwitch]="colCur.controlType">
                  <div  [class]="getColRowAddr(rowCur.cols)">
                    <div *ngSwitchDefault>
                        <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                            <mat-label >{{colCur.elemLabel}}</mat-label>
                            <input matInput class="addressItem" type="text" #nbHoursOfUse id="addressItem_+{{colCur.elmtNumber}}" 
                                [attr.maxlength]="colCur.dataLengthMax"
                                [ngClass]="{
                                    'inputAlert': colCur.inputError,
                                    'inputNormal': !colCur.inputError
                                    }"
                                [value]="colCur.inputValue" 
                                (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                                formControlName="{{colCur.dataName}}"
                                >
                        </mat-form-field>
                    </div> 
                    <div *ngSwitchCase="'zipcode'">
                        <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                            <mat-label >{{colCur.elemLabel}}</mat-label>
                            <input matInput class='addressItem' type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                                [attr.maxlength]="colCur.dataLengthMax"
                                [ngClass]="{
                                    'inputAlert': colCur.inputError,
                                    'inputNormal': !colCur.inputError
                                    }"
                                [value]="colCur.inputValue" 
                                (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                                formControlName="{{colCur.dataName}}"
                                >
                        </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div> <!-- end addressBlock -->
          <!--
          <div class="col-10" style="padding-left: 5px; padding-right: 0px;" >
            <mat-form-field class="smallerField" appearance="standard">
              <mat-label>{{ 'LOCATION.EQUIPMENT_LOCATION' | translate }}</mat-label>

              <textarea matInput  style="margin: 0px;padding: 0px;"
                #equipmentLocation
                formControlName="equipmentLocation"
                cdkTextareaAutosize
                #equipmentLocation="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="6"
                (keyup)="unValidInput()"
                (change)="addressToCoordinates(equipmentLocation.value)"
                [readonly]="!locationAddress"
                ></textarea>
            </mat-form-field>
          </div>
          -->
        </div>
        <!--================================================================-->
        <div #mooringBlock *ngIf="locationMooring" formGroupName="mooringBlockFg" style="margin-bottom: 20px;" >
          <div *ngFor="let rowCur of rowsMooring; let iRow = index" >
            <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
              <div class="col-2"></div>
              <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                      [ngStyle]="{width: calWidthCol(rowCur.cols,colCur.dataLengthMax,38)}"
                      [ngSwitch]="colCur.controlType">
                <div  [class]="getColRowAddr(rowCur.cols)">
                  <div *ngSwitchDefault>
                      <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                          <mat-label >{{colCur.elemLabel}}</mat-label>
                          <input matInput class="addressItem" type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                              [attr.maxlength]="colCur.dataLengthMax"
                              [ngClass]="{
                                  'inputAlert': colCur.inputError,
                                  'inputNormal': !colCur.inputError
                                  }"
                              [value]="colCur.inputValue" 
                              (input)="formatFieldMooring('address', iRow, iCol, colCur,$any($event.target).value)"
                              formControlName="{{colCur.dataName}}"
                              >
                      </mat-form-field>
                  </div> 
                  <!--
                  <div *ngSwitchCase="'statesList'">
                    <mat-form-field style="width: 100%" appearance="standard">
                        <mat-label class="stdTheme">{{ 'state' | translate }}</mat-label>
                        <mat-select class="stdTheme"  panelClass="panelSelectContent" #stateSelected  
                                    disableOptionCentering
                                    id="{{colCur.dataName}}"
                                    required formControlName="{{colCur.dataName}}" 
                                    (selectionChange)="onSelectionStateChangeMat($event)" >
                            <div class="filterInput" style="display: inline-block;" >
                                <input class="searchTheme" #filterState placeholder="{{ 'filterInput' | translate }}" 
                                        formControlName="filterState" style="margin: 5px" (keyup)="setFilterState($event.target.value)" >
                                <button mat-icon-button matSuffix aria-label="clear" 
                                            *ngIf="filterState.value" (click)="filterState.value='';setFilterState(filterState.value)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                            <mat-option class="stdTheme stdSelect" *ngFor="let state of statesFilteredList" 
                                    class="selectOptionsTheme"  [value]="state.stateAbb" >
                                    {{state.stateName }} ({{state.stateAbb}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                -->
                </div> 
              </div>
            </div>
      
          </div>
          <!--
          <div class="row-fluid row" style= "margin: 0px;">
            <div class="col-10">
              <mat-form-field class="smallerField" appearance="standard" style="height: 50px">
                <mat-label>{{ 'LOCATION.PORT_CITY' | translate }}</mat-label>
                <input id="portCity" #portCity class="form-control inputTheme" formControlName="portCity" 
                      matInput placeholder="{{ 'LOCATION.PORT_CITY' | translate }}"
                    (change)="onChangeField('portCity', portMooring.value); validInput(jobsite.value)"
                >
              </mat-form-field>
            </div>        
            <div class="w-100"></div>
            <div class="col-6">
              <mat-form-field class="smallerField" appearance="standard" style="height: 50px">
                <mat-label>{{ 'LOCATION.PORT_MOORING' | translate }}</mat-label>
                <input id="portMooring" #portMooring class="form-control inputTheme" formControlName="portMooring" 
                      matInput placeholder="{{ 'LOCATION.PORT_MOORING' | translate }}"
                (change)="onChangeField('portMooring', portMooring.value)"
                >
              </mat-form-field>
            </div>        

            <div class="col-6">
              <mat-form-field class="mdiinput form-group" appearance="standard" style="height: 50px">
                  <mat-label>{{ 'LOCATION.PORT_POOL' | translate }}</mat-label>
                  <input id="portPool" #portPool class="form-control inputTheme" formControlName="portPool" 
                          matInput placeholder="{{ 'LOCATION.PORT_POOL' | translate }}"
                              (change)="onChangeField('portPool', portPool.value)">
              </mat-form-field>
            </div>    
            <div class="w-100"></div>
            <div class="col-6">
              <mat-form-field class="mdiinput form-group" appearance="standard" style="height: 50px">
                  <mat-label>{{ 'LOCATION.PORT_PONTOON' | translate }}</mat-label>
                  <input id="portPontoon" #portPontoon class="form-control inputTheme" 
                          formControlName="portPontoon" 
                          matInput placeholder="{{ 'LOCATION.PORT_PONTOON' | translate }}"
                              (change)="onChangeField('portPontoon', portPontoon.value)">
              </mat-form-field>
            </div>   
            <div class="col-6">
              <mat-form-field class="mdiinput form-group" appearance="standard" style="height: 50px">
                  <mat-label>{{ 'LOCATION.PORT_RING' | translate }}</mat-label>
                  <input id="portRing" #portRing class="form-control inputTheme" 
                          formControlName="portRing" 
                          matInput placeholder="{{ 'LOCATION.PORT_RING' | translate }}"
                              (change)="onChangeField('portRing', portRing.value)">
              </mat-form-field>
            </div>     
          </div>  
          -->
        </div>
        <!--================================================================-->
        <!--
        <div *ngIf="locationAtSea || locationAddress" class="flex-row  row stdTheme align-items-center" 
                                          style="margin-right: 0px;margin-left: 0px;margin-bottom: 5px" >
          <div class="col-2 align-self-center" style="text-align: start" style="padding-left: 5px; padding-right: 0px;">
            <div *ngIf="locationAtSea" class="flex-row  row stdTheme align-items-center" style="margin-right: 0px;margin-left: 0px;" >
              <div class="col-12" style="text-align: center;margin-right: 0px;margin-left: 0px; padding: 0px">
                <label for="equipmentPositionMap">{{ 'LOCATION.EQUIPMENT_MAP' | translate }}</label>
              </div>
            </div>
            <div *ngIf="locationAtSea" class="flex-row  row stdTheme align-items-center" style="margin-right: 0px;margin-left: 0px;" >
              <div class="col-12" style="text-align: center;margin-right: 0px;margin-left: 0px; padding: 0px">
                <button  mat-icon-button class="buttonTheme" id="equipmentPositionMap" matTooltip="{{ 'LOCATION.EQUIPMENT_POSITION' | translate }}" 
                          (click)="checkGeoLocAddress($event)" >
                  <mat-icon class="mat-icon-work">map</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-10" >
            <div class="flex-row  row stdTheme align-items-center" style="margin-right: 0px;margin-left: 0px;" >
              <div class="col-12" style="margin-bottom: 10px; text-align: center; padding: 0px">
                <label for="equipmentPosition">{{ 'LOCATION.EQUIPMENT_POSITION' | translate }}</label>
                <input id="equipmentPosition" class="form-control inputTheme" formControlName="equipmentPosition" 
                      matInput #equipmentPosition  readonly >
              </div>  
              
            </div>
            <div class="flex-row  row stdTheme align-items-center" style="margin-right: 0px;margin-left: 0px" >
              <div class="col-12" style="text-align: center; padding: 0px">
                  <input id="equipmentPositionCoord" class="form-control inputTheme" formControlName="equipmentPositionCoord" 
                                      matInput #equipmentPositionCoord  readonly  >
              </div>  
            </div>
          </div>
        </div>
        -->
      </fieldset>
    </mat-card-content>
    <mat-card-actions  *ngIf="!readOnlyData" class="actions">
      <button mat-raised-button (click)="onPrevious()">
        <mat-icon>navigate_before</mat-icon>
        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
      </button>
      <button [disabled]="requestedServiceForm.invalid || incompleteInput" mat-raised-button type="submit" (click)="onSubmit($event)">
        {{ 'COMMUN.BUTTON.CONFIRM' | translate }}
        <mat-icon>check</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
</div>
<ng-template #modalMap let-modal >
  <app-google-location [googleMaps] = "googleMaps" (outputMap)="getReturnMap($event)" ></app-google-location>
</ng-template>