import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { WksEntityImg } from '../../../../models/shared.model';

@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
  styleUrls: ['./home-form.component.less']
})
export class HomeFormComponent implements OnInit {

  @Input()
  wksLogoHome: WksEntityImg;

  @Output()
  nextStepEvent = new EventEmitter<number>();
  constructor() { }

  styleSize: {
    width: '290px';
    height: '300px';
  };
  logoToShow: any;

  ngOnInit(): void {
    if (this.wksLogoHome.height === undefined) {
      this.wksLogoHome.height = this.styleSize.height;
    }
    if (this.wksLogoHome.width === undefined) {
      this.wksLogoHome.width = this.styleSize.width;
    }
    this.createImageFromBlob(this.wksLogoHome);
  }
  startProcess(): void {
    this.nextStepEvent.emit(1);
  }
  // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.logoToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;

   }
}
