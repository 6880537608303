


<span  *ngIf="!checkEquipmentsIsLoaded" style="background-color: transparent!important">
    <mat-progress-spinner class="waiting-data" [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
    <br>
    <span class="blink_text">Please wait...</span>
</span>
<!--
<div mat-dialog-title class="stdTheme" style="margin: 0px!important">
    <span  *ngIf="!checkEquipmentsIsLoaded" >
        <mat-progress-spinner [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
        <br>
        <span class="blink_text">Please wait...</span>
    </span>

</div>
-->
<mat-dialog-content *ngIf="checkEquipmentsIsLoaded"  class="stdTheme verifCheck" style="padding: 0px!important; margin: 0px!important">

    <div *ngIf="isCheckLoaded" >
        <div #refElement  class="container-fluid stdTheme"
            [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px', height: '100%'}" >
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px">
                <div class="col-lg-12 col-xs-12 " >
                    <table  style="width:100%!important;" class="stdTheme" >
                        <thead >
                            <tr style="width:100%!important;">
                                <th style="width: 65%; ">{{equipLabels[0]}}</th>
                                <th style="width: 13%; ">{{equipLabels[1]}}</th>
                                <th style="width: 13%; ">{{equipLabels[2]}}</th>
                                <th style="width: 14%; ">{{equipLabels[3]}}</th>
                              </tr>
                        </thead>
                    </table>
                    <table  style="width:100%!important;">

                        <tbody style="width:100%!important;overflow: auto!important;" *ngFor="let equipCur of checkEquipmentsList; let i = index">
                            <!-- [ngClass]="{greenBorder: getThemeRow('taskAction'), redBorder: !getThemeRow('taskAction')}">-->
                            <!--<div *ngFor="let equipCur of checkEquipmentsList; let i = index"  >-->
                                <tr  class="curActionDisplayed"  style="width:100%!important;" 
                                            [ngClass]="{'greenBorder':!equipCur.equipFailed,'redBorder':equipCur.equipFailed}"
                                            >
                                    <td [attr.data-label]=" " style="width: 57%; padding: 2px; text-align: start; vertical-align: middle" (click)="equipCur.isCollapsed = !equipCur.isCollapsed;">
                                        <span class="label" style="margin-top: 5px;">
                                            {{equipCur.equipDesign}}
                                        </span>
                                    </td>
                                    <td [attr.data-label]="equipLabels[1]" style="width: 15%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <!--https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_circles-->
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipHours !== undefined && equipCur.equipHours > 1)" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipHours === undefined || equipCur.equipHours < 1)" class="red-dot"></span>
                                    </td>
                                    <td [attr.data-label]="equipLabels[2]" style="width: 17%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipSN !== null && equipCur.equipSN !== undefined && equipCur.equipSN !== '')" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipSN === null || equipCur.equipSN === undefined || equipCur.equipSN === '')" class="red-dot"></span>
                                    </td>
                                    <td [attr.data-label]="equipLabels[3]" style="width: 17%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <span style="margin-top: 5px" *ngIf="(!equipCur.missingParts)" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.missingParts)" class="red-dot"></span>
                                    </td>
                                </tr>
                                
                                <tr *ngIf="!equipCur.isCollapsed" >
                                    <div *ngFor="let taskItem of equipCur.tasksList; let i = index">
                                        <td>
                                            <span *ngIf="(taskItem.missingParts)" class="red-line">
                                                &nbsp;{{taskItem.taskrefLabel}}&nbsp;
                                            </span>
                                            <span *ngIf="(!taskItem.missingParts)" class="green-line">
                                                &nbsp;{{taskItem.taskrefLabel}}&nbsp;
                                            </span>
                                            
                                        </td>
                                    </div>
                                </tr>
                            <!--</div>-->
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="checkEquipmentsIsLoaded"  >
    <button  class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="closeModal()" >{{'COMMUN.BUTTON.CLOSE' | translate}}</button>
    <div fxFlex></div>
    <button  *ngIf="isOkToValid && !ihmOptions.readOnly" 
            class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" 
            (click)="lockWork()" >
        {{'WORK_TASK.completed' | translate}}
    </button>
    <!--<button  *ngIf="isOkToValid" class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="lockWork()" >{{'WORK_TASK.completed' | translate}}</button>-->
</mat-dialog-actions>