<div [ngClass]="{'dark-theme': themeIsDark}">
    <app-home-form *ngIf="step === 0" [wksLogoHome]="wksLogoHome" (nextStepEvent)="goStep($event)"></app-home-form>
    <div class="flex-row row">
        <div *ngIf="nbCols === 2 && step > 0" class="col-4">
            <div class="logo" >
                <img  [src]="logoToShow | safeHtml: 'resourceUrl' "
                [ngStyle]="{'width': wksLogoHome.width, 'height': wksLogoHome.height, 'margin-top': '50px' }">
            </div>
        </div>
        <div [class.col-8]="nbCols === 2"  [class.col-12]="nbCols === 1">
            <app-client-form *ngIf="step === 1" [wksEntity]="wksEntity" [client]="client" [countryFmt]="countryFmt" 
                                                (nextStepEvent)="goStep($event)" (nbEquipmentsEvent)="setNbEquip($event)">
            </app-client-form>
            <app-equipment-form *ngIf="step > 1 && step <= maxstep - 2 " [equipment]="appointmentRequest.equipments[step-2]" 
                                                [appointmentRequest]="appointmentRequest" [wksEntity]="wksEntity"
                                                [countryFmt]="countryFmt"  [wksEntityParams]="wksEntityParams"
                                                [inputSN]="ctrlInput.inputSerialNumber"
                                                [isCheckList]="isCheckList" [idxEquip]="idxEquip"
                                                [client]="client" (nextStepEvent)="goStep($event)">
            </app-equipment-form>
            <app-location-form *ngIf="step === maxstep - 1" [appointmentRequest]="appointmentRequest" 
                                                [wksEntity]="wksEntity" [countryFmt]="countryFmt" 
                                                [wksEntityParams]="wksEntityParams" (nextStepEvent)="goStep($event)">
            </app-location-form>
            <app-confirmation *ngIf="step === maxstep" [appointmentRequest]="appointmentRequestValidate" 
                                                [isRegistred]="isRegistred"  (nextStepEvent)="goStep($event)">
            </app-confirmation>
        </div>
    </div>
</div>
