import { Component, OnInit, Input, Output, Inject,  ElementRef, ViewChild, AfterContentInit,
      ViewEncapsulation, EventEmitter, Renderer2  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
import { GoogleMaps, LatLng } from '../../../models/geoLocation.model';


@Component({
    selector: 'app-google-location',
    templateUrl: './google-location.component.html',
    styleUrls: ['./google-location.component.less'],
    encapsulation: ViewEncapsulation.None,
  })
  export class GoogleLocationComponent implements OnInit, AfterContentInit {

  @ViewChild('mapLatLng', {read: ElementRef, static: true}) mapLatLng: ElementRef | undefined;
  @ViewChild('pacInput', {read: ElementRef, static: true}) pacInput: ElementRef | undefined;

  @Input() googleMaps: GoogleMaps | undefined;
  @Output()
  outputMap = new EventEmitter<any>();

  displayWidth!: string;
  displayHeight!: string;

  constructor(
    private themeService: ThemeService,
    private translate: TranslateService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: any,
    ) { }
  ngOnInit(): void {
    this.displayWidth = '100%';
    this.displayHeight = '';
    if ((this.googleMaps !== undefined) && (this.googleMaps.style !== undefined) && (this.googleMaps.style !== null)) {
      /* if ((this.googleMaps.style.width !== undefined) && (this.googleMaps.style.width !== null)) {
        this.displayWidth = this.googleMaps.style.width;
      } */
      if ((this.googleMaps.style.height !== undefined) && (this.googleMaps.style.height !== null)) {
        this.displayHeight = this.googleMaps.style.height;
      }
    }
    if ((this.googleMaps !== undefined) && (this.googleMaps.displayLocal)) {
      this.getUserLocation()
      .then ((userLocation: LatLng) => {
        if ((this.googleMaps !== undefined)
            && (this.googleMaps.markers === undefined)) {
          this.googleMaps.markers = [];
        }
        if ((this.googleMaps !== undefined)
              && (this.googleMaps.markers !== undefined)) {
          this.googleMaps.markers.push({
            position: userLocation,
            title: 'User localized'}
            );
        }
        if  ((this.googleMaps !== undefined)
            && (this.googleMaps.mapOptions !== undefined)
            && (this.googleMaps.mapOptions.center === undefined)) {
          this.googleMaps.mapOptions.center = userLocation;
          this.googleMaps.mapOptions.zoom = 14;
        }
        if ((this.googleMaps !== undefined)
          && (this.googleMaps.position === undefined)) {
          this.googleMaps.position = userLocation;
        }
      })
      .catch ((error: any) => {
        if (error !== undefined) {
          console.log('getUserLocation : ' + error);
        }
      });
    }
  }
  ngAfterContentInit(): void {
    if (this.googleMaps.action === 'getLatLng') {
      this.getUserLocation()
      .then ((userLocation: LatLng) => {
        this.initMapLatLng(userLocation);
      })
      .catch ((error: any) => {
        if (error !== undefined) {
          console.log('getUserLocation : ' + error);
        }
      });
    }
    if (this.googleMaps.action === 'searchLatLng') {
      this.getUserLocation()
      .then ((userLocation: LatLng) => {
        if ((this.googleMaps.addressInput !== undefined)
            && (this.googleMaps.addressInput !== '')) {
          this.initGeoCoder(userLocation);
        } else {
          this.initMapLatLng(userLocation);
        }
      })
      .catch ((error: any) => {
        if (error !== undefined) {
          console.log('getUserLocation : ' + error);
        }
      });
    }
  }
  initGeoCoder(userLocation: LatLng): void {
    let positionCur = userLocation;
    if (this.googleMaps.position !== undefined) {
      positionCur = this.googleMaps.position;
    }
    const mapCur = this.getMap(positionCur, 14);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        address: this.googleMaps.addressInput }, (results, status) => {
          console.log(results);
          if (status === 'OK') {
            this.initMapGeocoder(results, mapCur);
          } else {
              alert('Geocode was not successful for the following reason: ' + status);
          }
        }
      );
  }
  initMapGeocoder(results: google.maps.GeocoderResult[], mapCur: google.maps.Map<Element>): void {
    let markers: google.maps.Marker[] = [];
    let markerCur: google.maps.Marker;
    let firtsMarker: google.maps.LatLng;
    let latLng2: any;
    const bounds = new google.maps.LatLngBounds();

    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
    let placeNum = 0;
    for (const place of results ) {
      try {
        latLng2 = place.geometry.location.toJSON();
      } catch  {
        continue;
      }

      markerCur = new google.maps.Marker({
        map: mapCur,
        // icon,
        title: place.formatted_address,
        position: place.geometry.location,
      });
      if (placeNum === 0)  {
        firtsMarker = latLng2;
      }
      markers.push(markerCur);
      markerCur.addListener('click', () => {
        const markLatLng = markerCur.getPosition() as google.maps.LatLng;
        const latLng = markLatLng.toJSON() as LatLng ;
        // console.log(latLng);
        if (this.googleMaps.isModal) {
          this.checkOutData(latLng);
        }
        // map.setZoom(8);
        // map.setCenter(markerCur.getPosition() as google.maps.LatLng);
      });
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      placeNum++;
    }
    mapCur.fitBounds(bounds);

    mapCur.setZoom(18);
    mapCur.setCenter(firtsMarker);

  }
  initMapLatLng(userLocation: LatLng): void {
    // const myLatlng = { lat: -25.363, lng: 131.044 };
    //  const map = new google.maps.Map(document.getElementById('mapLatLng'), {
    let positionCur = userLocation;
    if (this.googleMaps.position !== undefined) {
      positionCur = this.googleMaps.position;
    }
    const map = this.getMap(positionCur, 18);
    // Create the initial InfoWindow.
    let infoWindow = new google.maps.InfoWindow({
      content: this.translate.instant('COMMUN.MAPS.GOOGLE.INFO-CLICK'),
      position: positionCur,
    });
    infoWindow.open(map);
    // Configure the click listener.
    map.addListener('click', (mapsMouseEvent) => {
      // Close the current InfoWindow.
      infoWindow.close();
      // const latLng = JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2) ;
      const latLng = mapsMouseEvent.latLng.toJSON() as LatLng ;

      console.log(latLng);
      if (this.googleMaps.isModal) {
        this.checkOutData(latLng);
      } else {
        // Create a new InfoWindow.
        infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });
        infoWindow.setContent(
          JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
        );
        infoWindow.open(map);
      }
    });
  }
  checkOutData(latLngCur: LatLng): void {
    // const latLng = JSON.stringify(latLngCur);
    // this.outputMap.next(latLng);
    this.getAddress(latLngCur);
    // this._globalEventsManagerService.synchroEvents.next('closeMapModalLatLng__' + latLng);
  }
  getUserLocation(): any {
    return new Promise<LatLng>((resolve, reject) => {
      if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
          // console.log('Lat : ' + position.coords.latitude + ' Lng : ' + position.coords.longitude );
          const myLatLng1 = {
            lat: position.coords.latitude,
            lng: position.coords.longitude };

          resolve(myLatLng1);
        });
      }
    });
  }
// https://developers.google.com/maps/documentation/javascript/examples/places-searchbox#maps_places_searchbox-typescript
initAutocomplete(latLngCur: LatLng): void {

  const map = this.getMap(latLngCur , 14);
  // Create the search box and link it to the UI element.
  // this.renderer.setProperty(this.pacInput.nativeElement, 'innerHTML', this.googleMaps.addressInput);
  // const input = this.pacInput.nativeElement;
  const input = document.getElementById('pacInput') as HTMLInputElement;
  input.value = this.googleMaps.addressInput;
  const searchBox = new google.maps.places.SearchBox(input);

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
  });
  map.addListener('click', (mapsMouseEvent) => {

    // const latLng = JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2) ;
    const latLng = mapsMouseEvent.latLng.toJSON() as LatLng ;
    console.log(latLng);
    if (this.googleMaps.isModal) {
      this.checkOutData(latLng);
    }
  });
  let markers: google.maps.Marker[] = [];
  let markerCur: google.maps.Marker;
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener('places_changed', () => {
    const places = searchBox.getPlaces();

    if (places.length === 0) {
      return;
    }
    // const latLng1 = places[0].geometry.viewport.toJSON();
    // console.log(latLng1);
    const latLng2 = places[0].geometry.location.toJSON();
    // console.log(latLng2);
    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();
    places.forEach((place) => {
      if (!place.geometry) {
        console.log('Returned place contains no geometry');
        return;
      }
      /*
      const icon = {
        url: place.icon as string,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25),
      };
*/
      // Create a marker for each place.
      markerCur = new google.maps.Marker({
        map,
        // icon,
        title: place.name,
        position: place.geometry.location,
      });
      markers.push(markerCur);
      markerCur.addListener('click', () => {
        const markLatLng = markerCur.getPosition() as google.maps.LatLng;
        const latLng = markLatLng.toJSON() as LatLng ;
        // console.log(latLng);
        if (this.googleMaps.isModal) {
          this.checkOutData(latLng);
        }
        // map.setZoom(8);
        // map.setCenter(markerCur.getPosition() as google.maps.LatLng);
      });
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);

  });

}
  getMap(centerCur: LatLng, zoomCur: number): google.maps.Map {
    const darkMode = this.themeService.isDarkTheme();
    // this.renderer.setStyle(this.mapLatLng.nativeElement, 'position', 'inherit');

    let map: any;
    if (!darkMode) {
      map = new google.maps.Map(this.mapLatLng.nativeElement, {
        zoom: zoomCur,
        center: centerCur,
        mapTypeId: 'roadmap'
      });
    } else  {
      map = new google.maps.Map(this.mapLatLng.nativeElement, {
        zoom: zoomCur,
        center: centerCur,
        mapTypeId: 'roadmap',
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ],
      });
    }
    if ((this.googleMaps.markers !== undefined) && (this.googleMaps.markers.length > 0)) {
      for ( const markerCur of this.googleMaps.markers) {
        // const latLng = markerCur.position;
        // const title  = markerCur.title;
        // tslint:disable-next-line:no-unused-expression
        new google.maps.Marker({
          position: markerCur.position,
          map,
          title: markerCur.title,
        });
      }
    }
    return map;
  }
  getAddress(positionCur: LatLng): void {

    const geocoder = new google.maps.Geocoder();
    const returnValue =  {
      latLng : JSON.stringify(positionCur),
      address: '',
    };
    geocoder.geocode({
      location: positionCur }, (results, status) => {
          console.log(results);
          if (status === 'OK') {
            // console.log(results);
            // if (results.length === 1)  {
            returnValue.address = results[0].formatted_address;
            // }
            this.outputMap.next(returnValue);

          } else {
              // alert('Geocode was not successful for the following reason: ' + status);
              returnValue.address = status;
              this.outputMap.next(returnValue);
          }
        }
      );
  }

}
