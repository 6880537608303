import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService,  } from '@ngx-translate/core';
import { CalendarView } from 'angular-calendar';
import { isTypeOnlyImportOrExportDeclaration } from 'typescript';
export interface CalendarViewLocal {
  Month: 'Mmonth';
  Week: 'Week';
  Day: 'Day';
}

@Component({
  selector: 'app-calendar-header',
  template: `
    <div class="row text-center">
      <div class="col-sm-3">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view === 'month' ? 'month': view === 'week' ? 'week' : view === 'day' ? 'day': 'month'"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          {{'CALENDAR.PREVIOUS'| translate}}
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          {{'CALENDAR.TODAY'| translate}}
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view === 'month' ? 'month': view === 'week' ? 'week' : view === 'day' ? 'day': 'month'"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          {{'CALENDAR.NEXT'| translate}}
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-11">
            <h3 style="margin-bottom: 0px; margin-top: 5px">{{ viewDate | calendarDate: view + 'ViewTitle':locale }} &nbsp;&nbsp; (planning : {{ userName }})</h3>
          </div>
          <div *ngIf="displayRefresh" class="col-sm-1" >
            <button mat-icon-button class="buttonTheme" (click)="refreshEvents()">
              <span class="fa fa-redo" style="font-size: 15px" ></span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="btn-group">
          <div
            class="btn "
            (click)="viewChange.emit('month')"
            [class.btn-outline-secondary]="view === 'month'"
            [class.btn-primary]="view !== 'month'"
          >
            {{ 'CALENDAR.MONTH' | translate}}
          </div>
          <div
            class="btn "
            (click)="viewChange.emit('week')"
            [class.btn-outline-secondary]="view === 'week'"
            [class.btn-primary]="view !== 'week'"
          >
            {{'CALENDAR.WEEK'| translate}}
          </div>
          <div
            class="btn"
            (click)="viewChange.emit('day')"
            [class.btn-outline-secondary]="view === 'day'"
            [class.btn-primary]="view !== 'day'"
          >
            {{'CALENDAR.DAY'| translate}}
          </div>
        </div>
      </div>
    </div>
    <br />
  `
})
export class CalendarHeaderComponent implements OnChanges {


  @Input() view: string;
  @Input() viewDate: Date;
  @Input() locale: any;
  @Input() userName: string;
  @Input() sizeWidth: number;
  @Output() viewChange: EventEmitter<string> = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  @Output() todoRefresh: EventEmitter<string> = new EventEmitter();
  viewHeader: CalendarView;
  calendarViewLocal: CalendarViewLocal;

  displayRefresh: boolean;
  constructor( private translate: TranslateService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'view': {
            // tslint:disable-next-line:no-string-literal
            this.view = changes['view'].currentValue;
            if (this.view === 'month') {
              this.viewHeader = CalendarView.Month;
            }
            if (this.view === 'week') {
              this.viewHeader = CalendarView.Week;
            }
            if (this.view === 'day') {
              this.viewHeader = CalendarView.Day;
            }
            break;
          }
          case 'viewDate': {
            // tslint:disable-next-line:no-string-literal
            this.viewDate = changes['viewDate'].currentValue;
            break;
          }
          case 'sizeWidth': {
            // tslint:disable-next-line:no-string-literal
            this.sizeWidth = changes['sizeWidth'].currentValue;
            break;
          }
          case 'userName': {
            // tslint:disable-next-line:no-string-literal
            this.userName = changes['userName'].currentValue;
            break;
          }
          case 'locale': {
            // tslint:disable-next-line:no-string-literal
            this.locale = changes['locale'].currentValue;
            const localeTmp = this.locale.split('-');
            this.locale = localeTmp[0];
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.displayRefresh = true;
    if (this.sizeWidth < 800) {
      this.displayRefresh = false;
      return;
      }
  }
  changeView($event: string): void {
    if ($event === 'month') {
      this.viewHeader = CalendarView.Month;
      this.view = 'month';
    }
    if ($event === 'week') {
      this.viewHeader = CalendarView.Week;
      this.view = 'week';
    }
    if ($event === 'day') {
      this.viewHeader = CalendarView.Day;
      this.view = 'day';
    }
  }
  refreshEvents(): void {
    this.todoRefresh.emit('refreshEvents');
  }
}
