import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { WorksService } from '../../../services/works.service';
import { FileToDisplay, LabelValue } from '../../../models/shared.model';
import { GlobalEventsManagerService } from '../../../services/global-events-manager.service';


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.less']
})
export class PdfViewerComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() dataList: FileToDisplay[];
  @Input() otherData: LabelValue[];

  @ViewChild('pdfViewer', {static: false}) pdfViewer: any;
  private readonly onDestroy = new Subject<void>();

  pdfFiles: any[];
  displayClose: boolean;
  selectedPdf: any;
  pdfDisplay: any;
  dataPdfLoaded: boolean;
  readyToDisplayPdf: boolean;
  callChain: string;
  isQuoteView: boolean;
  toValidated: boolean;
  toApproved: boolean;
  toRead: boolean;
  pdfSrc: any;
  quoteInWordWT: string;
  constructor(private globalEventsManagerService: GlobalEventsManagerService,
              private worksService: WorksService,
              public translate: TranslateService ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initAllBooleans();
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            // tslint:disable-next-line:no-string-literal
            this.statutCall = changes['statutCall'].currentValue;
            this.callChain = this.statutCall as string;
            if (this.callChain.startsWith('quotes')) {
              this.displayClose = true;
            }
            if (this.callChain.includes('read')) {
              this.toRead = true;
            }
            if (this.callChain.includes('approved')) {
              this.toApproved = true;
            }
            break;
          }
          case 'dataList': {
            // tslint:disable-next-line:no-string-literal
            this.dataList = changes['dataList'].currentValue;
            break;
          }
          case 'otherData': {
            // tslint:disable-next-line:no-string-literal
            this.otherData = changes['otherData'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    if ((this.dataList.length !== 0) || (this.dataList[0] !== undefined)) {
     this.generatePdfList();
    } else {
      this.dataPdfLoaded = true;
    }
  }
  initAllBooleans(): void {
    this.dataPdfLoaded = false;
    this.displayClose = false;
    this.toValidated = false;
    this.toRead = false;
    this.toApproved = false;
    this.readyToDisplayPdf = false;
  }
  generatePdfList(): any {
    this.readyToDisplayPdf = false;
    this.loadListPdf()
    .then (() => {
      if (this.pdfFiles[0] === undefined) { return; }
      this.loadPdfFile(this.pdfFiles[0].filePath)
        .then ((res: any) => {
          const response = res;
          this.dataPdfLoaded = true;
        })
        .then((error: any) => { // error read label
          console.log('Read loadPdfFile error :' + error);
        });
    })
    .then((error: any) => { // error read label
      console.log('Read loadListPdf error :' + error);
    });
  }
  loadListPdf(): any {
    this.pdfFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of this.dataList ) {
        const fileCur =    {
          fileLabel: (docCur.fileLegend ? docCur.fileLegend : docCur.fileName),
          fileLegend: (docCur.fileLegend ? docCur.fileLegend : docCur.fileName),
          filePath: docCur.filePath,
          fileRef: (docCur.fileRef ? docCur.fileRef : undefined),
          dataImgLoaded: false
        };
        this.pdfFiles.push(fileCur);
      }
      this.pdfFiles.sort((obj1, obj2) => {
        return obj1.fileLabel < obj2.fileLabel ? 1 : -1;
      });
      this.selectedPdf = this.pdfFiles[0];
      // const status = this.pdfFiles[0].filePath.split('/').pop();
      const status: string[] = this.pdfFiles[0].filePath.split('/');
      const item = status.length - 2;
      if (!this.toRead) {
        this.toValidated = (status[item] === 'new' ? true : false);
        this.toApproved = (status[item] === 'submitted' ? true : false);
      }
// var filename = nameString.split("/").pop();
      if (this.pdfFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  loadPdfFile(path: any): any {
    this.readyToDisplayPdf = false;
    return new Promise((resolve, reject) => {
      this.worksService.getPdfDoc(path)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          this.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
          // this.pdfViewer.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
          this.pdfFiles[0].localUrl = response;
          // this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
          let totalWTInWords;
          for (const labelValue of this.otherData) {
            if (labelValue.label === 'totalWT_inWords') {
              totalWTInWords = labelValue.value;
              break;
            }
          }
          // {identifierCur: $event }
          if (totalWTInWords !== undefined)  {
            this.quoteInWordWT = this.translate.instant('WORK_DOCUMENT.quoteInWordWT', {WTInWords: totalWTInWords });
          }
          this.readyToDisplayPdf = true;
          resolve(response);
        }, error => {
          reject(error);
        }
      );
    });
  }
  setPdfCur(pdfName: any): any {

    for (const pdfSelected of this.pdfFiles) {
      if (pdfSelected.fileLabel === pdfName) {

        if ( pdfSelected.localUrl !== undefined) {
          this.pdfDisplay = pdfSelected.localUrl;
        } else {
          this.loadPdfFile(pdfSelected.filePath)
          .then ((response: any) => {
            pdfSelected.localUrl = response;
            this.selectedPdf = pdfSelected;
            /*
            this.pdfDisplay = url;
            pdfSelected.localUrl = url;*/
          })
          .then((error: any) => { // error read label
            //  console.log('Read data :'+ error);
          });
        }
        break;
      }
    }
  }
  afterLoadPdfComplete(event: any): void {
    console.log('pdf loaded)');
  }
  closeEvent($event: Event): void  {

    if (this.callChain.startsWith('quotes'))  {
      this.globalEventsManagerService.synchroEvents.next('closeQuote__' + this.selectedPdf.fileRef);
    }
    return;
  }
  validateEvent($event: Event): void  {
    if ((this.callChain.startsWith('quotes')) && (this.toValidated) )  {
      this.globalEventsManagerService.synchroEvents.next('validateQuote__' + this.selectedPdf.fileRef);
    }

    return;
  }
  approvedEvent($event: Event): void  {
    if ((this.callChain.startsWith('quotes')) && (this.toApproved) )  {
      this.globalEventsManagerService.synchroEvents.next('approveQuote__' + this.selectedPdf.fileRef);
    }

    return;
  }
  refusedEvent($event: Event): void  {
    if ((this.callChain.startsWith('quotes')) && (this.toApproved) )  {
      this.globalEventsManagerService.synchroEvents.next('refuseQuote__' + this.selectedPdf.fileRef);
    }

    return;
  }
  onErroPdfviewer(error: any): any {
    console.log('onErroPdfviewer ' + error.message);
  }
}
