import * as moment from 'moment';
import { CommonMethods } from './commonMethods';
import {ElementRef, Renderer2 } from '@angular/core';

export interface KeyValue {
    key: string;
    value: any;
}

export class GridTools {

    public static dateRendererUnixM = (params: any): string => {
        let timeCur: string;
        if (params.value === null) {
            timeCur = '';
        } else if (!params.value) {
            timeCur = '';
        } else if (params.value) {
            timeCur = CommonMethods.dateToString(params.value, 'unixM', params.array[0]);
        }
        return '<span >' + timeCur + '</span>';
    }
    public static dateRendererJson = (params: any): string => {
        let timeCur: string;
        if (params.value === null) {
            timeCur = '';
        } else if (!params.value) {
            timeCur = '';
        } else if (params.value) {
            timeCur = CommonMethods.dateToString(params.value, 'json', params.array[0]);
        }
        return '<span >' + timeCur + '</span>';
    }
    public static dateUnix = (value: string, format: string ): string => {
        let timeCur: string;
        if (value === null) {
            timeCur = '';
        } else if (!value) {
            timeCur = '';
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'unixM', format);
        }
        return timeCur;
    }
    public static dateJson = (value: string, format: string ): string => {
        let timeCur: string;
        if (value === null) {
            timeCur = '';
        } else if (!value) {
            timeCur = '';
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'json', format);
        }
        return timeCur;
    }
    public static dateJson1 = (value: string, format: string ): string => {
        let timeCur: string;
        if (value === null) {
            timeCur = '';
        } else if (!value) {
            timeCur = '';
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'json1', format);
        }
        return timeCur;
    }
    public static dateToString = (value: string, format: string ): string => {
        let timeCur: string;
        if (value === null) {
            timeCur = '';
        } else if (!value) {
            timeCur = '';
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'date', format);
        }
        return timeCur;
    }
    public static hourToString = (value: string, format: string ): string => {
        let timeCur: string;
        if (value === null) {
            timeCur = '';
        } else if (!value) {
            timeCur = '';
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'hour', format);
        }
        return timeCur;
    }
    // JLG - 2019-05-31 :  NON OK (ne liste que 5 des 78 colonnes du test) : cause inconnue
    // https://www.ag-grid.com/javascript-grid-cell-rendering/
    public static cellRendererSelector =  (params: any): any => {
        if (params === null) {
            return undefined;
        }
        const dataType = params.colDef.cellRendererParams.colFmtName;
        const numericData = {
            component: 'numericRendererComponent'
        };
        const alphanumData = {
            component: 'alphanumRendererComponent'
        };
        const dateData = {
            component: 'datetimeRendererComponent'
        };

        switch (dataType) {
            case 'numeric': {
                return numericData;
                break;
            }
            case 'date': {
                return dateData;
                break;
            }
            default : {
                return alphanumData;
                break;
            }
        }
        // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
        /*
        if (params.colDef.cellRendererParams.colFmtName === 'numeric')
            return numericData;
        else
            return alphanumData;
        */
    }
    public static cellRendererDetailSelector = (params: any): any => {
        const dataType = params.colDef.cellRendererParams.colFmtName;
        const subGrid = {
            component: 'gridDetailRendererComponent'
        };

        const detailData = {
            component: 'dataDetailRendererComponent'
        };
        switch (dataType) {
            case 'subGrid': {
                return subGrid;
                break;
            }
            case 'detailData': {
                return detailData;
                break;
            }
            default : {
                return subGrid;
                break;
            }
        }

    }
    // Bootstrap Grid
    public static createBS_Row(rendererArg: Renderer2, classListArg: string[]): any {
        const divRow = rendererArg.createElement('div');
        if (classListArg !== undefined) {
            for (const classCur of classListArg) {
                rendererArg.addClass(divRow, classCur);
            }
        }
        return divRow;
    }
    public static createElementBR(rendererArg: Renderer2): any {
        const brEl = rendererArg.createElement('br');
        return brEl;
    }
    public static createBStextArg(rendererArg: Renderer2, classListArg: string[], textArg: string, listStyleArg: KeyValue[]): any {
        const divText = rendererArg.createElement('div');
        const textCol = rendererArg.createText(textArg);
        if (classListArg !== undefined) {
            for (const classCur of classListArg) {
                rendererArg.addClass(divText, classCur);
            }
        }
        if (listStyleArg !== undefined) {
            for (const styleCur of listStyleArg) {
                rendererArg.setStyle(divText, styleCur.key, styleCur.value);
            }
        }
        rendererArg.appendChild(divText, textCol);
        return divText;
    }
    public static createColText(rendererArg: Renderer2, classListArg: string[], textArg: string): any {
        const divCol = rendererArg.createElement('div');
        if (classListArg !== undefined) {
            for (const classCur of classListArg) {
            rendererArg.addClass(divCol, classCur);
            }
        }
        if (textArg !== undefined) {
        const textCol = rendererArg.createText(textArg);
        rendererArg.appendChild(divCol, textCol);
        }
        return divCol;
    }
    public static createBS_LabelValue(rendererArg: Renderer2, classListArg: string [], textArg: any []): any {
        const divCol = rendererArg.createElement('div');
        if (classListArg !== undefined) {
          for (const classCur of classListArg) {
            rendererArg.addClass(divCol, classCur);
          }
        }
        for (const textCur of textArg) {
            rendererArg.appendChild(divCol, textCur);
        }
        return divCol;
    }
    public static createBS_Col(rendererArg: Renderer2, classListArg: string[], elementArg: any): any {
        const divCol = rendererArg.createElement('div');
        if (classListArg !== undefined) {
          for (const classCur of classListArg) {
            rendererArg.addClass(divCol, classCur);
          }

        }
        if (elementArg !== undefined) {
            rendererArg.appendChild(divCol, elementArg);
        }
        return divCol;
    }
}
