import { CalendarEvent } from 'angular-calendar';
import { SafeHtml } from '@angular/platform-browser';

export interface WksEntityParams {
  equipments?: EquipmentParam[];
  images?: {
    logoHome: any;
    logoOtherPages: any;
    logoHomeTitle: any;
  };
  ctrlInput?: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
  };
  statuts?: Statuts[];
  supportMails: string[];
  dayHours: string[];
  weekStartsOn: number;
  weekendDays: number[];
  calendarEvents?: EventDef[];
  rhEvents?: EventRh[];
}
export interface Statuts {
  ranking: number;
  name: string;
  validating: string[];
}
export interface InternationalsFormats {
  datetime: NameValue[];
  numbers: NameValue[];
  separators: NameValue[];
  justifs: NameValue[];
}
export interface NameValue {
  name: string;
  value: string;
}
export interface KeyValue {
  text: string;
  value: string;
}
export interface LabelValue {
    label: string;
    value?: string;
    values?: any[];
    otherData?: any;
  }
export interface LabelInt {
    label: string;
    value?: number;
  }
export interface FieldValue {
    name?: string;
    label?: string;
    value?: any;
    latin?: any;
    values?: any[];
  }
export interface FormFieldFmt {
  pattern: string;
  displayFmt: string;
  displayUnfmt: string;
  fieldType: string;
  lenght: number;
  separator: string;
  upperCase: boolean;
}
export interface CustomParam  {
  holder: string;
  fmt: string;
  display: string;
  lenght: number;
  separator: string;
  upperCase: boolean;
  helpImg: string;
}

export interface BrandParam {
  label: string;
  model: CustomParam;
  serialNumber: CustomParam;
}
export interface Hours {
  hours: {
    min: number;
    max: number;
  };
}
export interface EquipmentParam {
  type: string;
  brands: BrandParam[];
  services: string[];
  hours?: {
    min: number;
    max: number;
  };
}
export interface WksEntity {
  id?: number;
  stdEntity?: string;
  entityCountry?: string;
  accountCash?: string;
  accountFmt?: string;
  entityScales?: string;
  sharingPark?: string;
  sharingSettings?: string;
  entityAddress?: string;
  otherData?: string;
  entityDataPurge?: number;
  subsidiaryOf?: string;
  entityId?: string;
  entityPosition?: string;
  entityTz?: string;
  stdCreation?: string;
  stdLastupdate?: string;
  stdDisable?: string;
  telFmt?: TelDef;
  localAddress?: LocalAddress;
  mooring?: string[];
  wintering?: string[];
  locales?: string[];
  directionInput?: any;
  entityOptions?: string;
  isQuotationActive?: boolean;
  activity?: string;
  versionBE?: string;
  versionDateBE?: string;
}

export interface WksEntityImg {
  fileName: string;
  fileContent: any;
  fileMediaType: string;
  blobCur?: Blob;
  width?: string;
  height?: string;
}
export interface CitiesCountry {
  nameCity: string;
}
export interface Telephone {
  ITU_T_Code?: string;
  domesticFmt?: string;
  domesticDisplay?: string;
  E_164_Fmt?: string;
  E_164_Display?: string;
  phoneLengthMax?: string;
  domesticMask: string;
}
export interface Address {
  nbLinesMax: number;
  nbLinesMin: number;
  uppercaseLastLines: string;
  elemAddr: ElemAddr[];
  citiesCountry?: CitiesCountry[];
  statesList?: StatesList[];
}

export class PhoneInput {
  controlFmt: string;
  displayFmt: string;
  phoneLengthMax: string;
  phoneLength?: string;
  phoneCountry: string;
  phonePrefix: string;
  phoneNumber: string;
  phoneType: string;
  inputError?: boolean;
}
export class EmailInput {
  controlFmt: string;
  displayFmt: string;
  emailAddress: string;
  emailType: string;
  inputError?: boolean;
}

export interface CountryDef {
  isoNum: string;
  isoAlpha2: string;
  isoAlpha3: string;
  commonName: string;
  currency?: string;
  measurementUnit?: string;
  telephone?: Telephone;
  address?: Address;
  localAddress?: Address;
  sovereignty: string;
  timezones?: string[];
  taxation: Taxation;
  customRules?: string;
}
export interface TaxRow {
  taxPercent: number;
  taxName: string;
  taxBase: string;
}
export interface ProfileTax {
  profileName: string;
  taxRows: TaxRow[];
}
export interface Taxation {
  profilesTax: ProfileTax[];
}
export interface CountryLabel {
  isoAlpha2: string;
  commonName: string;
  disabled?: boolean;
  selected?: boolean;
}

export interface StatesLabel {
  stateName: string;
  stateAbb: string;
  disabled?: boolean;
  selected?: boolean;
}

export interface CountryLib  {
  isoAlpha2: string;
  commonName: string;
}

export interface TelDef {
  ITU_T_Code: string;
  domesticFmt: string;
  domesticDisplay: string;
  E_164_Fmt: string;
  E_164_Display: string;
  phoneLengthMax: string;
  domesticMask: string;
}
export interface ElemAddr {
  elmtNumber: string;
  lineNumber: string;
  lineDataIndex: string;
  dataName: string;
  dataMandatory: string;
  dataInput: string;
  dataValue: string;
  dataType: string;
  dataLengthMax: string;
  controlType?: string;
  controlFmt?: string;
  displayFmt?: string;
  elemCountry: string;
  elemLabel?: string;
  inputValue?: string;
  latinValue?: string;
  inputError?: boolean;
  elemUpperCase?: boolean;
  enable?: boolean;
}
export interface StatesList {
  stateName: string;
  stateAbb: string;
}
export interface CitiesCountry {
  nameCity: string;
}
export interface LocalAddress {
  nbLinesMax: number;
  nbLinesMin: number;
  uppercaseLastLines: string;
  elemAddr: ElemAddr[];
  citiesCountry?: CitiesCountry[];
  statesList?: StatesList[];
}
export interface InputDirection {
  lang: string;
  direction: string;
}

export enum AlertType {
  Success,
  Important,
  Error,
  Info,
  Warning
}

export enum LevelType {
  parent,
  child,
}

// Calendar
export interface EventDef {
  type: string;
  name: string;
  primary: string;
  secondary: string;
  bulletColor: string;
}
export interface EventRh {
    type: string;
    label: string;
  }
export interface ColorEvent {
    primary: string;
    secondary: string;
}
export interface CalendarEventBdd {
    id: string;
    stdEntity: string;
    calApplication: string;
    calType: string;
    calLinkid: string;
    calStart: string;
    calEnd: string;
    calAction: string;
    calTitle: string;
    calOutside: string;
    calDescription: string;
    calComment: string;
    calAllday: boolean;
    calActor: string;
    calOtherdatas: string;
    calStatut: string;
    stdCreationDate?: Date;
    stdCreationUser?: string;
    stdLastupdateDate?: Date;
    stdLastupdateUser?: string;
    stdDisableDate?: Date;
    stdDisableUser?: string;
    calDateStart?: string;
    calDateEnd?: string;
    workRef?: string;
  }
export interface CalendarEvt extends CalendarEvent {
    linkId?: string;
    description?: string;
    comment?: string;
    calType?: string;
    ref?: string;
    outside?: string;
    bddId?: string;
    calStatut?: string;
    stdCreationDate?: Date;
    stdCreationUser?: string;
    action?: string;
    bddAction?: string;
    oldTime?: {
      start?: Date;
      end?: Date;
    };
}
export interface EventChanged {
  eventCur: CalendarEvt;
  action: string;
}
export interface WorkingTime {
  dayHours: string[];
  weekStartsOn: number;
  weekendDays: number[];
}

export class Alert {
  type: AlertType;
  message: string;
  data1: string;
  data2: string;
  alertType: string;
  htmlVar?: any;
}


export enum StateType {
  noState,
  waiting,
  start,
  done,
}

export class CommonReport {
  type: AlertType;
  level: LevelType;
  state: StateType;
  pathParent: string;
  id: string;
  message: string;
  isEndReport: boolean;
  isStartReport: boolean;
  isLastChild: boolean;
  isNewParent: boolean;
  orig: string;
  toString(): string {
      return ' orig : ' + this.orig + ' type : ' + this.type + ' level : ' + this.level +
      ' state : ' + this.state + ' pathParent : ' + this.pathParent + ' id : ' +
          this.id + ' message : ' + this.message + ' isEndReport : ' + this.isEndReport +  ' isStartReport : ' +
          this.isStartReport +  ' isLastChild : ' + this.isLastChild + ' isNewParent : ' + this.isNewParent;

  }
}
export interface FileToDisplay {
  fileName: string;
  fileLegend: string;
  filePath: string;
  fileRef?: string;
  dataImgLoaded: boolean;
  imageToShow: any;
}

export interface ShareDataInt {
  isProfessionnal: boolean;
  isAdministration: boolean;
  isPerson: boolean;
  isCompany: boolean;
  isIndividual: boolean;
}
