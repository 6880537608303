<mat-card class="stdTheme">
  <mat-card-header>
    <mat-card-title></mat-card-title>
  </mat-card-header>
  <mat-card-content class="confirmation-container">
    <div *ngIf="!isRegistred">
      <fieldset class="informationsborder">
        <div style="margin-top: 10px; margin-bottom: 10px;">
          <!-- https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons -->
          <div class="fa-3x">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          
          <p>{{ 'CONFIRMATION.WAIT_REGISTERED' |  translate }}</p>
        </div>
      </fieldset>
    </div>
    <div *ngIf="isRegistred">
      <fieldset class="informationsborder">
        <div style="margin-top: 10px; margin-bottom: 10px;">
          <div style="display:flex; justify-content: center; ">
            <!--<img src="/assets/img/success.png | safeHtml" style="width: 80px; height:80px;">-->
            <span style="font-size: 2em; color: green;">
              <i class="fas fa-check fa-3x"></i>
            </span>
          </div>
          <!--<h3 class="first-paragraph">{{ 'CONFIRMATION.FIRST_PARAGRAPH' | translate: { param1: appRef } }}</h3>-->
          <h3 class="first-paragraph" >{{ stringInCode }}</h3>
          <!--| translate : [row.username] -->
          <p>{{ 'CONFIRMATION.SECOND_PARAGRAPH' |  translate }}</p>
        </div>
      </fieldset>
    </div>
  </mat-card-content>
  <mat-card-actions align="end" class="actions">
    <div *ngIf="isRegistred">
      <button  mat-raised-button  (click)="onGoHome()">
        {{ 'COMMUN.BUTTON.HOME' | translate }}
        <mat-icon class="matIconMiddle" >home</mat-icon>
      </button>
    </div>
  </mat-card-actions>

</mat-card>
