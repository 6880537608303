
<h2 mat-dialog-title style="text-align: center;">{{modalTitle}}</h2>

<!-- <mat-dialog-content [formGroup]="form"> -->
<mat-dialog-content class="mat-typography">

    <!-- <div [innerHTML]="textHTML" ></div> -->
    <div *ngIf="uploadProcess">
        <ng-template ngFor let-line [ngForOf]= "jsonData" let-i="index">
                <div *ngIf="numberErrors[i] == 0;then noerror else errors">here is ignored</div>    
                <ng-template #noerror>
                    <button mat-button  (click)="toggleMessages(i)">
                        <span *ngIf = "!displayMessages[i]" class="fa fa-plus" ></span>
                        <span *ngIf = "displayMessages[i]" class="fa fa-minus" ></span>
                        <b>  {{line.parent}}</b> ({{numberMessages[i]}})
                    </button>
                </ng-template>
                <ng-template #errors>
                    <button mat-button color="warn" (click)="toggleMessages(i)">
                        <span *ngIf = "!displayMessages[i]" class="fa fa-plus" ></span>
                        <span *ngIf = "displayMessages[i]" class="fa fa-minus" ></span>
                        <b>  {{line.parent}}</b> ({{numberMessages[i]}}) / ({{'NbErrors' | translate}} {{numberErrors[i]}})
                    </button>
                </ng-template>
            <!--<p><b>{{line.parent}}</b></p>-->
            <div *ngIf = "displayMessages[i]">
                <ng-template ngFor let-lineMessage [ngForOf]= "line.children" let-iMessage="index">
                    <p *ngIf = "displayMessages[i]" >{{lineMessage.message}} </p>
                </ng-template> 
            </div>
        </ng-template> 
    </div>
    <div *ngIf="infoProgressWks" class="container-fluid" style="width: 100%; height: 100px">
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="spinner-container  stdTheme col-12"  fxLayout="row" fxLayoutAlign="space-around center">
                <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
            </div>
        </div>
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="stdTheme col-12"  style="text-align: center;">
                <span > 
                    {{ contentMessage }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="infoWks || alertWks || confirmAction" class="container" style="width: 100%; height: 100px">
        <app-alert-common 
            [messageInfo] = "contentMessage"
            [data1] = "data1"
            [data2] = "data2"
            [messageType] = "messageType"
            [alertType] = "infoWks ? 'infoWks' : 'alertWks'"
            [actionCur] = "actionCur"
        ></app-alert-common>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center" >
    <div *ngIf="uploadProcess && isEndReport">
        <!--<button class="mat-raised-button buttonTheme" mat-dialog-close (click)="close()" [disabled]="!isEndReport" >Close</button>-->
        <button class="mat-raised-button buttonTheme" mat-dialog-close (click)="close()" >{{'COMMUN.BUTTON.CLOSE' | translate}}</button>
    </div>
    <div *ngIf="updatePwd" >
        <button style="margin-left:10px; margin-right: 40px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onNoClick()">{{'COMMUN.BUTTON.CANCEL' | translate}}</button>
        <button style="margin-left:30px; margin-right: 10px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onOKClick()" [disabled]="!newPasswdOK">{{'COMMUN.BUTTON.SUBMIT' | translate}}</button> 
    </div>
    <div *ngIf="confirmAction" >
        <button style="margin-left:10px; margin-right: 40px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onNoClick()">{{'COMMUN.BUTTON.CANCEL' | translate}}</button>
        <button style="margin-left:30px; margin-right: 10px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onOKClick()">{{'COMMUN.BUTTON.SUBMIT' | translate}}</button> 
    </div>
    <div *ngIf="confirmAlert" >
        <button  class="mat-raised-button buttonTheme" mat-dialog-close (click)="close()" >{{'COMMUN.BUTTON.OK' | translate}}</button> 
    </div>
    <!-- <button class="mat-raised-button mat-primary"(click)="save()" [disabled]="!isEndReport">Save</button> 
     <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
    -->
</mat-dialog-actions>
