<div *ngIf="okToDisplay"class="stdTheme" style="margin-top: 5px;margin-left: 10px;margin-right: 10px;">
    <mat-horizontal-stepper class="stdTheme" #stepper linear>
        <!--<mat-step [stepControl]="startForm" [completed]="startFormCompleted">-->
        <mat-step [stepControl]="startForm" >
            <ng-template matStepLabel>
                <span class="stdTheme">{{ 'ADDRESS_BLOCK.SETTINGS' | translate }}</span>
            </ng-template>
            <ng-container [ngTemplateOutlet]="startFormTemplate"  ></ng-container>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button (click)="returnHome()" >
                    <mat-icon>home</mat-icon>
                    {{ 'COMMUN.BUTTON.HOME' | translate }}
                </button>
                <button mat-raised-button matStepperNext>
                    <mat-icon>navigate_next</mat-icon>
                    {{ 'COMMUN.BUTTON.NEXT' | translate }}
                </button>
            </div>
        </mat-step>
        <mat-step [stepControl]="credentialsForm" >
            <ng-template matStepLabel>
                <span class="stdTheme">{{ 'ADDRESS_BLOCK.CREDENTIALS' | translate }}</span>
            </ng-template>
            <ng-container [ngTemplateOutlet]="credentialsFormTemplate"  ></ng-container>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                </button>
                <button *ngIf="credentialsOK" mat-raised-button matStepperNext>
                    <mat-icon>navigate_next</mat-icon>
                    {{ 'COMMUN.BUTTON.NEXT' | translate }}
                </button>
            </div>
        </mat-step>   
        <mat-step *ngIf="isPerson && isStructureChange && isTaxsystemChange" [completed]="isPersonCompleted">
            <ng-template matStepLabel>
                <span class="stdTheme">{{ 'ADDRESS_BLOCK.PERSON' | translate }}</span>
            </ng-template>
            <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
                <app-address-block style="width: 100%"
                            [config]="configAddress" 
                            [dataIn]="dataAccount" 
                            (dataOut)="dataOutAddress($event)"></app-address-block>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                </button>
                <button mat-raised-button matStepperNext *ngIf="isPersonCompleted">
                    <mat-icon>navigate_next</mat-icon>
                    {{ 'COMMUN.BUTTON.NEXT' | translate }}
                </button>
            </div>
        </mat-step>
    
        <mat-step *ngIf="(isCompany || isAdministration) && isStructureChange" [completed]="isCorporateCompleted"> 
            <ng-template matStepLabel>
                <span *ngIf="isCompany" class="stdTheme">{{ 'ADDRESS_BLOCK.COMPANY' | translate }}</span>
                <span *ngIf="isAdministration" class="stdTheme">{{ 'ADDRESS_BLOCK.ADMINISTRATION' | translate }}</span>
            </ng-template>
            <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
                <app-address-block style="width: 100%"
                            [config]="configAddress" 
                            [dataIn]="dataAccount" 
                            (dataOut)="dataOutAddress($event)"></app-address-block>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                </button>
                <button mat-raised-button matStepperNext *ngIf="isCorporateCompleted">
                    <mat-icon>navigate_next</mat-icon>
                    {{ 'COMMUN.BUTTON.NEXT' | translate }}
                </button>
            </div>
        </mat-step>
        <mat-step *ngIf="!isPerson  && isStructureChange"  [completed]="isContactCompleted">
            <ng-template matStepLabel>
                <span class="stdTheme">{{ 'ADDRESS_BLOCK.CONTACT' | translate }}</span>
            </ng-template>
            <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
                <app-address-block style="width: 100%"
                            [config]="configContact" 
                            [dataIn]="dataContact" 
                            (dataOut)="dataOutAddress($event)"></app-address-block>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                </button>
                <button mat-raised-button matStepperNext *ngIf="isContactCompleted">
                    <mat-icon>navigate_next</mat-icon>
                    {{ 'COMMUN.BUTTON.NEXT' | translate }}
                </button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>
                <span class="stdTheme">{{ 'ADDRESS_BLOCK.DONE' | translate }}</span>
            </ng-template>
            <p>{{ 'ADDRESS_BLOCK.DONE_LABEL' | translate }}</p>
            <div style="display: flex;justify-content: space-between;">
                <button mat-raised-button matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                </button>
                <button mat-raised-button (click)="submitAccount()">
                    {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
                </button>
            </div>
        </mat-step>
    
      </mat-horizontal-stepper>           
</div>
<ng-template #startFormTemplate>
    <div class="stdTheme" style="min-height: 350px!important;">
        <form [formGroup]="startForm">
            <div class="flex-row row wkscard stdTheme" style="margin-right: 0px;margin-left: 0px;height:80px" >
                <div class="col-lg-6 col-xs-12" style="padding-left: 0px;padding-right: 0px;">
                    <mat-form-field class="stdSelect form-group" appearance="standard">
                        <mat-label>{{ 'ADDRESS_BLOCK.SELECT_COUNTRY' | translate }}</mat-label>
                        <mat-select id="entitycountry" placeholder="{{ 'ADDRESS_BLOCK.SELECT_COUNTRY' | translate }}" formControlName="entitycountry" 
                                        panelClass="mdiselectcontent"
                                        (ngModelChange)="onChangeCountry($event)"
                                        ngDefaultControl required>
                            <div style="display: inline-block;" >
                                <button mat-icon-button matSuffix aria-label="search" >
                                    <mat-icon>search</mat-icon>
                                </button>
                                <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                            (keyup)="onKeyCountry($event)" >
                                <button mat-icon-button matSuffix aria-label="clear" 
                                            *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                                <mat-icon>close</mat-icon>
                                </button>
                            </div>
                            <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                                [value]="country.isoAlpha2"  >{{country.commonName | translate}}
                            </mat-option>
                        </mat-select>      
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row row wkscard stdTheme">
                <div class="col-lg-6 col-xs-12" style="padding-left: 0px;padding-right: 0px;">
                <mat-radio-group #structure="matRadioGroup" aria-label="Select an option" 
                        formControlName="bsnStructure" (change)="onStructureChange(structure.value)">
                    <mat-radio-button style="margin-right: 10px; margin-top: 5px;" value="company">{{ 'ADDRESS_BLOCK.COMPANY' | translate }}</mat-radio-button>
                    <mat-radio-button style="margin-right: 10px; margin-top: 5px;" value="administration">{{ 'ADDRESS_BLOCK.ADMINISTRATION' | translate }}</mat-radio-button>
                    <mat-radio-button style="margin-right: 10px; margin-top: 5px;" value="person">{{ 'ADDRESS_BLOCK.PERSON' | translate }}</mat-radio-button>
                </mat-radio-group>
                </div>
            </div>
            <div *ngIf="isPerson" class="flex-row row wkscard stdTheme">
                <div class="col-lg-6 col-xs-12"  style="padding-left: 0px;padding-right: 0px;">
                    <div >
                    <!--<span style="margin-top: 5px;margin-right:3%;">{{ 'ADDRESS_BLOCK.STATUT' | translate }} : </span>-->
                    <mat-radio-group  #taxsystem="matRadioGroup" aria-label="Select an option" [required]="isPerson"
                            formControlName="bsnTaxsystem" (change)="onTaxsystemChange(taxsystem.value)">
                        <mat-radio-button style="margin-right: 10px; margin-top: 5px;" [checked]="isProfessional" value="professional">{{ 'ADDRESS_BLOCK.PROFESSIONAL' | translate }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 10px; margin-top: 5px;" [checked]="isIndividual" value="individual">{{ 'ADDRESS_BLOCK.INDIVIDUAL' | translate }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 10px; margin-top: 5px;" [checked]="isUnknow" value="unknow">{{ 'ADDRESS_BLOCK.UNKNOW' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #credentialsFormTemplate>
    <div *ngIf="credentialsFormIsBuild" class="stdTheme" style="min-height: 350px!important;">
        <form [formGroup]="credentialsForm">
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px" >
                <div class="col-lg-6 col-xs-12" style="padding-right: 2px; padding-left: 2px">
                    <mat-form-field appearance="standard"  class="wksNormalInput" >
                        <mat-label>{{ 'ADDRESS_BLOCK.IDENTIFIER' | translate }}</mat-label> 
                        <input id="identifierName"  class="form-control inputTheme" matInput formControlName="identifierName" #identifierName
                                placeholder="{{ 'ADDRESS_BLOCK.IDENTIFIER_FMT' | translate }}" required
                                type="email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                                (change)="identifierNameChange(identifierName.value)"
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px">
                <div class="col-lg-3 col-xs-12 passwordField" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="wksNormalInput form-group" appearance="standard">
                    <mat-label>{{ 'CREDENTIALS_BLOCK.PASSWORD' | translate }}</mat-label>
                    <input id="password" class="form-control inputTheme" formControlName="password" 
                                    matInput #password minlength="7" placeholder="{{ 'CREDENTIALS_BLOCK.PASSWORD' | translate }}"
                      required [type]="showPassword ? 'password' : 'text'" (change)="sendPasswd();">
                    <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-hint align="end">{{password.value?.length || 0}}</mat-hint>
                    <mat-error style="margin-bottom: 20px;font-size: 15px;" *ngIf="passwordCtrl.invalid">{{getErrorMessage('password')}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-xs-12" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="wksNormalInput form-group" appearance="standard">
                    <mat-label>{{ 'CREDENTIALS_BLOCK.PASSWORD_CONFIRM' | translate }}</mat-label>
                    <input id="confirm" class="form-control inputTheme" [type]="showPassword ? 'password' : 'text'" 
                            [readonly]="passwordCtrl.invalid"
                            formControlName="confirmPassword" 
                            matInput placeholder="{{ 'CREDENTIALS_BLOCK.PASSWORD_CONFIRM' | translate }}" required (change)="sendPasswd();"
                            (keyup)="ctrlPasswd()">
                    <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error style="margin-bottom: 20px;font-size: 15px;" *ngIf="confirmPasswordCtrl.invalid">{{getErrorMessage('confirmPassword')}}</mat-error>
                  </mat-form-field>
                </div>
            </div>

            <div *ngIf="activeSecurities" formArrayName="securities">
                <div *ngFor="let security of securities.controls; let i=index">
                    <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px; width: 100%" [formGroupName]="i">
                        <div class="col-lg-3 col-xs-12" style="margin-right: 0px;margin-left: 0px; padding-left: 0px; padding-right: 0px;">
                            <mat-form-field class="wksNormalInput form-group" appearance="standard">
                                <mat-label>{{ 'CREDENTIALS_BLOCK.QUESTION' | translate }} {{i+1}}</mat-label>
                                <input  class="form-control inputTheme"
                                    formControlName="question" 
                                    matInput placeholder="{{ 'CREDENTIALS_BLOCK.QUESTION' | translate }}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3 col-xs-12 " style="margin-right: 5px;margin-left: 5px; padding-left: 0px; padding-right: 0px;">
                            <mat-form-field class="wksNormalInput form-group" appearance="standard">
                                <mat-label>{{ 'CREDENTIALS_BLOCK.ANSWER' | translate }} {{i+1}}</mat-label>
                                <input  class="form-control inputTheme"
                                    formControlName="answer" 
                                    matInput placeholder="{{ 'CREDENTIALS_BLOCK.ANSWER' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>