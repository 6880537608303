<div   class="container-fluid stdTheme col-lg-6 col-md-offset-3" >
    <mat-card  class="stdTheme matCardLogin" style="padding: 0px;width: 100%;" 
            fxLayout="column" fxLayoutAlign="center" >
        <!--
            <mat-card-title class="stdTheme" style="padding-top: 10px;text-align: center;">
                <h1 class="stdTheme" >{{ 'LOGIN.SIGN_IN' | translate }}</h1>
            </mat-card-title>
            optionItem = 'authenticateCustomer'
        -->
        <mat-card-title *ngIf="optionItem !== 'authenticateCustomer'" class="stdTheme" style="padding-top: 10px;text-align: center;" >{{ 'LOGIN.SIGN_IN' | translate }}</mat-card-title> 
        <mat-card-title *ngIf="optionItem === 'authenticateCustomer'" class="stdTheme" style="padding-top: 10px;text-align: center;" >{{ 'LOGIN.SIGN_IN_CUSTOMER' | translate }}</mat-card-title> 
        <mat-card-content class="stdTheme" style="padding-top: 10px ;">
         <!--
          <div fxLayoutAlign="end" style="padding-inline-end: 10px ;">
                
            <button mat-raised-button class="buttonTheme" #buttonTheme style="border-radius: 20px;" (click)="toggleDarkTheme($event)">
              <i class="fas fa-adjust"> </i>
                <span *ngIf="!themeIsDark" style="padding-left: 10px;">{{ 'LOGIN.DARK_THEME' | translate }}</span>
                <span *ngIf="themeIsDark" style="padding-left: 10px;" > {{ 'LOGIN.LIGHT_THEME' | translate }}</span>
            </button>
     
          </div>
          -->
          <div class="alert alert-danger" *ngIf="authenticationFailed">
            <button type="button" class="close" aria-label="Close" (click)="authenticationFailed = false;">
              <span aria-hidden="true">&#215;</span>
            </button>
            {{ 'LOGIN.ERROR' | translate }}
            <br>
            <button *ngIf="optionItem === 'authenticateCustomer'" style="text-align: middle;" (click)="changePassword()" >
                <mat-icon>home</mat-icon>
                {{ 'LOGIN.PASSWORD_FORGOTTEN' | translate }}
            </button>
          </div>
          <form (ngSubmit)="authenticate()" #loginForm="ngForm" >  
              <div class="form-group stdTheme" style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em">
                <div >
                  <span class="fa fa-user" ></span>
                  <span style="padding-left: 1em"></span>
                  <span for="login" >{{ 'LOGIN.ID' | translate }}</span>
                  <span class="spacer5" ></span>
                </div>
                <div>
                  <input id="login" class="form-control inputTheme"  name="login" [(ngModel)]="credentials.login" placeholder="{{ 'ID' | translate }}"
                              required #loginCtrl="ngModel">
                </div>
                <div *ngIf="loginCtrl.control.dirty && loginCtrl.control.hasError('required')">Login is required</div>
              </div>
  
              <div class="form-group stdTheme"  style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em">
                <div >
                  <span class="fa fa-lock" ></span>
                  <span style="padding-left: 1em"></span>
                  <span for="password" >{{ 'LOGIN.PASSWORD' | translate }}</span>
                </div>
                <div class="input-group">
                  <input  id="password" class="form-control inputTheme" type="password" name="password" placeholder="{{ 'Password' | translate }}"
                            [type]="showPassword ? 'text' : 'password'"            
                            [(ngModel)]="credentials.password" required #passwordCtrl="ngModel">
                  <div class="input-group-append inputTheme">
                    <div class="input-group-text inputTheme">
                      <i class="fa fa-eye-slash inputTheme" *ngIf="!showPassword" (click)="showPassword = !showPassword"></i>
                      <i class="fa fa-eye inputTheme" *ngIf="showPassword" (click)="showPassword = !showPassword"></i>
                    </div>
                  </div>
                  
                </div>
                <div *ngIf="passwordCtrl.control.dirty && passwordCtrl.control.hasError('required')">Password is required</div>
              </div>
   
              <div *ngIf="optionItem !== 'authenticateCustomer'" style="padding-bottom: 20px;display: flex;justify-content: center;">

                <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!loginForm.valid" >
                  <mat-icon>input</mat-icon>  {{ 'LOGIN.LOGIN' | translate }}
                </button>
              </div>
              <div *ngIf="optionItem === 'authenticateCustomer'" style="padding-bottom: 20px;display: flex;justify-content: space-between;">
                <button  mat-raised-button (click)="returnHome()" >
                    <mat-icon>home</mat-icon>
                    {{ 'COMMUN.BUTTON.HOME' | translate }}
                </button>
                <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!loginForm.valid" >
                  <mat-icon>input</mat-icon>  {{ 'LOGIN.LOGIN' | translate }}
                </button>
              </div>
          </form>
        </mat-card-content>
  
      </mat-card>
  </div>