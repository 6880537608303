<form [formGroup]="equipmentForm" [dir]="wksEntity.directionInput" >
  <mat-card class="stdTheme">
    <mat-card-title  *ngIf="!readOnlyData && !isCheckList" style="text-align: center;" >{{ 'EQUIPMENT.FORM_TITLE' | translate }} {{equipment.equipmentNum}}</mat-card-title> 
    <mat-card-title  *ngIf="isCheckList" style="text-align: center;" >{{ 'EQUIPMENT.CHECKLIST' | translate }} {{equipment.equipmentNum}}</mat-card-title> 
    <mat-card-content>
      <fieldset class="informationsborder">
        <div *ngIf="imgHelpLoaded" >
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px;" >
            <div class="col-12"  style="display:flex; justify-content: center; ">
              <div class="logo" >
                <img [src]="helpToShow | safeHtml: 'resourceUrl' " style="width: 290px; height: 300px;">
              </div>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px;" >
            <div class="col-12"  style="display:flex; justify-content: center; ">
              <button mat-raised-button (click)="closeHelp()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!imgHelpLoaded">
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'EQUIPMENT.TYPE' | translate }}</mat-label>
                <mat-select id="language" placeholder="{{ 'Choose equipment' | translate }}" formControlName="type" 
                          #equipType required
                          (ngModelChange)="onChangeType(equipType.value)"
                          ngDefaultControl >
                          <mat-option class="selectOptionsTheme" *ngFor="let equipCur of equipmentParam" 
                                      [value]="equipCur.value"  >{{ equipCur.label | translate }}</mat-option>
                </mat-select>         
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'EQUIPMENT.BRAND' | translate }}</mat-label>
                <mat-select id="brand" placeholder="{{ 'Choose brand' | translate }}" formControlName="brand" 
                          #brand required 
                          (focus)="onFocus('brand', brand.value)" 
                          (ngModelChange)="onChangeBrand($event)"
                          ngDefaultControl >
                          <mat-option class="selectOptionsTheme" *ngFor="let brandCur of equipmentBrand"  
                                      [value]="brandCur.label"  >{{ brandCur.label }}</mat-option>
                </mat-select>         
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'EQUIPMENT.MODEL' | translate }}</mat-label>
                <input formControlName="model" matInput type="text" required #model (change)="onChangeModel(model.value)">
                <mat-icon matSuffix (click)="onHelp('model')">help_outline</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div  class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
            <div *ngIf="ctrlInput.inputSerialNumber" class="col-10" style="margin-top: -20px">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'EQUIPMENT.SERIAL_NUMBER' | translate }}</mat-label>
                <!--<input formControlName="serialNumber" matInput type="text">-->
                <input matInput class='serialNumber' type="text" id="serialNumber" 
                  placeholder="{{ serialNumberInput.holder }}"
                  [attr.maxlength]="serialNumberInput.dataLengthMax" 
                  matInput #serialNumber 
                  [ngClass]="{
                      'inputAlert': serialNumberInput.inputError,
                      'inputNormal': !serialNumberInput.inputError
                      }"
                  [value]="serialNumberInput.inputValue" 
                  (input)="formatField('serialNumber',serialNumberInput,serialNumber.value)"
                  formControlName="serialNumber"
                  appFormatField= "{{ formField }}"
                >
                <mat-icon matSuffix (click)="onHelp('serialNumber')">help_outline</mat-icon>
              </mat-form-field>
            </div>
          </div>
          
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px" >
            <div class="col-5" style="margin-top: -20px">
              <mat-label>{{ 'EQUIPMENT.USAGE' | translate }}</mat-label>
            </div>
            <div class="col-3" style="margin-top: -30px;padding-left: 0px;margin-left: 0px;">
              <mat-form-field appearance="standard" floatLabel="never" style="margin-top: -20px;" >
                <input matInput  formControlName="nbHoursOfUse" #nbHoursOfUse style="height: 30px;text-align: center" 
                     (input)="formatField('nbHoursOfUse','',nbHoursOfUse.value)"
                      />
              </mat-form-field>
            </div>
            <div class="col-2" style="margin-top: -20px;margin-left:-15px">
              <mat-label>({{ 'EQUIPMENT.HOURS' | translate }})</mat-label>
            </div>
          </div>
          <div class="col-4" *ngIf="isMotorBoat && isCheckList">
            <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'EQUIPMENT.MOTOR_POSITION' | translate }}</mat-label>
                <mat-select id="motorPosition" formControlName="motorPosition" 
                        #motorPosition  (selectionChange)="onChangeMotorPosition(motorPosition.value,'motorPosition')"
                        panelClass="mdiselectcontent"   ngDefaultControl >
                        <mat-option class="selectOptionsTheme" *ngFor="let motorPosition of positionsMotorList" 
                            [value]="motorPosition"  >{{motorPosition | translate}}
                        </mat-option>
                </mat-select>
            </mat-form-field>
        </div> 
          <div  class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px" >
            <div class="col-10" style="margin-top: 0px">
              <mat-form-field appearance="standard"  style="margin-top: -30px">
                <mat-label>{{ 'EQUIPMENT.ASKED_SERVICE' | translate }}</mat-label>
                <!--<input formControlName="askedService" matInput type="text" required>-->
                <mat-select id="askedService" formControlName="askedService" 
                          #askedService required
                          (ngModelChange)="onChangeService($event)"
                          ngDefaultControl >
                          <mat-option class="selectOptionsTheme" *ngFor="let serviceCur of servicesList" 
                                      [value]="serviceCur.value"  >{{ serviceCur.label }}</mat-option>
                </mat-select>         
              </mat-form-field>
            </div>
          </div>
          <!-- REGULAR_SERVICE -->
          <div *ngIf="displayCountHours" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
            <div class="col-3" style="margin-top: 0px;">
              <mat-label>{{ 'EQUIPMENT.HOURS_SERVICE' | translate }}</mat-label>
            </div>
            <div class="col-5" style="margin-top: -30px;padding-left: 0px;margin-left: 0px;margin-left: -10px;">
              <mat-form-field appearance="standard" floatLabel="never">
                <!-- <mat-label>{{ 'EQUIPMENT.NB_HOURS_OF_USE' | translate }}</mat-label>-->
                <button 
                  mat-icon-button 
                  matPrefix 
                  aria-label="Remove" 
                  (click)="counter('askedServiceHours',-100, askedServiceHours.value)" >
                  <mat-icon style="margin-top: 10px;">remove</mat-icon>
                </button>
                <input matInput formControlName="askedServiceHours" #askedServiceHours readonly style="height: 30px;text-align: center" />
                <div matSuffix style="position: relative">
                  <button 
                    mat-icon-button
                    aria-label="Add" 
                    (click)="counter('askedServiceHours',100,  askedServiceHours.value)" >
                    <mat-icon style="margin-top: 10px;">add</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>
    </mat-card-content>
    <mat-card-actions *ngIf="!readOnlyData  && !isCheckList" class="actions">
      <button mat-raised-button (click)="onPrevious()">
        <mat-icon>navigate_before</mat-icon>
        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
      </button>
      <button [disabled]="equipmentForm.invalid" mat-raised-button (click)="onSubmit()">
        {{ 'COMMUN.BUTTON.NEXT' | translate }}
        <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
