
<div  #refElement class="mat-elevation-z8 container-fluid stdTheme" [ngStyle]="{'margin-top': '0px', width:  '100%' ,
                                                                                    padding: '10px', height: '100%'}" >
    <!--
    <mat-card  class="register-card stdTheme"  style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;">
        <span>toto</span>
    </mat-card>
-->
        <!--
    <div *ngIf="!appointmentIsLoaded" class="border d-flex align-items-center justify-content-center">
        <div class="stdTheme" >
            <mat-spinner diameter="30" strokeWidth="5"></mat-spinner>
        </div>
    </div>  
-->
    <div >                 
        <mat-card class="register-card stdTheme"  style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;">
            <mat-card-actions>
                <div class="container-fluid d-flex justify-content-between" >
                    <button *ngIf="parentForm === 'fullScreen'" mat-raised-button  #buttonCancel type="reset" (click)="doClose()">
                        <mat-icon>arrow_back</mat-icon>
                        {{ 'COMMUN.BUTTON.CLOSE' | translate }}
                    </button>
                    <span *ngIf="parentForm === 'modal'"  style="text-align: center!important;font-size:large"><strong></strong></span>
                    <span style="text-align: center!important;font-size:large"><strong>{{ titleCard | translate }}</strong></span>
                    <span *ngIf="appointmentRequest !== undefined"style="text-align: right!important;font-size:large">{{ appointmentRequest.appointmentRef }}</span>
                </div>
            </mat-card-actions>
            <mat-card-content class="stdTheme">
                <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px;">
                    <!--                    <mat-tab label="{{ 'TRACKING.TRACKING' | translate }}" >
                        <div *ngIf="appointmentRequest">
                            <div>
                                <ng-container [ngTemplateOutlet]="trackingCard"></ng-container> 
                            </div>
                        </div>
                    </mat-tab>
                    -->
                    <mat-tab label="{{ 'CLIENT.DETAIL_CLIENT' | translate }}" >
                        <div *ngIf="appointmentRequest && appointmentRequest.client">
                            <div>
                                <ng-container [ngTemplateOutlet]="clientCard" [ngTemplateOutletContext]="{client:appointmentRequest.client}"></ng-container> 
                            </div>
                        </div>
                    </mat-tab>
                    <div *ngIf="appointmentRequest && appointmentRequest.equipments">
                        <div  *ngFor="let equipment of appointmentRequest.equipments; let index = index; let isFirst = first; let isOdd = odd;">
                            <mat-tab label="{{ 'EQUIPMENT.EQUIPMENT' | translate }} {{equipment.equipmentNum}}" >
                                <div>
                                    <ng-container [ngTemplateOutlet]="equipmentCard" [ngTemplateOutletContext]="{equipment:equipment, client:appointmentRequest.client, idxEquip:index}"></ng-container> 
                                </div>
                            </mat-tab>
                        </div>
                    </div>
                    <mat-tab label="{{ 'LOCATION.EQUIPMENT_LOCATION' | translate }}" >
                        <div *ngIf="appointmentRequest">
                            <div>
                                <ng-container [ngTemplateOutlet]="locationCard" [ngTemplateOutletContext]="{appointmentRequest:appointmentRequest}"></ng-container> 
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div> 

</div>
<ng-template #trackingCard  >

</ng-template>
<ng-template #clientCard let-client='client' >
    <app-client-form [wksEntity]="wksEntity" [client]="client" [countryFmt]="countryFmt" [readOnlyData]="readOnlyData"></app-client-form>
</ng-template>
<ng-template #equipmentCard let-equipmentCur='equipment' let-client='client' let-idxEquip='idxEquip' >
    <app-equipment-form [equipment]="equipmentCur" 
            [idxEquip]="idxEquip"
            [appointmentRequest]="appointmentRequest" [wksEntity]="wksEntity"
            [countryFmt]="countryFmt"  [wksEntityParams]="wksEntityParams"
            [client]="client" [readOnlyData]="readOnlyData">
    </app-equipment-form>
</ng-template>
<ng-template #locationCard let-appointmentRequest='appointmentRequest' >
    <app-location-form [appointmentRequest]="appointmentRequest" 
                [wksEntity]="wksEntity" [countryFmt]="countryFmt" 
                [wksEntityParams]="wksEntityParams" [readOnlyData]="readOnlyData">
    </app-location-form>
</ng-template>