import { Component, EventEmitter, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Equipment, Client, AppointmentRequest } from 'src/app/models';
import { AppointmentService } from '../../../../services/appointment.service';
import { SharingDataService } from '../../../../services/sharing-data.service';
import { WksEntityParams, EquipmentParam, LabelValue, LabelInt, BrandParam, CustomParam, WksEntityImg,
          FormFieldFmt, WksEntity, MotorsConfigModel, EquipmntOtherData } from '../../../../models/';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['../../../../shared.less', './equipment-form.component.less']
})
export class EquipmentFormComponent implements OnChanges {
  @Input()
  wksEntityParams: WksEntityParams;
  @Input()
  equipment!: Equipment;
  @Input()
  client!: Client;
  @Input()
  appointmentRequest!: AppointmentRequest;
  @Input()
  wksEntity: WksEntity;
  @Input()
  countryFmt: any;
  @Input()
  readOnlyData: boolean;
  @Input()
  inputSN: boolean;
  @Input()
  isCheckList: boolean;
  @Input()
  idxEquip: number;
  @Output()
  nextStepEvent = new EventEmitter<number>();

  equipmentForm: FormGroup;
  servicesList: LabelValue[];
  wksEntityImgCur: WksEntityImg;
  imgHelpLoaded: boolean;
  helpToShow: any;
  formField: string;
  equipmentParam: LabelValue[];
  equipmentBrand: BrandParam[];
  motorsConfigList: MotorsConfigModel[];
  positionsMotorList: string[];
  modelParam: CustomParam;
  serialNumberParam: CustomParam;
  displayCountHours: boolean;

  nbHoursOfUse: number;
  arabicsToLatin: LabelInt[];
  latinToArabics: LabelValue[];
  hindusArabic: boolean;

  equipmntOtherData: EquipmntOtherData;

  serialNumberInput = {
    fmt: '',
    holder: '',
    display: '',
    dataLengthMax: 0,
    inputError: false,
    inputValue: '',
    separator: ''
  };

  hours = {
    min: 100,
    max: 2000,
  };

  ctrlInput: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
  };
  isMotorBoat: boolean;
  constructor(private appointmentService: AppointmentService,
              private sharingDataService: SharingDataService,
              private translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'wksEntityParams': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntityParams = changes['wksEntityParams'].currentValue;
            this.ctrlInput = this.wksEntityParams.ctrlInput;
            break;
          }
          // readOnlyData
          case 'readOnlyData': {
            // tslint:disable-next-line:no-string-literal
            this.readOnlyData = changes['readOnlyData'].currentValue;
            break;
          }
          case 'equipment': {
            // tslint:disable-next-line:no-string-literal
            this.equipment = changes['equipment'].currentValue;
            break;
          }
          case 'client': {
            // tslint:disable-next-line:no-string-literal
            this.client = changes['client'].currentValue;
            break;
          }
          case 'appointmentRequest': {
            // tslint:disable-next-line:no-string-literal
            this.appointmentRequest = changes['appointmentRequest'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'countryFmt': {
            // tslint:disable-next-line:no-string-literal
            this.countryFmt = changes['countryFmt'].currentValue;
            this.settingHindusArabic();
            break;
          }
          case 'inputSN': {
            // tslint:disable-next-line:no-string-literal
            this.inputSN = changes['inputSN'].currentValue;
            break;
          }
          case 'isCheckList': {
            // tslint:disable-next-line:no-string-literal
            this.isCheckList = changes['isCheckList'].currentValue;
            break;
          }
          // idxEquip
          case 'idxEquip': {
            // tslint:disable-next-line:no-string-literal
            this.idxEquip = changes['idxEquip'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  settingHindusArabic(): void {
    this.hindusArabic = false;

    if (!this.translate.currentLang.startsWith('ar')) {
      return;
    }
    const numbersFmt = this.countryFmt.numbers;
    for (const numberItem of numbersFmt) {
      if (numberItem.name === 'hindusArabics') {
        this.hindusArabic = (numberItem.value === 'true' ? true : false);
        break;
      }
    }
    if (this.hindusArabic) {
      const unitsArabicsNumbers = environment.indusArabicsNumbers.units;
      this.arabicsToLatin = [];
      this.latinToArabics = [];
      for (const item of unitsArabicsNumbers) {
        const latin = item.latin;
        const hindus = item.hindus;
        this.arabicsToLatin.push({
          label: item.hindus,
          value: parseInt(item.latin, 10)
        });
        this.latinToArabics.push({
          label: item.latin,
          value: item.hindus
        });
      }
    }
  }
  initData(): void {
    if (this.inputSN) {
      this.ctrlInput.inputSerialNumber = this.inputSN;
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.settingHindusArabic();
    });
    this.isMotorBoat = false;
    this.buildWksParams();
    this.displayCountHours = false;
    this.imgHelpLoaded = false;

    this.equipmentForm = new FormGroup({
      type: new FormControl('', Validators.required),
      brand: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      nbHoursOfUse: new FormControl('', Validators.required),
      askedService: new FormControl(''),
      // askedServiceHours: new FormControl('', [Validators.max(this.hours.max), Validators.min(this.hours.min)]),
      askedServiceHours: new FormControl(''),
    });
    if (this.ctrlInput.inputSerialNumber) {
      this.equipmentForm.addControl('serialNumber', new FormControl('', Validators.required ));
      // this.equipmentForm.controls.serialNumber.disable();
    }

    this.equipmentForm.controls.model.disable();
    this.equipmentForm.controls.askedService.disable();

    this.buildParams(this.wksEntityParams.equipments);
    this.fillForm();

  }
  buildParams(equipParams: EquipmentParam[]): void {

    this.equipmentParam = [];
    this.equipmentBrand = [];
    for (const equipCur of equipParams) {
      const typeCur: LabelValue = {
        label : 'COMMUN.EQUIPMENT.' + equipCur.type,
        value: equipCur.type,
        values: equipCur.brands
      };
      this.equipmentParam.push(typeCur);
    }
    if (this.equipmentParam.length === 1) {
      this.onChangeType(this.equipmentParam[0].value);
    }
  }
  buildWksParams(): void {
    this.motorsConfigList = [];
    this.motorsConfigList = this.sharingDataService.getMotorsconfigList();
  }
  buildListServices(): void {
    this.displayCountHours = false;
    this.servicesList = [];
    for (const equipCur of this.wksEntityParams.equipments) {
      if (equipCur.type === this.equipment.type)  {
        for (const serviceCur of equipCur.services ) {
          const prestationCur: LabelValue = {
            label : this.translate.instant('EQUIPMENT.' + serviceCur),
            value: serviceCur,
            values: [],
          };
          this.servicesList.push(prestationCur);
        }
        break;
      }
    }
    if (this.servicesList.length === 1 ) {
      if (this.servicesList[0].value === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
    }
  }
  onChangeService($event: any): void {
    this.equipment.askedService = $event;
    if (this.equipment.askedService === 'REGULAR_SERVICE') {
      // this.displayCountHours = true;
    }
  }
  onSubmit(): void {
    this.fillModel();
    this.nextStepEvent.emit(1);
  }

  onPrevious(): void {
    this.nextStepEvent.emit(-1);
  }
  onChangeType($event: any): void {
    // console.log($event);
    this.equipmentBrand = [];
    this.equipment.type = $event;
    for (const equipCur of this.equipmentParam) {
      if (equipCur.value === $event)  {
        for (const brandCur of equipCur.values ) {
          this.equipmentBrand.push(brandCur);
        }
        break;
      }
    }

  }
  onFocus(field: string, value: any): void {
    if (value !== '') {
      if (field === 'brand') {
        this.onChangeBrand(value);
      }
    }
  }
  onChangeBrand($event: any): void {

    for (const brandCur of this.equipmentBrand) {
      if (brandCur.label === $event)  {
        this.serialNumberParam = brandCur.serialNumber;
        this.modelParam = brandCur.model;
        this.serialNumberInput.fmt = brandCur.serialNumber.fmt;
        this.serialNumberInput.holder = brandCur.serialNumber.holder;
        this.serialNumberInput.display = brandCur.serialNumber.display;
        this.serialNumberInput.inputError = false;
        this.serialNumberInput.dataLengthMax = brandCur.serialNumber.holder.length + 2;
        this.serialNumberInput.separator = brandCur.serialNumber.separator;
        const fmtLocal: FormFieldFmt = {
          pattern: brandCur.serialNumber.fmt,
          displayFmt: brandCur.serialNumber.display,
          displayUnfmt: '[ -]',
          fieldType: 'text',
          lenght: brandCur.serialNumber.lenght,
          separator: brandCur.serialNumber.separator,
          upperCase: brandCur.serialNumber.upperCase
        };
        this.formField = JSON.stringify(fmtLocal);

        this.equipmentForm.controls.model.enable();
        break;
      }
    }
  }
  onChangeModel(value: string): void {
    if (value !== '') {
      if (this.ctrlInput.inputSerialNumber) {
        this.equipmentForm.controls.serialNumber.enable();
      }
      this.equipmentForm.controls.askedService.enable();
      this.buildListServices();
    }
  }
  onHelp(field: string): void {
    let imgName;
    this.imgHelpLoaded = false;
    switch (field) {
      case 'model': {
        imgName = this.modelParam.helpImg;
        break;
      }
      case 'serialNumber': {
        imgName = this.serialNumberParam.helpImg;
        break;
      }
    }
    if (imgName !== null) {
      this.loadHelpImg(imgName)
      .then ((data: WksEntityImg) => {
        this.wksEntityImgCur = data;
        this.createImageFromBlob(data);
        this.imgHelpLoaded = true;
      });
    }
  }
  onChangeMotorPosition($event: any, selectedValue: string): void {
    this.equipmntOtherData = {
      positionMotor: selectedValue
    };

  }
  formatField(typeRaw: string, paramsCur: any, inputValue: any): string {
    let valueReturn: any;
    let pattern: any;
    let displayFmt: string;
    let matchesGroup: any;
    let testMatch: any;
    switch (typeRaw) {
      case 'serialNumber': {
        const patternUnFmt = new RegExp(paramsCur.separator, 'g');
        const valueUnFmt = inputValue.replace(patternUnFmt, '');
        pattern = new RegExp(paramsCur.fmt, 'i');
        displayFmt = paramsCur.display;
        testMatch = valueUnFmt.match(pattern);
        if (testMatch === null) {
          this.serialNumberInput.inputError = true;
          // matchesGroup = inputValue.replace(pattern, displayFmt);
        } else {
          this.serialNumberInput.inputError = false;
          matchesGroup = inputValue.replace(pattern, displayFmt);
        }
        valueReturn = matchesGroup;
        this.serialNumberInput.inputValue = valueReturn;
        this.equipment.serialNumber = valueReturn;
        for (const equipmentCur of this.appointmentRequest.equipments){
          if (equipmentCur.equipmentNum === this.equipment.equipmentNum) {
            continue;
          }
          if (equipmentCur.serialNumber === this.equipment.serialNumber) {
            valueReturn = '';
            this.serialNumberInput.inputValue = '';
            this.equipment.serialNumber = '';
            // this.equipmentForm.controls.serialNumber.setValue('');
            // this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
            // tslint:disable-next-line:no-string-literal
            this.equipmentForm.controls['serialNumber'].patchValue(valueReturn, { emitEvent: true, onlySelf: false });
            break;
          }
        }
        break;
      }
      case 'nbHoursOfUse' : {

        if (this.hindusArabic) {
          let valueLatin = '';
          const arrayChar = [...inputValue];
          for (const charCur of arrayChar) {
            for (const arabicItem of this.arabicsToLatin) {
              if (charCur === arabicItem.label) {
                valueLatin += arabicItem.value.toString();
                break;
              }
            }
          }
          if (isNaN( Number.parseInt(valueLatin, 10))) {
            this.equipmentForm.controls.nbHoursOfUse.setValue('');
            this.equipment.nbHoursOfUse = 0;
            valueReturn = '';
          } else {
            valueReturn = Number.parseInt(valueLatin, 10);
            this.equipmentForm.controls.nbHoursOfUse.setValue(inputValue);
            this.equipment.nbHoursOfUse = valueReturn;
          }
        } else {
          if (isNaN( Number.parseInt(inputValue, 10))) {
            this.equipmentForm.controls.nbHoursOfUse.setValue('');
            this.equipment.nbHoursOfUse = 0;
            valueReturn = '';
          } else {
            valueReturn = Number.parseInt(inputValue, 10);
            this.equipmentForm.controls.nbHoursOfUse.setValue(valueReturn);
            this.equipment.nbHoursOfUse = valueReturn;
          }
        }
        break;
      }
      default: {
        valueReturn = '';
        break;
      }
    }

    return valueReturn;
  }
  counter(field: string, increment: number, value: any ): void  {

    switch (field) {
      case 'askedServiceHours': {
        let valueCur = 0;
        if (value === '') {
          valueCur = 0;
        } else {
          valueCur = Number.parseInt(value, 10);
        }
        valueCur = valueCur + increment;
        if (valueCur < this.hours.min)  {
          valueCur = this.hours.min;
        }
        if (valueCur > this.hours.max)  {
          valueCur = this.hours.max;
        }
        this.equipmentForm.controls.askedServiceHours.setValue(valueCur);
        break;
      }
      default: {
        break;
      }
    }
  }
  fillForm(): void {

    this.buildListServices();
    if (this.equipment.equipmentOtherdata !== ''
    && this.equipment.equipmentOtherdata !== null
    && this.equipment.equipmentOtherdata !== undefined)  {
      this.equipmntOtherData = JSON.parse(this.equipment.equipmentOtherdata);
    } else  {
      this.equipmntOtherData = {
        positionMotor: ''
      };
    }
    this.isMotorBoat = false;
    if (this.isCheckList) {
      this.isMotorBoat = true;
    }
    if (this.isMotorBoat) {
      this.equipmentForm.addControl('motorPosition', new FormControl('', Validators.required ));
    }
    for (const configMotors of this.motorsConfigList) {
      if (configMotors.numberMotors === this.equipment.equipmentNum) {
        this.positionsMotorList = configMotors.positions;
      }
    }
    /*
    if (this.equipment.equipmentNum > 1) {
      const equipFirst: Equipment = this.appointmentRequest.equipments[0];
      this.equipment.type = equipFirst.type;
      this.equipment.brand = equipFirst.brand;
      this.equipment.model = equipFirst.model;
      this.equipment.askedService = equipFirst.askedService;
      this.equipment.askedServiceHours = equipFirst.askedServiceHours;
      if (this.equipment.askedService === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
      this.equipment.nbHoursOfUse = equipFirst.nbHoursOfUse;
      if (this.ctrlInput.inputSerialNumber) {
        this.equipmentForm.controls.serialNumber.enable();
      }
      let idxMotor = 0;

      for (const positionMotorTmp of this.positionsMotorList) {
        if (idxMotor === this.idxEquip) {
          this.equipmntOtherData.positionMotor = positionMotorTmp;
          break;
        }
        idxMotor++;
      }
    */
    if (this.equipment.type === undefined || this.equipment.type === '' || this.equipment.type === null ) {
      this.equipment.type = this.wksEntityParams.equipments[0].type;
      this.equipment.brand = this.equipmentBrand[0].label;
      this. onChangeBrand( this.equipment.brand);
      this.equipmentForm.controls.model.enable();
      this.equipment.askedService = this.servicesList[0].value;
      if (this.equipment.askedService === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
      this.equipmntOtherData.positionMotor = this.positionsMotorList[this.idxEquip];
    }
    this.equipmentForm.controls.type.setValue(this.equipment.type);
    this.equipmentForm.controls.brand.setValue(this.equipment.brand);
    this.equipmentForm.controls.model.setValue(this.equipment.model);
    this.equipmentForm.controls.nbHoursOfUse.setValue((this.equipment.nbHoursOfUse > 0 ? this.equipment.nbHoursOfUse : ''));
    this.equipmentForm.controls.askedService.setValue(this.equipment.askedService);
    this.equipmentForm.controls.askedServiceHours.setValue(this.equipment.askedServiceHours);
    if (this.ctrlInput.inputSerialNumber) {
      this.equipmentForm.controls.serialNumber.setValue(this.equipment.serialNumber);
    }
    if (this.isMotorBoat) {
      this.equipmentForm.controls.motorPosition.setValue(this.equipmntOtherData.positionMotor);
    }
  }

  fillModel(): void {
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.type = this.equipmentForm.get('type')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.brand = this.equipmentForm.get('brand')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.model = this.equipmentForm.get('model')!.value;
    if (this.ctrlInput.inputSerialNumber) {
     // tslint:disable-next-line:no-non-null-assertion
      this.equipment.serialNumber = this.equipmentForm.get('serialNumber')!.value;
    }
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.nbHoursOfUse = this.equipmentForm.get('nbHoursOfUse')!.value;
      // tslint:disable-next-line:no-non-null-assertion
    this.equipment.askedService = this.equipmentForm.get('askedService')!.value;
      // tslint:disable-next-line:no-non-null-assertion
    this.equipment.askedServiceHours = this.equipmentForm.get('askedServiceHours')!.value;
    // this.equipment.equipmentNum = 1;
    this.equipment.equipmentOtherdata = JSON.stringify(this.equipmntOtherData);
  }
  loadHelpImg(path: string): any {
    const fileExtension = path.split('.').pop();
    const mimeApp =  this.appointmentService.getTypeMime(fileExtension);
    return new Promise((resolve, reject) => {
      this.appointmentService.downloadImg(path, this.client.stdEntity, mimeApp, fileExtension)
      .subscribe(
        (retMes: any) => {
          const wksEntityImgCur = retMes.body;
          // const blob = new Blob([new Uint8Array(response.fileContent)], { type: mimeApp });
          const blob = new Blob([wksEntityImgCur.fileContent], { type: mimeApp });
          wksEntityImgCur.blobCur = blob;
          resolve(wksEntityImgCur);
        }, err => {
          reject(err);
        }
      );
    });
  }
    // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.helpToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;
  }
  closeHelp(): void {
    this.imgHelpLoaded = false;
  }
}
