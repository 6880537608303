import { Component, OnInit, ViewEncapsulation, Output, EventEmitter  } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import { Subscription} from 'rxjs';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SharingDataService } from '../../../../../services/sharing-data.service';
import { WorksService } from '../../../../../services/works.service';
import { AuthService } from '../../../../../services/auth.service';
import { AESEncryptDecryptService } from '../../../../../services/aesencrypt-decrypt.service';
import { ModalCommonComponent } from '../../../../../components/common/modal-common/modal-common.component';

import { WksEntityParams, WksEntity, WksEntityImg, CountryLabel, StatesLabel, EmailInput } from '../../../../../models/shared.model';
import { WksBusinessModel, BsnAccountingModel, ContactInt, WksContactsModel } from '../../../../../models/works/wks-business.model';
import { Security, UserAccount, UserDataJson } from '../../../../../models/oAuth.model';
import { CommonMethods } from '../../../../common/tools/commonMethods';

import { environment } from '../../../../../../environments/environment';
import { CustomValidators, ConfirmValidParentMatcher } from '../../../../common/validators/customValidators';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CreateAccountComponent implements OnInit {

  @Output()
  returnData = new EventEmitter<string>();

  private readonly onDestroy = new Subject<void>();

  wksEntityParams: WksEntityParams;
  wksEntity: WksEntity;
  wksLogoHome: WksEntityImg;
  countryFmt: any;

  startForm: FormGroup;
  credentialsForm: FormGroup;


  bpSub: Subscription;
  isMobile: boolean;
  okToDisplay: boolean;
  credentialsFormIsBuild: boolean;
  credentialsOK: boolean;
  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];
  countryCur: string;
  isProfessional: boolean;
  isIndividual: boolean;
  isUnknow: boolean;
  isCompany: boolean;
  isAdministration: boolean;
  isPerson: boolean;
  isStructureChange: boolean;
  isTaxsystemChange: boolean;
  isPersonCompleted: boolean;
  isCorporateCompleted: boolean;
  isContactCompleted: boolean;
  configAddress: any;
  configContact: any;
  dataAccount: any;
  dataContact: any;
  dataAccountInput: any;
  dataContactInput: any;
  dataContacts: ContactInt[];
  contactsList: WksContactsModel[];
  structureCur: string;
  userAccount: UserAccount;

  configModal = {
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    size: 'sm'
  };

  passwordCtrl: FormControl;
  confirmPasswordCtrl: FormControl;
  showPassword = true;
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  emailAttr = environment.emailAttr;
  activeSecurities: boolean;

  constructor(private formBuilder: FormBuilder,
              private translate: TranslateService,
              private breakpointObserver: BreakpointObserver,
              private worksService: WorksService,
              private dialog: MatDialog,
              private authService: AuthService,
              private aesEncryptDecryptService: AESEncryptDecryptService,
              private sharingDataService: SharingDataService) {
    this.bpSub = breakpointObserver
        .observe(['(max-width: 599px)'])
        .subscribe((state: BreakpointState) => {
            this.setMobileView(state.matches);
          }
        );
  }

  ngOnInit(): void {
    this.initBooleans();
    this.initParams();
    this.buildForms();
    this.fillFormStart();
  }
  initBooleans(): void {
    this.isMobile = false;
    this.okToDisplay = false;
    this.isProfessional = false;
    this.isIndividual = false;
    this.isCompany = false;
    this.isAdministration = false;
    this.isPerson = false;
    this.isUnknow = false;
    this.isStructureChange = false;
    this.isTaxsystemChange = false;
    this.isPersonCompleted = false;
    this.isCorporateCompleted = false;
    this.isContactCompleted = false;
    this.credentialsFormIsBuild = false;
    this.credentialsOK = false;
    this.activeSecurities = false;
  }
  initParams(): void {
    this.countryFmt = this.sharingDataService.getCountryFmt();
    this.wksEntityParams = this.sharingDataService.getWksEntityParams();
    this.wksEntity = this.sharingDataService.getWksEntity();
    this.wksLogoHome = this.sharingDataService.getWksEntityImg();
    this.loadCountries();
    this.initConfig();
    this.okToDisplay = true;
  }
  initConfig(): void {
    this.configAddress = {
      langCur: this.translate.currentLang,
      cpltEventName: '',
      cpltEventOrig: 'createAccount',
      countryDefault: undefined,
      address: false,
      telephone: true,
      email: true,
      contact: false,
      corporate: false,
      identifier: undefined,
      isReadOnly: false,
      fieldsId: []
    };
    this.configContact = {
      langCur: this.translate.currentLang,
      cpltEventName: 'contact',
      cpltEventOrig: 'createAccount',
      countryDefault: undefined,
      address: false,
      telephone: true,
      email: true,
      contact: true,
      corporate: false,
      identifier: undefined,
      isReadOnly: false,
      fieldsId: []
    };
  }
  buildForms(): void {
    this.startForm = this.formBuilder.group({
      entitycountry: ['', Validators.required],
      bsnStructure: ['', Validators.required],
      bsnTaxsystem: ['']
    },
    { updateOn: 'blur'});
    this.passwordCtrl = this.formBuilder.control('', Validators.compose([
      Validators.required,
      Validators.minLength(7),
      // this is for the letters (both uppercase and lowercase) and numbers validation
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9_?!-]+$')
      // this is for the letters (both uppercase and lowercase) and numbers and special character validation
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&_{}\-])[a-zA-Z0-9_@$!%*?&_{}\-]+$')
    ]));
    this.confirmPasswordCtrl = this.formBuilder.control('', Validators.required);

    this.credentialsForm = this.formBuilder.group({
        identifierName: ['', Validators.required],
        password: this.passwordCtrl,
        confirmPassword: this.confirmPasswordCtrl,
        securities: this.formBuilder.array([])
      },
      {
        validators: [CustomValidators.match('password', 'confirmPassword')],
        updateOn: 'change'
      }
    );
    if (this.activeSecurities) {
      for (let i = 0; i < 3; i++) {
        let securityForm ;
        if (i === 0) {
          securityForm = this.formBuilder.group({
            question: ['', Validators.required],
            answer: ['', Validators.required]
          });
        } else  {
          securityForm = this.formBuilder.group({
            question: [''],
            answer: ['']
          });
        }
        this.securities.push(securityForm);
      }
    }
    this.credentialsFormIsBuild = true;
    this.credentialsForm.valueChanges.subscribe(
      result => {
        // console.log(this.wksBnsForm.status);
        switch (this.credentialsForm.status) {
          case 'VALID':
            // WorkInputEvent
            this.credentialsOK = true;
            break;
          case 'INVALID':
            this.credentialsOK = false;
            break;
        }
      }
    );
  }
  get securities(): FormArray {
    return this.credentialsForm.get('securities') as FormArray;
  }
  fillFormStart(): void {
    this.startForm.controls.entitycountry.setValue(this.wksEntity.entityCountry);
    this.countryCur = this.wksEntity.entityCountry;
  }
  setMobileView(isMobile: boolean): void {
    this.isMobile = isMobile;
  }
  loadCountries(): void {
    const countriesListTmp = this.sharingDataService.getCountriesList();

    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this.sharingDataService.getLabelCountry(this.translate.currentLang, countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
    }

    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];

  }
  identifierNameChange($event: any): void {
    // if (this.credentialsForm.controls.identifierName.valid) {
      this.verifAccount($event)
      .then ((data: any) => {
        const titleBox = this.translate.instant('ADDRESS_BLOCK.IDENTIFIER_TITLE_BOX');
        const messageBox = this.translate.instant('ADDRESS_BLOCK.IDENTIFIER_MESSAGE_BOX_1', {identifierCur: $event });
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'controlIdentifier', undefined, undefined);
        // console.log(data);
      }) // loadentity
      .catch((error: any) => {
        // console.error(error);

      });
    // }
  }
  onChangeCountry($event: any): void {
    // const valueArg = ($event.target as HTMLInputElement).value;
    this.countryCur = $event;
    if (this.structureCur !== undefined) {
      this.initConfig();
      this.onStructureChange(this.structureCur);
    }
  }
  onKeyCountry($event: any): void {
    const valueArg = ($event.target as HTMLInputElement).value;
    this.countriesFilteredList = this.searchCountry(valueArg);
  }
  searchCountry(value: string): any {
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  onStructureChange($event: any): void {
    // console.log('onStructureChange : ' + $event);
    this.isStructureChange = true;
    // this.isProfessional = false;
    // this.isIndividual = false;
    // this.isUnknow = false;
    this.isCompany = false;
    this.isAdministration = false;
    this.isPerson = false;

    this.structureCur = $event;
    switch ($event) {
      case 'company': {

        this.configAddress.countryDefault = this.countryCur;
        this.configAddress.cpltEventName = 'company',
        this.configAddress.address = true;
        this.configAddress.telephone = false;
        this.configAddress.contact = false;
        this.configAddress.corporate = true;
        this.configAddress.email = false;
        this.configAddress.identifier = 'professional';
        this.configContact.countryDefault = this.countryCur;
        this.configContact.identifier = '';
        this.dataAccount = undefined;
        this.dataAccountInput = undefined;
        this.dataContact = undefined;
        this.dataContactInput = undefined;
        this.isCompany = true;
        break;
      } // end case
      case 'administration': {

        this.configAddress.countryDefault = this.countryCur;
        this.configAddress.cpltEventName = 'administration',
        this.configAddress.address = true;
        this.configAddress.telephone = false;
        this.configAddress.contact = false;
        this.configAddress.corporate = true;
        this.configAddress.email = false;
        this.configAddress.identifier = 'professional';
        this.configContact.countryDefault = this.countryCur;
        this.configContact.identifier = '';

        this.dataAccount = undefined;
        this.dataAccountInput = undefined;
        this.dataContact = undefined;
        this.dataContactInput = undefined;
        this.isAdministration = true;
        break;
      } // end case
      // dataIn
      case 'person': {

        this.configAddress.countryDefault = this.countryCur;
        this.configAddress.cpltEventName = 'person',
        this.configAddress.address = true;
        this.configAddress.telephone = true;
        this.configAddress.contact = true;
        this.configAddress.corporate = false;
        this.configAddress.email = false;
        this.configAddress.identifier = (this.isUnknow ? 'unknow' : this.isProfessional ? 'professional' : 'individual');
        this.dataAccount = undefined;
        this.dataAccountInput = undefined;
        this.dataContact = undefined;
        this.dataContactInput = undefined;
        this.isPerson = true;
        break;
      } // end case
    }  // end switch
  }
  onTaxsystemChange(event: any): void {
    // console.log('onTaxsystemChange : ' + event);

    this.isProfessional = false;
    this.isIndividual = false;
    this.isUnknow = false;
    let identifierId: string;
    if (event === 'professional') {
      this.isProfessional = true;
      identifierId = 'professional';
      this.configAddress.identifier = identifierId;
    } else if (event === 'individual' ) {
      this.isIndividual = true;
      identifierId = 'individual';
      this.configAddress.identifier = identifierId;
    } else {
      this.isUnknow = true;
      identifierId = 'unknow';
    }
    if (this.structureCur !== undefined) {
      this.initConfig();
      this.onStructureChange(this.structureCur);
    }
    this.isTaxsystemChange = true;
  }
  returnHome(): void {
    this.okToDisplay = false;
    this.returnData.emit('exitCreate');

  }
  dataOutAddress(event: any): void {
    // console.log(JSON.stringify(event));

    switch (event.cpltEventName) {
      case 'person': {
        if (this.validPerson(event))  {
          this.isPersonCompleted = true;
        }
        this.dataAccountInput = event;
        break;
      } // end case
      case 'administration': {
        if (this.validCorporate(event))  {
          this.isCorporateCompleted = true;
        }
        this.dataAccountInput = event;
        break;
      } // end case
      case 'company': {
        if (this.validCorporate(event))  {
          this.isCorporateCompleted = true;
        }
        this.dataAccountInput = event;
        break;
      } // end case
      case 'contact': {
        if (this.validContact(event))  {
          this.isContactCompleted = true;
        }
        this.dataContactInput = event;
        break;
      } // end case
    }

  }
  validContact(event: any): boolean {
    let identityValid = false;

    const emailValid = false;
    let phoneValid = false;
    let appsValid = false;
    let isContactCompleted = false;

    try  {
      const identityTest = JSON.parse(JSON.stringify(event.identity));
      if ((event.formAdressValid)
        && (identityTest.cntCivility !== undefined && identityTest.cntCivility !== '')
        && (identityTest.cntFirstname !== undefined && identityTest.cntFirstname !== '')
        && (identityTest.cntLastname !== undefined && identityTest.cntLastname !== '')
        ) {
          identityValid = true;
      }
    } catch (err) {
    }
    /*
    try  {
      const emailsTest = JSON.parse(JSON.stringify(event.emails));
      if ((event.formAdressValid)
        && (emailsTest[0].emailAddress !== undefined && emailsTest[0].emailAddress !== '')
        && (!emailsTest[0].inputError)
        ) {
          emailValid = true;
      }
    } catch (err) {
    }
  */
    try  {
      const phonesTest = JSON.parse(JSON.stringify(event.phones));
      if ((event.formAdressValid)
        && (phonesTest[0].phoneNumber !== undefined && phonesTest[0].phoneNumber !== '')
        && (!phonesTest[0].inputError)
        ) {
          phoneValid = true;
      }
    } catch (err) {
    }
    try  {
      const appsTest = JSON.parse(JSON.stringify(event)).selectApps;
      if ((event.formAdressValid)
        && (appsTest !== undefined && appsTest !== '')
        ) {
          appsValid = true;
      }
    } catch (err) {
    }

    /* if ((Number(identityValid) + Number(emailValid) + Number(phoneValid) + Number(appsValid))  === 4)  {
      isContactCompleted = true;
    }*/
    if ((Number(identityValid) + Number(phoneValid) + Number(appsValid))  === 3)  {
      isContactCompleted = true;
    }
    return isContactCompleted;
  }
  validCorporate(event: any): boolean {
    let corporateValid = false;
    let addressValid = false;

    let isCorporateCompleted = false;

    let cityTest: string;
    try  {
      cityTest = event.address.city;
      // cityTest = JSON.parse(JSON.stringify(event.address)).city;
      if ((event.formAdressValid) && (cityTest !== '')) {
        addressValid = true;
      }
    } catch (err) {
    }
    let corporateTest: string;
    try  {
      corporateTest = JSON.parse(JSON.stringify(event)).corporateName;
      if ((event.formAdressValid) && (corporateTest !== '')) {
        corporateValid = true;
      }
    } catch (err) {
    }
    if ((Number(corporateValid) + Number(addressValid) )  === 2)  {
      isCorporateCompleted = true;
    }
    return isCorporateCompleted;
  }
  validPerson(event: any): boolean {
    let identityValid = false;
    let addressValid = false;

    let phoneValid = false;
    let appsValid = false;
    let isPersonCompleted = false;

    let cityTest: string;
    try  {
      cityTest = event.address.city;
      // cityTest = JSON.parse(JSON.stringify(event.address)).city;
      if ((event.formAdressValid) && (cityTest !== '')) {
        addressValid = true;
      }
    } catch (err) {
    }
    try  {
      const identityTest = JSON.parse(JSON.stringify(event.identity));
      if ((event.formAdressValid)
        && (identityTest.cntCivility !== undefined && identityTest.cntCivility !== '')
        && (identityTest.cntFirstname !== undefined && identityTest.cntFirstname !== '')
        && (identityTest.cntLastname !== undefined && identityTest.cntLastname !== '')
        ) {
          identityValid = true;
      }
    } catch (err) {
    }
    /*
    try  {
      const emailsTest = JSON.parse(JSON.stringify(event.emails));
      if ((event.formAdressValid)
        && (emailsTest[0].emailAddress !== undefined && emailsTest[0].emailAddress !== '')
        && (!emailsTest[0].inputError)
        ) {
          emailValid = true;
      }
    } catch (err) {
    }
    */
    try  {
      const phonesTest = JSON.parse(JSON.stringify(event.phones));
      if ((event.formAdressValid)
        && (phonesTest[0].phoneNumber !== undefined && phonesTest[0].phoneNumber !== '')
        && (!phonesTest[0].inputError)
        ) {
          phoneValid = true;
      }
    } catch (err) {
    }
    try  {
      const appsTest = JSON.parse(JSON.stringify(event)).selectApps;
      if ((event.formAdressValid)
        && (appsTest !== undefined && appsTest !== '')
        ) {
          appsValid = true;
      }
    } catch (err) {
    }

    // if ((Number(identityValid) + Number(addressValid) + Number(emailValid) + Number(phoneValid) + Number(appsValid))  === 5)  {
    if ((Number(identityValid) + Number(addressValid) + Number(phoneValid) + Number(appsValid))  === 4)  {
      isPersonCompleted = true;
    }
    return isPersonCompleted;
  }
  formattingAddressBlock(data: any): any {

    let propertieCur = '';
    let addressBlockContent = '{';
    // tslint:disable-next-line:forin
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // console.log(data[key]);
        propertieCur = '"' + key + '": "' + data[key] + '"';
        if (addressBlockContent === '{') {
          addressBlockContent += propertieCur;
        } else {
          addressBlockContent += ',' + propertieCur;
        }
      }
    }
    addressBlockContent += '}';

    const jsonAddress = JSON.parse(addressBlockContent);
    return jsonAddress;
  }


  fillModel(): WksBusinessModel {

    if (this.dataAccountInput === undefined)  {
      return undefined;
    }
    const dataAccountCur = JSON.parse(JSON.stringify(this.dataAccountInput));
    dataAccountCur.address = this.formattingAddressBlock(this.dataAccountInput.address);
    this.fillModelContact();
    const wksBusinessCur: WksBusinessModel = {
      id: undefined,
      stdEntity: this.sharingDataService.getWksEntity().stdEntity,
      bsnCountry: this.startForm.controls.entitycountry.value,
      bsnNature: 'wks-customers',
      bsnParent:  undefined,
      bsnType: this.startForm.controls.bsnStructure.value,
      bsnTax: this.startForm.controls.bsnTaxsystem.value,
      bsnAddress: JSON.stringify(dataAccountCur)
    };
    if ((this.isCompany) || (this.isAdministration)) {
      wksBusinessCur.bsnName = this.dataAccountInput.corporateName;
      wksBusinessCur.bsnTax = 'unknow';
      wksBusinessCur.bsnCompany = JSON.stringify(dataAccountCur);
    } else {
      wksBusinessCur.bsnName = this.dataAccountInput.identity.cntLastname + ' ' + this.dataAccountInput.identity.cntFirstname;
      wksBusinessCur.bsnPerson = JSON.stringify(dataAccountCur);
    }
    wksBusinessCur.bsnZipcode = this.dataAccountInput.address.zipcode;
    wksBusinessCur.bsnState = this.dataAccountInput.address.state;
    const cashPay = true;
    let bsnAccounting: BsnAccountingModel ;
    if (cashPay) {
      bsnAccounting  = {
        bsnCashpay: true,
        bsnAccount: this.sharingDataService.getWksEntity().accountCash,
        scaleName: 'PUBLIC',
        bsnCoef: 1.0,
        bsnDiscount: 0,
        bsnDelayDays: 0,
        bsnDefferedPaiement: '' };
    } else {
      bsnAccounting  = {
        bsnCashpay: false,
        bsnAccount: this.sharingDataService.getWksEntity().accountCash,
        scaleName: 'PUBLIC',
        bsnCoef: 1.0,
        bsnDiscount: 0,
        bsnDelayDays: 0,
        bsnDefferedPaiement: '' };
    }
    wksBusinessCur.bsnAccounting = JSON.stringify(bsnAccounting);

    if ((this.contactsList !== undefined) && (this.contactsList !== null)) {
      // wksBsnCard.dataContacts = '{ "contacts": ' + JSON.stringify(this.dataContacts) + '}';
      wksBusinessCur.dataContacts = this.contactsList;
    }
    return wksBusinessCur;
  }
  fillModelContact(): void {
    this.contactsList = undefined;
    const emailRow = {
      controlFmt: this.emailAttr.emailRegex,
      displayFmt: this.emailAttr.emailDisplay,
      emailAddress: this.credentialsForm.controls.identifierName.value,
      emailType: (this.isPerson && !this.isProfessional) ? 'perso' : 'prof'
    };
    const emailsList: EmailInput[] = [] ;
    emailsList.push(emailRow);
    this.contactsList = [];
    let cntCur: any;

    if (this.dataContactInput) {
       cntCur = this.dataContactInput;
    } else if (this.dataAccountInput) {
      cntCur = this.dataAccountInput;
    } else {
      return;
    }
    cntCur.emails = emailsList;
    // for (const cntCur of this.dataContactInput) {
    const localCnt: WksContactsModel = {
      id: undefined,
      stdEntity: this.sharingDataService.getWksEntity().stdEntity,
      cntAppli: 'wks-customers',
      cntAppliLnk:  undefined,
      cntSpeciality: cntCur.selectDept,
      cntFunction: cntCur.fonctionDept,
      cntChannels: cntCur.selectApps,
      cntMails: JSON.stringify(cntCur.emails),
      cntPhones: JSON.stringify(cntCur.phones),
      cntCivility: cntCur.identity.cntCivility,
      cntFirstname: cntCur.identity.cntFirstname,
      cntLastname: cntCur.identity.cntLastname,
      cntStatut: 'create',
    };
    this.contactsList.push(localCnt);
    this.userAccount = this.fillModelUser(localCnt);
      // }
  }
  fillModelUser(localCnt: WksContactsModel): UserAccount {
    let emailDefault;
    try  {
      const emailsTest = JSON.parse(localCnt.cntMails);
      if ((emailsTest[0].emailAddress !== undefined && emailsTest[0].emailAddress !== '')) {
        emailDefault = emailsTest[0].emailAddress;
      }
    } catch (err) {
    }
    const listControl = this.credentialsForm.get('securities') as FormArray;
    const listQuestions: Security [] = [];

    for (let i = 0 ; i < listControl.length ; i++) {
      const securityCur: Security = {
        question: listControl.at(i).get('question').value,
        answer: listControl.at(i).get('answer').value,
      };
      listQuestions.push(securityCur);
    }
    const token = localCnt.stdEntity + this.credentialsForm.controls.identifierName.value + environment.constant;
    const securitiesEncrypt = this.aesEncryptDecryptService.encryptUsingAES256(token, JSON.stringify(listQuestions));
    const userDataJson: UserDataJson =  {
      securities: securitiesEncrypt,
      contactUUID: undefined
    };
    const userAccount: UserAccount = {
      username: this.credentialsForm.controls.identifierName.value,
      firstname: localCnt.cntFirstname,
      lastname: localCnt.cntLastname,
      sexe: 'N',
      email: emailDefault,
      birthday: CommonMethods.dateMomentForBdd(undefined, `${environment.fmtDateTimeBdd}`),
      // birthday: undefined,
      roles: 'ROLE_WKS_CUSTOMER',
      enabled: true,
      accountNonExpired: true,
      credentialsNonExpired: true,
      userdebug: false,
      entity: localCnt.stdEntity,
      usergroup: 'CUSTOMERS',
      userlang: this.translate.currentLang,
      password: this.credentialsForm.controls.password.value,
      accountNonLocked: true,
      userdata: JSON.stringify(userDataJson)
    };
    return userAccount;

  }
  verifAccount(accountName: string): any {
    return new Promise((resolve, reject) => {
      this.authService.verifAccount(accountName)
      .subscribe(
        data => {
          resolve(data);
        },
        err => {
          // console.log('verifAccount' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  sendPasswd(): void {
    if (!this.credentialsForm.invalid) {
      // this.pwdChanged.next(this.credentialsForm.value.password);
    }
  }
  ctrlPasswd(): void {
    /*
    const passwd = this.credentialsForm.controls.password.value;
    const confirmPassword = this.credentialsForm.controls.confirmPassword.value;
    if (confirmPassword === passwd && this.credentialsForm.controls.identifierName.status === 'VALID') {
      const listControl = this.credentialsForm.get('securities') as FormArray;
      if (listControl.status === 'VALID' ) {
        this.credentialsOK = true;
      }
    }
    */
  }
  getErrorMessage(ctrlNameArg: string): string {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (ctrlNameArg) {
      case 'password': {
        !this.credentialsForm.controls[ctrlNameArg].dirty ? messageLib = '' :
          this.credentialsForm.controls[ctrlNameArg].hasError('required') ?
            messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_REQUIRED') :
            this.credentialsForm.controls[ctrlNameArg].hasError('minlength') ?
              messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_MINLENGTH') :
              this.credentialsForm.controls[ctrlNameArg].hasError('pattern') ?
                messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_PATTERN') : messageLib = '';
        break;
      }
      case 'confirmPassword': {
        !this.credentialsForm.controls[ctrlNameArg].dirty ? messageLib = '' :
          this.credentialsForm.controls[ctrlNameArg].hasError('required') ?
            messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_CONFIRMATION_REQUIRED') :
            this.credentialsForm.hasError('childrenNotEqual') ?
              messageLib = this.translate.instant('CREDENTIALS_BLOCK.PASSWORD_NOT_MATCH') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
  submitAccount(): void {
    // console.log(JSON.stringify(this.dataAccountInput));
    // this.okToDisplay = false;
    // this.returnData.emit('submtCreate');

      const currentCard = this.fillModel();
      // this._wksCentralService.onRegisterValid.emit('closeRegister');

      this.worksService.saveWksBusiness(currentCard, 'createBsn')
      .subscribe(
        (dataResponse) => {

          this.addUser(dataResponse.body);
        },
        () => {
          const titleBox = this.translate.instant('ADDRESS_BLOCK.CUSTOMER_TITLE_BOX');
          const messageBox = this.translate.instant('ADDRESS_BLOCK.CUSTOMER_NOT_CREATE', {customerName: currentCard.bsnName });
          this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'submitCreate', undefined, undefined);
        }
      );

  }
  addUser(currentCard: WksBusinessModel): void {

    const contacts = currentCard.dataContacts;
    const uuidContact = contacts[0].id;
    const userDataJson = JSON.parse(this.userAccount.userdata);
    userDataJson.contactUUID = uuidContact;
    this.userAccount.userdata = JSON.stringify(userDataJson);

    this.authService.saveUser(this.userAccount, 'createUser')
    .subscribe(
      () => {
        const titleBox = this.translate.instant('ADDRESS_BLOCK.CUSTOMER_TITLE_BOX');
        const messageBox = this.translate.instant('ADDRESS_BLOCK.CUSTOMER_SUCCESS_CREATE',
                            {customerName: currentCard.bsnName, username: this.userAccount.username });
        this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoWks', 'submitCreate', undefined, undefined);
      },
      () => {
        const titleBox = this.translate.instant('ADDRESS_BLOCK.CUSTOMER_TITLE_BOX');
        const messageBox = this.translate.instant('ADDRESS_BLOCK.USER_NOT_CREATE', {username: this.userAccount.username });
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'submitCreate', undefined, undefined);
      }
    );
  }
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, data1Arg: string, data2Arg: string): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: titleBoxArg,
      typeDialog: typeDialogArg,
      panelClass: 'stdTheme',
      contentMessage: messageBoxArg,
      data1: data1Arg,
      data2: data2Arg,
      messageType: messageTypeArg,
      actionCur: actionCurArg
    };

    const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
    data => {
      if (actionCurArg === 'controlIdentifier' ) {
        this.credentialsForm.controls.identifierName.setValue('');
      }
      if (actionCurArg === 'submitCreate' ) {
        this.okToDisplay = false;
        this.returnData.emit('submitCreate');
      }
    });

  }
}
