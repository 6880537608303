export interface Theme {
    name: string;
    properties: any;
  }
// quinary, senary, septenary, octonary, nonary, and denary
export const light: Theme = {
    name: 'light',
    properties: {
        '--foreground-alarm-selected': '#191919',
        '--foreground-data-selected': '#191919',
        '--foreground-primary': '#191919',
        '--foreground-secondary': '#EBE3E7',
        '--foreground-tertiary': '#191919',
        '--foreground-quaternary': '#3D383F',
        '--foreground-quinary': '#600CAC',
        '--foreground-brandrow': '#191919',

        '--foreground-tablerowodd': '#191919',
        '--foreground-tableroweven': '#191919',
        '--foreground-tablerowselect': '#191919',
        '--foreground-tableheader': '#191919',
        '--foreground-home': '#600CAC',

        '--background-alarm-selected': '#CCFFCC',
        '--background-data-selected': '#FFFF00',
        '--background-primary': '#FBFAFA',
        '--background-secondary': '#600CAC',
        '--background-tertiary': '#CECECE',
        '--background-quaternary': '#A3A3A3',
        '--background-quinary': '#DAD6D0',
        '--background-brandrow': '#EBE3E7',

        '--background-tablerowodd': '#EBE3E7',
        '--background-tableroweven': '#CECECE',
        '--background-tablerowselect': '#FFFF00',
        '--background-tableheader': '#22455A',

        '--background-fieldset': '#dddddd',
        '--border-fieldset': '#1F497D',
        '--background-legend': '#1F497D',
        '--color-legend': '#ffffff',
        '--box-legend': '#dddddd',

        '--background-home': '#EBE3E7',
        '--primary-default': '#5DFDCB',
        '--primary-dark': '#24B286',
        '--primary-light': '#B2FFE7',

        '--error-default': '#EF3E36',
        '--error-dark': '#800600',
        '--error-light': '#FFCECC',
        '--background-error': '#FF8058',
        '--foreground-error': '#3D3937',
        '--background-tertiary-shadow': '0 1px 3px 0 rgba(92, 125, 153, 0.5)',
        '--logo-nws': 'url(assets/img/nws-dark.png)',
// https://www.w3schools.com/cssref/css_colors.asp
        '--background-redTheme': '#FF0000',
        '--foreground-redTheme': '#F0FFFF',
        '--background-greenTheme': '#9ACD32',
        '--foreground-greenTheme': '#4B0082',
    }
};

export const dark: Theme = {
    name: 'dark',
    properties: {
        '--foreground-alarm-selected': '#191919',
        '--foreground-data-selected': '#191919',
        '--foreground-primary': '#ECEBED',
        '--foreground-secondary': '#ECEBED',
        '--foreground-tertiary': '#ECEBED',
        '--foreground-quaternary': '#ECEBED',
        '--foreground-quinary': '#DAD6D0',
        '--foreground-brandrow': '#ECEBED',

        '--foreground-tablerowodd': '#ECEBED',
        '--foreground-tableroweven': '#ECEBED',
        '--foreground-tablerowselect': '#191919',
        '--foreground-tableheader': '#ECEBED',
        '--foreground-home': '#ECEBED',

        '--background-alarm-selected': '#CCFFCC',
        '--background-data-selected': '#FFFF00',
        '--background-primary': '#36596D',
        '--background-secondary': '#36596D',
        '--background-tertiary': '#22455A',
        '--background-quaternary': '#527183',
        '--background-quinary': '#214357',
        '--background-brandrow': '#051D2B',

        '--background-tablerowodd': '#112F41',
        '--background-tableroweven': '#36596D',
        '--background-tablerowselect': '#FFFF00',
        '--background-tableheader': '#22455A',

        '--background-fieldset': '#112F41',
        '--border-fieldset': '#dddddd',

        '--background-legend': '#112F41',
        '--color-legend': '#dddddd',
        '--box-legend': '#ffffff',

        '--background-home': '#112F41',
        '--primary-primary': '#5DFDCB',
        '--primary-dark': '#24B286',
        '--primary-light': '#B2FFE7',

        '--error-default': '#EF3E36',
        '--error-dark': '#800600',
        '--error-light': '#FFCECC',
        '--background-error': '#FF8058',
        '--foreground-error': '#3D3937',

        '--background-tertiary-shadow': '0 1px 3px 0 rgba(8, 9, 10, 0.5)',
        '--logo-nws': 'url(assets/img/nws-light.png)',
        // https://www.w3schools.com/cssref/css_colors.asp
        '--background-redTheme': '#FF0000',
        '--foreground-redTheme': '#F0FFFF',
        '--background-greenTheme': '#9ACD32',
        '--foreground-greenTheme': '#4B0082',
    }
};

