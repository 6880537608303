import { Component, EventEmitter, Input, OnChanges, Output,
            ViewChild, TemplateRef, NgZone, HostListener, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { GoogleMaps, LatLng, GeoValues } from '../../../../models/geoLocation.model';
import { GeolocationService } from '../../../../services/geolocation.service';
import { AppointmentRequest, WksEntityParams, WksEntity, LabelValue, LabelInt, ElemAddr, FieldValue } from '../../../../models';
import { environment } from '../../../../../environments/environment';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['../../../../shared.less', './location-form.component.less'],
  encapsulation : ViewEncapsulation.None,
})

export class LocationFormComponent implements OnChanges {
  @Input()
  appointmentRequest!: AppointmentRequest;
  @Input()
  wksEntityParams: WksEntityParams;
  @Input()
  wksEntity: WksEntity;
  @Input()
  countryFmt: any;
  @Input()
  readOnlyData: boolean;

  @Output()
  nextStepEvent = new EventEmitter<number>();
  modalRef: BsModalRef;
  googleMaps: GoogleMaps;
  positionMap: boolean;
  @ViewChild('askedComment') askedComment: CdkTextareaAutosize;
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;

  servicesList: LabelValue[];
  requestedServiceForm: FormGroup;

  scrHeight: string;
  scrWidth: string;
  sizeWidth: number;
  locationAddress: boolean;
  locationAtSea: boolean;
  locationMooring: boolean;
  incompleteInput: boolean;
  // bloc mooring
  rowsMooring: any[];
  mooringBlockFg: FormGroup;
  // bloc address
  rowsAddress: any[];
  addressBlockFg: FormGroup;
  // arabic numbers
  arabicsToLatin: LabelInt[];
  latinToArabics: LabelValue[];
  hindusArabicNumbers: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
        this.scrHeight = window.innerHeight + 'px';
        this.scrWidth = window.innerWidth + 'px';
        this.sizeWidth = window.innerWidth;
  }

  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private ngZone: NgZone,
    private geolocationService: GeolocationService,
    private coordinatesService: CoordinatesService) {
      this.getScreenSize();
      //  console.log('Window size ' + this.scrHeight, this.scrWidth);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.positionMap = false;
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'appointmentRequest': {
            this.appointmentRequest = changes.appointmentRequest.currentValue;
            break;
          }
          // readOnlyData
          case 'readOnlyData': {
            // tslint:disable-next-line:no-string-literal
            this.readOnlyData = changes['readOnlyData'].currentValue;
            break;
          }
          case 'wksParams': {
            this.wksEntityParams = changes.wksParams.currentValue;
            break;
          }
          case 'countryFmt': {
            // tslint:disable-next-line:no-string-literal
            this.countryFmt = changes['countryFmt'].currentValue;
            this.settingHindusArabic();
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
    this.fillForm();
  }
  initData(): void {
    this.addressBlockFg = new FormGroup({});
    this.mooringBlockFg = new FormGroup({});

    this.requestedServiceForm = new FormGroup({
      // equipmentLocation: new FormControl(''),
      askedComment: new FormControl(''),
      equipmentPosition: new FormControl(''),
      equipmentPositionCoord: new FormControl(''),
      jobsite: new FormControl(''),

      mooringBlockFg: this.mooringBlockFg,
      addressBlockFg: this.addressBlockFg,
    });
  }
  settingHindusArabic(): void {
    this.hindusArabicNumbers = false;
    if (!this.translate.currentLang.startsWith('ar')) {
      return;
    }
    const numbersFmt = this.countryFmt.numbers;
    for (const numberItem of numbersFmt) {
      if (numberItem.name === 'hindusArabics') {
        this.hindusArabicNumbers = (numberItem.value === 'true' ? true : false);
        break;
      }
    }
    if (this.hindusArabicNumbers) {
      const unitsArabicsNumbers = environment.indusArabicsNumbers.units;
      this.arabicsToLatin = [];
      this.latinToArabics = [];
      for (const item of unitsArabicsNumbers) {
        const latin = item.latin;
        const hindus = item.hindus;
        this.arabicsToLatin.push({
          label: item.hindus,
          value: parseInt(item.latin, 10)
        });
        this.latinToArabics.push({
          label: item.latin,
          value: item.hindus
        });
      }
    }
  }
  convertArabicHindusToLatin(value: any): number  {
    let isHindusArabic = true;
    let isFound = false;
    let valueLatin = '';

    const arrayChar = [...value];
    for (const charCur of arrayChar) {
      isFound = false;
      for (const arabicItem of this.arabicsToLatin) {
        if (charCur === arabicItem.label) {
          isFound = true;
          valueLatin += arabicItem.value;
          break;
        }
      }
      if (!isFound) {
        isHindusArabic = false;
        break;
      }
    }
    let latinNumber = -1;
    if (isHindusArabic) {
      try {
        latinNumber = parseInt(valueLatin, 10);
      }
      catch (err) {
      }
    }
    return  latinNumber;
  }
  onSubmit($event: any): void {
    this.fillModel();
    this.nextStepEvent.emit(1);
  }

  jobsiteChange($event: any): void {

    this.locationAddress = false;
    this.locationAtSea = false;
    this.locationMooring = false;
    // this.requestedServiceForm.get('equipmentLocation').clearValidators();
    this.requestedServiceForm.get('equipmentPosition').clearValidators();
    /*this.requestedServiceForm.get('portMooring').clearValidators();
    this.requestedServiceForm.get('portCity').clearValidators();
    this.requestedServiceForm.get('equipmentLocation').setValue('');
    this.requestedServiceForm.get('equipmentPosition').setValue('');
    this.requestedServiceForm.get('equipmentPositionCoord').setValue('');
    this.requestedServiceForm.get('portMooring').setValue('');
    this.requestedServiceForm.get('portCity').setValue('');*/

    switch ($event) {
      case 'workshop' : {
        break;
      }
      case 'address' : {
        this.locationAddress = true;
        this.incompleteInput = true;
        this.buildAddressBlock();
        // this.requestedServiceForm.get('equipmentLocation').setValidators(Validators.required);
        this.requestedServiceForm.get('equipmentPosition').setValidators(Validators.required);
        break;
      }
      case 'atSea' : {
        this.locationAtSea = true;
        this.requestedServiceForm.get('equipmentPosition').setValidators(Validators.required);
        break;
      }
      case 'mooring' : {
        this.locationMooring = true;
        this.incompleteInput = true;
        this.buildMooringBlock();
        // this.requestedServiceForm.get('portMooring').setValidators(Validators.required);
        // this.requestedServiceForm.get('portCity').setValidators(Validators.required);
        break;
      }
      default: {
        // this.requestedServiceForm.controls.jobsite.setValue('workshop');
        // this.incompleteInput = false;
        break;
      }
    }

  }
  onChangeField(field: string, value: any): void {

  }
  // https://stackblitz.com/angular/ldvyvvnqdlr?file=src%2Fapp%2F.%2Ftext-field-autosize-textarea-example.html
  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
        .subscribe(() => this.askedComment.resizeToFitContent(true));
  }
  onPrevious(): void {
    this.nextStepEvent.emit(-1);
  }

  fillForm(): void {
    if (this.appointmentRequest.equipmentJobsite === '') {
     // this.appointmentRequest.equipmentJobsite = 'workshop';
     this.incompleteInput = true;
    }
    this.requestedServiceForm.controls.askedComment.setValue(this.appointmentRequest.askedComment);
    this.requestedServiceForm.controls.jobsite.setValue(this.appointmentRequest.equipmentJobsite);

    this.jobsiteChange(this.appointmentRequest.equipmentJobsite);
/*
    if (this.appointmentRequest.equipmentJobsite !== 'mooring') {
      this.requestedServiceForm.controls.equipmentLocation.setValue(this.appointmentRequest.equipmentLocation);
    } else {
      if (this.appointmentRequest.equipmentLocation !== undefined) {
        const mooringLocation = this.appointmentRequest.equipmentLocation.split(';');
        if (mooringLocation.length > 0){
          this.requestedServiceForm.controls.portCity.setValue(mooringLocation[0]);
        }
        if (mooringLocation.length > 1){
          this.requestedServiceForm.controls.portMooring.setValue(mooringLocation[0]);
        }
        if (mooringLocation.length > 2){
          this.requestedServiceForm.controls.portPool.setValue(mooringLocation[1]);
        }
        if (mooringLocation.length > 3){
          this.requestedServiceForm.controls.portPontoon.setValue(mooringLocation[2]);
        }
        if (mooringLocation.length > 4){
          this.requestedServiceForm.controls.portRing.setValue(mooringLocation[3]);
        }
      }

    }
    */
    if ((this.appointmentRequest.equipmentPosition !== undefined) &&
    (this.appointmentRequest.equipmentPosition !== '')) {
      const tmpLatLng = this.appointmentRequest.equipmentPosition.split(',');
      const curLatLng = {
        lat : Number.parseFloat(tmpLatLng[0]),
        lng : Number.parseFloat(tmpLatLng[1])
      };
      this.displayLatLng(curLatLng);
    }
    this.validInput(this.appointmentRequest.equipmentJobsite);
  }
  unValidInput(): void {
    this.incompleteInput = true;
  }
  validInput(jobSite: string): void {
    this.incompleteInput = true;
    switch (jobSite) {
      case 'workshop' : {
        this.incompleteInput = false;
        break;
      }
      case 'address' : {
        if (this.requestedServiceForm.get('equipmentPosition').value !== '' ) {
            this.incompleteInput = false;
          }
        break;
      }
      case 'atSea' : {
        this.requestedServiceForm.get('equipmentPosition').setValidators(Validators.required);
        if (this.requestedServiceForm.get('equipmentPosition').value !== '' ) {
          this.incompleteInput = false;
        }
        break;
      }
      case 'mooring' : {
        /*this.requestedServiceForm.get('portCity').setValidators(Validators.required);
        if (this.requestedServiceForm.get('portCity').value !== '' ) {
          this.incompleteInput = false;
        }*/
        this.incompleteInput = false;

        break;
      }
      default: {
        this.incompleteInput = true;
        break;
      }
    }
  }
  fillModel(): void {
    // tslint:disable-next-line:no-non-null-assertion
    const locationValue = this.requestedServiceForm.get('equipmentPosition')!.value;
    /*if (this.appointmentRequest.equipmentJobsite !== 'mooring') {
      // tslint:disable-next-line:no-non-null-assertion
      this.appointmentRequest.equipmentLocation = this.requestedServiceForm.get('equipmentLocation')!.value;
    } else  {
      this.appointmentRequest.equipmentLocation =  (this.requestedServiceForm.get('portCity').value ?
                                                    this.requestedServiceForm.get('portCity').value : '') + ';' +
                                                    (this.requestedServiceForm.get('portMooring').value ?
                                                    this.requestedServiceForm.get('portMooring').value : '') + ';' +
                                                    (this.requestedServiceForm.get('portPool').value ?
                                                    this.requestedServiceForm.get('portPool').value : '') + ';' +
                                                    (this.requestedServiceForm.get('portPontoon').value ?
                                                    this.requestedServiceForm.get('portPontoon').value : '') + ';' +
                                                    (this.requestedServiceForm.get('portRing').value ?
                                                    this.requestedServiceForm.get('portRing').value : '');
    }*/
    // tslint:disable-next-line:no-non-null-assertion
    this.appointmentRequest.askedComment = this.requestedServiceForm.get('askedComment')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.appointmentRequest.equipmentPosition = this.requestedServiceForm.get('equipmentPosition')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.appointmentRequest.equipmentJobsite = this.requestedServiceForm.get('jobsite')!.value;

  }
  // mooring block
  buildMooringBlock(): void {
    const mooringFields = this.wksEntity.mooring;
    if (mooringFields === undefined) {
      const addrElem = Object.keys(this.mooringBlockFg.controls);
      for (const elem of addrElem) {
        this.mooringBlockFg.removeControl(elem);
      }
      return;
    }
    let iRowCur = 0;
    let iElemCur = 0;
    this.rowsMooring = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    for (const elemMooring of mooringFields) {
      let elemCur = elemMooring;
      let controlTypeCur = '';
      if (elemCur.indexOf('|') > -1) {
        const elemBool = elemCur.split('|');
        elemCur = elemBool[0];
        const booleanFields = elemMooring.replace(elemCur, '');
        controlTypeCur = 'boolean' + booleanFields;
      }
      const elemAddr: ElemAddr = {
        elmtNumber: iElemCur.toString(),
        lineNumber: iRowCur.toString(),
        lineDataIndex:  iElemCur.toString(),
        dataName: elemCur,
        dataMandatory: 'N',
        dataInput: 'Y',
        dataValue: '',
        dataType: 'AN',
        dataLengthMax: '25',
        controlType: controlTypeCur,
        controlFmt: '.*',
        displayFmt: '',
        elemCountry: 'N',
        elemLabel: this.translate.instant('LOCATION.PORT_' + elemCur.toLocaleUpperCase()),
        inputValue: '',
        latinValue: undefined,
        inputError: false,
        elemUpperCase: false,
        enable: true,
      };
      iLengthMax = 25;
      colsArray.push(elemAddr);
      // if ((iRowBuild > 0) && (iRowCur !== iRowBuild)) {
      this.rowsMooring.push({
        row: Number(iRowCur).toString,
        lengthMax: iLengthMax,
        cols: colsArray,
      });
      colsArray = [];
      iRowBuild = iRowCur;
      iLengthMax = 0;
      // }
      if (iElemCur === 0) {
        this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl('', Validators.required));
      } else  {
        this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl(''));
      }
      this.mooringBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      iRowCur++;
      iElemCur++;
    }
    this.appointmentRequest.equipmentLocation = '';
  }
  buildAddressBlock(): void {

    const localAddress = this.wksEntity.localAddress;
    const elemsAddr = localAddress.elemAddr;
    // const rowsUpperCase = localAddress.uppercaseLastLines.split(';') as string[];
    if (elemsAddr === undefined) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      return;
    }
    let localAddressData: FieldValue[];
    localAddressData = [];
    if ((this.appointmentRequest.equipmentLocation != null)
      && (this.appointmentRequest.equipmentLocation !== undefined)
      && (this.appointmentRequest.equipmentLocation !== '')) {
      localAddressData = JSON.parse(this.appointmentRequest.equipmentLocation);
    }
    // console.log(elemsAddr);
    let iRow = 0;

    let iRowCur = 0;
    this.rowsAddress = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;

    let isMandatory = false;
    for (const elemAddr of elemsAddr) {
      iRowCur++;

      isMandatory = (elemAddr.dataMandatory !== 'N');
      elemAddr.inputValue = '';
      // console.log(elemAddr.dataName);
      if ((elemAddr.controlType === null) || (elemAddr.controlType === '')) {
        elemAddr.controlType = elemAddr.dataName;
      }
      iRow = Number(elemAddr.lineNumber);
      /*if (elemAddr.controlType === 'statesList') {
        this.buildStatesList(address.statesList);
        this.addressBlockFg.addControl('filterState', new FormControl());
      }*/
      if (elemAddr.dataInput === 'N') { continue; }
      if (elemAddr.dataInput === 'proID') { continue; }
      if (elemAddr.dataInput === 'individualID') { continue; }
      if (this.readOnlyData) {
        let isFound = false;
        if ((localAddressData != null) && (localAddressData !== undefined) ) {
          for (const localData of localAddressData )  {
            if (localData.value === '') {
              continue;
            }
            if (elemAddr.dataName === localData.name) {
              elemAddr.inputValue = localData.value;
              elemAddr.elemLabel = localData.label;
              isFound = true;
              break;
            }
          }
        }
        if (!isFound ) {
          continue;
        }
      }
      if ((iRowBuild > 0) && (iRow !== iRowBuild)) {
        this.rowsAddress.push({
          row: Number(iRow).toString,
          lengthMax: iLengthMax,
          cols: colsArray,
        });
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      if (iRowBuild < 0) {
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      iLengthMax += Number(elemAddr.dataLengthMax);
      const labelElem = this.translate.instant('COMMUN.ADDRESS.' + elemAddr.dataName.toLocaleUpperCase());

      if (labelElem === elemAddr.dataName) {
        elemAddr.elemLabel = ''; // if label is ''
      } else {
        elemAddr.elemLabel = labelElem;
      }

      elemAddr.elemUpperCase = false;

      colsArray.push(elemAddr);
      if (isMandatory) {
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl('', Validators.required));
      } else {
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl(''));
      }
      this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });

    } // elem address loop
    this.rowsAddress.push({
      row: Number(iRow).toString,
      lengthMax: iLengthMax,
      cols: colsArray,
    });
    this.appointmentRequest.equipmentLocation = '';
  }
  calWidthCol(cols: any[], dataLengthMax: number, lengthMax: number): string {

    let fieldSize = dataLengthMax * 11;
    // padding left / right
    if (fieldSize < 90 ) {
      fieldSize = fieldSize + (15 * 2);
    }
    return fieldSize.toString() + 'px';
  }
  getColRowAddr(cols: any[]): any {
    // console.log(cols.length);
    return 'col';

  }
  formatFieldMooring(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;

    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
      /*
        const elemBool = elemCur.split('|');
        elemCur = elemBool[0];
        const booleanFields = elemMooring.replace(elemCur, '');
        controlTypeCur = 'boolean' + booleanFields;
      */
      if (this.rowsMooring[iRow].cols[iCol].controlType.startsWith('boolean')) {
        const elemsBool = this.rowsMooring[iRow].cols[iCol].controlType.split('|');
        for (const elmCur of elemsBool) {
            if (elmCur === 'boolean' ) {
              continue;
            }
            if (valueReturn === '') {
              this.mooringBlockFg.controls[elmCur].enable();
            } else  {
              this.mooringBlockFg.controls[elmCur].disable();
            }
        }
      }
      this.verifMooring(iRow, iCol);
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;
    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    // this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
    // this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
    if (isNumeric && this.hindusArabicNumbers ) {
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsMooring[iRow].cols[iCol].inputValue = value;
      this.rowsMooring[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsMooring[iRow].cols[iCol].latinValue = undefined;
    }

    this.verifMooring(iRow, iCol);

    return;
  }
  verifMooring(iRow: number, iCol: number): void  {
    let adressFields: FieldValue[];
    adressFields = [];
    let mandatoryFieldsOK = true;
    this.incompleteInput = false;
    let mooringCur = '';
    let mooringData = '';
    let iData = 0;
    let iDataPresent = 0;
    for ( const rowCur of this.rowsMooring ) {
      for (const colCur of rowCur.cols ) {
        if ((colCur.dataMandatory === 'O') && ( this.mooringBlockFg.controls[colCur.dataName].enabled)) {
          if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
            this.incompleteInput = true;
            mandatoryFieldsOK = false;
          }
        }
        if ((colCur.inputValue !== '') && (colCur.inputValue !== undefined)) {
          iDataPresent++;
        }
        let valueCur = colCur.inputValue;
        if (valueCur === undefined) {
          valueCur = '';
        }
        if (iData === 0) {
          mooringCur += valueCur;
        } else {
          mooringCur += ',' + valueCur;
        }
        if (iData === 0) {
          mooringData = colCur.elemLabel + ' : ' + valueCur;
          mooringCur += valueCur;
        } else {
          mooringData  += ',' + colCur.elemLabel + ' : ' + valueCur;
          mooringCur += ',' + valueCur;
        }

        adressFields.push( {
          label: colCur.elemLabel,
          name: colCur.dataName,
          value: colCur.inputValue,
          latin: colCur.latinValue
        });
        iData++;
      }
    }
    if (iDataPresent < 2) {
      this.incompleteInput = true;
      mandatoryFieldsOK = false;
    }
    if (mandatoryFieldsOK) {
      // this.appointmentRequest.equipmentLocation = mooringData;
      this.appointmentRequest.equipmentLocation = JSON.stringify(adressFields);
      this.incompleteInput = false;
    }

  }
  // formatField(typeRaw: string, iRow: number, iCol: number, paramsCur: any, { target = {} as HTMLInputElement }): void {
  formatFieldAddress(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;

    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
      this.verifAddress(iRow, iCol);
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;

    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    if (isNumeric && this.hindusArabicNumbers ) {
      this.addressBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsAddress[iRow].cols[iCol].inputValue = value;
      this.rowsAddress[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsAddress[iRow].cols[iCol].latinValue = undefined;
    }

    this.verifAddress(iRow, iCol);

    return;
  }
  verifAddress(iRow: number, iCol: number): void  {
    // if (iRow === this.rowsAddress.length - 1) {
      let adressFields: FieldValue[];
      adressFields = [];
      let mandatoryFieldsOK = true;
      this.incompleteInput = false;
      // if (iCol === this.rowsAddress[iRow].cols.length - 1) {
      let addressCur = '';
      let addressData = '';
      let iData = 0;
      for ( const rowCur of this.rowsAddress ) {
        for ( const colCur of rowCur.cols ) {
          if (colCur.dataMandatory === 'O') {
            if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
              this.incompleteInput = true;
              mandatoryFieldsOK = false;
            }
          }
          let valueCur = colCur.inputValue;
          if (valueCur === undefined) {
            valueCur = '';
          }
          if (iData === 0) {
            addressData = colCur.elemLabel + ' : ' + valueCur;
            addressCur += valueCur;
          } else {
            addressData  += ',' + colCur.elemLabel + ' : ' + valueCur;
            addressCur += ',' + valueCur;
          }
          adressFields.push( {
            label: colCur.elemLabel,
            name: colCur.dataName,
            value: colCur.inputValue,
            latin: colCur.latinValue,
          });

          iData++;

        }
      }
      if (mandatoryFieldsOK) {
        // this.appointmentRequest.equipmentLocation = addressData;
        this.appointmentRequest.equipmentLocation = JSON.stringify(adressFields);
        // this.addressToCoordinates(addressCur);
      }
      // }
    // }
  }
// ================================ maps ==
  getReturnMap($event: any): void {
    this.closeModal();
    if ($event !== undefined)  {
      try  {
        const latLng = JSON.parse($event.latLng) as LatLng;
        if ((latLng.lat !== undefined) && (latLng.lng !== undefined)) {
          this.displayLatLng(latLng);
        }
        /*if ($event.address) {
          this.requestedServiceForm.controls.equipmentLocation.setValue($event.address);
        }*/
        this.validInput(this.requestedServiceForm.controls.jobsite.value);
      }
      finally {
      }
    }
  }
  displayLatLng(coord: LatLng): void {
    const values: string[] = [];
    values[0] = coord.lat.toString();
    values[1] = coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.positionMap = true;
      this.requestedServiceForm.controls.equipmentPosition.setValue(values[0] + ',' + values[1]);
      this.requestedServiceForm.controls.equipmentPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.positionMap = false;
    }
  }
  getLatLng(values: string[]): any[] {
    const latLng = [];
    const lat = Number.parseFloat(values[0]);
    const lng = Number.parseFloat(values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this.coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this.coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }
  checkGeoLocAddress(event: Event): void {

    if ((this.requestedServiceForm.controls.equipmentLocation.value !== null)
        && (this.requestedServiceForm.controls.equipmentLocation.value !== '')) {
      this.searchLatLngMap(this.requestedServiceForm.controls.equipmentLocation.value);
    } else if (this.requestedServiceForm.controls.equipmentPosition.value !== null) {
      this.displayMap(this.requestedServiceForm.controls.equipmentPosition.value);
    }

  }
  addressToCoordinates(address: string): void {
    this.incompleteInput = true;
    let geoValue: GeoValues = {
    };
    this.geolocationService.addressTolatLng(address)
    .subscribe((geoValues: GeoValues) => {
        if (geoValues.status === 'OK') {
          geoValue = {
            latLng: geoValues.latLngs[0],
            address: geoValues.addresses[0],
          };
          this.displayLatLng(geoValue.latLng);
          this.validInput(this.requestedServiceForm.controls.jobsite.value);
        }
        // this.requestedServiceForm.controls.equipmentLocation.setValue(geoValue.address);

        // this.validInput(this.requestedServiceForm.controls.jobsite.value);
        /*
        Object.keys(this.requestedServiceForm.controls).forEach(field => { // {1}
          const control = this.requestedServiceForm.get(field);            // {2}
          control.markAsTouched({ onlySelf: true });       // {3}
        });
        */
      },
      (err) => {
        // alert('Geocode was not successful for the following reason: ' + status);
      }
    );
  }
  coordinatesToAddress(positionCur: LatLng): void {
    let geoValue: GeoValues = {
    };
    const latLngCur = new google.maps.LatLng(positionCur.lat, positionCur.lng);

    this.geolocationService.latLngToAddress(latLngCur)
    .subscribe((geoValues: GeoValues) => {
        if (geoValues.status === 'OK') {
          geoValue = {
            address: geoValues.addresses[0],
          };
        }
        return geoValue;
      },
      (err) => {
        // alert('Geocode was not successful for the following reason: ' + status);
      }
    );
  }
  displayMap(positionCur: string): void {

    let latLng: LatLng;
    const values = positionCur.split(',');
    latLng = {
        lat: Number.parseFloat(values[0]),
        lng: Number.parseFloat(values[1])
    };
    if (positionCur === '') {
      this.googleMaps = {
        isModal: true,
        displayLocal: true,
        style: {
          width: this.scrWidth,
          height: this.scrHeight,
        },
        // displayLatLng , getLatLng
        action: 'getLatLng',
        position: undefined,
      markers:  [],
      };
    } else {
      this.googleMaps = {
        isModal: true,
        displayLocal: true,
        style: {
          width: this.scrWidth,
          height: this.scrHeight,
        },
        // displayLatLng , getLatLng
        action: 'getLatLng',
        position: latLng,
        mapOptions:  {
          center: latLng,
          zoom : 18
      },
      markers:  [],
      };
      if (positionCur !== undefined) {
        this.googleMaps.markers.push({
          position: latLng,
          title: ''}
        );
      }
    }
    this.openModalMap();
  }
  searchLatLngMap(address: string): void {

    this.googleMaps = {
      isModal: true,
      addressInput: address,
      zoom: 18,
      style: {
        width: this.scrWidth,
        height: this.scrHeight,
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'searchLatLng',
      position: undefined,
      mapOptions:  { },
    markers:  [],
    };

    this.openModalMap();
  }
  openModalMap(): void {

    const configModalMap = {
      class: 'modal-dialog-centered handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  closeModal(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }
}
