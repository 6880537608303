<form [formGroup]="clientForm" [dir]="wksEntity.directionInput" >
  <mat-card class="stdTheme">
    <mat-card-title *ngIf="!readOnlyData" style="text-align: center;" >{{ 'CLIENT.FORM_TITLE' | translate }}</mat-card-title> 
    <mat-card-content>
      <fieldset class="informationsborder">
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-10">
            <mat-form-field appearance="standard" >
              <mat-label class="appointmentsLabel">{{ 'CLIENT.FIRST_NAME' | translate }}</mat-label>
              <input formControlName="firstName" matInput type="text" required>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-10">
            <mat-form-field appearance="standard" >
              <mat-label>{{ 'CLIENT.LAST_NAME' | translate }}</mat-label>
              <input formControlName="lastName" matInput type="text" required >
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-10">
            <mat-form-field appearance="standard" >
              <mat-label>{{ 'CLIENT.COMPANY_NAME' | translate }}</mat-label>
              <input formControlName="companyName" matInput type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-10">
            <mat-form-field appearance="standard" >
              <mat-label>{{ 'CLIENT.MAIL_ADDRESS' | translate }}</mat-label>
              <input formControlName="mailAddress" matInput placeholder="pat@example.com" type="email" required>
              <mat-icon matSuffix>email</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-10">
            <mat-form-field appearance="standard" >
              <mat-label>{{ 'CLIENT.PHONE_NUMBER' | translate }}</mat-label>
              <div *ngIf="hindusArabic">
                <input formControlName="phoneNumber" #arabicHindus matInput [placeholder]="domesticMask" (input)="ctrlArabicHindus(arabicHindus.value)" required>
              </div>
              <div *ngIf="!hindusArabic">
                <input formControlName="phoneNumber" matInput [placeholder]="wksEntity.telFmt.domesticMask" [mask]="wksEntity.telFmt.domesticMask" type="tel" required>
              </div>
                <mat-icon matSuffix>phone</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
          <div class="col-5" style="margin-top: 0px;">
            <mat-label>{{ 'CLIENT.EQUIPMENTS_NB' | translate }}</mat-label>
          </div>
          <div class="col-5" style="margin-top: -30px;padding-left: 0px;margin-left: 0px;margin-left: -10px;">
            <mat-form-field appearance="standard" floatLabel="never">
              <!-- <mat-label>{{ 'EQUIPMENT.NB_HOURS_OF_USE' | translate }}</mat-label>-->
              <button 
                mat-icon-button 
                matPrefix 
                aria-label="Remove" 
                (click)="counter('nbEquipments',-1, nbEquipments.value)" >
                <mat-icon style="margin-top: 10px;">remove</mat-icon>
              </button>
              <input matInput formControlName="nbEquipments" #nbEquipments required readonly style="height: 30px;text-align: center" />
              <div matSuffix style="position: relative">
                <button 
                  mat-icon-button
                  aria-label="Add" 
                  (click)="counter('nbEquipments',1,  nbEquipments.value)" >
                  <mat-icon style="margin-top: 10px;">add</mat-icon>
                </button>
              </div>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </mat-card-content>
    <mat-card-actions *ngIf="!readOnlyData" class="actions">
      <button  mat-raised-button  (click)="onGoHome()">
        {{ 'COMMUN.BUTTON.HOME' | translate }}
        <mat-icon>home</mat-icon>
      </button>
      <div fxFlex></div>
      <button [disabled]="clientForm.invalid" mat-raised-button  (click)="onSubmit()" type="submit">
        {{ 'COMMUN.BUTTON.NEXT' | translate }}
        <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
