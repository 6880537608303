
<mat-card  class="wkscard stdTheme" layout="column" flex  style="width: 100%; margin-top: 0px; margin-bottom: 0px;padding: 0px;" >
    <mat-card-content class="wkscard" style="height: 55vh!important">
        <div class="flex-row row" style="margin: 0px;padding: 0px;padding-left: 10px; margin-bottom: 10px; margin-top: 10px">
            <div *ngIf="dataLocationWork.typeLocation !== 'workshop'" class="container-fluid stdTheme" >
                <app-location-wks
                    [applicationCall]="'appointmentWork'" 
                    [choiceLocation]="false" 
                    [dataLocationEquip]="undefined"
                    [dataLocationArg]="dataLocationWork"
                    [readOnlyData]="isReadOnly" 
                    (dataOut)="dataOutLocation($event)">
                </app-location-wks>
            </div>
            <div *ngIf="dataLocationWork.typeLocation === 'workshop'" class="container-fluid stdTheme" >
                <span > 
                    {{ 'LOCATION.WORKSHOP' | translate }}
                </span>
            </div>
        </div>
    </mat-card-content>
</mat-card>

