import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { TranslateService  } from '@ngx-translate/core';

import { AuthService } from '../../../../services/auth.service';
import { WorksService } from '../../../../services/works.service';
import { SharingDataService } from '../../../../services/sharing-data.service';

import { WksWorksModel, TasksActionsModel, TasksActionsContentModel, WorkEquipmentRef,
          IhmOptions, WksEntity, InternationalsFormats, TasksActionCompletedModel, WksEquipmentModel,
          TaskrefJsonModel, WksTasksModel  } from '../../../../models';


@Component({
  selector: 'app-work-checklist',
  templateUrl: './work-checklist.component.html',
  styleUrls: ['./work-checklist.component.less']
})
export class WorkChecklistComponent implements OnInit {
  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) mSidenav: any;
  modalTitle: string;
  workId: string;
  workCur: WksWorksModel;
  checkEquipmentsIsLoaded: boolean;
  itemChoose: string;
  nameParent: string;

  ihmOptions: IhmOptions;
  ihmOptionsCkeck: IhmOptions;
  wksEntity: WksEntity;
  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  checkEquipments: WksEquipmentModel [];

  isReadOnly: boolean;
  isCheckLoaded: boolean;
  isOkToValid: boolean;
  itemEquip: WksEquipmentModel;
  itemTasks: TasksActionCompletedModel;
  tasksActionsCompleted: TasksActionCompletedModel[];
  tasksActionsArgs: TasksActionCompletedModel[];

  constructor(private dialogRef: MatDialogRef<WorkChecklistComponent>,
              private worksService: WorksService,
              private translateService: TranslateService,
              private sharingDataService: SharingDataService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.modalTitle = data.title;
                this.workId = data.workId;
                this.ihmOptions = data.ihmOptions;
  }

  ngOnInit(): void {
    this.initBooleans();
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
      this.ihmOptionsCkeck = this.ihmOptions;
    }
    this.worksService.getWorksById(this.workId)
    .subscribe(
      data => {
        this.workCur = data.body as WksWorksModel;
        if (this.ihmOptions === undefined) {
          this.getIhmOptions();
        }
        if (this.workCur.workCustomer.bsnAddress) {
          const dataAddress = JSON.parse(this.workCur.workCustomer.bsnAddress);
          if ((this.workCur.workCustomer.bsnType === 'company') || (this.workCur.workCustomer.bsnType === 'administration')) {
            this.nameParent = dataAddress.corporateName;
          } else {
            this.nameParent = dataAddress.identity.cntLastname + ' ' + dataAddress.identity.cntFirstname;
          }
        }
        this.checkEquipments = [];
        if (this.workCur.wksSubEquipments) {
          this.checkEquipments = this.workCur.wksSubEquipments;
        } else {
          this.checkEquipments.push(this.workCur.wksMainEquipment);
        }
        this.fillCheckTasks();
        this.itemEquip = this.checkEquipments[0];
        this.tasksActionsArgs = [];
        for (const itemTasks of this.tasksActionsCompleted) {
          if (itemTasks.equipId === this.itemEquip.id) {
            this.tasksActionsArgs.push(itemTasks);
          }
        }
        this.checkEquipments[0].isSelected = true;
        this.checkEquipmentsIsLoaded = true;
      },
      err =>  {
        console.log('getWorksById ' + err.message);
      }
    );

    this.wksEntity = this.sharingDataService.getWksEntity();
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this.sharingDataService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }

    }
    this.isCheckLoaded = true;
  }
  getIhmOptions(): void {
    this.ihmOptions = {
      readOnly: true
    };
    this.ihmOptionsCkeck = {
      readOnly: true
    };
    if (this.workCur === undefined) {
      return;
    }
    this.ihmOptions = this.worksService.getIhmOptions(this.workCur.workStatut, 'checkList');
    this.ihmOptionsCkeck = this.ihmOptions;
    this.ihmOptionsCkeck.collapse = false;
  }
  fillCheckTasks(): void {

    let worksActionsSelected: TasksActionsModel[] = [];
    let suppliesActionsSelected: TasksActionsModel[] = [];
    let equipments: {
        all: boolean;
        equipId: string[];
    };
    let okToCheck: boolean;
    this.tasksActionsCompleted = [];
    let workEquipmentId: WorkEquipmentRef;
    let numberEquip = 0;
    workEquipmentId = JSON.parse(this.workCur.workEquip);
    if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
      numberEquip = 1;
    } else {
      numberEquip = workEquipmentId.subEquipments.length;
    }
    let durationCur = 0;
    let equipmentsCur = 0;
    for (const taskCur of this.workCur.workTasksContent) {
      worksActionsSelected = [];
      suppliesActionsSelected = [];

      const taskrefJson = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        const tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
        worksActionsSelected = tasksActionsParentContent.works;
        suppliesActionsSelected = tasksActionsParentContent.supplies;
      }
      if (taskrefJson.tasksActionsContent !== undefined) {
        const tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        worksActionsSelected.push(...tasksActionsContent.works);
        suppliesActionsSelected.push(...tasksActionsContent.supplies);
        equipments = tasksActionsContent.equipments;
        if (equipments.all) {
          equipmentsCur = numberEquip;
        } else {
          equipmentsCur = equipments.equipId.length;
        }
        // console.log(tasksActionsContent);
      }
      if (taskrefJson.tasksActionCompleted !== undefined) {
        // this.tasksActionsCompleted = [...taskrefJson.tasksActionCompleted as TasksActionCompletedModel[]];
        for (const taskTmpCur of taskrefJson.tasksActionCompleted as TasksActionCompletedModel[] ) {
          if (taskTmpCur.equipId ===  this.workCur.wksMainEquipment.id) {
            taskTmpCur.equipSN = this.workCur.wksMainEquipment.equipSN;
          } else {
            if (this.workCur.wksSubEquipments) {
              for (const equipCur of this.workCur.wksSubEquipments) {
                if (taskTmpCur.equipId ===  equipCur.id) {
                  taskTmpCur.equipSN = equipCur.equipSN;
                  break;
                }
              }
            }
          }
          this.tasksActionsCompleted.push(taskTmpCur);
        }
      } else {
        for (const equipItem of this.checkEquipments) {
          okToCheck = false;
          if (equipments.all) {
            okToCheck = true;
          } else {
            for (const idEquip of equipments.equipId) {
              if (idEquip === equipItem.id) {
                okToCheck = true;
                break;
              }
            }
          }
          if (okToCheck) {
            durationCur = 0;
            for (const workTmp of worksActionsSelected) {
              durationCur += Number(workTmp.quantity);
            }
            const taskEmpty: TasksActionCompletedModel = {
              equipId: equipItem.id,
              equipSN: (equipItem.equipSN ? equipItem.equipSN : undefined),
              taskrefId: taskCur.taskrefId,
              taskrefLabel: taskCur.taskrefLabel,
              works: worksActionsSelected,
              supplies: suppliesActionsSelected,
              additionalTasks: undefined,
              equipHours: (equipItem.equipHours ? equipItem.equipHours : -1),
              taskDuration: durationCur
            };
            this.tasksActionsCompleted.push(taskEmpty);
          }
        }
        if (worksActionsSelected.length > 0 ) {
          durationCur = 0;
          for (const workTmp of worksActionsSelected) {
            durationCur += Number(workTmp.quantity);
          }
          this.workCur.workDuration += (durationCur * equipmentsCur);
        }
      }
    }

  }

  initBooleans(): void {
    this.checkEquipmentsIsLoaded = false;
    this.isReadOnly = true;
    this.isCheckLoaded = false;
    this.isOkToValid = false;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  choose($event: string, itemArg?: any): void {
    if (itemArg !== undefined) {
      for (const itemCur of this.checkEquipments) {
        itemCur.isSelected = false;
      }
      itemArg.isSelected = true;
      this.itemEquip = itemArg;
      this.tasksActionsArgs = [];
      for (const itemTasks of this.tasksActionsCompleted) {
        if (itemTasks.equipId === itemArg.id) {
          this.tasksActionsArgs.push(itemTasks);
        }
      }
    }
    if ($event !== '') {
      this.itemChoose = $event;
    }
    this.mSidenav.close();
    this.sidenavCollapsed = !this.sidenavCollapsed;
  }
  toggleSidenav(): void {
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.mSidenav.close();
    } else {
      this.mSidenav.open();
    }
  }
  toggleBackdropSidenav(): void {
    this.sidenavCollapsed = true;
    this.mSidenav.close();
  }
  inputData(dataReturn: any): void  {

    const dataTasks = dataReturn.tasksActions as TasksActionCompletedModel[];
    const inputChanged = dataReturn.inputChange;
    if (!inputChanged) {
      this.isOkToValid = false;
      return;
    }

    // console.log(dataTasks);
    for (const taskInput of dataTasks) {
      let isFound = false;
      let idx = 0;
      for (let taskCur of this.tasksActionsCompleted) {
        if ((taskInput.equipId === taskCur.equipId) && (taskInput.taskrefId === taskCur.taskrefId )) {
          isFound = true;
          taskCur = taskInput;
          this.tasksActionsCompleted.splice(idx, 1);
          this.tasksActionsCompleted.push(taskInput);
          break;
        }
        idx++;
      }
      if (!isFound) {
        this.tasksActionsCompleted.push(taskInput);
      }
    }

    this.isOkToValid = true;
  }
  validData(): any {
    this.fillModel();

    return new Promise((resolve, reject) => {
      this.worksService.saveWorks(this.workCur, 'checklist').subscribe(result => {
        const reponseCur = result.body;

        this.dialogRef.close();
        resolve(reponseCur);
      },
      err => {
        console.log(err);
        reject(err.status);
      });
    });
  }
  fillModel(): void {
    let workTasksContent: WksTasksModel[];
    workTasksContent = this.workCur.workTasksContent;

    let tasksActionCompletedTmp: TasksActionCompletedModel[];
    for (const taskCur of workTasksContent) {
      tasksActionCompletedTmp = [];
      let isFound = false;
      for (const taskCompletedCur of this.tasksActionsCompleted) {
        if (taskCompletedCur.taskrefId === taskCur.taskrefId) {
          tasksActionCompletedTmp.push(taskCompletedCur);
          isFound = true;
        }
      }
      if (isFound) {
        const taskrefJsonTmp = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
        taskrefJsonTmp.tasksActionCompleted = tasksActionCompletedTmp;
        taskCur.taskrefJson = JSON.stringify(taskrefJsonTmp);
        // console.log(taskCur.taskrefJson);
      }
    } // end existants tasks on works
    // New task
    for (const taskCompletedCur of this.tasksActionsCompleted) {
      let isFound = false;
      for (const taskCur of workTasksContent) {
        if (taskCompletedCur.taskrefId === taskCur.taskrefId) {
          isFound = true;
          break;
        }
      }
      if (isFound) {
        continue;
      }
      const newTask: WksTasksModel = {
        id: undefined,
        workId: this.workCur.id,
        equipType: taskCompletedCur.equipType,
        equipBrand: taskCompletedCur.equipBrand,
        equipModel: taskCompletedCur.equipModel,
        stdEntity: this.authService.getUserLogged().entity,
        taskrefLabel: taskCompletedCur.taskrefLabel,
        taskrefId: taskCompletedCur.taskrefId,
        taskrefJson: undefined,
        taskrefRow: 'work'
      };
      let taskrefJsonTmp: TaskrefJsonModel;
      tasksActionCompletedTmp = [];
      tasksActionCompletedTmp.push(taskCompletedCur);
      taskrefJsonTmp = {
        tasksActionsContent: undefined,
        tasksActionsParentContent: undefined,
        tasksActionCompleted: tasksActionCompletedTmp
      };
      newTask.taskrefJson = JSON.stringify(taskrefJsonTmp);
      this.workCur.workTasksContent.push(newTask);
    }
    for (const taskCompletedCur of this.tasksActionsCompleted) {
    // SN and hours equipment
    // Main equipment
      if (taskCompletedCur.equipId === this.workCur.wksMainEquipment.id) {
        if (taskCompletedCur.equipSN !== undefined
                && taskCompletedCur.equipSN !== ''
                && taskCompletedCur.equipSN !== null  ) {
          this.workCur.wksMainEquipment.equipSN = taskCompletedCur.equipSN;
        }
        if (taskCompletedCur.equipHours !== undefined
          && taskCompletedCur.equipHours !== 0
          && taskCompletedCur.equipHours !== null  ) {
          this.workCur.wksMainEquipment.equipHours = taskCompletedCur.equipHours;
        }
      }
    // sub equipments
      if (this.workCur.wksSubEquipments) {
        for (const equipCur of this.workCur.wksSubEquipments) {
          if (taskCompletedCur.equipId === equipCur.id) {
            if (taskCompletedCur.equipSN !== undefined
                    && taskCompletedCur.equipSN !== ''
                    && taskCompletedCur.equipSN !== null  ) {
                      equipCur.equipSN = taskCompletedCur.equipSN;
            }
            if (taskCompletedCur.equipHours !== undefined
              && taskCompletedCur.equipHours !== 0
              && taskCompletedCur.equipHours !== null  ) {
                equipCur.equipHours = taskCompletedCur.equipHours;
            }
          }
        }
      }
    }
  }
}
