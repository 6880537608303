import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { GlobalEventsManagerService } from '../../../../../services/global-events-manager.service';


import { WorksService } from '../../../../../services/works.service';

import { WksEquipmentModel, WksAnchoragesModel } from '../../../../../models/';
import { WksWorksInterventionEvent, WksWorksInterventionModel,
          WorkQuotation, Payment, WksWorksModel, WorkEquipments, DataLocationWorkModel,
          LabelValue, IhmOptions, GoogleMaps, LatLng} from '../../../../../models/';


import moment from 'moment';

@Component({
  selector: 'app-work-intervention',
  templateUrl: './work-intervention.component.html',
  styleUrls: ['./work-intervention.component.less']
})
export class WorkInterventionComponent implements OnInit {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() wksWorks: WksWorksModel;
  @Input() ihmOptions: IhmOptions;
  @Input() planningOptions: IhmOptions;
  @Output() dataOut = new EventEmitter<any>();

  private readonly onDestroy = new Subject<void>();

  @ViewChild('listConditions', {static: false}) tableConditions: ElementRef;
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;

  workForm: FormGroup;
  isReadOnly: boolean;
  isReadOnlyPlanning: boolean;
  disabledMooring: boolean;

  anchoragesList: LabelValue[];
  anchoragesCacheList: LabelValue[];
  anchorageFiltredList: LabelValue[];
  anchoragesIsLoaded: boolean;
  entityCur: string;

  googleMaps: GoogleMaps;
  isGeoLocation: boolean;

  wksWorksInterventionCache: WksWorksInterventionModel;
  dataLocationWork: DataLocationWorkModel;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  minStartDate: any;
  maxStartDate: any;
  minEndDate: any;
  maxEndDate: any;
  startDateValue: any;
  timeFormat: string;
  isGeoLocActived: boolean;
  workObject: string;

  modalRef: BsModalRef;
  wksOthersWorks: LabelValue[];

  constructor(private fb: FormBuilder,
              private translateService: TranslateService,
              private worksService: WorksService,
              private globalEventsManagerService: GlobalEventsManagerService,
              private modalService: BsModalService,
              private adapterDate: DateAdapter<any>,
              private coordinatesService: CoordinatesService) { }

  ngOnInit(): void {
    this.initDp();
    this.initAllBooleans();
    this.initGlobalsParameters();
    this.loadListsParameters();
    this.buildWorkForm();
    this.fillForm();
    this.initAllSubscribes();

  }
  initAllBooleans(): void {
    this.disabledMooring = true;
    this.isReadOnly = false;
    this.isGeoLocActived = false;
    this.anchoragesIsLoaded = false;
  }
  initGlobalsParameters(): void {
    this.adapterDate.setLocale(this.translateService.currentLang);
  }
  initAllSubscribes(): void {

    this.globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
        let eventString: string;
        const typeEvent = typeof event;
        if (typeEvent === 'string')  {
          eventString = String(event);
          if (eventString !== undefined) {
            if (eventString.startsWith('closeMapModalLatLng__')) {
              this.closeModal();
              const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;
              this.displayLatLng(latLng);
              // this.loadDocs();
              // console.log(typeEvent);
            }
          }
        }
        // console.log(typeEvent);
    });

  }
  loadListsParameters(): void  {
    this.initAnchorages();

  }
  initDp(): void {
    this.minStartDate = moment({year: this.year, month: this.month, day: this.day});
    this.maxStartDate = moment({year: this.year + 2, month: this.month, day: this.day});
    this.minEndDate = moment({year: this.year, month: this.month, day: this.day});
    this.maxEndDate = moment({year: this.year + 2, month: this.month, day: this.day});
  }
  initAnchorages(): void {
    this.anchoragesCacheList =  this.worksService.getWksAnchorages();
    this.anchoragesList =  this.worksService.getWksAnchorages();
    this.anchorageFiltredList =  this.worksService.getWksAnchorages();
  }
  onChangeField(field: string, value: any): void {
    switch (field) {
      case 'numberPayments': {
        break;
      }
      default: {
        break;
      }
    }
  }
  onKeyChangeObject($event: any): void {
    const valueArg = ($event.target as HTMLInputElement).value;
  }
  onChangeCriticalityState(value: any): void  {
    // console.log('onChangeCriticalityState : ' + _value);
  }
  onChangeworkPrevious(value: any): void  {
    // console.log('onChangeworkPrevious : ' + _value);

  }
  onChangeWorkStatut(value: any): void  {

  }
  dataOutLocation(dataReturn: any): void {
    this.dataLocationWork = dataReturn as DataLocationWorkModel;

  }

  onWorkLocationChange(value: string): void {

    switch (value) {
      case 'locationWorkshop': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationMooring': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        break;
      }
      case 'locationHomePort': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationOther': {
        this.disabledMooring = false;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationHomeOwner': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      default: {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
    }
  }

  mooringInitData(mooringPortArg: string, portPoolArg: string,
                  portPontoonArg: string, portRingArg: string, anchoragePosition: string ): void {
    this.workForm.patchValue({
      mooringPort: mooringPortArg,
      portPool: portPoolArg,
      portPontoon: portPontoonArg,
      portRing: portRingArg,
      workPosition: anchoragePosition,
    });
    this.changePosition(anchoragePosition);
  }
  mooringInputManage(disableMooring: boolean): void {
    if (disableMooring) {
      this.workForm.controls.mooringPort.disable();
      this.workForm.controls.portPool.disable();
      this.workForm.controls.portPontoon.disable();
      this.workForm.controls.portRing.disable();
    }else {
      this.workForm.controls.mooringPort.enable();
      this.workForm.controls.portPool.enable();
      this.workForm.controls.portPontoon.enable();
      this.workForm.controls.portRing.enable();
    }

  }
  onChangeAnchorage($event: any): void {
  // console.log($event);
  }
  onKeyAnchorage(value: any): void {
    // console.log(value);
    this.anchorageFiltredList = this.searchAnchorage(value);

  }
  searchAnchorage(value: string): LabelValue[] {
    const filter = value.toLowerCase();
    return this.anchoragesCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  changePosition(value: string): void {
    this.isGeoLocation = false;
    if (value === '') {
      this.workForm.controls.workPositionCoord.setValue('');
      return;
    }
    const tmp = value.split(',');
    const wrongPosition = this.translateService.instant('anchoragePositionCoordError');
    if ((tmp === null) || (tmp.length < 2)) {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
      return;
    }
    const latLng = this.getLatLng(tmp);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.workForm.controls.workPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    }else {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
    }
    const latCur = Number.parseFloat(tmp[0]);
    const lngCur = Number.parseFloat(tmp[1]);
    const myLatLng1 = { lat: latCur, lng: lngCur };
    // const myLatLng2 = { lat: 51.24132136413212, lng: 4.4073388696813875 };

    this.googleMaps = {
      isModal: false,
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'displayLatLng',
      style: undefined,
      position: myLatLng1,
      mapOptions:  {
        center: myLatLng1,
        zoom : 14
     },
     markers:  [],
    };

    // this.googleMaps.markers = [];
    this.googleMaps.markers.push({
        position: myLatLng1,
        title: ''}
    );

    this.isGeoLocation = true;
  }
  displayMap(): void {

    let positionCur ;
    let zoom = 1;
    let latLng: LatLng;
    if ((this.workForm.controls.workPosition !== undefined)
      && (this.workForm.controls.workPosition.value !== null)
      && (this.workForm.controls.workPosition.value !== '')) {
      zoom = 14;
      positionCur = this.workForm.controls.workPosition.value;
      const values = positionCur.split(',');
      latLng = {
          lat: Number.parseFloat(values[0]),
          lng: Number.parseFloat(values[1])
      };
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: latLng,
      mapOptions:  {
        center: latLng,
        zoom : 1
     },
     markers:  [],
    };
    if (positionCur !== undefined) {
      this.googleMaps.markers.push({
        position: latLng,
        title: ''}
      );
    }
    this.openModalMap();
  }
   /* geolocation */
  // Maps
  openModalMap(): void {

    const configModalMap = {
      class: 'modal-dialog-centered modalQuote handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  displayLatLng(coord: LatLng): void {
    const wrongPosition = this.translateService.instant('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = coord.lat.toString();
    values[1] = coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.workForm.controls.workPosition.setValue(values[0] + ',' + values[1]);
      this.workForm.controls.workPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    }else {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
    }
  }
  getLatLng(values: string[]): number[] {
    const latLng: number[] = [];
    const lat = Number.parseFloat(values[0]);
    const lng = Number.parseFloat(values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this.coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude) as number;
    latLng[1] = this.coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude) as number;
    return latLng;
  }
  buildWorkForm(): void {

    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.isReadOnlyPlanning = this.isReadOnly;
    if (this.planningOptions !== undefined) {
      this.isReadOnlyPlanning = this.planningOptions.readOnly;
    }
    this.workForm = this.fb.group({
      workStatut: this.fb.control({value: '', disabled: this.isReadOnly}),
      workTicket: this.fb.control({value: '', disabled: this.isReadOnly}),
      workObject: this.fb.control({value: '', disabled: this.isReadOnly}),
      workPrevious: this.fb.control({value: '', disabled: this.isReadOnly}),
      workLocation: this.fb.control({value: '', disabled: this.isReadOnly}),
      criticalityState: this.fb.control({value: '', disabled: this.isReadOnly}),
      workPlanningId: this.fb.control({value: '', disabled: this.isReadOnlyPlanning}),
      workComment: this.fb.control({value: '', disabled: this.isReadOnly}),
      mooringPort: this.fb.control({value: '', disabled: this.disabledMooring }),
      portPool: this.fb.control({value: '', disabled: this.disabledMooring }),
      portPontoon: this.fb.control({value: '', disabled: this.disabledMooring }),
      portRing: this.fb.control({value: '', disabled: this.disabledMooring }),
      workPosition:  this.fb.control({value: '', disabled: true }),
      workPositionCoord:  this.fb.control({value: '', disabled: true }),
      delayValidity: this.fb.control({value: '', disabled: this.isReadOnly }),
      customerConditions: this.fb.control({value: '', disabled: this.isReadOnly }),
      customerFinancials: this.fb.control({value: '', disabled: this.isReadOnly }),
      bsnCoef: this.fb.control({value: '', disabled: this.isReadOnly}),
      scaleName: this.fb.control({value: '', disabled: this.isReadOnly}),
      numberPayments: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(12), Validators.min(1)]),
      calStart: this.fb.control({value: '', disabled: true}),
      calEnd: this.fb.control({value: '', disabled: true}),
      calActor: this.fb.control({value: '', disabled: true}),

    });
  }
  formDefaultsValues(): void {
    this.dataLocationWork = undefined;
  }
  fillForm(): void {
    if (this.wksWorks.workStatut === undefined ) {
      this.formDefaultsValues();
      return;
    }
    this.workObject = this.wksWorks.workObject;
    this.workForm.controls.workStatut.setValue(this.wksWorks.workStatut);
    this.workForm.controls.workObject.setValue(this.wksWorks.workObject);
    this.workForm.controls.criticalityState.setValue(this.wksWorks.workCriticality);

    this.workForm.controls.workComment.setValue(this.wksWorks.workComment);
    this.workForm.controls.workLocation.setValue(this.wksWorks.workLocation);

    this.workForm.controls.workTicket.setValue(this.wksWorks.workTicket);
    this.workForm.controls.workPosition.setValue(this.wksWorks.workPosition);
    if ((this.wksWorks.workPosition !== undefined)
          && (this.wksWorks.workPosition !== null)) {
        this.workForm.controls.workPosition.setValue(this.wksWorks.workPosition);
        this.changePosition(this.wksWorks.workPosition);
    }

    this.dataLocationWork =  {
      typeLocation: (this.wksWorks.workLocation ? this.wksWorks.workLocation : undefined),
      dataLocation: (this.wksWorks.workLocationData ? JSON.parse(this.wksWorks.workLocationData) : undefined)
    };

  }
  fillModel(): void {

  }
  closeModal(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }
}
