import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ThemeService } from '../../../../services/theme.service';

import { WksEntity, WksEntityParams } from '../../../../models';
import { AppointmentRequest, Client, Equipment } from '../../../../models';
import { AppointmentService } from '../../../../services/appointment.service';
import { SharingDataService } from '../../../../services/sharing-data.service';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.less']
})
export class RequestComponent implements OnChanges {

  titleCard = 'APPOINTMENT_CARD.appointmentTitle';
  @Input() appointmentRequest: AppointmentRequest;
  @Input() parentForm: string;
  @Output() dataOut = new EventEmitter<any>();

  appointmentIsLoaded: boolean;

  wksEntity: WksEntity;
  wksEntityParams: WksEntityParams;
  countryFmt: any;
  readOnlyData: boolean;
  constructor(private appointmentService: AppointmentService,
              private sharingDataService: SharingDataService,
              private themeService: ThemeService,
              private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'appointmentRequest': {
            // tslint:disable-next-line:no-string-literal
            this.appointmentRequest = changes['appointmentRequest'].currentValue;
            break;
          }
          case 'parentForm': {
            // tslint:disable-next-line:no-string-literal
            this.parentForm = changes['parentForm'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    const idRef = this.appointmentRequest.id.split('-');
    this.appointmentRequest.appointmentRef = idRef[1].toUpperCase() + '-' + idRef[2].toUpperCase();
    this.readOnlyData = true;
    this.appointmentIsLoaded = false;
    this.wksEntity = this.sharingDataService.getWksEntity();
    this.wksEntityParams = this.sharingDataService.getWksEntityParams();
    this.countryFmt = this.sharingDataService.getCountryFmt();

  }

  doClose(): void {
    const dataReturn =  {
      statut: 'cancel'
    };
    this.dataOut.emit(dataReturn);
  }
}
