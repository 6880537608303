import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.less']
})
export class Page404Component implements OnInit {

  applicationName: string;
  constructor() { }

  ngOnInit(): void {
    this.applicationName = environment.applicationName;
  }

}
