
<div #refElement *ngIf="isOkToBuild" class="container-fluid stdTheme "
        [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px',overflow: 'auto'}" >
    <div class="flex-row row " style="margin-left: 0px;margin-right: 0px" 
                >
        <div class="col-lg-12"  style="padding-right: 5px; padding-left: 5px;" >
            <form [formGroup]="checkEquipForm"  style="margin: 0px; height: 100%;" >
                <div class="flex-row row" style="margin-left: 0px; margin-right: 0px; min-height: 51px;margin-bottom: 10px;padding-bottom: 20px;"
                    [ngClass]="{greenBorder: getThemeRow('SN-hours'), redBorder: !getThemeRow('SN-hours')}">
                    <div class="col-lg-6 col-xs-12" >
                        <mat-form-field class="wksInput form-group" appearance="standard"  >
                            <mat-label >{{ 'WORK_EQUIPMENT.equipSN' | translate }}</mat-label>
                            <input id="equipSN" #equipSN class="form-control inputTheme" formControlName="equipSN" 
                                        matInput (change)="changeSN_Hours('equipSN', equipSN.value)"
                                        [readonly]="isReadOnly">
                            <mat-placeholder >{{ 'WORK_EQUIPMENT.equipSN' | translate }}</mat-placeholder>
                        </mat-form-field>
                    </div>  
                    <div class="col-lg-6 col-xs-12" >
                        <mat-form-field class="wksInput form-group" appearance="standard" >
                            <mat-label >{{ 'WORK_EQUIPMENT.equipHours' | translate }}</mat-label>
                            <input id="equipHours" #equipHours  class="form-control inputTheme" 
                                    formControlName="equipHours" matInput #equipHours (keypress)="numberOnly($event)"
                                    (change)="changeSN_Hours('equipHours', equipHours.value)"
                                    [readonly]="isReadOnly"
                            >
                            <mat-placeholder >{{ 'WORK_EQUIPMENT.equipHours' | translate }}</mat-placeholder>
                        </mat-form-field>
                    </div>   
                </div>
            </form>
        </div>
    </div>
    <div  style="overflow: hidden;" >
        <div  *ngFor= "let taskActionsCur of tasksActionsCur; let iRow=index; let c=count;let isLast=last">
            <div  style="margin: 0px; padding: 0px;"  >
                <div id="taskActionsCur_{{iRow}}" #taskActionsCur_{{iRow}} class="row rowLine" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
                    <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
                        <button mat-icon-button
                            (click)="taskActionsCur.isCollapsed = !taskActionsCur.isCollapsed"
                            [attr.aria-expanded]="!taskActionsCur.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="!taskActionsCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="taskActionsCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                    </div>
                    <div class="col-lg-9 col-xs-9 labelLine" style="margin-top: 10px;" >
                        <p style="margin: 0px;"><b>{{ taskActionsCur.taskrefLabel }}</b> 
                            <a *ngIf="taskActionsCur.taskDuration"> ({{ taskActionsCur.taskDuration }} h) </a>              
                        </p>
                    </div>
                    <div class="col-lg-2 col-xs-2" style="padding-right: 0px;padding-left: 0px;">
                        <!--
                        <button mat-icon-button
                            (click)="taskActionsCur.isCollapsed = !taskActionsCur.isCollapsed"
                            [attr.aria-expanded]="!taskActionsCur.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="!taskActionsCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="taskActionsCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                        -->
                        <button *ngIf="iRow === 0 && !isReadOnly" mat-icon-button matSuffix aria-label="add" 
                                (click)="addAdditionalTasks(iRow)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                </div>
                <div  [ngbCollapse]="!taskActionsCur.isCollapsed">
                    
                    <div class="col-lg-12 col-xs-12 actionsSelected " >
                        <table class="table tableTask" border="0" cellpadding="0" cellspacing="0" id="tableTasks" style="margin-bottom: 50px;" >
                            <thead>
                                <tr class="thTaskRef">
                                    <th [ngStyle]="{'width': '25%', 'padding': '2px'}">{{'WORK_TASK.suppliesLabel' | translate}}</th>
                                    <th style="width: 15%; padding: 2px">{{'WORK_TASK.brandRef' | translate}}</th>
                                    <th style="width: 10%; padding: 2px">{{'WORK_TASK.billingUnit' | translate}}</th>
                                    <th style="width: 5%; padding: 2px">{{'WORK_TASK.quantity' | translate}}</th>
                                    <th style="width: 5%; padding: 2px">{{'WORK_TASK.actionTask' | translate}}</th>
                                    <th style="width: 15%; padding: 2px" >{{'WORK_TASK.realized' | translate}}</th>
                                    </tr>
                            </thead>
                            
                            <tbody>
                                <!-- [ngClass]="{greenBorder: getThemeRow('taskAction'), redBorder: !getThemeRow('taskAction')}">-->
                                <tr  class="curActionDisplayed" *ngFor="let suppliesCur of taskActionsCur.supplies; let i = index" 
                                            [ngClass]="{'greenBorder':!waitAction[iRow][i],'redBorder':waitAction[iRow][i]}"
                                            [ngStyle]="{'margin': '0px', 'margin-top' : '10px' }">
                                    <td [attr.data-label]="dataLabelCur[0]" 
                                            [ngStyle]="{'width': '25%', 'padding': '2px', 'text-align': 'left', 
                                                        'height': (suppliesCur.actionTaken === 'not-changed' ? '70px' : '50px')}">
                                        <span >
                                            {{suppliesCur.actionrefLabel}} 
                                        </span>
                                        <span *ngIf="suppliesCur.actionTaken === 'not-changed' " style="margin-top: 5px;width:100%!important">
                                            <table class="subtable" style="width:100%!important">
                                                <tr  style="width:100%!important; height: 30px!important">
                                                    <td style="padding-top: 0px;padding-bottom: 0px;">
                                                        <textarea  id="suppliesCurComment" class="form-control inputTheme textarea1"  
                                                                    matInput #suppliesCurComment placeholder="{{ 'WORK_TASK.taskrefComment' | translate }}"
                                                                    cdkTextareaAutosize
                                                                    #autosize="cdkTextareaAutosize"
                                                                    cdkAutosizeMinRows="1"
                                                                    cdkAutosizeMaxRows="3"
                                                                    [value]="suppliesCur.comment" 
                                                                    [readonly]="isReadOnly"
                                                                    (change)="changeTaskActionComment(suppliesCurComment.value, iRow,  i)"
                                                        ></textarea >
                                                    </td>
                                                </tr>
                                            </table>
                                        </span>
                                    </td>

                                    
                                    
                                    <td [attr.data-label]="dataLabelCur[1]" style="width: 15%; padding: 2px; text-align: left;">{{suppliesCur.brandRef}}</td>
                                    <td [attr.data-label]="dataLabelCur[2]" style="width: 10%; padding: 2px; text-align: center;">{{'WORK_TASK.' + suppliesCur.billingUnit | translate}}</td>
                                    <td [attr.data-label]="dataLabelCur[3]" style="width: 5%; padding: 2px; text-align: right;">
                                        {{suppliesCur.quantity  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                    </td>
                                    <td *ngIf="suppliesCur.actionTask !== undefined" [attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: right;">
                                                            {{'WORK_TASK.' + suppliesCur.actionTask | translate}}</td>
                                    <td *ngIf="suppliesCur.actionTask === undefined"[attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: right;">
                                                            {{'WORK_TASK.changed' | translate}}</td>
                                    <td [attr.data-label]="dataLabelCur[5]" style="width: 15%; padding: 2px; float: right!important;" >
                                        <div *ngIf="suppliesCur.actionTask ==='Check'" >
                                            <mat-radio-group class="taskAction-radio-group"  [disabled]="isReadOnly" #taskAction="matRadioGroup" aria-label="Select an option" labelPosition="before"
                                                    style="float: right!important;margin-top: 0px;margin-bottom: 0px;"
                                                    [value]="suppliesCur.actionTaken" 
                                                    (change)="changeAction(suppliesCur.actionTask,taskAction.value, i ,iRow)">
                                                <mat-radio-button class="radioButton" value="checked">{{ 'WORK_TASK.checked' | translate }}</mat-radio-button>
                                                <mat-radio-button class="radioButton" value="changed">{{ 'WORK_TASK.changed' | translate }}</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div *ngIf="suppliesCur.actionTask==='Exchange'  || suppliesCur.actionTask==='ExchFirst'" >
                                            <mat-radio-group class="taskAction-radio-group" [disabled]="isReadOnly" #taskAction="matRadioGroup"  aria-label="Select an option" labelPosition="before"
                                                    style="float: right!important;margin-top: 0px;margin-bottom: 0px;"
                                                    [value]="suppliesCur.actionTaken" 
                                                    (change)="changeAction(suppliesCur.actionTask,taskAction.value, i ,iRow)">
                                                <mat-radio-button class="radioButton" value="not-changed">{{ 'WORK_TASK.not_changed' | translate }}</mat-radio-button>
                                                <mat-radio-button class="radioButton" value="changed">{{ 'WORK_TASK.changed' | translate }}</mat-radio-button>
                                            </mat-radio-group> 
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="taskActionsCur.additionalTasks !== undefined">
                    <div *ngFor="let additionalTaskCur of taskActionsCur.additionalTasks; let i = index" style="margin: 0px; margin-top: 0px;">

                        <div id="taskActionsCur_{{iRow}}_{{i}}" #taskActionsCur_{{iRow}}_{{i}} class="row rowLine" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
                            <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
                                <button mat-icon-button
                                    (click)="additionalTaskCur.isCollapsed = !additionalTaskCur.isCollapsed"
                                    [attr.aria-expanded]="!additionalTaskCur.isCollapsed" aria-controls="collapseExample">
                                        <span *ngIf="!additionalTaskCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                        <span *ngIf="additionalTaskCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                </button> 
                            </div>
                            <div class="col-lg-9 col-xs-9 labelLine" style="margin-top: 10px;" >  
                                <p style="margin: 0px;"><b>{{ additionalTaskCur.taskDescription }}</b> 
                                    <a *ngIf="additionalTaskCur.taskDuration"> ({{ additionalTaskCur.taskDuration }} h) </a>              
                                </p>
                            </div>
                            <div class="col-lg-2 col-xs-2" style="padding-right: 0px;padding-left: 0px;">
                                <button *ngIf="!isReadOnly" mat-icon-button matSuffix aria-label="add" 
                                        (click)="addAdditionalTasks(iRow)">
                                    <mat-icon>add</mat-icon>
                                </button>
        
                                <button *ngIf="!isReadOnly"  mat-icon-button matSuffix
                                        (click)="removeAdditionalTasks(iRow, i)">
                                        <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
        
                        </div>
                        <div  [ngbCollapse]="!additionalTaskCur.isCollapsed">
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-10 col-xs-10" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'WORK_TASK.taskDescription' | translate }}</mat-label>
                                        <input id="taskDescription" #taskDescription  class="form-control inputTheme" 
                                                placeholder="{{ 'WORK_TASK.taskDescription' | translate }}"
                                                [value]="additionalTaskCur.taskDescription"
                                                matInput (keyup)="onChangeAdditionalTask('description', taskDescription.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-xs-2" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="wksInput form-group" appearance="standard" >
                                        <mat-label >{{ 'WORK_TASK.taskDuration' | translate }}</mat-label>
                                        <input id="taskDuration" #taskDuration  class="form-control inputTheme" 
                                                placeholder="{{ 'WORK_TASK.taskDuration' | translate }}"
                                                [value]="additionalTaskCur.taskDuration"
                                                matInput (keyup)="onChangeAdditionalTask('duration', taskDuration.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                        >
                                    </mat-form-field>
                                </div>
                            </div> 
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-12 col-xs-12" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'WORK_TASK.taskParts' | translate }}</mat-label>
                                        <textarea  id="taskParts" class="form-control inputTheme textarea1" 
                                                    [readonly]="isReadOnly"
                                                    matInput #taskParts placeholder="{{ 'WORK_TASK.taskParts' | translate }}"
                                                    [value]="additionalTaskCur.taskParts"
                                                    cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="2"
                                                    cdkAutosizeMaxRows="4"
                                                    (keyup)="onChangeAdditionalTask('parts', taskParts.value, iRow, i)"
                                        ></textarea >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-12 col-xs-12" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'WORK_TASK.taskComment' | translate }}</mat-label>
                                        <textarea  id="taskComment" class="form-control inputTheme textarea1" 
                                                    [readonly]="isReadOnly"
                                                    matInput #taskComment placeholder="{{ 'WORK_TASK.taskComment' | translate }}"
                                                    [value]="additionalTaskCur.taskComment"
                                                    cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="2"
                                                    cdkAutosizeMaxRows="4"
                                                    (keyup)="onChangeAdditionalTask('comment', taskComment.value, iRow, i)"
                                        ></textarea >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
