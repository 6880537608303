

<div class="container-fluid" >
    <div *ngIf="!planningLoaded">
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="spinner-container  stdTheme col"  fxLayout="row" fxLayoutAlign="space-around center">
                <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
            </div>
        </div>
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="stdTheme col"  style="text-align: center;">
                <span > 
                    {{ 'PLANNING.LOADING' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="planningLoaded">
        <app-calendar style="padding: 0px; margin-left: 0px; margin-right: 0px;width: 100%;"
            [applicationCall]="'appointmentWork'"
            [localelang]="localelang"
            [localeDateFmt]="localeDateFmt"
            [dateTimeFormat]="dateTimeFormat"
            [events]="eventsList"
            [userCur]="userCur"
            [calendarEvents]="wksEntityParams.calendarEvents"
            [workingTime]="workingTime"
            (eventClicked)="eventClicked($event)"
            (todoRefresh)="refreshEvents($event)"
        ></app-calendar>
    </div>
</div>
