import { Component, OnChanges,  EventEmitter, Output, Input, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { AppointmentRequest } from '../../../../models';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['../../../../shared.less', './confirmation.component.less']
})
export class ConfirmationComponent implements OnChanges {
  @Input()
  appointmentRequest!: AppointmentRequest;
  @Input()
  isRegistred: boolean;
  @Output()
  nextStepEvent = new EventEmitter<number>();
  appRef: string;
  stringInCode: string;
  observeData: any;

  constructor(private translate: TranslateService) { }


  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isRegistred': {
            // tslint:disable-next-line:no-string-literal
            this.isRegistred = changes['isRegistred'].currentValue;
            if (this.isRegistred) {
              if (this.appointmentRequest !== undefined) {
                const idRef = this.appointmentRequest.id.split('-');
                this.appRef = idRef[1].toUpperCase() + '-' + idRef[2].toUpperCase();
                // https://stackblitz.com/github/vitamindev/ngx-translate-parameters?file=src%2Fapp%2Fapp.component.ts
                this.stringInCode = this.translate.instant('CONFIRMATION.FIRST_PARAGRAPH', {
                  param1: this.appRef,
                });
              }
            }
            break;
          }
        } // end switch
      } // end if
    }
  }
  onGoHome(): void {
    this.nextStepEvent.emit(-99);
  }
  getData(): void {

  }
}
