import { Component,  ChangeDetectionStrategy,  ViewChild,  TemplateRef, Input, OnChanges, ElementRef,
  SimpleChanges, NgZone, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef, HostListener} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { startOfDay,  endOfDay,  subDays,  addDays,  endOfMonth,  isSameDay,  isSameMonth,  addHours} from 'date-fns';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { GridTools } from '../../../../../components/common/tools/gridTools';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { CalendarService } from '../../../../../services/calendar.service';
import { CustomDateFormatter } from '../utils/custom-date-formatter.provider';
import { CalendarEventAction,  CalendarEventTimesChangedEvent,  CalendarDateFormatter,
        CalendarMonthViewBeforeRenderEvent,  CalendarWeekViewBeforeRenderEvent,
        CalendarDayViewBeforeRenderEvent,  DAYS_OF_WEEK, CalendarUtils, CalendarView } from 'angular-calendar';
import { ValidateDrag } from 'angular-draggable-droppable';
import { PlacementArray } from 'positioning';
import { EventDef, CalendarEvt, ColorEvent, EventRh, EventChanged, WorkingTime } from '../../../../../models/shared.model';
import { ModalCommonComponent } from '../../../../../components/common/modal-common/modal-common.component';

import { WksEntityParams, WksEntity, WksEntityImg } from '../../../../../models/shared.model';
import { UserModel, AppointmentRequest, IhmOptions } from '../../../../../models';
import { SharingDataService } from '../../../../../services/sharing-data.service';
import { EnumReflection } from '../../../../common/tools/EnumReflection';
import { AppointmentService } from '../../../../../services/appointment.service';
import { WorkCardComponent } from '../../work-card/work-card.component';
import { WorkChecklistComponent } from '../../work-checklist/work-checklist.component';
import { VerificationCkecklistComponent } from '../../work-checklist/verification-ckecklist/verification-ckecklist.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.less'],
  providers: [
    NgbModalConfig, NgbModal,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnChanges {

  @Input() applicationCall: string;
  @Input() localelang: string;
  @Input() localeDateFmt: string;
  @Input() dateTimeFormat: string;
  @Input() events: CalendarEvt[];
  @Input() calendarEvents: EventDef[];
  @Input() eventsRh: EventRh[];
  @Input() workingTime: WorkingTime;
  @Input() userCur: UserModel;
  @Output() todoRefresh: EventEmitter<string> = new EventEmitter();

  appointmentRequest: AppointmentRequest;
  appointmentIsLoaded: boolean;
/*
  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvt;
    sourceEvent: MouseEvent | any;
  }>();
*/
  @Output() eventClicked = new EventEmitter<EventChanged>();

  @Output() highlightDay = new EventEmitter<{event: CalendarEvt; } >();
  @Output() unhighlightDay = new EventEmitter<{event: CalendarEvt; } >();

  @ViewChild('modalDetailEvent', {read: TemplateRef, static: false}) modalDetailEvent: TemplateRef<any>;
  @ViewChild('ngbModalDetailEvent', {read: TemplateRef, static: false}) ngbModalDetailEvent: TemplateRef<any>;
  @ViewChild('modalAppointment', {read: TemplateRef, static: false}) modalAppointment: TemplateRef<any>;
  @ViewChild('modalChecklist', {read: TemplateRef, static: false}) modalChecklist: TemplateRef<any>;

  @ViewChild('templateDetailEventForm', {read: TemplateRef, static: false}) templateDetailEventForm: TemplateRef<any>;
  @ViewChild('eventDescription', {read: TemplateRef, static: false}) eventDescription: CdkTextareaAutosize;
  @ViewChild('eventComment', {read: TemplateRef, static: false}) eventComment: CdkTextareaAutosize;

  wksLogoHome: WksEntityImg;

  refresh: Subject<any> = new Subject();

  tooltipPlacement: PlacementArray = 'auto';
  tooltipDelay: number | null = null;
  tooltipAppendToBody: true;
  tooltipTemplate: TemplateRef<any>;
  validateDrag: ValidateDrag;
  calendarUtils: CalendarUtils;

  private readonly onDestroy = new Subject<void>();

  detailEventForm: FormGroup;
  timeIsValid: boolean;
  segmentTimesMap = new Map();

  daysWeek: number[];
  hourStart: number;
  hourEnd: number;
  dayHours: string[];
  minInputDate: Date;
  maxInputDate: Date;
  now = new Date();
  yearCur = this.now.getFullYear();
  monthCur = this.now.getMonth();
  dayCur = this.now.getDay();
  // validateStartTime: any;
  // validateEndTime: any;
  viewCur: CalendarView = CalendarView.Week;
  viewHeader: string = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData: {
     action: string;
     event: CalendarEvt;
  };
  modalRef: BsModalRef;
  configModal = {
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    size: 'sm'
  };
  displayRequest: boolean;
  displayEvent: boolean;
  displayChecklist: boolean;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  actions: CalendarEventAction[];
  actionsNew: CalendarEventAction[];
  actionsLock: CalendarEventAction[];
  listViewCalendar = EnumReflection.getNames(CalendarView);

  eventCur: CalendarEvt;
  activeDayIsOpen: boolean;
  locale: string;
  optionsRouter: any;
  dateFmt: string;
  addEventOK: boolean;
  updateEventOK: boolean;
  deleteEventOK: boolean;
  isAppointmentCustomer: boolean;

  eventsOfDay: CalendarEvt[];

  // scrHeight: string;
  // scrWidth: string;
  sizeWidth: number;
  sizeHeight: number;
  contentMaxHeight: string;
  actionMargin: string;
  cardMaxHeight: string;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
        // this.scrHeight = window.innerHeight + 'px';
        // this.scrWidth = window.innerWidth + 'px';
        this.sizeWidth = window.innerWidth;
        this.sizeHeight = window.innerHeight;
        const maxHeight = window.innerHeight - 220;
        this.contentMaxHeight = maxHeight + 'px!important';
        this.actionMargin = maxHeight + 10 + 'px!important';
        this.cardMaxHeight = this.sizeHeight - 50 + 'px!important';
  }
  constructor(private sharingDataService: SharingDataService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private translateService: TranslateService,
              private appointmentService: AppointmentService,
              private adapterDate: DateAdapter<any>,
              private ngZone: NgZone,
              private dialog: MatDialog,
              private calendarService: CalendarService,
              private changeDetectorRef: ChangeDetectorRef,
              private configNgbModal: NgbModalConfig,
              private ngbModalService: NgbModal) {
    this.getScreenSize();

    // console.log('Window size ' + this.scrHeight, this.scrWidth);
   }

   ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'applicationCall': {
            // tslint:disable-next-line:no-string-literal
            this.applicationCall = changes['applicationCall'].currentValue;
            break;
          }
          // userCur
          case 'userCur': {
            // tslint:disable-next-line:no-string-literal
            this.userCur = changes['userCur'].currentValue;
            break;
          }
          case 'localeDateFmt': {
            // tslint:disable-next-line:no-string-literal
            this.localeDateFmt = changes['localeDateFmt'].currentValue;
            break;
          }
          case 'localelang': {
            // tslint:disable-next-line:no-string-literal
            this.localelang = changes['localelang'].currentValue;
            break;
          }
          case 'events': {
            // tslint:disable-next-line:no-string-literal
            this.events = changes['events'].currentValue;
            if ((this.events !== undefined) && (this.events.length > 0)) {
              this.segmentTimesStorage();
              this.addActionsAndColors();
            }
            break;
          }
          // workingTime
          case 'workingTime': {
            // tslint:disable-next-line:no-string-literal
            this.workingTime = changes['workingTime'].currentValue;
            break;
          }
          case 'dateTimeFormat': {
            // tslint:disable-next-line:no-string-literal
            this.dateTimeFormat = changes['dateTimeFormat'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.displayRequest = false;
    this.displayEvent = false;
    this.weekStartsOn = this.workingTime.weekStartsOn;
    this.weekendDays = this.workingTime.weekendDays;
    this.isAppointmentCustomer = false;
    this.wksLogoHome = this.sharingDataService.getWksEntityImg();
    this.addEventOK = false;
    this.updateEventOK = false;
    this.deleteEventOK = false;
    // this.dayHours = ['8:00', '18:00'];
    this.dayHours = this.workingTime.dayHours;

    this.settingHoursAndDays();

    this.timeIsValid = true;
    this.activeDayIsOpen = false;
    this.daysWeek = [0, 6];
    this.minInputDate = new Date();  // moment({year: this.yearCur, month: this.monthCur , day: this.dayCur});
    this.maxInputDate = new Date();
    this.maxInputDate.setDate(60);
    this.adapterDate.setLocale(this.localeDateFmt);
    this.locale = this.localelang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      // TODO This as a workaround.
      this.changeDetectorRef.detectChanges();
    });
  }
  settingHoursAndDays(): void {
    let timeHour = this.dayHours[0].split(':')[0];
    this.hourStart = parseInt(timeHour, 10);
    timeHour = this.dayHours[1].split(':')[0];
    this.hourEnd = parseInt(timeHour, 10);
    this.daysWeek =  [];
    for (let dayNum = 0; dayNum < 7; dayNum++) {
      let isFound = false;
      for (const dayNumCur of this.weekendDays) {
        if (dayNumCur === dayNum) {
          isFound = true;
        }
      }
      if ( !isFound) {
        this.daysWeek.push(dayNum);
      }
    }
  }
  viewChange($event: string): void {
    if ($event === 'month') {
      this.viewCur = CalendarView.Month;
      this.viewHeader = 'month';
    }
    if ($event === 'week') {
      this.viewCur = CalendarView.Week;
      this.viewHeader = 'week';
    }
    if ($event === 'day') {
      this.viewCur = CalendarView.Day;
      this.viewHeader = 'day';
    }
  }
  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
        .subscribe(() => this.eventDescription.resizeToFitContent(true));
  }
  getColorType(typeCur: string): any {
    const colorCur: ColorEvent = {
      primary: '',
      secondary: '',
    };
    for (const calEvent of this.calendarEvents) {
      if (calEvent.type === typeCur) {
        colorCur.primary = calEvent.primary;
        colorCur.secondary = calEvent.secondary;
        break;
      }
    }
    return colorCur;
  }
  addActionsAndColors(): void {
    let brString = '';
    if (this.sizeWidth < 500)  {
      brString = '<br>';
    }
    this.actions = [
      /*
      {
        label: '<i class="fa fa-fw fa-pencil-alt iconActionMargin" data-toggle="tooltip" title="Edit Mode"></i>' + brString,
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('Edited', event);
        }
      },*/
      {
        label: '<i class="fas fa-info-circle iconActionMargin" data-toggle="tooltip" title="Informations" ></i>' + brString,
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('displayWork', event);
        }
      },
      {
        // <i class="fa-solid fa-list-check"></i>
        // <i class="fas fa-tasks
        label: '<i class="fas fa-tasks iconActionMargin" data-toggle="tooltip" title="Tasks"></i>' + brString,
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('Tasks', event);
        }
      },
      {
        label: '<i class="fa fa-lock iconActionMargin" data-toggle="tooltip" title="Finished"></i>' + brString,
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('Finished', event);
        }
      }
    ];
    this.actionsNew = [
      {
        label: '<i class="fa fa-fw fa-pencil-alt" data-toggle="tooltip" title="Edit Mode"></i>',
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('Added', event);
        }
      }
    ];
    this.actionsLock = [
      {
        label: '<i class="fa fa-lock" data-toggle="tooltip" title="Finished"></i>',
        onClick: ({ event }: { event: CalendarEvt }): void => {
          this.handleEvent('Finished', event);
        }
      }
    ];


    for (const eventCur of this.events) {
      eventCur.actions = (this.applicationCall === eventCur.calType
                            && (this.applicationCall !== 'rhPlanning' ) ? this.actions : undefined);
      eventCur.color = this.getColorType(eventCur.calType);
    }
  }
  segmentTimesStorage(): void {
    this.segmentTimesMap = new Map();
    for (const eventCur of this.events) {
      this.updateSegmentTimes(eventCur);
    }
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      const dayOfMonth = day.date.getDate();
      day.cssClass = 'localTheme';
      if (day.isToday) {
        // todayLoc
        day.cssClass = 'todayLoc';
      }
    });
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent): void {
    renderEvent.hourColumns.forEach((hourColumn) => {
      if (this.isToday(hourColumn.date.getDate(), hourColumn.date.getMonth(), hourColumn.date.getFullYear()) ) {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
              segment.cssClass = 'todayLoc';
          });
        });
      } else {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
              segment.cssClass = 'localTheme';
          });
        });
      }
    });
  }

  beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent): void {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
            segment.cssClass = 'localTheme';
        });
      });
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvt[] }): void {
    this.eventsOfDay = events;
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      // if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
        this.activeDayIsOpen = false;
      } else {
        if (events.length > 0) {
          this.activeDayIsOpen = true;
        }
        /*if ((events.length === 0) || (events[events.length - 1].title === 'New event')) {
          this.addEvent(date);
        }*/
      }
    }
  }
  hourSegmentClicked($event: any): void {
    // console.log('hourSegmentClicked : ' + date );
    if (isSameMonth($event.date, this.viewDate)) {
      this.viewDate = $event.date;
      if (
        (isSameDay(this.viewDate, $event.date) && this.activeDayIsOpen === true) ||
        $event.events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  isToday(day: any, month: any, year: any): boolean {
    const today = new Date();
    return day === today.getDate() &&
      month === today.getMonth() &&
      year === today.getFullYear();
  }
  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    const eventTmp = JSON.stringify(event);
    const eventCur = JSON.parse(eventTmp);
    if (eventCur.calType === 'appointmentCustomer') {
      event.start = newStart;
      event.end = newEnd;
      this.handleEvent('TimeChanged', event);
    }
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvt): void {
    this.modalData = { event, action };
    this.eventCur = event;

    event.action = action.toLowerCase();
    if (action === 'Edited') {
      // this.dataUpdate.emit(event);
      this.buildEventDetailForm('edit');
      this.fillFormEvent();
      this.openModalEvent();
      this.addEventOK = false;
      this.updateEventOK = true;
      this.deleteEventOK = false;
    }
    if (action === 'AppointmentRequest') {
      // this.dataUpdate.emit(event);
      this.doLoadAppointment(action);

    }
    if (action === 'Finished') {
      this.openCheckListVerification(event.linkId);
    }
    if (action === 'displayWork') {
      // this.dataUpdate.emit(event);
      this.openWorkDisplay(event.linkId);
    }
    if (action === 'Tasks') {
      // this.doLoadAppointment(action);
      this.openCheckListDisplay(event.linkId);
    }
    if (action === 'Deleted') {
      const idxRow = event.id as number;
      this.addEventOK = false;
      this.updateEventOK = false;
      this.deleteEventOK = true;
      // this.events.splice(idxRow, 1);
      this.refresh.next();
      event.bddAction = 'deleted';
      // this.dataUpdate.emit(event);
      this.confirmDeleteEvent(event, idxRow);
    }
    if (action === 'TimeChanged') {
      const idxRow = event.id as number;
      this.addEventOK = false;
      this.updateEventOK = true;
      this.deleteEventOK = false;
      this.refresh.next();
      event.bddAction = 'changed';

      this.confirmUpdateEvent(event);
    }
    if (action === 'Clicked') {
      // console.log(event);
    }

  }
  confirmDeleteEvent(eventCur: CalendarEvt, idxRow: number): void {
    const titleBox = this.translateService.instant('appointmentDelete');
    let messageBox;
    let data1;
    let data2;

    messageBox = this.translateService.instant('referencedRequest') + eventCur.ref + ' ' +
                this.translateService.instant('scheduledFor') + ' ' +
                moment(eventCur.start).format(this.dateTimeFormat)  +
                this.translateService.instant('appointmentSeparator') +
                moment(eventCur.end).format(this.dateTimeFormat) + ' ';

    data1 = idxRow.toString();
    data2 = undefined;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'deleted', eventCur, data1, data2);
  }
  confirmUpdateEvent(eventCur: CalendarEvt): void {
    const titleBox = this.translateService.instant('CALENDAR_EVENT.appointmentChangeTime');
    let messageBox;
    let data1;
    let data2;

    const time1 = moment(eventCur.oldTime.start).format(this.dateTimeFormat) + ' ' +
                  moment(eventCur.oldTime.end).format(this.dateTimeFormat) ;
    const time2 = moment(eventCur.start).format(this.dateTimeFormat) + ' ' +
                  moment(eventCur.end).format(this.dateTimeFormat);
    if (time1 === time2)  {
      return;
    }
    messageBox =  this.translateService.instant('CALENDAR_EVENT.appointmentimetable');

    data1 =  this.translateService.instant('CALENDAR_EVENT.appointmentOldTime') + ' ' +
                   moment(eventCur.oldTime.start).format(this.dateTimeFormat) +
                   this.translateService.instant('CALENDAR_EVENT.appointmentSeparator') +
                   moment(eventCur.oldTime.end).format(this.dateTimeFormat) + ' ' ;

    data2 =  this.translateService.instant('CALENDAR_EVENT.appointmentNewTime') + ' ' +
                  moment(eventCur.start).format(this.dateTimeFormat)  +
                  this.translateService.instant('CALENDAR_EVENT.appointmentSeparator') +
                  moment(eventCur.end).format(this.dateTimeFormat) + ' ';

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'TimeChanged', eventCur, data1, data2);
  }
  updateSegmentTimes(eventCur: CalendarEvt): void {
    const dateStartTime: any = moment(eventCur.start).format('HH:mm').split(':');
    const dateEndTime: any = moment(eventCur.end).format('HH:mm').split(':');
    // console.log( 'dateStartTime : ' + dateStartTime + ' dateEndTime : ' + dateEndTime);
    const dateCur: any = moment(eventCur.start).format('YYYY-MM-DD') ;
    // console.log( 'dateCur : ' + dateCur +  ' dateStartTime : ' + dateStartTime + ' dateEndTime : ' + dateEndTime);
    const startHour = Number(dateStartTime[0]);
    const startMinutes = Number(dateStartTime[1]);
    const endHour = Number(dateEndTime[0]);
    const endMinutes = Number(dateEndTime[1]);

    let hours = startHour - 1 ;
    const minutes = startMinutes;
    let minutesEnd = 60;
    do {
      hours += 1;
      if (hours === endHour) {
        minutesEnd = endMinutes + 5;
      }
      const formattedHour = ('0' + hours).slice(-2);
      // console.log(formattedHour);
      for ( let minuteCur = minutes; minuteCur < minutesEnd ; minuteCur += 5) {
        const formattedTime = dateCur + ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minuteCur).slice(-2);
        this.segmentTimesMap.set(formattedTime, eventCur.ref);
        // console.log(formattedTime);
      }
      if (hours === endHour) {
        break;
      }
    } while (hours < endHour + 1);
  }

  changeTime(option: string, $event: any): void {
    // console.log('changeTime : ' + $event);

    if (option === 'start') {
      const calDuration = moment.duration(
        moment(new Date(this.eventCur.start)).diff(moment(new Date(this.eventCur.end)))
      ).asMinutes() * -1;
      const dateStart = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD') + ' ' + $event ;
      const newEndDate = moment(dateStart).add(calDuration, 'minutes') ;
      // const newEndTime = moment(newEndDate).format('HH:mm') ;
      // this.detailEventForm.controls.eventEndDate.setValue(newEndDate);
      // this.detailEventForm.controls.eventEndTime.setValue(newEndTime);
      const hourCtrl = this.validHour(newEndDate);
      const hourCur = moment(hourCtrl).format('HH:mm');
      this.detailEventForm.controls.eventEndDate.setValue(moment(newEndDate).toDate());
      // this.detailEventForm.controls.eventEndTime.setValue(moment(newEndDate).format('HH:mm'));
      this.detailEventForm.controls.eventEndTime.setValue(hourCur);
    }
  }
  onChangeTitle(option: string, $event: any): void {

    if (option === 'appointmentCustomer') {
      return;
      /// console.log($event);
    }
    if (option === 'rhPlanning') {
      for (const eventRhCur of this.eventsRh) {
        if (eventRhCur.label === $event)  {
          this.detailEventForm.controls.eventType.setValue(eventRhCur.type);
          break;
        }
      }
    }

  }
  validTimeInput(timeOpt: string, $event: any, displayError: boolean): void {
    let dateInput;
    if (timeOpt === 'start' ) {
      dateInput = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
    } else {
      dateInput = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
    }

    // const dateCur: any = moment(dateInput).format('YYYY-MM-DD') ;
    const hourDate = dateInput + ' ' + $event;
    this.timeIsValid = true;
    if (!this.compareSegmentHours(this.eventCur.ref, hourDate)) {
      this.timeIsValid = false;
      if (timeOpt === 'start' && displayError ) {
        this.errorMessage('startTime' );
      }
      if (timeOpt === 'end' && displayError ) {
        this.errorMessage('endTime' );
      }
    }
  }
  compareSegmentHours(refAppointment: string, hourDate: any): boolean {
    let isValid = false;
    const exitsHour = this.segmentTimesMap.has(hourDate);
    if (exitsHour) {
      const ref = this.segmentTimesMap.get(hourDate);
      if (ref === refAppointment ) {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  }
  fillFormEvent(): void {

    if ( this.applicationCall === 'appointmentCustomer' ) {
      this.isAppointmentCustomer = true;
    }

    this.detailEventForm.controls.eventType.setValue(this.translateService.instant('CALENDAR_EVENT.' + this.applicationCall));

    if (this.eventCur.title !== 'New event') {
      this.detailEventForm.controls.eventTitle.setValue(this.eventCur.title);
    } else {
      this.detailEventForm.controls.eventTitle.setValue('');
    }

    this.detailEventForm.controls.eventDescription.setValue(this.eventCur.description);
    this.detailEventForm.controls.eventComment.setValue(this.eventCur.comment);
    this.detailEventForm.controls.eventOutside.setValue(this.eventCur.outside);
    this.detailEventForm.controls.eventStartDate.setValue(this.eventCur.start);
    this.detailEventForm.controls.eventEndDate.setValue(this.eventCur.end);

    let validateStartTime: any = moment(this.eventCur.start).format('HH:mm');
    validateStartTime = this.validHour(validateStartTime);
    let validateEndTime: any = moment(this.eventCur.end).format('HH:mm');
    validateEndTime = this.validHour( validateEndTime);

    this.detailEventForm.controls.eventStartTime.setValue(validateStartTime.format('HH:mm'));
    this.detailEventForm.controls.eventEndTime.setValue(validateEndTime.format('HH:mm'));
  }
  fillModelEvent(): void {
    const calObject = this.calendarService.getCalObject();
    let startDate = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
    startDate += ' ' + this.detailEventForm.controls.eventStartTime.value;
    let endDate = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
    endDate += ' ' + this.detailEventForm.controls.eventEndTime.value;
    const idxRow = this.eventCur.id as number;
    let refCur = '';
    let linkIdCur = '';
    if (this.applicationCall === 'appointmentCustomer') {
      if (calObject && calObject.id ) {
        linkIdCur = calObject.id;
        refCur =  (this.applicationCall === 'appointmentCustomer') ? calObject.appointmentRef : '';
      } else {
        linkIdCur = this.eventCur.linkId;
        refCur = this.eventCur.ref;
      }

    }
    if (this.applicationCall === 'rhPlanning') {
      refCur = 'HR';
    }
    let bddActionCur = 'add';
    if (this.updateEventOK) {
      bddActionCur = 'updated';
    }
    let modifOption = false;
    if ((this.eventCur.calStatut === '') ||
      (this.eventCur.calStatut === null) ||
      (this.eventCur.calStatut === 'WAITING')) {
      modifOption = true;
    }

    const eventModel = {
      id: this.eventCur.bddId,
      start: new Date(startDate),
      end:  new Date(endDate),
      title: this.detailEventForm.controls.eventTitle.value,
      color: this.getColorType(this.applicationCall),
      actions: (this.applicationCall === this.eventCur.calType ? this.actions : undefined),
      draggable: modifOption,
      resizable: {
        beforeStart: modifOption,
        afterEnd: modifOption
      },
      allDay: false,
      linkId: linkIdCur,
      description: this.detailEventForm.controls.eventDescription.value,
      comment: this.detailEventForm.controls.eventComment.value,
      calType: this.eventCur.calType,
      calStatut: (this.eventCur.calStatut === '' ? 'WAITING' : this.eventCur.calStatut),
      outside: this.detailEventForm.controls.eventOutside.value,
      ref: refCur,
      stdCreationDate: this.eventCur.stdCreationDate,
      stdCreationUser: this.eventCur.stdCreationUser,
      bddAction: bddActionCur
    };
    // this.dataOut.emit(eventModel);
    this.eventCur = eventModel;
    this.activeDayIsOpen = true;
    if (!this.addEventOK) {
      this.events.splice(idxRow, 1);
    }
    this.events.push(this.eventCur);
    this.refresh.next();
    this.calendarService.setCalObject(undefined);
  }

  buildEventDetailForm(statut: string): void {
    // const isReadOnly = (statut === 'read' ? true : false);
    const isReadOnly = true;
    this.detailEventForm = this.fb.group({
      eventType: this.fb.control({value: '', disabled: isReadOnly}),
      eventTitle: this.fb.control({value: '', disabled: isReadOnly}),
      eventDescription: this.fb.control({value: '', disabled: false}),
      eventComment: this.fb.control({value: '', disabled: false}),
      eventOutside: this.fb.control({value: '', disabled: isReadOnly}),
      eventStartDate: this.fb.control({value: '', disabled: false}),
      eventStartTime: this.fb.control({value: '', disabled: false}),
      eventEndDate: this.fb.control({value: '', disabled: false}),
      eventEndTime: this.fb.control({value: '', disabled: false}),
    });

  }

  openWorkDisplay(workId: string): void {
    this.displayModalWork(workId);
  }
  openCheckListDisplay(workId: string): void {
    this.displayModalCheckList(workId);
  }
  openCheckListVerification(workId: string): void {
    this.displayModalVerification(workId);
  }
  openModalEvent(): void {

    this.displayRequest = false;
    this.displayChecklist = false;
    if (this.sizeWidth < 800) {
      this.displayEvent = true;
      return;
    }
    this.modalRef = this.modalService.show(this.modalDetailEvent, this.configModal);
  }
  openModalAppointmentRequest(): void {

    this.displayEvent = false;
    this.displayChecklist = false;
    if (this.sizeWidth < 800) {
      this.displayRequest = true;
      return;
    }
    this.modalRef = this.modalService.show(this.modalAppointment, this.configModal);
  }
  openModalChecklist(): void {

    this.displayEvent = false;
    this.displayRequest = false;
    if (this.sizeWidth < 800) {
      this.displayChecklist = true;
      return;
    }
    this.modalRef = this.modalService.show(this.modalChecklist, this.configModal);
  }
  closeModal(status: string): void {
    if (this.displayRequest) {
      this.displayRequest = false;
      this.activeDayIsOpen = false;
      if ((this.eventsOfDay) && (this.eventsOfDay.length > 0)) {
        this.activeDayIsOpen = true;
      }
      return;
    }
    if (this.displayChecklist) {
      this.displayChecklist = false;
      this.activeDayIsOpen = false;
      if ((this.eventsOfDay) && (this.eventsOfDay.length > 0)) {
        this.activeDayIsOpen = true;
      }
      return;
    }
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
    if (status === 'validate') {
      this.activeDayIsOpen = true;
    }
    if (status === 'canceled') {
      if (this.eventCur.title === 'New event') {
        this.eventCur = undefined;
      }
      this.activeDayIsOpen = false;
      if ((this.eventsOfDay) && (this.eventsOfDay.length > 0)) {
        this.activeDayIsOpen = true;
      }
    }
    this.displayEvent = false;
  }

  filterDate = (d: Date): boolean => {
    // console.log('Filter is called');
    const day = moment(d).day();
    // const day = d.getDay();
    // return day !== this.daysWeek[0] && day !== this.daysWeek[1];
    return day !== this.weekendDays[0] && day !== this.weekendDays[1];
    // return true;
    // 0 means sunday
    // 6 means saturday
  }
  validStatutDate(event: MatDatepickerInputEvent<Date>, fieldName: string): void {

    const dateInput = moment(event.value);
    const okDate = dateInput.isBetween(this.minInputDate, this.maxInputDate);

    if (okDate) {
      if (fieldName === 'eventStartDate') {
        const dateStartTime: any = moment(event.value).format('YYYY-MM-DD') + ' ' + moment(this.eventCur.start).format('HH:mm');
        const calDuration = moment.duration(
          moment(new Date(this.eventCur.start)).diff(moment(new Date(this.eventCur.end)))
        ).asMinutes() * -1;
        const newEndDate = moment(dateStartTime).add(calDuration, 'minutes') ;
        const newEnd = new Date(newEndDate.toString());
        const newStart = new Date(dateStartTime.toString());

        this.detailEventForm.controls.eventStartDate.setValue(newStart);
        this.detailEventForm.controls.eventEndDate.setValue(newEnd);
        this.detailEventForm.controls.eventEndTime.setValue(moment(newEndDate).format('HH:mm'));
      }
    } else {
      this.detailEventForm.controls[fieldName].setValue('');
    }

  }
  validHour(hourOrig: any): any {
    let hourRef = moment(hourOrig, 'HH:mm');

    if (hourRef.isBefore(moment(this.dayHours[0], 'HH:mm'))) {
      // hourRef.set('hours', this.dayHours[0]);
      hourRef =  moment(this.dayHours[0], 'HH:mm');
    }
    if (hourRef.isAfter(moment(this.dayHours[1], 'HH:mm'))) {
      // hourRef.set('hours', this.dayHours[1]);
      hourRef =  moment(this.dayHours[1], 'HH:mm');
    }
    return hourRef;
  }
  validHourEnd(hourEnd: any, hourStart: any, intervalMini: number): any {
    const hourRef =  moment(hourStart);
    if (intervalMini !== undefined)  {
      hourRef.add(intervalMini, 'hours');
    }
    if (hourEnd.before(hourRef)) {
      hourEnd.set('hours', hourRef);
    }
    return hourEnd;
  }
  getDateTimeToString(valueArg: string): string {
    return GridTools.dateJson1(valueArg, this.dateTimeFormat);
  }
  getEventItem2(itemCur: CalendarEvt): string {
    let currentHTML = '';
    let curTitle;
    if ((this.applicationCall === 'rhPlanning') || (itemCur.calType === 'rhPlanning')) {
      curTitle = this.translateService.instant('CALENDAR_EVENT.' + itemCur.title);
    }
    if (curTitle === undefined) {
      curTitle = itemCur.title;
    }
    if (itemCur.ref !== undefined) {
      curTitle = itemCur.ref + ' ' + curTitle;
    }

    currentHTML += curTitle ;
    return currentHTML;
  }
  getEventItem(itemCur: CalendarEvt): string {
    let currentHTML: string;
    let curTitle;
    if ((this.applicationCall === 'rhPlanning') || (itemCur.calType === 'rhPlanning')) {
      curTitle = this.translateService.instant('CALENDAR_EVENT.' + itemCur.title);
    }
    if (curTitle === undefined) {
      curTitle = itemCur.title;
    }
    if (itemCur.ref !== undefined) {
      curTitle = itemCur.ref + ' ' + curTitle;
    }
    const startDate = moment(itemCur.start).format('YYYY-MM-DD HH:mm');
    const endDate = moment(itemCur.end).format('YYYY-MM-DD HH:mm');
    currentHTML = this.getDateTimeToString(startDate) + ' ---> '
                  + this.getDateTimeToString(endDate) + ' ---> ';
    if (itemCur.outside) {
      currentHTML += itemCur.outside + ' ---> ';
    }
    currentHTML += curTitle ;
    return currentHTML;
  }
  refreshEvents($event: any): void {
    this.todoRefresh.emit('refreshEvents');
  }
  validEvent(): void {
    let startDate = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
    startDate += ' ' + this.detailEventForm.controls.eventStartTime.value;
    let endDate = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
    endDate += ' ' + this.detailEventForm.controls.eventEndTime.value;
    this.validTimeInput('start', new Date(startDate), true);
    if (this.timeIsValid) {
      this.validTimeInput('end', new Date(endDate), true);
      if (!this.timeIsValid) {
        // this.errorMessage('endTime' );
        return;
      }
    } else {
      // this.errorMessage('startTime' );
      return;
    }
    this.fillModelEvent();
    this.closeModal('validate');
    // this.events.push(this.eventCur);
    const eventCur = {
      eventCur: this.eventCur,
      action: 'TimeChanged',
    };
    this.eventClicked.emit(eventCur);

    this.refresh.next();
  }
  errorMessage(option: string): void {
    const titleBox = this.translateService.instant('CALENDAR-EVENT.appointmentTimeError');
    let messageBox;

    if (option === 'startTime') {
      messageBox =  this.translateService.instant('CALENDAR-EVENT.appointmentStartTimeError') ;
    }
    if (option === 'endTime') {
      messageBox =  this.translateService.instant('CALENDAR-EVENT.appointmentEndTimeError') ;
    }
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', undefined, undefined, undefined, undefined);
  }
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, event: CalendarEvt, data1Arg: string, data2Arg: string): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: titleBoxArg,
      typeDialog: typeDialogArg,
      panelClass: 'stdTheme',
      contentMessage: messageBoxArg,
      data1: data1Arg,
      data2: data2Arg,
      messageType: messageTypeArg,
      actionCur: actionCurArg
      };

    const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') && actionCurArg === 'TimeChanged' ) {
          const eventCur = {
            eventCur: event,
            action: 'TimeChanged',
          };
          this.eventClicked.emit(eventCur);
        }
        if (( data === 'okAction') && actionCurArg === 'deleted' ) {
          const eventCur = {
            eventCur: event,
            action: 'deleted',
          };
          this.events.splice(parseInt(data1Arg, 10), 1);
          this.refresh.next();
          this.eventClicked.emit(eventCur);
        }
      });

  }
  displayModalWork(workIdArg: string): void {
    const dialogConfig = new MatDialogConfig();
// https://material.angular.io/components/dialog/api
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['workPanelModal'];
    dialogConfig.data = {
      workId: workIdArg,
      title: ''
      };

    const dialogRef = this.dialog.open(WorkCardComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
      });

  }
  // VerificationCkecklistComponent
  displayModalVerification(workIdArg: string): void {
    const dialogConfig = new MatDialogConfig();

// https://material.angular.io/components/dialog/api
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['checkVerifPanelModal'];
    dialogConfig.data = {
      workId: workIdArg,
      ihmOptions: undefined,
      title: ''
      };

    const dialogRef = this.dialog.open(VerificationCkecklistComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
      });

  }
  displayModalCheckList(workIdArg: string): void {
    const dialogConfig = new MatDialogConfig();

// https://material.angular.io/components/dialog/api
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['checklistPanelModal'];
    dialogConfig.data = {
      workId: workIdArg,
      ihmOptions: undefined,
      title: ''
      };

    const dialogRef = this.dialog.open(WorkChecklistComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
      });

  }
  doLoadAppointment(action: string): void {
    this.loadAppointment(this.eventCur.linkId)
    .then (
      (responseParams: any ) => {
        this.appointmentRequest = responseParams;

        this.addEventOK = false;
        this.updateEventOK = false;
        this.deleteEventOK = false;
        if (action === 'AppointmentRequest') {
          this.openModalAppointmentRequest();
        }
        if (action === 'Tasks') {
          this.openModalChecklist();
        }
      },
      (err: any) => {
        console.log('loadAppointment' + err.message);
        if (err.status === 404) {
          return;
        }
      }
    );
  }
  loadAppointment(uuidRef: string): any {
    return new Promise((resolve, reject) => {
      this.appointmentService.getAppointment(uuidRef)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadAppointment' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }


}
