import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { buildMapFromList } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import moment from 'moment';
import { TranslateService  } from '@ngx-translate/core';

import { WksWorksModel, WksEquipmentModel, IhmOptions, DataLocationWorkModel, LabelValue, CountryDef,
        MotorsConfigModel, PropulsionsModel, ThrusterConfigModel, TrailersOptions} from '../../../../../models/';
import { SharingDataService } from '../../../../../services/sharing-data.service';
import { AuthService } from '../../../../../services/auth.service';

@Component({
  selector: 'app-work-equipments',
  templateUrl: './work-equipments.component.html',
  styleUrls: ['./work-equipments.component.less']
})
export class WorkEquipmentsComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() ihmOptions: IhmOptions;
  @Input() mainEquipment: WksEquipmentModel;
  @Input() equipmentCur: WksEquipmentModel;
  @Input() dateTimeFormat: string;
  @Input() dateFormatMoment: string;

  isBoat: boolean;
  isTrailer: boolean;
  isJet: boolean;
  isMainEquip: boolean;
  isReadOnly: boolean;
  isSingleMotor: boolean;
  isThusterMotor: boolean;
  isEngine: boolean;
  isMotorBoat: boolean;
  isMetric: boolean;
  isThrusterPossible: boolean;
  countryEntity: CountryDef;
  positionsMotorList: string[];

  equipmentForm: FormGroup;

  mooringData: DataLocationWorkModel;
  winteringData: DataLocationWorkModel;
  homeAnchorageData: DataLocationWorkModel;

  motorsConfigList: MotorsConfigModel[];
  propulsionsList: PropulsionsModel[];
  thrustersConfigList: ThrusterConfigModel[];
  trailerOptions: TrailersOptions;
  hitchesList: LabelValue[];
  hitchSizes: string[];
  axlesList: string[];
  tiresList: string[];
  rimsList: string[];
  nutsList: string[];

  constructor(private fb: FormBuilder,
              private sharingDataService: SharingDataService,
              private translateService: TranslateService,
              private authService: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            // tslint:disable-next-line:no-string-literal
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          // ihmOptions
          case 'ihmOptions': {
            // tslint:disable-next-line:no-string-literal
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          }
          case 'mainEquipment': {
            // tslint:disable-next-line:no-string-literal
            this.mainEquipment = changes['mainEquipment'].currentValue;
            break;
          }
          case 'equipmentCur': {
            // tslint:disable-next-line:no-string-literal
            this.equipmentCur = changes['equipmentCur'].currentValue;
            break;
          }
          case 'dateTimeFormat': {
            // tslint:disable-next-line:no-string-literal
            this.dateTimeFormat = changes['dateTimeFormat'].currentValue;
            break;
          }
          // dateFormatMoment
          case 'dateFormatMoment': {
            // tslint:disable-next-line:no-string-literal
            this.dateFormatMoment = changes['dateFormatMoment'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.initAllBooleans();
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }

    switch (this.equipmentCur.equipType) {
      case 'boats': {
        this.isBoat = true;
        this.isMainEquip = true;
        break;
      } // end case
      case 'jets': {
        this.isMainEquip = true;
        this.isJet = true;
        break;
      } // end case
      case 'trailer': {
        this.isMainEquip = true;
        this.isTrailer = true;
        break;
      } // end case
      case 'engine': {
        this.isEngine = true;
        break;
      } // end case
      case 'thusterMotor': {
        this.isThusterMotor = true;
        break;
      } // end case
    }
    this.initListParams();
    this.buildForm();
    this.fillForm();
  }
  initAllBooleans(): void {
    this.isReadOnly = false;
    this.isBoat = false;
    this.isMainEquip = false;
    this.isTrailer = false;
    this.isJet = false;
    this.isSingleMotor = false;
    this.isThusterMotor = false;
    this.isEngine = false;
    this.isMotorBoat = false;
    this.isMetric = false;
    this.isThrusterPossible = false;

  }
  initListParams(): void {
    this.countryEntity  = this.sharingDataService.getCountry(this.authService.getUserLogged().entitycountry);
    this.isMetric = (this.countryEntity.measurementUnit === 'METRIC');
    if ((this.isBoat) || (this.isEngine )) {
      this.isSingleMotor = true;
      this.motorsConfigList = [];
      this.propulsionsList = [];
      this.thrustersConfigList = [];
      this.motorsConfigList = this.sharingDataService.getMotorsconfigList();
      this.propulsionsList = this.sharingDataService.getPropulsionsList();
      this.thrustersConfigList = this.sharingDataService.getThrustersconfigList();
    }
  }

  buildForm(): void {
    this.equipmentForm = this.fb.group({
      equipName: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipDesign: this.fb.control({value: '', disabled: this.isReadOnly}),
      commissioningDate: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipBrand: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipModel: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipYear: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipCountry: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipImmat: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipSN: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipBoatManeuvring: this.fb.control({value: '', disabled: this.isReadOnly}),
      propulsion: this.fb.control({value: '', disabled: this.isReadOnly}),
      motorsConfiguration: this.fb.control({value: '', disabled: this.isReadOnly}),
      motorPosition: this.fb.control({value: '', disabled: this.isReadOnly}),
      thrusterOption: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipHours: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipHoursDate: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipAddInfo: this.fb.control({value: '', disabled: this.isReadOnly}),
    });
  }
  fillForm(): void {
    const commissioningDate =  moment(this.equipmentCur.commissioningDate).format(this.dateFormatMoment);
    const equipHoursDate =  moment(this.equipmentCur.equipHoursDate).format(this.dateFormatMoment);
    if (this.isBoat) {

      this.homeAnchorageData = {
        typeLocation: 'homeAnchorage',
        dataLocation : {
          portId : this.equipmentCur.equipBoatHomeport,
          dataFields: undefined,
        }
      };
      this.mooringData = {
        typeLocation: 'mooring',
        dataLocation : {
          portId : this.equipmentCur.equipBoatAnchorage,
          dataFields: JSON.parse(this.equipmentCur.equipBoatBerthing)
        }
      };
      this.winteringData = {
        typeLocation: 'wintering',
        dataLocation : {
          portId : this.equipmentCur.equipBoatWintering,
          dataFields: JSON.parse(this.equipmentCur.equipBoatStorage)
        }
      };
      this.isMotorBoat = false;
      for (const curProp of this.propulsionsList) {
        if (curProp.propulsionName === this.equipmentCur.equipBoatPropulsion) {
          this.isMotorBoat = (curProp.propulsionType === 'motor');
          this.equipmentForm.controls.propulsion.setValue(this.translateService.instant('WORK_EQUIPMENT.' + curProp.propulsionName));
          break;
        }
      }

      if (this.isMotorBoat)  {
        this.equipmentForm.controls.motorsConfiguration.setValue(
                            this.translateService.instant('WORK_EQUIPMENT.' + this.equipmentCur.equipBoatConfiguration));
      }
      this.equipmentForm.controls.equipBoatManeuvring.setValue(this.equipmentCur.equipBoatManeuvring);
    }
    this.equipmentForm.controls.equipName.setValue(this.equipmentCur.equipName);
    this.equipmentForm.controls.equipDesign.setValue(this.equipmentCur.equipDesign);
    this.equipmentForm.controls.commissioningDate.setValue(commissioningDate);
    this.equipmentForm.controls.equipBrand.setValue(this.equipmentCur.equipBrand);
    this.equipmentForm.controls.equipModel.setValue(this.equipmentCur.equipModel);
    this.equipmentForm.controls.equipYear.setValue(this.equipmentCur.equipYear);
    this.equipmentForm.controls.equipCountry.setValue(this.equipmentCur.equipCountry);
    this.equipmentForm.controls.equipImmat.setValue(this.equipmentCur.equipImmat);
    this.equipmentForm.controls.equipSN.setValue(this.equipmentCur.equipSN);
    this.equipmentForm.controls.equipHours.setValue(this.equipmentCur.equipHours);
    this.equipmentForm.controls.thrusterOption.setValue(this.equipmentCur.thrusterOption);
    this.equipmentForm.controls.equipHoursDate.setValue(equipHoursDate);
    this.equipmentForm.controls.equipAddInfo.setValue(this.equipmentCur.equipAddInfo);
    const equipJsonLocal = this.equipmentCur.equipJson;
    let jsonEquip: any;
    if ((equipJsonLocal !== undefined) && (equipJsonLocal !== null)) {
      const jsonData = JSON.parse(equipJsonLocal);
      jsonEquip = jsonData.jsonEquip;
    }

    if (this.isEngine && jsonEquip !== undefined) {
      const jsonMotor = (jsonEquip.motorJson ? jsonEquip.motorJson : jsonEquip);

      if ((jsonMotor.motorPosition !== undefined) && (jsonMotor.motorPosition !== '')) {
        this.isMotorBoat = true;
        this.equipmentForm.controls.motorPosition.setValue(
                        this.translateService.instant('WORK_EQUIPMENT.' + jsonMotor.motorPosition));
      }
      if (jsonMotor.thrusterOption !== undefined) {
        this.equipmentForm.controls.thrusterOption.setValue(jsonMotor.thrusterOption);
        this.isThusterMotor = jsonMotor.thrusterOption;
        if ((this.isThusterMotor ) && (jsonMotor.motorPosition !== undefined)) {
          this.equipmentForm.controls.motorPosition.setValue(
                        this.translateService.instant('WORK_EQUIPMENT.' + jsonMotor.motorPosition));
        }
      }
      if ((!this.isThusterMotor ) && (jsonMotor.equipBoatConfiguration !== undefined)) {
        // this.equipmentForm.controls.equipBoatConfiguration.setValue(jsonMotor.equipBoatConfiguration);
        this.isThusterMotor = false;
        if (jsonMotor.equipBoatConfiguration === 'single installation') {
          this.isSingleMotor = true;
        } else {
          this.isSingleMotor = false;
          if (jsonMotor.motorPosition !== undefined) {
            this.settingMotorConfig(jsonMotor.equipBoatConfiguration, jsonMotor.motorPosition );
          }
        }

      }

    }
    if (this.isTrailer) {
      const jsonTrailer = jsonEquip.trailerJson;
      if (jsonTrailer !== undefined) {
        this.equipmentForm.controls.equipHitch.setValue(jsonTrailer.equipHitch);
        this.equipmentForm.controls.equipAxles.setValue(jsonTrailer.equipAxles);
        this.equipmentForm.controls.equipTires.setValue(jsonTrailer.equipTires);
        this.equipmentForm.controls.equipRims.setValue(jsonTrailer.equipRims);
        this.equipmentForm.controls.equipNuts.setValue(jsonTrailer.equipNuts);
        this.getHitchesSizes(jsonTrailer.equipHitch, jsonTrailer.equipHitchSize);
      }
    }
    if (this.isEngine) {
      this.isMotorBoat = true;
      if (this.mainEquipment !== undefined) {
        if ((this.mainEquipment.equipBoatManeuvring !== undefined)
            &&  (this.mainEquipment.equipBoatManeuvring)) {
          // this.thrustersConfigList = this._wksCentralService.getThrustersconfigList();
          this.isThrusterPossible = true;
        } // end test maneuvring
        if ((this.mainEquipment.equipBoatPropulsion !== undefined)
          && (this.mainEquipment.equipBoatPropulsion !== 'sails')
          &&  (this.mainEquipment.equipBoatConfiguration !== undefined)) {
            if (this.mainEquipment.equipBoatConfiguration === 'single installation') {
              this.isSingleMotor = true;
            } else {
              this.isSingleMotor = false;
              // this.motorsConfigList = this._wksCentralService.getMotorsconfigList();
              for (const configMotors of this.motorsConfigList) {
                if (configMotors.configName === this.mainEquipment.equipBoatConfiguration) {
                  this.positionsMotorList = configMotors.positions;
                  break;
                }
              }
            }
        } // end test propulsion
      } // end test mainEquipment
    } // test is Engine
  }
  settingMotorConfig($event: any, selectedValue: string): void {
    if (!this.isBoat) {
      return;
    }
    if ($event === 'single installation') {
      this.isSingleMotor = true;
    } else {
      this.isSingleMotor = false;
      for (const configMotors of this.motorsConfigList) {
        if (configMotors.configName === $event) {
          this.positionsMotorList = configMotors.positions;
          if (selectedValue !== undefined) {
            this.equipmentForm.controls.motorPosition.setValue(selectedValue);
          }
          break;
        }
      }
    }
  }
  getHitchesSizes(hitchNameArg: string, valueSelected: string): void {
    let measurementLocal = 'METRIC';
    if (!this.isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    this.hitchSizes = [];
    let isFound = false;
    for (const hitchCur of this.trailerOptions.hitches) {
      if (hitchCur.name === hitchNameArg ) {
        for (const sizesCur of hitchCur.sizes) {
          if (sizesCur.measurement === measurementLocal) {
            this.hitchSizes = [...sizesCur.list];
            isFound = true;
            break;
          }
        }
      }
      if (isFound) {
        this.hitchSizes.splice(0, 0, '');
        break;
      }
    }
    if (valueSelected !== undefined) {
      this.equipmentForm.controls.equipHitchSize.setValue(valueSelected);
    }

  }
}
