// https://stackblitz.com/edit/angular-crypto?file=src%2Fapp%2Fapp.component.html
// https://stackoverflow.com/questions/45068925/how-to-use-cryptojs-with-angular-4

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  constructor() { }

  encryptUsingAES256(token: string, request: string): string {
    let _key = CryptoJS.enc.Utf8.parse(token);
    let _iv = CryptoJS.enc.Utf8.parse(token);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(request), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
  
  decryptUsingAES256(token: string, encrypted: string): string {
    let _key = CryptoJS.enc.Utf8.parse(token);
    let _iv = CryptoJS.enc.Utf8.parse(token);

    return CryptoJS.AES.decrypt(
      encrypted, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
  }
}
