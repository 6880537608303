<router-outlet></router-outlet>

<mat-toolbar class="homeTheme">

  <button *ngIf="entityLoaded" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <span mat-menu-item >
      <span>
        <!--<mat-icon style="margin-right: -1px;">language</mat-icon>-->
        {{ 'COMMUN.MENU.LANGUAGE_DETECTED' | translate }} : <b>{{ browserLang | translate }}</b> 
      </span>
    </span>
    <button mat-menu-item [matMenuTriggerFor]="langs">{{ 'COMMUN.MENU.LANGS' | translate }}</button>
    <button mat-menu-item (click)="toggleDarkTheme($event)">
        <span *ngIf="themeIsDark" >{{ 'COMMUN.THEME.LIGTH'| translate }}</span>
        <span *ngIf="!themeIsDark" > {{ 'COMMUN.THEME.DARK' | translate }}</span>
    </button>
    <span mat-menu-item >
      <span>
          <p> Version FE {{ versionFE }} / BE {{ versionBE }} </p> 
      </span>
  </span>
  </mat-menu>
  <mat-menu #langs="matMenu">
    <button *ngFor="let lang of translate.getLangs()" mat-menu-item (click)="translate.use(lang); settingInputDirection(lang);">
      {{ 'COMMUN.MENU.' + lang | translate }}
    </button>
  </mat-menu>
  <div style="margin-left: 20px;">
    <mat-button-toggle-group style="border-top-width: 0px;border-left-width: 0px;border-bottom-width: 0px;border-right-width: 0px;">
      <button *ngFor="let langCur of usersLanguages" mat-icon-button 
        (click)="translate.use(langCur.langFile); settingInputDirection(langCur.lang); settingLangsButtons(langCur.langFile)"
            [ngStyle]="{'display': langCur.langDisplay}"  >
        <span class="flag-icon flag-icon-{{langCur.langFlag}} flag-icon-squared"></span>
        <!-- [hidden]="langCur.langHidden"-->
      </button>
    </mat-button-toggle-group>
  </div>
  <span class="flexExpand"></span>
  <span *ngIf="corporateName && !wksLogoHomeTitle">
    {{ corporateName }}
  </span>
  <span *ngIf="wksLogoHomeTitle">
    <img [src]="logoTitleToShow | safeHtml: 'resourceUrl' " style="width: 200px; height: 50px;" 
                            [ngStyle]="{'width': wksLogoHomeTitle.width, 'height': wksLogoHomeTitle.height }">
  </span>
  <span class="flexExpand"></span>
  <span (click)="returnHome()" >NautikoNWS</span>

</mat-toolbar>

<div >
  
    <app-appointment-form *ngIf="isAppointment && entityLoaded" 
                      [wksEntity]="wksEntity" 
                      [countryFmt]="countryFmt" 
                      [wksLogoHome]="wksLogoHome" [wksEntityParams]="wksEntityParams"></app-appointment-form>

    <app-login *ngIf="doAuthenticateUser || doLoginAccount "  
        [optionItem]="optionItem"
        (returnData)="loginAuthenticateAccount($event)"
        (loginEvent)="loginResult($event)"></app-login>

    <app-planning *ngIf="displayPlanning && entityLoaded" style="height: 80%;"
            [wksEntity]="wksEntity" 
            [countryFmt]="countryFmt" 
            [wksLogoHome]="wksLogoHome" 
            [wksEntityParams]="wksEntityParams"></app-planning>

    <app-wks-home *ngIf="displayWorkHome && entityLoaded"
      [displayWksHome]="displayWksHome" 
      (optionEvent)="loginAccount($event)"></app-wks-home>

    <app-wks-appointment-detail *ngIf="displayAppointmentDetail && entityLoaded"
        [displayApptmntDetail]="displayApptmntDetail" 
        (returnData)="returnAppointmentDetail($event)">
    </app-wks-appointment-detail>

    <app-create-account *ngIf="doCreateAccount && entityLoaded"
      (returnData)="returnCreate($event)" ></app-create-account>

    <app-update-pwd *ngIf="changePassword"
      [optionItem]="optionItem"
      (returnData)="updatePwdEvent($event)"></app-update-pwd>
<!-- displayQuotation -->
    <app-quotation-waiting *ngIf="displayQuotation"
      [statutCall]="'quotes'"
      [encodedReference]= "encodedReference"
      [wksEntity] = "wksEntity"
    ></app-quotation-waiting>


</div>

