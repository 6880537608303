import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Page404Component } from './components/common/pages/page404/page404.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PlanningComponent } from './components/job/planning-card/planning/planning.component';
import { CreateAccountComponent } from './components/job/wks-appointment/account/create-account/create-account.component';
import { UpdatePwdComponent } from './components/job/wks-appointment/account/update-pwd/update-pwd.component';
import { WksHomeComponent } from './components/job/wks-appointment/wks-home/wks-home.component';
import { AppComponent } from './app.component';



export const routes: Routes = [
  /*{ path: '**', component: Page404Component },*/
  /*{ path: '', redirectTo: 'appHome', pathMatch: 'full' },*/
  { path: 'login', component: LoginComponent },
  { path: 'planning', component: PlanningComponent },
  { path: 'createAccount', component: CreateAccountComponent },
  { path: 'wksHome', component: WksHomeComponent },
  { path: 'appHome', component: AppComponent },
  { path: '404', component: Page404Component },
  { path: 'updatePwd:/itemOption', component: UpdatePwdComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
