import {Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';

import {environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import {CalendarEventBdd } from '../models/shared.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private calObject: any;

  constructor(private httpClient: HttpClient, private authService: AuthService ) {}

  public setCalObject(calObjectArg: any): void {
    this.calObject = calObjectArg;
  }
  public getCalObject(): any {
    return this.calObject ;
  }

  getCalendarEvent(eventId: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/wks/jobs/getCalendarEvent/?` + 'eventId=' + eventId;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByType(stdEntity: string, calAppli: string, calType: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByType/?`
                                  + 'stdEntity=' + stdEntity + '&calAppli=' + calAppli + '&calType =' + calType;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActor(stdEntity: string, calAppli: string, calType: string, calActor: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActor/?`
                                  + 'stdEntity=' + stdEntity
                                  + '&calAppli=' + calAppli
                                  + '&calType =' + calType
                                  + '&calActor =' + calActor;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByLinkId(stdEntity: string, calAppli: string, calType: string, calLinkId: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByLinkId/?`
                                  + 'stdEntity=' + stdEntity
                                  + '&calAppli=' + calAppli
                                  + '&calType =' + calType
                                  + '&calLinkId =' + calLinkId;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActors(stdEntity: string, calActors: string, entityTz: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/planning/getEventsByActors/?`
                                  + 'stdEntity=' + stdEntity
                                  + '&calActors=' + calActors
                                  + '&entityTz=' + entityTz;
    // urlService = CommonMethods.cleanURLString(urlService);
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActorsDates( stdEntity: string, calActors: string, startDate: Date,
                          endDate: Date, entityTz: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
    const urlService = `${environment.baseUrl}/getEventsByActorsDate/?`
                                  + 'stdEntity=' + stdEntity
                                  + '&calActors=' + calActors
                                  + '&startDate=' + startDate
                                  + '&endDate=' + endDate
                                  + '&entityTz=' + entityTz;
    // urlService = CommonMethods.cleanURLString(urlService);
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  saveWksCalendar(eventCur: CalendarEventBdd, entityTz: string, action: string,
                  actorEmail: string): Observable<HttpResponse<CalendarEventBdd>> {

    const urlCur = `${environment.baseUrl}/planning/wksCalendar/save/?`
                                        + 'entityTz=' + entityTz
                                        + '&action=' + action
                                        + '&actorEmail=' + actorEmail;

    const body = JSON.stringify(eventCur);
    const localHttpHeaders: HttpHeaders  = this.authService.getHeaders();
    return this.httpClient
      .put<CalendarEventBdd>(urlCur, body, {
        headers: localHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  // tslint:disable-next-line:typedef
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}

