<span  *ngIf="!checkEquipmentsIsLoaded" style="background-color: transparent!important">
    <mat-progress-spinner  [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
    <br>
    <span class="blink_text">Please wait...</span>
</span>
<div *ngIf="checkEquipmentsIsLoaded"  mat-dialog-title class="stdTheme" style="margin: 0px!important">
    <!--
    <span  *ngIf="!checkEquipmentsIsLoaded" >
        <mat-progress-spinner [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
        <br>
        <span class="blink_text">Please wait...</span>
    </span>
    -->
    <mat-toolbar  class="stdTheme">
        <mat-toolbar-row style="height: 30px" >
          <button mat-icon-button (click)="toggleSidenav()" fxShow="true" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
          </button>
          <span class="titleWork" style="margin: auto;">{{ workCur.workRef }} {{workCur.workObject}} ({{itemEquip.equipDesign | translate}})</span>
        </mat-toolbar-row>
        <mat-toolbar-row style="height: 30px" >
            <div fxShow="true" fxHide.lt-md *ngIf="checkEquipments !== undefined && checkEquipments.length > 0">
                <mat-button-toggle-group #group="matButtonToggleGroup" [value]="itemEquip" (change)="choose('checkEquip', group.value)" >
                    <mat-button-toggle class="workCardButton"
                                [value]="itemCheck" *ngFor="let itemCheck of checkEquipments; let i = index" [checked]="i === 0">
                                    {{ itemCheck.equipDesign | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<mat-dialog-content *ngIf="checkEquipmentsIsLoaded"  class="stdTheme" style="padding: 0px!important; margin: 0px!important">

    <div >

        <mat-sidenav-container fxFlexFill (backdropClick)="toggleBackdropSidenav()" class="stdTheme">
          <mat-sidenav #sidenav>
            <mat-nav-list>
              <!--<a class="stdTheme" (click)="choose('')" mat-list-item>{{ 'WORK_CARD.MENU.CLOSE' | translate }}</a>-->
              <div *ngIf="checkEquipments !== undefined && checkEquipments.length > 0">
                <div *ngFor="let item of checkEquipments">
                    <a class="stdTheme" 
                        mat-list-item 
                        [ngClass]="{
                            'item-selected': item.isSelected,
                            'stdTheme': !item.isSelected
                            }"
                        (click)="choose('checkEquip', item)">{{ item.equipDesign | translate }} 
                        <i style="margin-left: 10px;" *ngIf="item.isSelected" class="fa fa-chevron-right"></i>
                    </a>
                    
                </div>
              </div>
            </mat-nav-list>
          </mat-sidenav>
          <mat-sidenav-content fxFlexFill class="stdTheme" style="overflow: hidden!important;">
            <div [ngSwitch] = "itemChoose">

                <div *ngSwitchCase="'checkEquip'">  
                    <ng-container [ngTemplateOutlet]="checkEquipTemplate"  ></ng-container>
                </div>
                <div *ngSwitchDefault>
                    <ng-container [ngTemplateOutlet]="checkEquipTemplate"  [ngTemplateOutletContext]="{value:'Say Hello'}"></ng-container>
                </div>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="checkEquipmentsIsLoaded" style="margin-bottom: 0px;padding-top: 0px; padding-bottom: 0px" >
    <button  class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="closeModal()" >{{'COMMUN.BUTTON.CLOSE' | translate}}</button>
    <div fxFlex></div>
    <button  *ngIf="isOkToValid" class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="validData()" >{{'COMMUN.BUTTON.SUBMIT' | translate}}</button>
</mat-dialog-actions>

<ng-template let-value="value" #checkEquipTemplate>
    <div *ngIf="isCheckLoaded">
        <app-tasks-checklist
            [ihmOptions]="ihmOptionsCkeck"
            [statutCall]="''" 
            [tasksActionsArgs]="tasksActionsArgs"
            [itemEquip]="itemEquip"
            (dataOut)="inputData($event)"
        ></app-tasks-checklist>
    </div>
</ng-template>

