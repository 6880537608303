
    <mat-card  class="wkscard stdTheme"  class="wkscard stdTheme" layout="column" flex 
                style="width: 100%; margin-top: 0px; margin-bottom: 0px;padding: 0px;" >

        <!-- <form [formGroup]="equipmentForm" (click)="initDpBirthDay($event)" >-->
        <form [formGroup]="workForm" >
            <mat-card-content class="wkscard" style="height: 55vh!important">
                <div class="flex-row row" style= "margin: 0px;">
                    <div class="col-5">
                        <mat-form-field class="wksInput form-group" appearance="standard">
                            <mat-label>{{ 'workObject' | translate }}</mat-label>
                            <input id="workObject" #workObject class="form-control inputTheme" 
                                    formControlName="workObject" required
                                    (keyup)="onKeyChangeObject($event)"
                                        matInput placeholder="{{ 'workObject' | translate }}">
                        </mat-form-field>
                    </div> 
                </div> 
                <div class="flex-row row" style= "margin: 0px;"> 
                    <div class="col-4" >
                        <mat-form-field class="wksInput form-group" appearance="standard">
                            <mat-label>{{ 'workPrevious' | translate }}</mat-label>
                            <mat-select id="workPrevious" #workPrevious  formControlName="workPrevious" 
                                    panelClass="mdiselectcontent" ngDefaultControl
                                    (selectionChange)="onChangeworkPrevious(workPrevious.value)" >
                                <mat-option class="selectOptionsTheme" *ngFor="let otherworkCur of wksOthersWorks" 
                                    [value]="otherworkCur.value"  >{{otherworkCur.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>  
                </div> 
                <div class="flex-row row" style= "margin: 0px;"> 
                    <div class="col-3">
                        <mat-form-field class="wksInput form-group" appearance="standard">
                            <mat-label>{{ 'workTicket' | translate }}</mat-label>
                            <input id="workTicket" #workTicket class="form-control inputTheme" 
                                    formControlName="workTicket" maxlength="25"
                                        matInput placeholder="{{ 'workTicket' | translate }}"
                                        (keyup)="onChangeField('workTicket', workTicket.value)">
                        </mat-form-field>
                    </div>  
                </div>
                
                <div class="flex-row row" style="margin: 0px;padding: 0px;padding-left: 10px; margin-bottom: 10px; margin-top: 10px">
                    <div  class="container-fluid stdTheme" >
                        <app-location-wks
                            [applicationCall]="'workIntervention'" 
                            [choiceLocation]="false" 
                            [dataLocationEquip]="undefined"
                            [dataLocationArg]="dataLocationWork"
                            [readOnlyData]="isReadOnly" 
                            (dataOut)="dataOutLocation($event)">
                        </app-location-wks>
                    </div>
                </div>
            </mat-card-content>
        </form>
    </mat-card>

